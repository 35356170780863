import { Environment } from 'config';
import request from 'utils/request';
import axios from 'axios';
import * as Types from '../types';
import store from 'redux/store';
import { showToastAction } from 'components/atoms/toast_message';
import _ from 'lodash';
import { IDashboard } from 'components/compounds/dashboard_builder/plugins/types';
// import {} from 'co'

interface ICrateDashboardInput extends IDashboard { }

export const createDashboard = async (
    input: ICrateDashboardInput
): Promise<Types.ResponseOutput> => {
    const result: any = await request(
        'post',
        `${Environment.API_BASE_URL}/api/dashboard`,
        {
            dashboardConfig: input
        }
    );

    let output: Types.ResponseOutput = {
        message: result.data.message,
        data: result.data.data,
        status: result.data.status,
    };
    return output;
};

export const getDashboardsConfig = async (): Promise<Types.ResponseOutput> => {
    const result: any = await request(
        'get',
        `${Environment.API_BASE_URL}/api/dashboard`
    );

    let output: Types.ResponseOutput = {
        message: result.data.message,
        data: result.data.data,
        status: result.data.status,
    };
    return output;
};

interface IDashboardByIDOutput extends Types.ResponseOutput {
    data: {
        _id: string;
        dashboardConfig: IDashboard;
        userID: string;
    };
}

export const getDashboardByID = async (
    dashboardid: string
): Promise<IDashboardByIDOutput> => {
    const result: any = await request(
        'get',
        `${Environment.API_BASE_URL}/api/dashboard/${dashboardid}`
    );

    let output: IDashboardByIDOutput = {
        message: result.data.message,
        data: result.data.data,
        status: result.data.status,
    };
    return output;
};

interface IEditDashboardInput {
    dashboardid: string;
    dashboardData: IDashboard;
}

export const editDashboard = async (
    input: IEditDashboardInput
): Promise<Types.ResponseOutput> => {
    const result: any = await request(
        'patch',
        `${Environment.API_BASE_URL}/api/dashboard/${input.dashboardid}`,
        {
            dashboardConfig: input.dashboardData,
        }
    );

    let output: Types.ResponseOutput = {
        message: result.data.message,
        data: result.data.data,
        status: result.data.status,
    };
    return output;
};
