import { LanguagesEnum } from '../../config/languages';
import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import {geoJsonMapDefaultParser} from 'components/molecules/leaflet_map';
import * as NumberFormattingUtils from 'utils/format_number';
import * as dummy_data from 'components/molecules/leaflet_map/fake_data/dummy_data.json';
import { Layer } from 'leaflet';
import request from 'utils/request';
import Environment from 'config/environment';

const CONFIGURATION = {};

export const Translations = {
  add_modal_btn_submit: {
    [LanguagesEnum.English]: 'Submit',
    [LanguagesEnum.Greek]: 'Υποβολή',
  },
  add_modal_btn_cancel: {
    [LanguagesEnum.English]: 'Cancel',
    [LanguagesEnum.Greek]: 'Ακύρωση',
  },
  add_modal_title: {
    [LanguagesEnum.English]: 'Add Graph',
    [LanguagesEnum.Greek]: 'Προσθήκη γραφήματος',
  },
};

const OptionsGraphType1 = () => ({
  legend: {
    display: false,
    align: 'start',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      color: function (context: any) {
        return context.dataset.backgroundColor;
      },
      font: function (context: any) {
        var w = context.chart.width;
        return {
          size: w < 512 ? 10 : 12,
          weight: 'bold',
          // marginTop: 5,
        };
      },
      formatter: function (value: any, context: any) {
        return NumberFormattingUtils.formatNumberWithCommas(value);
      },
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        // barThickness: 65, // number (pixels) or 'flex'
        maxBarThickness: 65, // number (pixels)
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
        ticks: {
          suggestedMax: 70000,
          display: true,
          // max: 70000,
          callback: function (label: any, index: any, labels: any) {
            return NumberFormattingUtils.formatNumberWithCommas(label);
          },
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});

const OptionsGraphType2 = (max: number) => ({
  legend: {
    display: true,
    align: 'center',
    position: 'bottom',
    labels: {
      usePointStyle: true,
      fontColor: '#333',
      // padding: 50,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      color: function (context: any) {
        return context.dataset.backgroundColor;
      },
      font: function (context: any) {
        var w = context.chart.width;
        return {
          size: w < 512 ? 12 : 14,
          weight: 'bold',
        };
      },
      formatter: function (value: any, context: any) {
        return NumberFormattingUtils.formatNumberWithCommas(value);
      },
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        // stacked: true,
        gridLines: {
          display: false,
        },
        // https://stackoverflow.com/questions/28716464/hiding-labels-on-y-axis-in-chart-js
        // barThickness: 65, // number (pixels) or 'flex'
        maxBarThickness: 40, // number (pixels)
      },
    ],
    yAxes: [
      {
        // stacked: true,
        ticks: {
          display: false,
          suggestedMax: max || 70000,
          callback: function (label: any, index: any, labels: any) {
            return `${label} %`;
          },
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});

export const DashboardBuilderConfigRightSide: IGridConf[] = [
  {
    width: 12,
    type: ComponentTypeEnum.chart,
    props: {
      data: [
        {
          data: [
            59044.5,
            7688.0,
            12662.0,
            367.0,
            12977.0,
            6467.0,
            395.0,
            1274.0,
            16642.0,
            1518.0,
          ],
          label: '',
          format: {
            label: 'Dataset 1',
            fill: false,
            borderColor: '#1e769c',
            lineTension: 0.1, // make the lines without radius
            backgroundColor: 'blue',
            pointBorderColor: '#1e769c',
            pointBackgroundColor: '#1e769c',
            pointHoverBackgroundColor: '#1e769c',
            pointHoverBorderColor: '#1e769c',
          },
        },
      ],
      labels: [
        '1play bundle',
        '2play bundle',
        '3play bundle',
        '4play bundle',
        'Footbal plan A',
        'Footbal plan B',
        'TV plan L',
        'TV plan M',
        'TV plan S',
        'Wifi service',
      ],
      chartType: ChartTypesEnum.COLUMN,
      options: OptionsGraphType1(),
      title: 'Overview',
    },
    fetch: null,
  },
  {
    width: 12,
    type: ComponentTypeEnum.chart,
    // height: 300,
    props: {
      data: [
        {
          data: [56099, 7688, 12662, 367, 0, 0, 0, 0, 0, 0],
          label: 'Yes',
          format: {
            label: 'Yes',
            fill: false,
            backgroundColor: 'red',
            borderColor: '#1e769c',
            lineTension: 0.1, // make the lines without radius
            pointBorderColor: '#1e769c',
            pointBackgroundColor: '#1e769c',
            pointHoverBackgroundColor: '#1e769c',
            pointHoverBorderColor: '#1e769c',
          },
        },
        {
          data: [2945, 0, 0, 0, 12977, 6467, 395, 1274, 16642, 1518],
          label: 'No',
          format: {
            label: 'No',
            fill: false,
            backgroundColor: 'green',
            borderColor: '#1e769c',
            lineTension: 0.1, // make the lines without radius
            pointBorderColor: '#1e769c',
            pointBackgroundColor: '#1e769c',
            pointHoverBackgroundColor: '#1e769c',
            pointHoverBorderColor: '#1e769c',
          },
        },
      ],
      // labels: ['Y', 'N'],
      labels: [
        '1play bundle',
        '2play bundle',
        '3play bundle',
        '4play bundle',
        'Footbal plan A',
        'Footbal plan B',
        'TV plan L',
        'TV plan M',
        'TV plan S',
        'Wifi service',
      ],
      chartType: ChartTypesEnum.COLUMN,
      options: OptionsGraphType2(70000),
      title: 'KPI',
    },
    fetch: null,
  },
];

export const DashboardBuilderConfigLeftSide: IGridConf[] = [
  {
    width: 12,
    type: ComponentTypeEnum.mapGeo,
    props: {
      height: '85vh',
      scrollWheelZoom: true,
      geoJsonProps: {
        data: dummy_data.features as any,
        // data: dummy_data.features as any,
        // onEachFeature: (element: any, layer: Layer) => {
        //   const name = element?.properties?.NAME;
        //   const confirmedText = element?.properties?.NOTES;
        //   layer.bindPopup(`${name} ${confirmedText}`);
        //   layer.on({
        //     click: (event) => {
        //       try {
        //         event.target.setStyle({
        //           color: 'green',
        //           fillColor: 'blue',
        //           fillOpacity: 1,
        //         });
        //       } catch (ex) {}
        //     },
        //   });
        // },
      },
    },
    fetch: null,
    // async () => {
    //   let result = await request(
    //     'post',
    //     `${Environment.API_BASE_URL}/api/users/mapData`
    //   );
    //   console.log("Resukt:", result.data.data);
    //   return geoJsonMapDefaultParser(result.data.data);
    // },
  },
];

export default CONFIGURATION;
