import _ from 'lodash';
import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom'; // import the react-router-dom components
import NavigationConfig, { Appbars } from '.';
import { RequireAuth, WithNavbar } from '../middlewares';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import { Roles } from '../../config/authRoles';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CachedIcon from '@material-ui/icons/Cached';
import Dialpad from '@material-ui/icons/Dialpad';
import Apartment from '@material-ui/icons/Apartment';

import * as ArrayUtils from 'utils/arrays';

interface IProps {
  routes: Routes[];
}

interface Routes {
  exact: boolean;
  path: string;
  component: any;
  roles: Array<string | number>;
  // any | Roles[];
}

const StackNavigator = ({ routes }: IProps) => {
  // console.log('Stack naviator...');
  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          exact={route.exact}
          path={route.path}
          render={() => (
            <RequireAuth Component={route.component} roles={route.roles} />
          )}
        />
      ))}
    </Switch>
  );
};

export const MainRoutes = () => {
  return (
    <main>
      <Switch>
        {Appbars.map((route, i) => (
          <Route
            key={i}
            exact={false}
            path={'/'}
            render={() => (
              <WithNavbar
                include={route.include}
                exclude={route.exclude}
                select={route.select}
                Component={route.component}
              />
            )}
          />
        ))}
      </Switch>
    </main>
  );
};

/** CHANGE THIS TO PUT NEW ROUTES */
export const Routes = React.memo(() => {
  const location = useLocation();
  console.log('location', location);

  return (
    <main>
      <StackNavigator
        routes={[
          { ...NavigationConfig.dashboardV2Page(), exact: true },
          { ...NavigationConfig.profilePage(), exact: true },
          { ...NavigationConfig.datatablesPage(), exact: true },
          { ...NavigationConfig.securityPage(), exact: true },
          { ...NavigationConfig.registerPage(), exact: true },
          { ...NavigationConfig.registerNoAuthPage(), exact: true },
          { ...NavigationConfig.formsPage(), exact: true },

          { ...NavigationConfig.loginPage(), exact: true },
          { ...NavigationConfig.loginNoAuthPage(), exact: true },

          { ...NavigationConfig.mainPage(), exact: true },

          { ...NavigationConfig.forgotPasswordPage(), exact: true },
          { ...NavigationConfig.forgotPasswordNoAuthPage(), exact: true },

          { ...NavigationConfig.resetPasswordPage(), exact: true },
          { ...NavigationConfig.dashboardBuilderPage(), exact: true },
          { ...NavigationConfig.chartsPage(), exact: true },
          { ...NavigationConfig.mapsPage(), exact: true },
          { ...NavigationConfig.productPage(), exact: true },
          { ...NavigationConfig.userproductPage(), exact: true },
          { ...NavigationConfig.pdfexportPage(), exact: true },
          { ...NavigationConfig.scenarioPage(), exact: true },
          { ...NavigationConfig.establishmentPage(), exact: true },
          { ...NavigationConfig.groupPage(), exact: true },
          { ...NavigationConfig.combineScenarioPage(), exact: true },
          { ...NavigationConfig.userManagementPage(), exact: true },

          { ...NavigationConfig.rootPage(), exact: true }, // always last
        ]}
      />
      {
        <Box
          height="41px"
          position="fixed"
          width="100%"
          bottom="0"
          left="0"
          padding="10px 20px"
          marginTop="30px"
          bgcolor="#fff"
          fontFamily="Lato"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Hidden smDown>
            <Typography variant="body1">
              The project “Reducing the Consumption and Disposal of Single-use
              Plastics in the Tourism Industry in Cyprus, Greece and Malta” is
              funded by Iceland, Liechtenstein and Norway through the EEA and
              Norway Grants Fund for Regional Cooperation.
            </Typography>
          </Hidden>

          <Box display="flex" justifyContent="center" alignItems="center">
            <a href="https://twitter.com/SupmedP" target="blank">
              <img src="https://img.icons8.com/color/48/000000/twitter-squared.png" />
            </a>
            <a href="https://www.facebook.com/SUPMedProject/" target="blank">
              <img src="https://img.icons8.com/color/48/000000/facebook.png" />
            </a>
            <a href="https://www.supmed.eu" target="blank">
              <img src="https://img.icons8.com/color/48/000000/domain--v1.png" />
            </a>

            <a
              href="https://www.linkedin.com/in/supmed-project-b47b40203/?originalSubdomain=cy"
              target="blank"
            >
              <img src="https://img.icons8.com/color/48/000000/linkedin.png" />
            </a>
          </Box>
        </Box>
      }
    </main>
  );
});

interface ISubItems {
  path: string;
  title: string;
  component: React.FC;
  id: number;
  requiredRoles: Array<number | string>;
}
interface ISidebarConfig {
  title: string;
  path: string | null;
  Icon: React.FC;
  id: number;
  component: React.FC | null;
  subItems?: ISubItems[];
  requiredRoles: Array<number | string> | null;
}

export const SideBarConfig: ISidebarConfig[] = [
  // {
  //   title: 'Dashboards',
  //   path: null,
  //   Icon: () => <DashboardOutlinedIcon />,
  //   requiredRoles: NavigationConfig.dashboardBuilderPage().roles,
  //   id: 1,
  //   component: null,
  //   subItems: [
  //     {
  //       path: NavigationConfig.dashboardBuilderPage().path,
  //       title: 'Dashboard Builder',
  //       component: NavigationConfig.dashboardBuilderPage().component,
  //       id: 2,
  //       requiredRoles: NavigationConfig.dashboardBuilderPage().roles,
  //     },
  //     {
  //       path: NavigationConfig.dashboardV2Page().path,
  //       title: 'Dashboard V2',
  //       component: NavigationConfig.dashboardV2Page().component,
  //       id: 4,
  //       requiredRoles: NavigationConfig.dashboardV2Page().roles,
  //     },
  //   ],
  // },
  // {
  //   title: 'Settings',
  //   path: null,
  //   Icon: () => <SettingsOutlinedIcon />,
  //   requiredRoles: NavigationConfig.profilePage().roles,
  //   id: 2,
  //   component: null,
  //   subItems: [
  //     {
  //       path: NavigationConfig.profilePage().path,
  //       title: 'Profile',
  //       component: NavigationConfig.profilePage().component,
  //       id: 1,
  //       requiredRoles: NavigationConfig.profilePage().roles,
  //     },
  //     {
  //       path: NavigationConfig.securityPage().path,
  //       title: 'Security',
  //       component: NavigationConfig.securityPage().component,
  //       id: 2,
  //       requiredRoles: NavigationConfig.securityPage().roles,
  //     },
  //   ],
  // },
  // {
  //   title: 'Auth',
  //   path: null,
  //   Icon: () => <VpnKeyOutlinedIcon />,
  //   requiredRoles: NavigationConfig.loginNoAuthPage().roles,
  //   id: 3,
  //   component: null,
  //   subItems: [
  //     {
  //       path: NavigationConfig.loginNoAuthPage().path,
  //       title: 'Login',
  //       component: NavigationConfig.loginNoAuthPage().component,
  //       id: 1,
  //       requiredRoles: NavigationConfig.loginNoAuthPage().roles,
  //     },
  //     {
  //       path: NavigationConfig.registerNoAuthPage().path,
  //       title: 'Register',
  //       component: NavigationConfig.registerNoAuthPage().component,
  //       id: 2,
  //       requiredRoles: NavigationConfig.registerNoAuthPage().roles,
  //     },
  //     {
  //       path: NavigationConfig.forgotPasswordNoAuthPage().path,
  //       title: 'Forgot Password',
  //       component: NavigationConfig.forgotPasswordNoAuthPage().component,
  //       id: 3,
  //       requiredRoles: NavigationConfig.forgotPasswordNoAuthPage().roles,
  //     },
  //   ],
  // },
  // {
  //   title: 'Maps',
  //   path: NavigationConfig.mapsPage().path,
  //   requiredRoles: NavigationConfig.mapsPage().roles,
  //   Icon: () => <RoomOutlinedIcon />,
  //   id: 4,
  //   component: NavigationConfig.mapsPage().component,
  // },
  // {
  //   title: 'Datatables',
  //   path: NavigationConfig.datatablesPage().path,
  //   requiredRoles: NavigationConfig.datatablesPage().roles,
  //   Icon: () => <TableChartOutlinedIcon />,
  //   id: 5,
  //   component: NavigationConfig.datatablesPage().component,
  // },
  // {
  //   title: 'Forms',
  //   path: NavigationConfig.formsPage().path,
  //   requiredRoles: NavigationConfig.formsPage().roles,
  //   Icon: () => <ListAltOutlinedIcon />,
  //   id: 6,
  //   component: NavigationConfig.formsPage().component,
  // },
  // {
  //   title: 'Charts',
  //   path: NavigationConfig.chartsPage().path,
  //   requiredRoles: NavigationConfig.chartsPage().roles,
  //   Icon: () => <AssessmentOutlinedIcon />,
  //   id: 7,
  //   component: NavigationConfig.chartsPage().component,
  // },
  {
    title: 'Group',
    path: NavigationConfig.groupPage().path,
    requiredRoles: NavigationConfig.groupPage().roles,
    Icon: () => <Dialpad />,
    id: 1,
    component: NavigationConfig.groupPage().component,
  },
  {
    title: 'Establishment',
    path: NavigationConfig.establishmentPage().path,
    requiredRoles: NavigationConfig.establishmentPage().roles,
    Icon: () => <Apartment />,
    id: 2,
    component: NavigationConfig.establishmentPage().component,
  },
  {
    title: 'Scenario',
    path: NavigationConfig.scenarioPage().path,
    requiredRoles: NavigationConfig.scenarioPage().roles,
    Icon: () => <SettingsOutlinedIcon />,
    id: 3,
    component: NavigationConfig.scenarioPage().component,
  },
  {
    title: 'Product',
    path: NavigationConfig.productPage().path,
    requiredRoles: NavigationConfig.productPage().roles,
    Icon: () => <DashboardOutlinedIcon />,
    id: 4,
    component: NavigationConfig.productPage().component,
  },
  {
    title: 'User Management',
    path: NavigationConfig.userManagementPage().path,
    requiredRoles: NavigationConfig.userManagementPage().roles,
    Icon: () => <PeopleAltOutlinedIcon />,
    id: 5,
    component: NavigationConfig.userManagementPage().component,
  },
  // {
  //   title: 'User Product',
  //   path: NavigationConfig.userproductPage().path,
  //   requiredRoles: NavigationConfig.userproductPage().roles,
  //   Icon: () => <PeopleAltOutlinedIcon />,
  //   id: 7,
  //   component: NavigationConfig.userproductPage().component,
  // },
  // {
  //   title: 'Export',
  //   path: NavigationConfig.pdfexportPage().path,
  //   requiredRoles: NavigationConfig.pdfexportPage().roles,
  //   Icon: () => <PeopleAltOutlinedIcon />,
  //   id: 9,
  //   component: NavigationConfig.pdfexportPage().component,
  // },
];

function filterSubElements(
  subElements: ISubItems[],
  roles: Array<Roles>
): ISubItems[] {
  // return (
  //   subElements.filter(({ requiredRoles }) => requiredRoles.includes(roles)) ||
  //   []
  // );
  return (
    subElements.filter(({ requiredRoles }) =>
      ArrayUtils.arraysHasCommonElements(requiredRoles, roles)
    ) || []
  );
}

export const getNavbarConfig = (
  roles: Array<Roles> | null
): ISidebarConfig[] => {
  return (Array.isArray(roles)
    ? SideBarConfig.filter(
        ({ requiredRoles }) =>
          Array.isArray(requiredRoles) &&
          ArrayUtils.arraysHasCommonElements(requiredRoles, roles)
      )
    : []
  ).map((element) => ({
    ...element,
    subItems: Array.isArray(element.subItems)
      ? filterSubElements(element.subItems, roles || [])
      : element.subItems,
  }));
};
