import { LanguagesEnum } from '../../config/languages';

export const Translations = {
  form_field_firstname: {
    [LanguagesEnum.English]: 'FirstName',
    [LanguagesEnum.Greek]: 'Όνομα',
  },
  form_field_surname: {
    [LanguagesEnum.English]: 'Surname',
    [LanguagesEnum.Greek]: 'Επίθετο',
  },
  form_field_email: {
    [LanguagesEnum.English]: 'Email',
    [LanguagesEnum.Greek]: 'Email',
  },
  form_field_gender: {
    [LanguagesEnum.English]: 'Gender',
    [LanguagesEnum.Greek]: 'Φύλο',
  },
  form_field_nationality: {
    [LanguagesEnum.English]: 'Nationality',
    [LanguagesEnum.Greek]: 'Εθνικότητα',
  },
  form_field_role: {
    [LanguagesEnum.English]: 'Role',
    [LanguagesEnum.Greek]: 'Ρόλος',
  },
  form_field_birthdate: {
    [LanguagesEnum.English]: 'Birth Date',
    [LanguagesEnum.Greek]: 'Ημέρομηνία Γενεθλίων',
  },
  form_field_registereddate: {
    [LanguagesEnum.English]: 'Registered Date',
    [LanguagesEnum.Greek]: 'Ημέρομηνία Εγγραφής',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'Required Field',
    [LanguagesEnum.Greek]: 'Υποχρεωτικό Πεδίο',
  },
  form_submit: {
    [LanguagesEnum.English]: 'Update Detail',
    [LanguagesEnum.Greek]: 'Update Detail',
  },
};

const Configuration = {};

export default Configuration;
