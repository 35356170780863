import { IChartConfig, convert as chartConvert } from './chart';
import { IMapsConfig, convert as mapsConvert } from './maps';
import {
  IGridConf,
  ComponentTypeEnum,
  IChartsFetchData,
  IGeoJsonFetchData,
} from '../../configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import request from 'utils/request';
import _ from 'lodash';
import Environment from 'config/environment';
import * as StringUtils from 'utils/strings';
import {
  ILeafletMapProps,
  geoJsonMapDefaultParser,
} from 'components/molecules/leaflet_map';

/** Key Value Map */
export interface IGlobals {
  [key: string]: number | string;
}

export interface IDashboard {
  dashboardElements: IDashboardElement[];
  globals?: IGlobals;
  title: string;
}

export type TBuilderTypes = 'chart' | 'map' | 'statistic-card';
export type TBuilderSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface IDashboardElement {
  type: TBuilderTypes;
  config: IChartConfig | IMapsConfig;
  size: TBuilderSize;
  url: string;
  urlMethod?: 'GET' | 'POST';
}

export interface IChartData extends IChartsFetchData {
  // data: number[][];
  // labels: Array<string>;
}

// 'map.onClick' | 'map.zoom';
export enum EventsEnum {
  MAP_ONCLICK_VALUE = 'map.onClick.value',
  MAP_ONCLICK_KEY = 'map.onClick.key',
  MAP_ZOOM_VALUE = 'map.zoom.value',
  MAP_ZOOM_KEY = 'map.zoom.key',
}

export interface IMapsData extends IGeoJsonFetchData { }

export const parseVariablesToUrl = (str: string, globals: IGlobals): string => {
  let newStr = str;
  let globalsArr = Object.keys(globals);
  for (let globalName of globalsArr) {
    newStr = StringUtils.replaceElementWith(
      newStr,
      `{{${globalName}}}`,
      `${globals[globalName]}`
    );
  }
  // console.log("Parse String:", newStr);
  return newStr;
};

export interface IGlobal {
  name: EventsEnum | string;
  value: string | number;
}

export const convert = (
  data: IDashboard,
  handleUpdateGlobals: (newGlobals: IGlobals) => void
): IGridConf[] => {
  const globals = data.globals || {};
  let result: IGridConf[] = data.dashboardElements.map(
    ({ type, config, size, url, urlMethod }) => {
      let data: IGridConf = {
        width: size,
        type:
          type === 'chart'
            ? ComponentTypeEnum.chart
            : type === 'statistic-card'
              ? ComponentTypeEnum.statistic
              : type === 'map'
                ? ComponentTypeEnum.mapGeo
                : ComponentTypeEnum.chart,
        fetch: async () => {
          let data = null;
          try {
            let result = await request(
              (urlMethod?.toLowerCase() as any) || 'get',
              Environment.API_BASE_URL + parseVariablesToUrl(url, globals)
            );

            if (type === 'chart') {
              let arr: Array<any> = result.data.data;
              return fetchChartsParser(config as any, arr);
            } else if (type === 'map') {
              // console.log("INPUT::", result.data.data);
              // let _result = geoJsonMapDefaultParser(result.data.data);
              let _result = fetchMapsParser(config as any, result.data.data);
              return _result;
            } else if (type === 'statistic-card') {
            } else {
            }
          } catch (error) { }
          return data;
        },
        props:
          type === 'chart'
            ? chartConvert(config as IChartConfig)
            : type === 'map'
              ? mapsConvert(config as IMapsConfig, globals, handleUpdateGlobals)
              : chartConvert(config as IChartConfig),
      };
      return data;
    }
  );
  return result;
};

const fetchMapsParser = (config: IMapsConfig, fetchResult: any): IMapsData => {
  let result = geoJsonMapDefaultParser(fetchResult);
  config.geoJsonData = result;
  return result;
};

const fetchChartsParser = (
  config: IChartConfig,
  fetchResult: Array<any>
): IChartData => {
  let data: IChartData = {
    data: [],
    labels: [],
  };

  for (let element of fetchResult) {
    data.labels.push(_.get(element, config.x_axis_field, ''));
    for (let index in config.datasets) {
      if (!Array.isArray(data.data[index])) {
        data.data[index] = [];
      }
      let y = config.datasets[index].y_axis_field;
      data.data[index].push(parseFloat(element[y]));
    }
  }
  return data;
};
