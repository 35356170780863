import { makeStyles, Theme } from '@material-ui/core/styles';
import Configuration from './configuration';
import { LOGIN_REGISTER_BACKGROUND_COLOR } from 'styles/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    // background: theme.palette.primary.
  },
  image: {
    backgroundImage: `url(${Configuration.backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  containerBackground: {
    width: '100%',
    height: '100%',
    padding: '0 10px 40px 10px',
    backgroundColor: 'rgba(15, 46, 105, .85)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  img_logo: {
    marginBottom: 13,
    height: 50,
    width: 'auto',
  },
  img_main_logo: {
    marginBottom: 13,
    height: 80,
    width: 'auto',
  },
  img_logo_black: {
    marginBottom: 13,
    height: 50,
    width: 'auto',
    background: '#292F5F',
  },
  languageButton: {
    position: 'absolute',
    right: 20,
    top: 8,
  },
  registrationContainer: {
    height: '100%',
    overflow: 'auto',
    background: LOGIN_REGISTER_BACKGROUND_COLOR(theme),
  },
  title: {
    color: '#404756',
    fontWeight: 'bold',
    marginBottom: 100,
    fontSize: 28,
  },
}));

export default useStyles;
