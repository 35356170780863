import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_SCENARIO_LIST: string = typeCreatorV2(
  'ScenarioReducer',
  'SetScenarioList'
);

export const SET_ADD_SCENARIO: string = typeCreatorV2(
  'ScenarioReducer',
  'SetAddScenario'
);

export const SET_EDIT_SCENARIO: string = typeCreatorV2(
  'ScenarioReducer',
  'SetEditScenario'
);

export const SET_SCENARIO_DETAIL: string = typeCreatorV2(
  'ScenarioReducer',
  'SetScenarioDetail'
);
export const SET_SET_MODAL: string = typeCreatorV2(
  'ScenarioReducer',
  'SetModal'
);
export const SET_SELECTED_SCENARIO: string = typeCreatorV2(
  'ScenarioReducer',
  'SetSelectedScenario'
);
export const SET_SCENARIO_OPTION: string = typeCreatorV2(
  'ScenarioReducer',
  'SetScenarioOption'
);
export const SET_ESTABLISHMENTS_NAME: string = typeCreatorV2(
  'ScenarioReducer',
  'SetEstablishmentsName'
);
export const SET_COMBINE: string = typeCreatorV2(
  'ScenarioReducer',
  'SetCombine'
);
export const SET_ACTIVE_SCENARIO: string = typeCreatorV2(
  'ScenarioReducer',
  'SetActiveScenario'
);
export const SET_COMBINE_SCENARIOS: string = typeCreatorV2(
  'ScenarioReducer',
  'SetCombineScenarios'
);

export const DELETE_SCENARIO: string = typeCreatorV2(
  'ScenarioReducer',
  'DeleteScenario'
);

export const GET_USER_SCENARIO: string = typeCreatorV2(
  'ScenarioReducer',
  'GetUserScenario'
);
export const UPDATE_USER_SCENARIO: string = typeCreatorV2(
  'ScenarioReducer',
  'UpdateUserScenario'
);
export const RESET_USER_SCENARIO: string = typeCreatorV2(
  'ScenarioReducer',
  'ResetUserScenario'
);
