import { Environment } from '../../../config';
import request from '../../../utils/request';
import axios from 'axios';
import * as Types from '../types';
import store from '../../../redux/store';
import { showToastAction } from '../../../components/atoms/toast_message';
import _ from 'lodash';

/** authenticateUser Endpoint */
interface IAuthenticateInput {
  email: string;
  password: string;
}

interface IAuthenticateOutput_User {
  uuid: string;
  roles: Array<string | number>;
}

interface IAuthenticateOutput extends Types.ResponseOutput {
  data: {
    access_token: string;
    user: IAuthenticateOutput_User;
  };
}

export const authenticateUser = async (
  data: IAuthenticateInput
): Promise<IAuthenticateOutput> => {
  const result: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/oauth/login`,
    {
      email: data.email,
      password: data.password,
    }
  );

  // ? result.data.data.user.role.map(({ role_id }: any) => role_id)
  result.data.data.user.roles = Array.isArray(result.data.data?.user?.role)
    ? result.data.data.user.role.map((role_id: string | number) => role_id)
    : [];

  return result.data;
};

/** registerUser Endpoint */
export const registerUser = async () => {};

/** Refresh token */
interface IRefreshTokenInput {
  token: string;
}

interface IRefreshTokenOutput {
  email: string;
  password: string;
}

export const refreshToken = async (
  token: IRefreshTokenInput
): Promise<IRefreshTokenOutput> => {
  const result: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users/auth/access-token?token=${token}`
  );
  return result.data;
};

interface IForgotPassword {
  email: string;
}

export const forgotPassword = async (data: IForgotPassword): Promise<any> => {
  const result: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/oauth/forgot-password`,
    {
      email: data.email,
    }
  );
  return result.data;
};

interface IResetPasswordInput {
  password: string;
  confirm_password: string;
  token: string;
}
export const resetPassword = async (
  input: IResetPasswordInput
): Promise<Types.ResponseOutput> => {
  try {
    // console.log(input.token);
    // const instance = axios.create();
    const { data } = await axios({
      method: 'patch',
      url: `${Environment.API_BASE_URL}/api/oauth/change-password`,
      data: {
        password: input.password,
        confirm_password: input.confirm_password,
      },
      headers: {
        'x-access-token': input.token,
      },
    });

    let output: Types.ResponseOutput = {
      message: data.message,
      data: data.data,
      status: data.status,
    };
    return output;
  } catch (error) {
    showToastAction(
      store.dispatch,
      _.get(error, 'response.data.message', error.message),
      'error'
    );
    throw new Error(error.message);
  }
};

interface IChangePasswordInput {
  newPassword: string;
  confirmPassword: string;
  oldPassword: string;
}
export const changePassword = async (
  input: IChangePasswordInput
): Promise<Types.ResponseOutput> => {
  const result: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/oauth/change-password`,
    {
      password: input.newPassword,
      confirm_password: input.confirmPassword,
      old_password: input.oldPassword,
    }
  );

  let output: Types.ResponseOutput = {
    message: result.data.message,
    data: result.data.data,
    status: result.data.status,
  };
  return output;
};
