import { Button } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

export interface IButtonShareProps {
  children: string | React.ReactElement;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  style?: React.CSSProperties;
}

export default function ButtonShare(props: IButtonShareProps) {
  const classes = useStyles();

  const { children, style = {}, onClick } = props;

  return (
    <Button
    className={classes.button}
    style={style}
    onClick={onClick}
  >
    {children}
  </Button>
  );
}
