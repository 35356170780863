import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles';

const useStyles = makeStyles((theme) => ({
  titleLayout: {
    padding: 20,
    backgroundColor: Colors.APP_COLOR_HIGHLIGHT({ darkMode: false }),
    color: 'white',
  },
  cardLayout: {
    marginBottom: 10,
  },
}));

export default useStyles;
