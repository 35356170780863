import { Button } from '@material-ui/core';
import React from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import ModalActions from '../../../../redux/reducers/gl_modal_reducer/actions';
import Actions from '../../../../redux/reducers/user_management_reducer/actions';

import { ModalIDs } from '../../../../redux/reducers/gl_modal_reducer/types';
import useStyles from './styles';

interface IProps {
  rowIndex: number;
  data: string;
  dataid: string | number;
}

export default function ActionsBlock(props: IProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = (type: 'view' | 'edit') => {
    // dispatch(
    //   Actions.setModal({
    //     data: props.dataid,
    //     isOpen: true,
    //     type: type,
    //   })
    // );
  };
  function changeRole(id: string) {
    dispatch(Actions.fetchUserDetail({ id }));

    dispatch(Actions.setModal({ isOpen: true, type: 'roles', data: id }));
  }
  function changePassword(id: string) {
    dispatch(Actions.setModal({ isOpen: true, type: 'password', data: id }));
  }

  function handleVerify(id: string) {
    dispatch(Actions.updateUser({ id, emailConfirmed: true }));
  }

  return (
    <div>
      <Button
        className={classes.editButton}
        // variant="outlined"
        onClick={() => changeRole(props.dataid.toString())}
      >
        Change role
      </Button>
      <Button
        // variant="outlined"
        className={classes.viewButton}
        onClick={() => changePassword(props.dataid.toString())}
      >
        Add new password
      </Button>
      <Button
        // variant="outlined"
        className={classes.verifyButton}
        onClick={() => handleVerify(props.dataid.toString())}
      >
        Make user verified
      </Button>
    </div>
  );
}
