import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_GROUP_LIST: string = typeCreatorV2(
  'GroupReducer',
  'SetGroupList'
);

export const SET_ADD_GROUP: string = typeCreatorV2(
  'GroupReducer',
  'SetAddGroup'
);

export const SET_EDIT_GROUP: string = typeCreatorV2(
  'GroupReducer',
  'SetEditGroup'
);

export const SET_GROUP_DETAIL: string = typeCreatorV2(
  'GroupReducer',
  'SetGroupDetail'
);
export const SET_USERNAMES: string = typeCreatorV2(
  'GroupReducer',
  'SetUsernames'
);
export const SET_ESTABLISHMENTS_NAME: string = typeCreatorV2(
  'GroupReducer',
  'SetEstablishmentsName'
);
export const DELETE_GROUP: string = typeCreatorV2(
  'GroupReducer',
  'DeleteGroup'
);
