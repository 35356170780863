import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchEstablishmentList  */
interface IEstablishmentListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  name: string | null;
  address: string | null;
  users: string | null;
  admin: string | null;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IEstablishmentListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  name?: string;
}

export const fetchEstablishmentList = async (
  payload: IEstablishmentListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/establishment/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let _data: IEstablishmentListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const searchEstablishment = async (
  payload: IEstablishmentListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/establishment/searchByName?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}&name=${payload.name}`
  );

  let _data: IEstablishmentListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** Establishment Detail */
interface IEstablishmentDetailInput {
  id: string;
}

interface IEstablishmentDetailOutput extends Types.ResponseOutput {
  data: {
    name: string | null;
    address: string | null;
    users: string | null;
    admin: string | null;
  };
}

export const fetchEstablishmentDetail = async (
  payload: IEstablishmentDetailInput
): Promise<IEstablishmentDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/establishment/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add Establishment */
interface IEstablishmentAddInput {
  name: string | null;
  address: string | null;
  users: string | null;
  admin: string[] | null;
  group?: string | null;
}

interface IEstablishmentAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addEstablishment = async (
  payload: IEstablishmentAddInput
): Promise<IEstablishmentAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/establishment `,

    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit Establishment */
interface IEstablishmentEditInput {
  id: string;
  name: string | null;
  address: string | null;
  users: string | null;
  admin: string[] | null;
  group?: string | null;
}

interface IEstablishmentEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editEstablishment = async (
  payload: IEstablishmentEditInput
): Promise<IEstablishmentEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/establishment/${[payload.id]}`,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export const deleteEstablishment = async (
  payload: IEstablishmentDetailInput
): Promise<IEstablishmentDetailOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/establishment/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
export const fetchEstablishmentName = async (): Promise<IEstablishmentDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/establishment/fetch/establishment-name`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
