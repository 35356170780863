import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { useGetProductPersist } from 'redux/reducers/product_reducer/selectors';
import { SET_PRODUCT, SET_OPEN } from 'redux/reducers/product_reducer/types';
import { StateStatus } from 'redux/utils/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    container: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

export default function Header(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { status, data } = useGetProductPersist();

  function handleSearch(search: string) {
    if (search !== '') {
      const searchedProducts = data.items.filter((product: any) =>
        product.productCategory.toLowerCase().includes(search.toLowerCase())
      );
      dispatch({
        type: SET_PRODUCT,
        status: StateStatus.Success,
        data: {
          items: searchedProducts,
        },
      });
    } else {
      dispatch({
        type: SET_PRODUCT,
        status: StateStatus.Success,
        data: {
          items: data.items,
        },
      });
    }
  }

  return (
    <Paper component="div" className={classes.container}>
      <Paper component="form" className={classes.root}>
        <InputBase
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          className={classes.input}
          placeholder="Search Product by Product Category"
          inputProps={{ 'aria-label': 'Search Product by Product Category' }}
        />
        <SearchIcon />
      </Paper>
      <Button
        onClick={() =>
          dispatch({
            type: SET_OPEN,
            data: {
              isOpen: true,
              data: null,
              type: 'add',
            },
          })
        }
        variant="contained"
        color="primary"
      >
        Add New Product
      </Button>
    </Paper>
  );
}
