import { Box, Paper, Typography, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ButtonShare from 'components/app_common/button_share';
import Datatable from 'components/molecules/datatable';
import FormSearch from 'components/app_common/form_search';
import { Roles } from 'config/authRoles';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelectUserRoles } from 'redux/reducers/gl_auth_reducer/selectors';
import {
  default as Actions,
  default as ScenarioActions,
} from 'redux/reducers/scenario_reducer/actions';
import * as ScenarioReducer from 'redux/reducers/scenario_reducer/selectors';
import {
  useGetScenarioOption,
  useGetSelectedScenario,
} from 'redux/reducers/scenario_reducer/selectors';
import { TableColumns } from '../../configuration';
import ActionsBlock from './ActionsBlock';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';
import { TableDefaultFetchValues } from '../../configuration';

export default function DatatableList() {
  const result = ScenarioReducer.useGetScenarioList();
  const paging = result.data?.paging;
  const dispatch = useDispatch();
  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  const classes = useStyles();
  const selectedScenario = useGetSelectedScenario();
  const scenarioOptions = useGetScenarioOption();
  const roles = useSelectUserRoles();
  const [tableColumns, setTableColumns] = React.useState(TableColumns);

  React.useEffect(() => {
    const columns = TableColumns;

    //  if (roles.length !== 0 && roles.includes(Roles.EstablishmentAdmin)) {
    columns.unshift({
      label: 'Establishment',
      id: 'establishment',
      render: (rowIndex: number, cellValue: any, columnIndex: number) => (
        <React.Fragment>{cellValue?.name}</React.Fragment>
      ),
      inData: true,
    });
    //}
    //  if (roles.length !== 0 && roles.includes(Roles.GroupAdmin)) {
    columns.unshift({
      label: 'Group',
      id: 'group',
      render: (rowIndex: number, cellValue: any, columnIndex: number) => (
        <React.Fragment>{cellValue}</React.Fragment>
      ),
      inData: true,
    });
    //  }
    setTableColumns(_.uniqBy(columns, (item: any) => item.id));
  }, [roles]);

  const handleChangeSelectedScenario = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch(Actions.setSelectedScenario(`${event.target.value}`));
  };
  const onSearch = (value: string) => {
    console.log(value);
    if (value) {
      dispatch(
        Actions.searchScenario({
          sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
          page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
          pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
          order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          name: value,
        })
      );
    } else {
      dispatch(
        Actions.fetchScenarioListAction({
          sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
          page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
          pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
          order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        })
      );
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      marginBottom="50px"
      className={classes.boxContainer}
    >
      <Box display="flex" className={classes.boxAbove}>
        <Typography
          style={{ fontWeight: 'bold', fontSize: 20, color: '#404756' }}
          variant="h5"
        >
          Scenarios
        </Typography>
        <Box>
          <ButtonShare
            children={'Combine scenario'}
            onClick={() =>
              dispatch(
                Actions.setCombine({
                  isOpen: true,
                  data: null,
                })
              )
            }
          />
          <ButtonShare
            children={
              <>
                <AddIcon style={{ fontSize: 17, marginRight: 5 }} /> Add
                Scenarios
              </>
            }
            onClick={() =>
              dispatch(
                Actions.setModal({
                  data: null,
                  isOpen: true,
                  type: 'add',
                })
              )
            }
          />
        </Box>
      </Box>
      <FormSearch title="Scenaios List" onSearch={onSearch} />
      <Datatable
        loading={true}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        paging={paging}
        data={result.data?.items || []}
        columns={tableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          sortType.current = order;
          sortField.current = _sortField;
          dispatch(
            ScenarioActions.fetchScenarioListAction({
              sortField: _sortField,
              page: selectedPage,
              pageSize: pageSize,
              order: order,
            })
          );
        }}
      />
    </Box>
  );
}

export { ActionsBlock };
