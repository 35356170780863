import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Configuration from './configuration';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor: '#356CB0',
  },
  layoutContainer: {
    height: '100%',
    width: '100%',
  },
  layoutForgot: {
    justifyContent: 'center',
    display: 'flex',
    margin: 25,
  },
  cardView: {
    padding: 100,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '800px',
    zIndex: 1,
  },
  root1: {
    background:
      'linear-gradient(to right, ' +
      theme.palette.primary.dark +
      ' 0%, ' +
      darken(theme.palette.primary.dark, 0.5) +
      ' 100%)',
    color: theme.palette.primary.contrastText,
    width: '40%',
  },
  languageButton: {
    position: 'absolute',
    right: 20,
    top: 8,
  },
  curveImageBg: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}));

export default useStyles;
