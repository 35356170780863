import React from 'react';
import GridLayout from 'components/molecules/grid_layout';
import TranslatedText from 'components/atoms/translated_text';
import { Box, Typography } from '@material-ui/core';
import HeatMapChart from './HeatMap';
import BubblesChart from './BubblesChart';

/**
 * Official Documentation: https://reactchartjs.github.io/react-chartjs-2/#/
 * @returns
 */
export default function ReactChartsJS2() {
  return (
    <Box>
      <Typography variant="h4">
        <TranslatedText defaultText="ApexCharts" />
      </Typography>
      <GridLayout
        spacing={1}
        elements={[
          {
            id: '1',
            size: 6,
            element: <HeatMapChart />,
          },
          {
            id: '2',
            size: 6,
            element: <BubblesChart />,
          },          
        ]}
      />
    </Box>
  );
}
