import React, { useState } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import TextField from '@material-ui/core/TextField';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Control, Controller, useForm } from 'react-hook-form';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from 'react-redux';
import TranslatedText from 'components/atoms/translated_text';
import { AppTextField } from 'components/molecules/form_controls';
import { useGetOpen } from '../../../redux/reducers/product_reducer/selectors';
import Actions from '../../../redux/reducers/product_reducer/actions';
import { SET_OPEN } from '../../../redux/reducers/product_reducer/types';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: 900,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
    margin: theme.spacing(3, 0, 3),
    width: '100%',
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  },
  formControl: {},
  layout_links: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 25,
  },
  btnNormal: {
    textTransform: 'capitalize',
  },
  title: {
    padding: '10px 24px 0 24px',
  },
  content: {
    paddingTop: '0px !important',
  },
}));

interface IFormData {
  productCategory: string;
  usageCategory: string;
  productType: string;
  productCost: number;
  Comments: string;
  Compliance: string;
  hoverdescription: string;
  // Furans: number;
  // Dioxins: number;
  // Formaldehyde: number;
  // PAH: number;
  // Benzene: number;
  // _1_3Butadiene: number;
  // Nickel: number;
  // Chromium: number;
  // Cadmium: number;
  // Arsenic: number;
  // VOCs: number;
  // SO2: number;
  // PM10: number;
  // PM2dot5: number;
  // NOx: number;
  // NH3: number;
  // Methane: number;
  // CO2NonFossil: number;
  // CO2Fossil: number;
  annualReplacementRate: number;
  weight: number;
  climateChange: number;
  humanToxicity: number;
  acidificationPotential: number;
  respiratoryEffectsOnHuman: number;
  carcinogenicEffectsOnHuman: number;
  marineAquaticEcotoxicity: number;
  climateChangeImpactsAssociatedWithEOL: number;
  SUPLitteringRate: number;
  SUNPLitteringRate: number;
  MULitteringRate: number;
  MarineLittering: number;
}
// add types or interface
const defaultValues = {
  productCategory: '',
  usageCategory: '',
  productType: '',
  productCost: 0,
  Comments: '',
  Compliance: '',
  annualReplacementRate: 0,
  weight: 0,
  hoverdescription: '',
  climateChange: 0,
  humanToxicity: 0,
  acidificationPotential: 0,
  respiratoryEffectsOnHuman: 0,
  carcinogenicEffectsOnHuman: 0,
  marineAquaticEcotoxicity: 0,
  climateChangeImpactsAssociatedWithEOL: 0,
  SUPLitteringRate: 0,
  SUNPLitteringRate: 0,
  MULitteringRate: 0,
  MarineLittering: 0,
  // Furans: 0,
  // Dioxins: 0,
  // Formaldehyde: 0,
  // PAH: 0,
  // Benzene: 0,
  // _1_3Butadiene: 0,
  // Nickel: 0,
  // Chromium: 0,
  // Cadmium: 0,
  // Arsenic: 0,
  // VOCs: 0,
  // SO2: 0,
  // PM10: 0,
  // PM2dot5: 0,
  // NOx: 0,
  // NH3: 0,
  // Methane: 0,
  // CO2NonFossil: 0,
  // CO2Fossil: 0,
};

export default function ProductDialog() {
  const dispatch = useDispatch();
  const { isOpen, type, data } = useGetOpen();
  const [file, setFile] = useState(null);

  const classes = useStyles();
  const {
    setValue,
    watch,
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
  } = useForm<IFormData>({
    defaultValues,
  });
  React.useEffect(() => {
    if (type === 'edit') {
      reset({ ...data });
    }
  }, [type, data]);
  const onSubmit = (_data: IFormData) => {
    if (type === 'add') {
      dispatch(Actions.insertProduct({ ..._data, file }));
    } else if (type === 'edit') {
      dispatch(Actions.UpdateProduct({ ..._data, id: data._id, file }));
    }
    reset(defaultValues);
  };
  const handleClose = () => {
    reset(defaultValues);
    dispatch({
      type: SET_OPEN,
      data: {
        isOpen: false,
        type: 'add',
        data: null,
      },
    });
  };

  const handleChangeStatus = (
    { meta, file: uploadFile }: any,
    status: string
  ) => {
    if (status === 'done') {
      setFile(uploadFile);
    }
    if (status === 'removed') {
      setFile(null);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className={classes.root}
        maxWidth={false}
        classes={{ paper: classes.paper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          {type === 'add' ? 'Add New Product' : 'Update Product'}
        </DialogTitle>
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <DialogContent classes={{ root: classes.content }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    inputRef={register}
                    error={'productCategory' in errors}
                    helperText={
                      errors.productCategory && errors.productCategory.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="productCategory"
                    control={control}
                    label="Product Category"
                    autoComplete="productCategory"
                    name="productCategory"
                  />
                </InputWrapper>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    inputRef={register}
                    error={'productType' in errors}
                    helperText={
                      errors.productType && errors.productType.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="productType"
                    control={control}
                    label="Product Type"
                    autoComplete="productType"
                    name="productType"
                  />
                </InputWrapper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'productCost' in errors}
                    helperText={
                      errors.productCost && errors.productCost.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="productCost"
                    control={control}
                    label="Product Cost"
                    autoComplete="productCost"
                    name="productCost"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    inputRef={register}
                    error={'usageCategory' in errors}
                    helperText={
                      errors.usageCategory && errors.usageCategory.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="usageCategory"
                    control={control}
                    label="Usage Category"
                    autoComplete="usageCategory"
                    name="usageCategory"
                  />
                </InputWrapper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'weight' in errors}
                    helperText={errors.weight && errors.weight.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="weight"
                    control={control}
                    label="Weight"
                    autoComplete="weight"
                    name="weight"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'annualReplacementRate' in errors}
                    helperText={
                      errors.annualReplacementRate &&
                      errors.annualReplacementRate.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="annualReplacementRate"
                    control={control}
                    label="Annual Replacement Rate"
                    autoComplete="annualReplacementRate"
                    name="annualReplacementRate"
                  />
                </InputWrapper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'climateChange' in errors}
                    helperText={
                      errors.climateChange && errors.climateChange.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="climateChange"
                    control={control}
                    label="Climate Change"
                    autoComplete="climateChange"
                    name="climateChange"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    type="number"
                    margin="normal"
                    inputRef={register}
                    error={'humanToxicity' in errors}
                    helperText={
                      errors.humanToxicity && errors.humanToxicity.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="humanToxicity"
                    control={control}
                    label="Human Toxicity"
                    autoComplete="humanToxicity"
                    name="humanToxicity"
                  />
                </InputWrapper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'acidificationPotential' in errors}
                    helperText={
                      errors.acidificationPotential &&
                      errors.acidificationPotential.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="acidificationPotential"
                    control={control}
                    label="Acidification Potential"
                    autoComplete="acidificationPotential"
                    name="acidificationPotential"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'respiratoryEffectsOnHuman' in errors}
                    helperText={
                      errors.respiratoryEffectsOnHuman &&
                      errors.respiratoryEffectsOnHuman.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="respiratoryEffectsOnHuman"
                    control={control}
                    label="Respiratory effects on humans"
                    autoComplete="respiratoryEffectsOnHuman"
                    name="respiratoryEffectsOnHuman"
                  />
                </InputWrapper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'carcinogenicEffectsOnHuman' in errors}
                    helperText={
                      errors.carcinogenicEffectsOnHuman &&
                      errors.carcinogenicEffectsOnHuman.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="carcinogenicEffectsOnHuman"
                    control={control}
                    label="Carcinogenic Effects On Humans"
                    autoComplete="carcinogenicEffectsOnHuman"
                    name="carcinogenicEffectsOnHuman"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    inputRef={register}
                    type="number"
                    error={'marineAquaticEcotoxicity' in errors}
                    helperText={
                      errors.marineAquaticEcotoxicity &&
                      errors.marineAquaticEcotoxicity.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="marineAquaticEcotoxicity"
                    control={control}
                    label="Marine Aquatic Eco toxicity"
                    autoComplete="marineAquaticEcotoxicity"
                    name="marineAquaticEcotoxicity"
                  />
                </InputWrapper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'climateChangeImpactsAssociatedWithEOL' in errors}
                    helperText={
                      errors.climateChangeImpactsAssociatedWithEOL &&
                      errors.climateChangeImpactsAssociatedWithEOL.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="climateChangeImpactsAssociatedWithEOL"
                    control={control}
                    label="Climate Change Impacts Associated With EOL"
                    autoComplete="climateChangeImpactsAssociatedWithEOL"
                    name="climateChangeImpactsAssociatedWithEOL"
                  />
                </InputWrapper>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'SUPLitteringRate' in errors}
                    helperText={
                      errors.SUPLitteringRate && errors.SUPLitteringRate.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="SUPLitteringRate"
                    control={control}
                    label="SUP Littering Rate"
                    autoComplete="SUPLitteringRate"
                    name="SUPLitteringRate"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'MULitteringRate' in errors}
                    helperText={
                      errors.MULitteringRate && errors.MULitteringRate.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="MULitteringRate"
                    control={control}
                    label="MU Littering Rate"
                    autoComplete="MULitteringRate"
                    name="MULitteringRate"
                  />
                </InputWrapper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'SUPLitteringRate' in errors}
                    helperText={
                      errors.SUPLitteringRate && errors.SUPLitteringRate.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="SUPLitteringRate"
                    control={control}
                    label="SUP Littering Rate"
                    autoComplete="SUPLitteringRate"
                    name="SUPLitteringRate"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'SUNPLitteringRate' in errors}
                    helperText={
                      errors.SUNPLitteringRate &&
                      errors.SUNPLitteringRate.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="SUNPLitteringRate"
                    control={control}
                    label="SUNP Littering Rate"
                    autoComplete="SUNPLitteringRate"
                    name="SUNPLitteringRate"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    inputRef={register}
                    error={'MarineLittering' in errors}
                    helperText={
                      errors.MarineLittering && errors.MarineLittering.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="MarineLittering"
                    control={control}
                    label="Marine Littering"
                    autoComplete="MarineLittering"
                    name="MarineLittering"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputWrapper>
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    inputRef={register}
                    error={'hoverdescription' in errors}
                    helperText={
                      errors.hoverdescription && errors.hoverdescription.message
                    }
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="hoverdescription"
                    control={control}
                    label="Hover description"
                    autoComplete="hoverdescription"
                    name="hoverdescription"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={12}>
                <InputWrapper>
                  <AppTextField
                    margin="normal"
                    style={{ height: 'auto' }}
                    variant="outlined"
                    // error={'Comments' in errors}
                    // helperText={errors.Comments && errors.Comments.message}
                    rules={
                      {
                        // required: <RequiredField />,
                      }
                    }
                    // required
                    multiline
                    rows={5}
                    fullWidth
                    id="Comments"
                    control={control}
                    label="Comments"
                    name="Comments"
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} md={12}>
                <InputWrapper>
                  <AppTextField
                    margin="normal"
                    style={{ height: 'auto' }}
                    variant="outlined"
                    multiline
                    rows={5}
                    // error={'Compliance' in errors}
                    // helperText={errors.Compliance && errors.Compliance.message}
                    rules={
                      {
                        // required: <RequiredField />,
                      }
                    }
                    // required
                    fullWidth
                    id="Compliance"
                    control={control}
                    label="Compliance"
                    name="Compliance"
                  />
                </InputWrapper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  margin: '10px 0',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Accordion style={{ width: '92%' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="Upload"
                    id="upload"
                  >
                    <Typography>Upload Picture for product</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Dropzone
                      multiple={false}
                      maxFiles={1}
                      // InputComponent={Input}
                      // getUploadParams={getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      // onSubmit={handleSubmit}
                      accept="image/*"
                      inputContent="Photo"
                      styles={{
                        dropzone: { height: 40 },
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.btnNormal}
              variant="contained"
              color="primary"
              type="submit"
            >
              {type === 'add' ? 'Add' : 'Update'}
            </Button>
            <Button
              className={classes.btnNormal}
              style={{ background: '#fff' }}
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

const RequiredField = () => {
  return <React.Fragment>Required Value</React.Fragment>;
};
const InputWrapper = (props: any) => {
  return (
    <div style={{ width: '100%', padding: '0 20px' }}>{props.children}</div>
  );
};
