import React from 'react';
import Datatable from 'components/molecules/datatable';
import { Button, TextField } from '@material-ui/core';

export default function EditableDatatable() {
  return (
    <Datatable data={DummyData} disablePaper={false} columns={DummyColumns} />
  );
}

interface IData {
  name: string;
  code: number;
  population: string;
  size: string;
}

export const DummyColumns = [
  {
    label: 'Population',
    id: 'population',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Size',
    id: 'size',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Name',
    id: 'name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Code',
    id: 'code',
    // align: 'right',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Calculate Field',
    id: 'cal_1',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: IData[]
    ) => (
      <React.Fragment>
        {' '}
        {`${data[rowIndex].code} _ ${data[rowIndex].name}`}
      </React.Fragment>
    ),
    inData: false,
  },
  {
    label: 'View',
    id: 'view',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <Button variant="contained" color="primary" fullWidth>
        View
      </Button>
    ),
    inData: false,
  },
];

export const DummyData: IData[] = Array.from({ length: 10 }, (_, index) => ({
  size: 'Size' + index,
  name: 'Name' + index,
  code: index,
  population: index * 3 + '',
}));
