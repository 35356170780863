import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // overflow: 'auto',
  },
  tableCell: {
    borderBottom: '1px solid #F7F7F7',
  },
  container: {
    height: (props: any) => props?.height || undefined,
  },
  highlightRow: {
    background: '#FBFBFB',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  pagingPaper: {
    marginTop: 6,
  },
}));

export default useStyles;
