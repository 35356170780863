import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import TranslatedText from 'components/atoms/translated_text';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { StateStatus } from 'redux/utils/common';
import { Translations } from '../../configuration';
import ResetPasswordActions from '../../store/actions';
import { useSelectResetPassword } from '../../store/selectors';
import useStyles from './styles';

interface IProps {
  token: string;
}
export default function ResetPasswordForm(props: IProps) {
  const classes = useStyles();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
    register,
  } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  let watchPassword = watch('password');
  let watchConfirmPassword = watch('confirmPassword');
  let resetPasswordStatus = useSelectResetPassword();

  const errorSelector = (fieldName: string) => {
    if (fieldName in errors) {
      if (errors[fieldName].type === 'required') {
        return <TranslatedText textMap={Translations.form_error_required} />;
      }
    } else if (
      fieldName === 'confirmPassword' &&
      watchPassword !== watchConfirmPassword
    ) {
      return <TranslatedText textMap={Translations.form_error_match} />;
    } else {
      return '';
    }
  };

  const onSubmit = (data: any) => {
    console.log('Data:', data);
    if (data.password !== data.confirmPassword) return;
    dispatch(
      ResetPasswordActions.resetPasswordAction(
        data.password,
        data.confirmPassword,
        props.token,
        history
      )
    );
  };

  console.log('TEST:', errors);
  return (
    <div className={clsx(classes.layout)}>
      <Typography
        variant="h5"
        className={clsx(classes.title, 'text-center md:w-full mb-48')}
      >
        <TranslatedText textMap={Translations.recover_title} />
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box style={{ minWidth: '500px' }}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            type="password"
            id="password"
            label={
              <TranslatedText textMap={Translations.form_field_password} />
            }
            name="password"
            autoComplete="password"
            error={'password' in errors}
            helperText={errorSelector('password')}
            inputRef={register({ required: true })}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="confirmPassword"
            label={
              <TranslatedText
                textMap={Translations.form_field_confirm_password}
              />
            }
            name="confirmPassword"
            autoComplete="confirmPassword"
            error={
              'confirmPassword' in errors ||
              watchPassword != watchConfirmPassword
            }
            helperText={errorSelector('confirmPassword')}
            inputRef={register({ required: true })}
          />
        </Box>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          classes={{ containedPrimary: classes.containerButton }}
          disabled={
            !watchPassword ||
            !watchConfirmPassword ||
            resetPasswordStatus == StateStatus.Pending
          }
          className={classes.submit}
        >
          <TranslatedText textMap={Translations.form_button_recover} />
        </Button>
      </form>
    </div>
  );
}
