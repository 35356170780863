import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import _ from 'lodash';
import { numberWithCommas } from 'utils/strings';

function getRevenueConfig(
  series: any,
  title: string[],
  projectedField: string,
  unit: string,
  id: string,
  labels: string[]
) {
  const barChart: IGridConf[] = [
    // 'Estimated Reduction in yearly marine litter'
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'bar',
        height: 350,

        series: series.map((item: any, index: number) => {
          return {
            name: labels[index],
            data: item.map((e: any) => {
              return e[projectedField] !== null
                ? Number(e[projectedField]).toFixed(4)
                : 0;
            }),
          };
        }),
        options: {
          chart: {
            id,
            type: 'bar',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            // height: 350,
          },
          title: {
            text: title,
          },
          // colors: ['#008FFB', '#FF4560'],
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: _.uniq(
              _.flatten(series).map((item: any) => item.productCategory)
            ),
            title: {
              text: unit,
            },
          },
          yaxis: {
            forceNiceScale: true,
            show: true,
          },
          tooltip: {
            shared: false,
            y: {
              formatter: function (
                value: number,
                { series, seriesIndex, dataPointIndex, w }: any
              ) {
                return value;
              },
              title: {
                formatter: (seriesName: string) =>
                  seriesName.length > 20
                    ? seriesName.substring(0, 20) + ' ...'
                    : seriesName,
              },
            },
          },
        },
      },
      fetch: null,
      // fetch: `/api/dashboard-pub/general-publisher-revenue-areachart/${userid}?appid=${appid}&publisherid=${publisherid}&datefrom=${datefrom}&dateto=${dateto}`,
    },
  ];
  return barChart;
}

export default getRevenueConfig;
