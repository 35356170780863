import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchScenarioList  */
interface IScenarioListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  type: string | null;
  name: string;
  totalnumberofguestsperyear: string | null;
  totalnumberofrooms: string | null;
  hotelguestcapacity: string | null;
  restaurantcafecapacity: string | null;
  totalnumberoftablesacrossallrestaurant: string | null;
  beachbarcapacity: string | null;
  totalnumberoftables: string | null;
  totalcapacity: string | null;
  user: string | null;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IScenarioListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  name?: string;
}

export const fetchScenarioOption = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/scenario/scenarios`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const fetchScenarioList = async (
  payload: IScenarioListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/scenario/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let _data: IScenarioListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

// Search
export const searchScenario = async (
  payload: IScenarioListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/scenario/searchByName?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}&name=${payload.name}`
  );

  let _data: IScenarioListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** Scenario Detail */
interface IScenarioDetailInput {
  id: number | string;
}

interface IScenarioDetailOutput extends Types.ResponseOutput {
  data: {
    type: string | null;
    totalnumberofguestsperyear: string | null;
    totalnumberofrooms: string | null;
    hotelguestcapacity: string | null;
    restaurantcafecapacity: string | null;
    totalnumberoftablesacrossallrestaurant: string | null;
    beachbarcapacity: string | null;
    totalnumberoftables: string | null;
    totalcapacity: string | null;
    user: string | null;
  };
}

export const fetchScenarioDetail = async (
  payload: IScenarioDetailInput
): Promise<IScenarioDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/scenario/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add Scenario */
interface IScenarioAddInput {
  type: string | null;
  totalnumberofguestsperyear: string | null;
  totalnumberofrooms: string | null;
  hotelguestcapacity: string | null;
  restaurantcafecapacity: string | null;
  totalnumberoftablesacrossallrestaurant: string | null;
  beachbarcapacity: string | null;
  totalnumberoftables: string | null;
  totalcapacity: string | null;
}

interface IScenarioAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addScenario = async (
  payload: IScenarioAddInput
): Promise<IScenarioAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/scenario `,

    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit Scenario */
interface IScenarioEditInput {
  type: string | null;
  totalnumberofguestsperyear: string | null;
  totalnumberofrooms: string | null;
  hotelguestcapacity: string | null;
  restaurantcafecapacity: string | null;
  totalnumberoftablesacrossallrestaurant: string | null;
  beachbarcapacity: string | null;
  totalnumberoftables: string | null;
  totalcapacity: string | null;
  user: string | null;
}

interface IScenarioEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editScenario = async (
  payload: any
): Promise<IScenarioEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/scenario/${payload._id}`,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
export const fetchActiveScenario = async (): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/scenario/activeScenario`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
export const deleteScenario = async (payload: {
  _id: string;
}): Promise<IScenarioEditOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/scenario/${payload._id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
