import { TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TranslatedText from 'components/atoms/translated_text';
import FormFeedback from 'components/molecules/form_feedback';
import NavigationConfig from 'navigation/config';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router'; // import the react-router-dom components
import { Link } from 'react-router-dom';
import Actions from 'redux/reducers/gl_auth_reducer/actions';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import LoginForm from 'components/app_common/login_form';
import { Translations } from './configuration';
import useStyles from './styles';

interface IProps {}
interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  const authStatus = AuthSelectors.useSelectAuthStatus();
  return (
    <Button
      {...props}
      color="primary"
      disabled={StateStatus.Pending === authStatus}
    />
  );
};

const WrongCrentetialsMessage = () => {
  const authStatus = AuthSelectors.useSelectAuthStatus();
  
  return (
    <React.Fragment>
      {authStatus === StateStatus.Failed && (
        <FormFeedback data={Translations.messgae_wrong_credentials} />
      )}
    </React.Fragment>
  );
};

const LoginPage: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const errorSelector = (fieldName: string) => {
    if (fieldName in errors) {
      if (errors[fieldName].type === 'required') {
        return <TranslatedText textMap={Translations.form_error_required} />;
      }
      if (errors[fieldName].type === 'pattern') {
        if (fieldName === 'email')
          return <TranslatedText textMap={Translations.email_format_error} />;
      }
    } else {
      return '';
    }
  };
  const onSubmit = (data: any) => {
    dispatch(
      Actions.authenticateAction({
        email: data.email,
        password: data.password,
        stayLogin: Boolean(data.rememberMe),
      })
    );
  };

  return (
    <div>
      <LoginForm>
        <Fragment>
          <Typography
            component="h1"
            variant="h5"
            style={{
              color: '#404756',
              fontWeight: 'bold',
              marginBottom: 100,
              fontSize: 28,
            }}
          >
            <TranslatedText textMap={Translations.login_title} />
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={<TranslatedText textMap={Translations.form_field_email} />}
              name="email"
              autoComplete="email"
              error={'email' in errors}
              helperText={errorSelector('email')}
              inputRef={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid email format',
                },
              })}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={
                <TranslatedText textMap={Translations.form_field_password} />
              }
              type="password"
              id="password"
              inputRef={register({ required: true })}
              error={'password' in errors}
              helperText={errorSelector('password')}
              autoComplete="current-password"
            />
            <WrongCrentetialsMessage />
            <Box>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={
                  <TranslatedText textMap={Translations.label_remember_me} />
                }
                name={'rememberMe'}
                className={classes.rememberMe}
                // inputRef={register({ required: true })}
              />
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <SubmitWrapper
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
              >
                <TranslatedText textMap={Translations.form_button_login} />
              </SubmitWrapper>
            </Box>
            <Box className={classes.forgotPassword}>
              <Link
                to={NavigationConfig.forgotPasswordPage().path}
                style={{ color: 'inherit' }}
              >
                <TranslatedText textMap={Translations.label_forgot_password} />
              </Link>
            </Box>
            <Box className={classes.signUpHere}>
              <Link
                style={{ color: 'inherit' }}
                to={NavigationConfig.registerPage().path}
              >
                <TranslatedText textMap={Translations.dont_account} />
                <span>
                  <TranslatedText textMap={Translations.sign_up_here} />
                </span>
              </Link>
            </Box>
          </form>
        </Fragment>
      </LoginForm>
    </div>
  );
};

export default withRouter(LoginPage);
