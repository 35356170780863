import React from 'react';
import * as Config from '../../config';
import LoginPage from '../../scenes/login_page';
import MainPage from '../../scenes/main_page';
import RegisterPage from '../../scenes/register_page';
import DashboardBuilderPage from 'samples/dashboardBuilder_page';
import RootPage from '../../scenes/root_page';
import AppbarMenu from '../components/appbar';
import ForgotPasswordPage from '../../scenes/forgot_password';
import ResetPasswordPage from '../../scenes/reset_password';
import SecurityPage from '../../scenes/security_page';
import ProfilePage from '../../scenes/profile_page';
import DashboardV2Page from 'samples/dashboardV2_page';
import DatatablesPage from 'samples/datatables_page';
import ChartsPage from 'samples/charts_page';
import MapsPage from 'samples/maps_page';
import FormsPage from 'samples/forms_page';
import ProductPage from '../../scenes/product_page';
import UserProductPage from '../../scenes/userproducts_page';
import PDFExportPage from '../../scenes/pdf_export';
import ScenarioPage from '../../scenes/scenario_page';
import EstablishmentPage from '../../scenes/establishment_page';
import GroupPage from '../../scenes/group_page';
import CombineScenarioPage from '../../scenes/combine_scenario_page';
import UserManagementPage from '../../scenes/user_management_page';

interface IPage {
  path: string;
  component: any;
  roles: Array<number | string>;
}

interface INavigationConfig {
  rootPage: () => IPage;
  mainPage: () => IPage;
  mapsPage: () => IPage;
  formsPage: () => IPage;
  loginPage: () => IPage;
  loginNoAuthPage: () => IPage;

  registerPage: () => IPage;
  registerNoAuthPage: () => IPage;
  forgotPasswordPage: () => IPage;
  forgotPasswordNoAuthPage: () => IPage;

  resetPasswordPage: (token?: string) => IPage;
  dashboardBuilderPage: () => IPage;
  securityPage: () => IPage;
  profilePage: () => IPage;
  dashboardV2Page: () => IPage;
  datatablesPage: () => IPage;
  chartsPage: () => IPage;
  productPage: () => IPage;
  userproductPage: () => IPage;
  pdfexportPage: () => IPage;
  scenarioPage: () => IPage;
  establishmentPage: () => IPage;
  groupPage: () => IPage;
  combineScenarioPage: () => IPage;
  userManagementPage: () => IPage;

  // generatorPage: () => IPage;
}

interface IAppbarsConfig {
  primaryNav: INav;
  secondaryNav: INav;
}

interface INav {
  component: any;
  include: 'all' | string[] | null;
  exclude: 'all' | string[] | null;
  select: 'include' | 'exclude';
  // roles: Array<string>;
}

// console.log('All users:', Config.AuthRAuthRoles.All);

const NavigationConfig: INavigationConfig = {
  rootPage: (params?: any): IPage => ({
    path: '*',
    component: RootPage,
    roles: Config.AuthRoles.All,
  }),
  mainPage: (params?: any) => ({
    path: `/`,
    component: MainPage,
    roles: Config.AuthRoles.All,
  }),
  formsPage: (params?: any) => ({
    path: `/forms`,
    component: FormsPage,
    roles: Config.AuthRoles.Registered,
  }),
  mapsPage: () => ({
    path: `/maps`,
    component: MapsPage,
    roles: Config.AuthRoles.Registered,
  }),
  dashboardV2Page: (params?: any): IPage => ({
    path: '/dashboard/v2',
    component: DashboardV2Page,
    roles: Config.AuthRoles.All,
  }),
  loginPage: () => ({
    path: '/login',
    component: LoginPage,
    roles: [Config.AuthRoles.Guest],
  }),
  loginNoAuthPage: () => ({
    path: '/no-auth/login',
    component: LoginPage,
    roles: Config.AuthRoles.All,
  }),
  registerPage: () => ({
    path: '/register',
    component: RegisterPage,
    roles: [Config.AuthRoles.Guest],
  }),
  registerNoAuthPage: () => ({
    path: '/no-auth/register',
    component: RegisterPage,
    roles: Config.AuthRoles.All,
  }),
  forgotPasswordPage: () => ({
    path: '/forgot',
    component: ForgotPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  forgotPasswordNoAuthPage: () => ({
    path: '/no-auth/forgot',
    component: ForgotPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  resetPasswordPage: (token = ':token') => ({
    path: `/reset-password/${token}`,
    component: ResetPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  dashboardBuilderPage: () => ({
    path: '/dashboard-builder',
    component: DashboardBuilderPage,
    roles: Config.AuthRoles.Registered,
  }),
  datatablesPage: () => ({
    path: `/datatables`,
    component: DatatablesPage,
    roles: Config.AuthRoles.Registered,
  }),
  securityPage: () => ({
    path: `/security`,
    component: SecurityPage,
    roles: Config.AuthRoles.Registered,
  }),
  profilePage: () => ({
    path: `/profile`,
    component: ProfilePage,
    roles: Config.AuthRoles.Registered,
  }),
  chartsPage: () => ({
    path: `/charts`,
    component: ChartsPage,
    roles: Config.AuthRoles.Registered,
  }),
  productPage: () => ({
    path: `/product`,
    component: ProductPage,
    roles: [Config.AuthRoles.Admin],
  }),
  userproductPage: () => ({
    path: `/user-product`,
    component: UserProductPage,
    roles: Config.AuthRoles.Registered,
  }),
  pdfexportPage: () => ({
    path: `/pdf-export`,
    component: PDFExportPage,
    roles: Config.AuthRoles.Registered,
  }),
  scenarioPage: () => ({
    path: `/scenario`,
    component: ScenarioPage,
    roles: Config.AuthRoles.Registered,
  }),
  establishmentPage: () => ({
    path: `/establishment`,
    component: EstablishmentPage,
    roles: Config.AuthRoles.EstablishmentAdmins,
  }),
  groupPage: () => ({
    path: `/group`,
    component: GroupPage,
    roles: Config.AuthRoles.GroupAdmins,
  }),
  combineScenarioPage: () => ({
    path: `/combined-scenario`,
    component: CombineScenarioPage,
    roles: Config.AuthRoles.Registered,
  }),
  userManagementPage: () => ({
    path: `/user-management`,
    component: UserManagementPage,
    roles: [Config.AuthRoles.Admin],
  }),
};

export const AppbarsConfig: IAppbarsConfig = {
  primaryNav: {
    component: AppbarMenu,
    include: null,
    exclude: [
      NavigationConfig.loginPage().path,
      NavigationConfig.registerPage().path,
      NavigationConfig.forgotPasswordPage().path,
      NavigationConfig.resetPasswordPage().path,
    ],
    select: 'exclude',
  },
  secondaryNav: {
    component: AppbarMenu,
    include: [],
    exclude: 'all',
    select: 'exclude',
  },
};

export const Appbars: Array<INav> = Object.values(AppbarsConfig);
export default NavigationConfig;
