import { LanguagesEnum } from '../../config/languages';

const Configuration = {
  backgroundImage: require('../../assets/images/backgrounds/background_login_register.png'),
  loginIcon: '../../assets/images/logos/supmed_logo.png',
};

export const Translations = {
  login_title: {
    [LanguagesEnum.English]: 'Sign in',
    [LanguagesEnum.Greek]: 'Σύνδεση',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'This is required',
    [LanguagesEnum.Greek]: 'Υποχρεωτικό πεδίο',
  },
  form_field_email: {
    [LanguagesEnum.English]: 'Email Address',
    [LanguagesEnum.Greek]: 'Email',
  },
  form_field_password: {
    [LanguagesEnum.English]: 'Password',
    [LanguagesEnum.Greek]: 'Κωδικός',
  },
  form_button_login: {
    [LanguagesEnum.English]: 'Log in',
    [LanguagesEnum.Greek]: 'Σύνδεση',
  },
  label_forgot_password: {
    [LanguagesEnum.English]: 'Forgot Password?',
    [LanguagesEnum.Greek]: 'Ξεχάσατε κωδικό;',
  },
  messgae_wrong_credentials: {
    [LanguagesEnum.English]: 'Wrong Credentials',
    [LanguagesEnum.Greek]: 'Λάθος στοιχεία χρήστη',
  },
  email_format_error: {
    [LanguagesEnum.English]: 'Invalid email address',
    [LanguagesEnum.Greek]: 'Λάθος email',
  },
  label_remember_me: {
    [LanguagesEnum.English]: 'Remember Me',
    [LanguagesEnum.Greek]: 'Θυμήσου με',
  },
  dont_account: {
    [LanguagesEnum.English]: "Don't have an account yet? ",
    [LanguagesEnum.Greek]: 'Δεν έχετε λογαριασμό ακόμα; ',
  },
  sign_up_here: {
    [LanguagesEnum.English]: 'Sign up here',
    [LanguagesEnum.Greek]: 'Εγγραφείτε εδώ',
  },
};

export default Configuration;
