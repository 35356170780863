import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import useStyles from './styles';
import TranslatedText from 'components/atoms/translated_text';
import {
  AppTextField,
  AppSelect,
  AppColorPicker,
  AppSwitch,
} from 'components/molecules/form_controls';
import { useForm, useFieldArray } from 'react-hook-form';
import GridLayout from 'components/molecules/grid_layout';
import * as Configuration from '../../configuration';
// import {} from '../../configuration';
import SubFormLayout from './SubFormLayout';
import reactLogger from 'utils/logger';
import { IMapsConfig } from 'components/compounds/dashboard_builder/plugins/types/maps';
import {
  TBuilderTypes,
  IDashboard,
  IDashboardElement,
} from 'components/compounds/dashboard_builder/plugins/types';
import DividerLine from 'components/atoms/divider_line';
import * as DashboardBuilderSelectors from '../../store/selectors';
import DashboardBuilderActions from '../../store/actions';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

interface IFormData extends Omit<IMapsConfig, 'geoJsonData'> {}

const defaultValues = (
  urlPath: string,
  initial: IMapsConfig | {} = {}
): IFormData => ({
  ...initial,
});

interface IFormProps {
  urlPath: string;
  initialValuesconfig: any; // IMapsConfig;
  type: 'edit' | 'add';
  itemIndex?: number;
  dashboardElementType: TBuilderTypes;
  width: number;
  reqMethod: 'GET' | 'POST';
}

export default function Form(props: IFormProps) {
  reactLogger.renderComponent('DatasetsForm');
  const { urlPath, initialValuesconfig, reqMethod } = props;

  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues: defaultValues(urlPath || ''),
  });
  const formRef: any = React.useRef();
  const dispatch = useDispatch();
  reactLogger.renderComponent('Render Datasets Form');
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'datasets',
    }
  );

  React.useEffect(() => {
    reset(defaultValues(urlPath || '', initialValuesconfig || {}));
  }, [urlPath, initialValuesconfig]);

  const onSubmit = (_data: IFormData, submitType: 'add' | 'edit') => {
    // console.log('Daaaaaaaaaa:', _data);
    // let data = DefaultDashboard();
    // const { selectedDatasetsNumber, ...config } = _data;
    let element: IDashboardElement = {
      type: props.dashboardElementType,
      config: {
        geoJsonData: {
          // default geojson
          type: 'FeatureCollection',
          features: [],
        },
        ..._data,
      },
      size: props.width as any,
      url: urlPath || '',
      urlMethod: reqMethod,
    };

    if (submitType === 'add') {
      dispatch(
        DashboardBuilderActions.setBuilderConfigurationAction({
          configuration: element,
          type: 'add',
        })
      );
    } else {
      dispatch(
        DashboardBuilderActions.setBuilderConfigurationAction({
          configuration: element,
          type: 'edit',
          index: props.itemIndex || 0,
        })
      );
    }
  };

  return (
    <form ref={formRef}>
      <GridLayout
        spacing={1}
        justify="flex-start"
        elements={[
          {
            id: 'submit',
            element: (
              <GridLayout
                spacing={1}
                justify="flex-start"
                elements={[
                  {
                    id: '1',
                    element: (
                      <Button
                        // type="submit"
                        variant="outlined"
                        fullWidth
                        color="primary"
                        onClick={handleSubmit((data: IFormData) =>
                          onSubmit(data, 'add')
                        )}
                      >
                        <TranslatedText defaultText={'Add New'} />
                      </Button>
                    ),
                    size: 3,
                  },
                  {
                    id: '2',
                    element: (
                      <ButtonEditWrapper
                        onSubmit={handleSubmit((data: IFormData) =>
                          onSubmit(data, 'edit')
                        )}
                      />
                    ),
                    size: 3,
                  },
                  {
                    id: '3',
                    element: (
                      <Button
                        // type="submit"
                        variant="outlined"
                        fullWidth
                        color="primary"
                      >
                        <TranslatedText defaultText={'Clear'} />
                      </Button>
                    ),
                    size: 3,
                  },
                ]}
              />
            ),
            size: 12,
          },
        ]}
      />
    </form>
  );
}

interface IButtonEditWrapperProps {
  onSubmit: any;
}

const ButtonEditWrapper = (props: IButtonEditWrapperProps) => {
  const selectElement = DashboardBuilderSelectors.useGetSelectedElementDashboard(
    'element'
  );
  const isSelected = Boolean(selectElement);

  return (
    <Button
      onClick={props.onSubmit}
      variant="outlined"
      fullWidth
      disabled={!isSelected}
      color="primary"
    >
      <TranslatedText defaultText={'Edit'} />
    </Button>
  );
};

const RequiredField = React.memo(() => {
  return <TranslatedText defaultText={'Required Value'} />;
});
