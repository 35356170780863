import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  userOutput: {
    status: StateStatus | null;
    data: any;
  };
  userProduct: {
    status: StateStatus | null;
    data: any;
  };
  envImpactSelection: string;
  envDescSelection: string;
  productCategorySelection: string;
}

const initialState: IState = {
  userOutput: {
    status: null,
    data: null,
  },
  userProduct: {
    status: null,
    data: null,
  },
  envImpactSelection: '',
  envDescSelection: '',
  productCategorySelection: '',
};

const CombineScenariosReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USER_OUTPUT:
      return {
        ...state,
        userOutput: {
          ...state.userOutput,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.userOutput.data : action.data,
        },
      };
    case Types.SET_USER_PRODUCT:
      return {
        ...state,
        userProduct: {
          ...state.userProduct,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.userProduct.data
              : action.data,
        },
      };
    case Types.SET_ENV_DESC_SELECTION:
      return {
        ...state,
        envDescSelection: action.data,
      };
    case Types.SET_ENV_IMPACT_SELECTION:
      return {
        ...state,
        envImpactSelection: action.data,
      };
    case Types.SET_PRODUCT_CATEGORY_SELECTION:
      return {
        ...state,
        productCategorySelection: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default CombineScenariosReducer;
