import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import { Colors } from '../../../styles';
import Configuration from './configuration';
import { SideBarWidth } from 'config';
import {
  APP_COLOR_PRIMARY,
  NAVBAR_ITEM_SELECTED,
  SIDE_BAR_COLOR,
} from 'styles/colors';

const drawerWidth = SideBarWidth;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  img_logo: {
    marginBottom: 13,
    height: 50,
    width: 'auto',
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  appBar: {
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.05)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 1, //temp
    },
    backgroundColor: '#346CB0',
  },
  boxNavbar: {
    borderRadius: 4,
    border: 'none',
    boxShadow: '0px 5px 9px rgb(0, 0, 0, 0.2)',
  },
  boxAvatar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: 20,
    '& .img-avatar': {
      position: 'absolute',
      top: 24,
      left: '50%',
      transform: 'translateX(-50%)',
      borderRadius: '50%',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    background: theme.palette.secondary.main,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    color: 'white',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    backgroundColor: '#E5E5E5',
    padding: '0 24px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      // backgroundColor:
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  title: {
    display: 'none',
    // fontFamily: 'Sansita',
    // fontStyle: 'italic',
    // fontWeight: '',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  titleContainer: {
    // border: '1px solid #FFFFFF',
    // borderRadius: 5,
    display: 'none',
    marginLeft: 20,
    // boxSizing: 'border-box',
    // padding: '1px 10px 1px 10px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  avatar: {
    cursor: 'pointer',
    width: '170px',
    height: 'auto',
    padding: 4,
  },
  drawerListItemText: {},
  drawerListItemTextSelected: {},
  drawerPaper: {
    background: SIDE_BAR_COLOR(theme),
    color: theme.palette.type === 'dark' ? 'white' : 'dark',
    width: drawerWidth,
  },
  drawerItem: {
    width: '97%',
    backgroundColor: (props: any) =>
      props.isSelected
        ? APP_COLOR_PRIMARY({
            darkMode: theme.palette.type === 'dark',
            transparency: 0.5,
          })
        : 'transparent',
    // color: (props: any) => (props.isSelected ? NAVBAR_ITEM_SELECTED(theme) : 'white'),
    '&:hover': {
      backgroundColor: (props: any) =>
        !props.isSelected
          ? APP_COLOR_PRIMARY({
              darkMode: theme.palette.type === 'dark',
              transparency: 0.2,
            })
          : undefined,
    },
    borderBottomRightRadius: 50,
    borderTopRightRadius: 50,
    marginBottom: 6,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    // color: Configuration.sidebar.config?.textColor1,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  subElement: {
    cursor: 'pointer',
    // color: Configuration.sidebar.config?.textColor1,
    paddingLeft: 20,
    marginLeft: 5,
  },
  buttonText: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  menuItemSelected: {
    color: NAVBAR_ITEM_SELECTED(theme),
    fontWeight: 'bold',
  },
}));

export default useStyles;
