import {
  ICustomChartProps,
  ChartTypesEnum,
} from 'components/molecules/custom_chart';
import { ChartOptions } from 'chart.js';
import * as RandomUtils from 'utils/randomize';

export type GenericChartTypes =
  | 'combo-bar-line'
  | 'pie'
  | 'doughnut'
  | 'scatter'
  | 'bubble';

export type ChartTypes =
  | 'line'
  | 'bar'
  | 'pie'
  | 'doughnut'
  | 'scatter'
  | 'bubble';

export interface IChartConfig {
  x_axis_field: string;
  loadData: ILoadData;
  legend: ILegend;
  datasets: Array<IDataset>;
  startAxis: IStartAxis;
  height?: number | string;
  gridLines: IGridLines;
  tooltips: ITooltips;
  cardTitle: string;
  genericChartType: GenericChartTypes;
}

export interface IDataset {
  type: ChartTypes;
  y_axis_field: string;
  color: string;
  border_color: string;
  name: string;
}

export interface ILoadData {
  type: 'api-load' | 'upload';
  url: string;
}

export interface IStartAxis {
  min?: number;
  max?: number;
}

export interface IGridLines {
  enableX: boolean;
  enableY: boolean;
}

export interface ITooltips {
  enable: boolean;
}

export type LegendPosition =
  | 'bottom-left'
  | 'bottom-right'
  | 'bottom-center'
  | 'top-center'
  | 'top-left'
  | 'top-right';

export interface ILegend {
  visible: boolean;
  position: LegendPosition;
}

export const Sample: IChartConfig = {
  x_axis_field: 'name',
  loadData: {
    type: 'api-load',
    url: '',
  },
  legend: {
    visible: true,
    position: 'top-center',
  },
  gridLines: {
    enableX: true,
    enableY: true,
  },
  tooltips: {
    enable: true,
  },
  datasets: [
    {
      type: 'bar',
      y_axis_field: 'number',
      color: 'red',
      border_color: 'blue',
      name: 'Nationality',
      // tooltips: {
      //   enable: true,
      // },
    },
  ],
  startAxis: {
    min: 5,
    max: 5,
  },
  cardTitle: 'Sample Card',
  genericChartType: 'combo-bar-line',
};

interface IOptions {
  gridLines: IGridLines;
  legend: ILegend;
  startAxis: IStartAxis;
}

const defaultOptions = (data: IOptions): ChartOptions => ({
  legend: {
    display: data.legend.visible,
    align: data.legend.position.includes('left')
      ? 'start'
      : data.legend.position.includes('right')
        ? 'start'
        : 'center',
    position: data.legend.position.includes('bottom')
      ? 'bottom'
      : data.legend.position.includes('top')
        ? 'top'
        : 'bottom',
    labels: {
      // usePointStyle: true,
      fontColor: '#333',
      // // padding: 50,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
      align: 'end',
      anchor: 'end',
      // color: function (context: any) {
      //   return context.dataset.backgroundColor;
      // },
      // font: function (context: any) {
      //   var w = context.chart.width;
      //   return {
      //     size: w < 512 ? 12 : 14,
      //     weight: 'bold',
      //   };
      // },
      // formatter: function (value: any, context: any) {
      //   return NumberFormattingUtils.formatNumberWithCommas(value);
      // },
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        // stacked: true,
        gridLines: {
          display: data.gridLines.enableX,
        },
        // https://stackoverflow.com/questions/28716464/hiding-labels-on-y-axis-in-chart-js
        // barThickness: 65, // number (pixels) or 'flex'
        // maxBarThickness: 40, // number (pixels)
      },
    ],
    yAxes: [
      {
        // stacked: true,
        ticks: {
          display: data.gridLines.enableY,
          min: data?.startAxis?.min || undefined,
          suggestedMax: data?.startAxis?.max || undefined,
          // callback: function (label: any, index: any, labels: any) {
          //   return `${label} %`;
          // },
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});

// export const fetchChartsData = async (config: IChartsFetchData) : Promise<ICustomChartProps> => {
//   let data: IChartData = {
//     data: [],
//     labels: [],
//   };
//   try {
//     let result = await request(
//       (urlMethod?.toLowerCase() as any) || 'get',
//       Environment.API_BASE_URL + parseVariablesToUrl(url, globals)
//     );
//     let arr: Array<any> = result.data.data;

//     for (let element of arr) {
//       data.labels.push(_.get(element, config.x_axis_field, ''));
//       // let _arr = [];
//       // let dataset of config.datasets
//       for (let index in config.datasets) {
//         if (!Array.isArray(data.data[index])) {
//           data.data[index] = [];
//         }
//         let y = config.datasets[index].y_axis_field;
//         data.data[index].push(parseFloat(element[y]));
//       }
//     }
//     // console.log('ERRROR::', data);
//   } catch (error) {
//     // console.error('ERRROR::', error.message);
//   }
//   return data;
// };

const MAX_RANDOM_COLORS = 100;
let randomColors = () => Array.from({ length: MAX_RANDOM_COLORS }, () => RandomUtils.getRandomColor());
// () => new Array(MAX_RANDOM_COLORS).map(() => RandomUtils.getRandomColor());
// console.log("Random Colors:", randomColors());

export const convert = (data: IChartConfig): ICustomChartProps => {
  // console.log('DAAAA:', data.datasets);
  return {
    data: data.datasets.map(({ type, name, color, border_color }, index) => ({
      data: [],
      label: `${index}`,
      format: {
        label: name || `${index}`,
        type: type,
        backgroundColor: ['doughnut', 'pie'].includes(type) ? randomColors() : color,
        borderColor: border_color,
      },
    })),
    labels: [],
    options: defaultOptions({
      gridLines: data.gridLines,
      legend: data.legend,
      startAxis: data.startAxis,
    }),
    // limitPerPage?: number;
    chartType:
      data.genericChartType === 'combo-bar-line'
        ? ChartTypesEnum.COLUMN
        : data.genericChartType === 'bubble'
          ? ChartTypesEnum.BUBBLE
          : data.genericChartType === 'doughnut'
            ? ChartTypesEnum.DOUGHNUT
            : data.genericChartType === 'pie'
              ? ChartTypesEnum.PIE
              : ChartTypesEnum.LINE,
    title: data.cardTitle || undefined,
    // includePaging: true,
    height: data.height || undefined,
  };
};
