import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

export interface IModal {
  isOpen: boolean;
  type: string;
  data: any;
}

interface IState {
  UserProductsList: {
    status: StateStatus | null;
    data: any;
  };
  productsList: {
    status: StateStatus | null;
    data: any;
  };
  userOutputList: {
    status: StateStatus | null;
    data: any;
  };
  addUserProduct: {
    status: StateStatus | null;
    data: any;
  };
  updateUserProduct: {
    status: StateStatus | null;
  };
  updateProduct: {
    status: StateStatus | null;
  };
  deleteUserProduct: {
    status: StateStatus | null;
  };
  modal: IModal;
  selected: string | null;
  graphImg: {
    impacts_1: string;
    impacts_2: string;
    marineLitter_1_1: string;
    marineLitter_1_2: string;
    litter_2_1: string;
    litter_2_2: string;
    cost_per_category_year_1: string;
    cost_all_categories: string;
  };
  envImpactSelection: string;
  envDescSelection: string;
  productCategorySelection: string;
  exportModal: IModal;
}

const initialState: IState = {
  UserProductsList: {
    status: null,
    data: null,
  },
  productsList: {
    status: null,
    data: null,
  },
  userOutputList: {
    status: null,
    data: null,
  },
  addUserProduct: {
    status: null,
    data: null,
  },
  updateUserProduct: {
    status: null,
  },
  updateProduct: {
    status: null,
  },
  deleteUserProduct: {
    status: null,
  },
  modal: {
    isOpen: false,
    type: 'add',
    data: null,
  },
  selected: null,
  graphImg: {
    impacts_1: '',
    impacts_2: '',
    marineLitter_1_1: '',
    marineLitter_1_2: '',
    litter_2_1: '',
    litter_2_2: '',
    cost_per_category_year_1: '',
    cost_all_categories: '',
  },
  envImpactSelection: '',
  envDescSelection: '',
  productCategorySelection: '',
  exportModal: {
    isOpen: false,
    type: 'add',
    data: null,
  },
};

const UserProductsReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USERPRODUCTS_LIST:
      return {
        ...state,
        UserProductsList: {
          ...state.UserProductsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.UserProductsList.data
              : action.data,
        },
      };
    case Types.SET_PRODUCT_LIST:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.productsList.data
              : action.data,
        },
      };
    case Types.SET_USER_OUTPUT_LIST:
      return {
        ...state,
        userOutputList: {
          ...state.userOutputList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.userOutputList.data
              : action.data,
        },
      };
    case Types.SET_ADD_USER_PRODUCT:
      return {
        ...state,
        addUserProduct: {
          ...state.addUserProduct,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.addUserProduct.data
              : action.data,
        },
      };
    case Types.SET_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.data,
        },
      };
    case Types.SET_EXPORT_MODAL:
      return {
        ...state,
        exportModal: {
          ...state.exportModal,
          ...action.data,
        },
      };
    case Types.UPDATE_PRODUCT:
      return {
        ...state,
        updateProduct: {
          status: action.status || null,
        },
      };
    case Types.SET_SELECTED:
      return {
        ...state,
        selected: action.data,
      };
    case Types.SET_GRAPHS_IMG:
      return {
        ...state,
        graphImg: action.data,
      };
    case Types.SET_ENV_DESC_SELECTION:
      return {
        ...state,
        envDescSelection: action.data,
      };
    case Types.SET_ENV_IMPACT_SELECTION:
      return {
        ...state,
        envImpactSelection: action.data,
      };
    case Types.SET_PRODUCT_CATEGORY_SELECTION:
      return {
        ...state,
        productCategorySelection: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default UserProductsReducer;
