import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Environment } from 'config';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import * as UserProfileSelectors from '../../../../redux/reducers/userProfile_reducer/selectors';
import Actions from '../../../../redux/reducers/userProfile_reducer/actions';
import * as StringUtils from '../../../../utils/strings';
import { isNullOrUndefined } from 'util';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7',
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  avatar: {
    height: 100,
    width: 100,
  },
  dateText: {},
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 'calc(50% - 42px)',
    right: 'calc(50% - 70px)',
  },
  boxAvatar: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '-42px',
    '& .img-avatar': {
      borderRadius: '50%',
    },
  },
  img_logo: {
    height: 84,
    width: 84,
    objectFit: 'cover',
  },
  cardRoot: {
    padding: 0,
  },
}));

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userDetailsData = UserProfileSelectors.useGetUserProfile();
  console.log('userDetailsData', userDetailsData);

  return (
    <Card variant={'outlined'} className={clsx(classes.root)}>
      <CardContent classes={{ root: classes.cardRoot }}>
        <Box alignItems="center" display="flex" flexDirection="column">
          {userDetailsData.status === 'Success' ? (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  height: '66px',
                  backgroundColor: '#346CB0',
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              />
              <Box className={classes.boxAvatar}>
                <img
                  onError={(e: any) => {
                    e.target.src = require('assets/images/avatars/profile4.png');
                  }}
                  src={`${Environment.API_BASE_URL}/${userDetailsData?.data?.user?.avatarURL}`}
                  className={clsx(classes.img_logo, 'img-avatar')}
                />
              </Box>
            </Box>
          ) : (
            <CircularProgress />
          )}
          <Typography
            component={'p'}
            color="textPrimary"
            // gutterBottom
            variant="h5"
            style={{ margin: '30px 0' }}
          >
            {`${
              StringUtils.capitalizeFirstLetter(
                userDetailsData.data?.user?.firstname
              ) || ''
            } ${
              StringUtils.capitalizeFirstLetter(
                userDetailsData.data?.user?.surname
              ) || ''
            }`}
          </Typography>
        </Box>
      </CardContent>
      <CardActions className={classes.center}>
        <input
          accept="image/*"
          // className={classes.input}
          style={{ display: 'none' }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const files = _.get(event, 'target.files', []);
            // const file = event?.target?.files[0];
            files.length !== 0 &&
              dispatch(Actions.uploadAvatarAction({ file: files[0] }));
          }}
          id="raised-button-file"
          // multiple
          name="avatar"
          type="file"
        />
        <label htmlFor="raised-button-file">
          <IconButton component="span" aria-label="add">
            <AddCircleIcon style={{ color: '#0A84FF' }} fontSize="small" />
          </IconButton>
        </label>
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  //   className: PropTypes.string,
};

export default Profile;
