import React from 'react';
import moment from 'moment';
import ActionsBlock from './components/datatable/ActionsBlock';
import * as DatesUtils from '../../utils/dates';
import { Languages, AuthRoles } from '../../config';

const CONFIGURATION = {};

export const allowedActions = {};

export default CONFIGURATION;
//Columns
/*
     
   type 
    , totalnumberofguestsperyear 
    , totalnumberofrooms 
    , hotelguestcapacity 
    , restaurantcafecapacity 
    , totalnumberoftablesacrossallrestaurant 
    , beachbarcapacity 
    , totalnumberoftables 
    , totalcapacity 
    , user
     */
//table key
//
export const TableColumns = [
  {
    label: 'Scenario title',
    id: 'name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Establishement Type',
    id: 'type',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Establishment country',
    id: 'country',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Yearly Turnover',
    id: 'year',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Period of applicability – Start date',
    id: 'start_date',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {moment(cellValue).format('DD-MM-YYYY hh:mm:ss')}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Period of applicability – End date’',
    id: 'end_date',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {moment(cellValue).format('DD-MM-YYYY hh:mm:ss')}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Created at',
    id: 'createdAt',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {moment(cellValue).format('DD-MM-YYYY hh:mm:ss')}
      </React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Actions',
    align: 'center',
    id: 'action1',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ActionsBlock
        rowIndex={rowIndex}
        data={data}
        dataid={data[rowIndex]._id}
      />
    ),
    inData: false,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};

export const Translations = {
  page_title: {
    [Languages.Greek]: 'Scenario',
    [Languages.English]: 'Scenario',
  },
  manage_registration_title: {
    [Languages.Greek]: 'Scenario Title',
    [Languages.English]: 'Scenario Title',
  },
  manage_registration_description: {
    [Languages.Greek]: 'Scenario Description',
    [Languages.English]: 'Scenario Description',
  },
  table_title: {
    [Languages.Greek]: 'Title',
    [Languages.English]: 'Title',
  },
  ScenarioModalAddTitle: {
    [Languages.Greek]: 'Προσθήκη',
    [Languages.English]: 'Add',
  },
  ScenarioModalEditTitle: {
    [Languages.Greek]: 'Επεξεργασία',
    [Languages.English]: 'Edit',
  },
  ScenarioModalViewTitle: {
    [Languages.Greek]: 'Προεπισκόπηση',
    [Languages.English]: 'View',
  },
  addEditModalButton: {
    [Languages.Greek]: 'Submit',
    [Languages.English]: 'Submit',
  },
};
