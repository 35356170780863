import React, { ReactElement } from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import reactLogger from 'utils/logger';
import GridLayout from 'components/molecules/grid_layout';
import TranslatedText from 'components/atoms/translated_text';
import ApexCharts from '../apexcharts';
import ReactChartsJS2 from '../react_chartsjs2';

export default function GraphsLayout(): ReactElement {
  reactLogger.renderComponent('GraphsLayout');
  return (
    <React.Fragment>
      <GridLayout
        spacing={1}
        elements={[
          {
            size: 12,
            element: <ApexCharts />,
            id: 'react-chartsjs2',
          },          
          {
            size: 12,
            element: <ReactChartsJS2 />,
            id: 'apex-charts',
          },
 
          // {
          //   size: 6,
          //   element: <ImportanceOfWeekDayGraph />,
          //   id: '4',
          // },
          // {
          //   size: 6,
          //   element: <DailyVisitorsOfWeekGraph />,
          //   id: '5',
          // },
          // {
          //   size: 3,
          //   element: <PeekHourPerWeekGraph />,
          //   id: '6',
          // },
          // {
          //   size: 3,
          //   element: <VisitorsByTimegroupGraph />,
          //   id: '7',
          // },
        ]}
      />
    </React.Fragment>
  );
}
