import { LanguagesEnum } from 'config/languages';

const Configuration = {
  backgroundImage: require('assets/images/backgrounds/background_login_register.png'),
  registerIcon: require('assets/images/logos/supmed_logo2.png'),
};

export default Configuration;

export const Translations = {
  signup_title: {
    [LanguagesEnum.English]: 'Sign Up',
    [LanguagesEnum.Greek]: 'Εγγραφείτε',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'This is required',
    [LanguagesEnum.Greek]: 'Υποχρεωτικό πεδίο',
  },
  form_field_firstname: {
    [LanguagesEnum.English]: 'First Name',
    [LanguagesEnum.Greek]: 'Όνομα',
  },
  form_field_surname: {
    [LanguagesEnum.English]: 'Surname',
    [LanguagesEnum.Greek]: 'Επίθετο',
  },
  form_field_birthdate: {
    [LanguagesEnum.English]: 'Birth Date',
    [LanguagesEnum.Greek]: 'Ημέρα Γενεθλίων',
  },
  form_field_username: {
    [LanguagesEnum.English]: 'Username',
    [LanguagesEnum.Greek]: 'Username',
  },
  form_field_password: {
    [LanguagesEnum.English]: 'Password',
    [LanguagesEnum.Greek]: 'Κωδικός',
  },
  form_field_confirm_password: {
    [LanguagesEnum.English]: 'Confirm Password',
    [LanguagesEnum.Greek]: 'Επιβεβαίωση Κωδικού',
  },
  form_button_register: {
    [LanguagesEnum.English]: 'Sign up',
    [LanguagesEnum.Greek]: 'Εγγραφή',
  },
  label_forgot_password: {
    [LanguagesEnum.English]: 'Forgot Password',
    [LanguagesEnum.Greek]: 'Ξεχάσατε κωδικό;',
  },
  form_email: {
    [LanguagesEnum.English]: 'Email Address',
    [LanguagesEnum.Greek]: 'Διεύθυνση Email',
  },
  form_confirm_email: {
    [LanguagesEnum.English]: 'Confirm Email Address',
    [LanguagesEnum.Greek]: 'Επιβεβαίωση Διεύθυνσης Email',
  },
  back_to_login: {
    [LanguagesEnum.English]: 'Back to login',
    [LanguagesEnum.Greek]: 'Επιστροφή στο Login',
  },
  already_have_an_account: {
    [LanguagesEnum.English]: 'Already have an account?',
    [LanguagesEnum.Greek]: 'Εχετε ήδη λογαριασμό;',
  },
  form_field_gender: {
    [LanguagesEnum.English]: 'Gender',
    [LanguagesEnum.Greek]: 'Φύλο',
  },
  form_field_nationality: {
    [LanguagesEnum.English]: 'Nationality',
    [LanguagesEnum.Greek]: 'Εθνικότητα',
  },
  form_field_accept_term: {
    [LanguagesEnum.English]: 'Accept terms & conditions',
    [LanguagesEnum.Greek]: 'Αποδεχτείτε τους όρους και τις προϋποθέσεις',
  },
  have_an_account: {
    [LanguagesEnum.English]: 'Your have an account? ',
    [LanguagesEnum.Greek]: 'Έχετε λογαριασμό; ',
  },
  login: {
    [LanguagesEnum.English]: 'Login',
    [LanguagesEnum.Greek]: 'Σύνδεση',
  },
};
