import React from 'react';
import Datatable from 'components/molecules/datatable';
import { TableColumns } from '../configuration';
import * as ScenarioReducer from 'redux/reducers/scenario_reducer/selectors';
import { useDispatch } from 'react-redux';
import ScenarioActions from 'redux/reducers/scenario_reducer/actions';
import { Box } from '@material-ui/core';
import useStyles from './styles';

import { useSelectUserRoles } from 'redux/reducers/gl_auth_reducer/selectors';

import { Roles } from 'config/authRoles';

export default function DatatableList() {
  const result = ScenarioReducer.useGetActiveScenario();
  const paging = result.data?.paging;
  const dispatch = useDispatch();
  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  const classes = useStyles();
  const roles = useSelectUserRoles();
  const [tableColumns, setTableColumns] = React.useState(TableColumns);

  React.useEffect(() => {
    console.log('role', roles, Roles.GroupAdmin, typeof Roles.GroupAdmin);

    if (roles.length !== 0 && roles.includes(Roles.GroupAdmin)) {
      setTableColumns([
        ...TableColumns,
        {
          label: 'Group',
          id: 'group',
          render: (rowIndex: number, cellValue: any, columnIndex: number) => (
            <React.Fragment>{cellValue}</React.Fragment>
          ),
          inData: true,
        },
      ]);
    }
    if (roles.length !== 0 && roles.includes(Roles.EstablishmentAdmin)) {
      setTableColumns([
        ...TableColumns,
        {
          label: 'Establishment',
          id: 'establishment',
          render: (rowIndex: number, cellValue: any, columnIndex: number) => (
            <React.Fragment>{cellValue?.name}</React.Fragment>
          ),
          inData: true,
        },
      ]);
    }
  }, [roles]);

  return (
    <Box display="flex" flexDirection="column">
      <Datatable
        loading={true}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        // paging={paging}
        data={result?.data || []}
        columns={tableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          sortType.current = order;
          sortField.current = _sortField;
          dispatch(
            ScenarioActions.fetchScenarioListAction({
              sortField: _sortField,
              page: selectedPage,
              pageSize: pageSize,
              order: order,
            })
          );
        }}
      />
    </Box>
  );
}
