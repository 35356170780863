//  [REDUCER][TYPE]
import { IReduxType, typeCreatorV2 } from '../../utils/common';
export const SET_PRODUCT: string = typeCreatorV2('MainReducer', 'SetProduct');
export const INSERT_PRODUCT: string = typeCreatorV2(
  'MainReducer',
  'InsertProduct'
);
export const UPDATE_PRODUCT: string = typeCreatorV2(
  'MainReducer',
  'UpdateProduct'
);
export const SET_PRODUCT_PERSIST: string = typeCreatorV2(
  'MainReducer',
  'SetProductPersist'
);
export const SET_OPEN: string = typeCreatorV2('MainReducer', 'SetOpen');
