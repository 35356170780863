import _, { flatten } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
} from '@material-ui/core';
import CombineReducerActions from 'redux/reducers/combine_scenario_reducer/actions';
import Actions from 'redux/reducers/userproducts_reducer/actions';
import Description from './components/Description';

import { useGetCombineScenarios } from 'redux/reducers/scenario_reducer/selectors';
import {
  useGetUserOutput,
  useGetUserProduct,
} from 'redux/reducers/combine_scenario_reducer/selectors';
import DashboardBuilder from 'components/compounds/dashboard_builder';
import useStyles from './styles';
import GridLayout, { IElementsProps } from 'components/molecules/grid_layout';
import getBarConfig from './configurations/getBarConfig';
import getLineConfig from './configurations/getLineConfig';
import getColumnConfig from './configurations/getColumnConfig';
import getBubbleConfig, {
  EnvironmentImpact,
  EnvironmentImpactMapping,
} from './configurations/getBubbleConfig';

function getReductionMarineLitter(series: any[], projectedField: string) {
  return series.map((item: any) => {
    const _1stTotalEstimatedReductioninLitter =
      item.length !== 0
        ? item
            .map((item: any) => item[projectedField])
            .reduce((a: any, b: any) => a + b)
        : 0;
    const _2ndtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 2;
    const _3rdtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 3;
    const _4thtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 4;
    const _5thtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 5;
    return [
      _1stTotalEstimatedReductioninLitter,
      _2ndtTotalEstimatedReductioninLitter,
      _3rdtTotalEstimatedReductioninLitter,
      _4thtTotalEstimatedReductioninLitter,
      _5thtTotalEstimatedReductioninLitter,
    ];
  });
}

function getCostByYear(series: any[]) {
  return series.map((item: any) => {
    const _1stTotalCost =
      item.length !== 0
        ? item
            .map((item: any) => item.estimatedReductionInCost1stYear)
            .reduce((a: any, b: any) => a + b)
        : 0;

    const _2ndTotalCost =
      item.length !== 0
        ? item
            .map((item: any) => item.estimatedReductionInCost2ndYear)
            .reduce((a: any, b: any) => a + b)
        : 0;

    const _3rdtTotalCost =
      item.length !== 0
        ? item
            .map((item: any) => item.estimatedReductionInCost3rdYear)
            .reduce((a: any, b: any) => a + b)
        : 0;

    const _4thtTotalCost =
      item.length !== 0
        ? item
            .map((item: any) => item.estimatedReductionInCost4thYear)
            .reduce((a: any, b: any) => a + b)
        : 0;
    const _5thtTotalCost =
      item.length !== 0
        ? item
            .map((item: any) => item.estimatedReductionInCost5thYear)
            .reduce((a: any, b: any) => a + b)
        : 0;
    return [
      _1stTotalCost,
      _2ndTotalCost,
      _3rdtTotalCost,
      _4thtTotalCost,
      _5thtTotalCost,
    ];
  });
}

export default function CombineScenario() {
  const combineScenarios = useGetCombineScenarios();
  const dispatch = useDispatch();
  const classes = useStyles();
  const userOutput = useGetUserOutput();
  const userProduct = useGetUserProduct();
  const [productCategory, setProductCategory] = React.useState('');
  const [environmentImpact, setEnvironmentImpact] = React.useState('');
  const [environmentDesc, setEnvironmentDesc] = React.useState('');

  const [tab, setTab] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  const handleChangeProductCategory = (event: any) => {
    setProductCategory(event.target.value);
  };

  const handleChangeEnvImpact = (event: any) => {
    setEnvironmentImpact(event.target.value);
    setEnvironmentDesc(
      _.get(EnvironmentImpactMapping, event.target.value as string, '')
    );
  };
  useEffect(() => {
    if (combineScenarios.length > 0)
      dispatch(
        CombineReducerActions.fetchUserOutputByScenario({
          scanarios: combineScenarios,
        })
      );
    dispatch(
      CombineReducerActions.fetchUserProductByScenario({
        scanarios: combineScenarios,
      })
    );
  }, [combineScenarios]);

  useEffect(() => {
    if (userProduct.status === 'Success') {
      const defaultEnvImpact = Object.keys(EnvironmentImpact)[0];
      const productCategories = _.uniq(
        userProduct.data.items.map((item: any) => item.product.productCategory)
      );
      const defaultProductCategory =
        productCategories.length > 0 ? (productCategories[0] as string) : '';
      setProductCategory(defaultProductCategory);
      setEnvironmentImpact(defaultEnvImpact);
      setEnvironmentDesc(
        _.get(EnvironmentImpactMapping, defaultEnvImpact as string, '')
      );
    }
  }, [userProduct]);

  function renderPotential() {
    const useroutputs = _.groupBy(
      userOutput?.data?.items,
      (item: any) => item.scenario._id
    );
    const series = Object.keys(useroutputs).map((key: string) => {
      const output = useroutputs[key];
      return output;
    });
    const scenarios = series.map((item: any) => {
      return item[0]?.scenario?.name;
    });

    return (
      <GridLayout
        elements={[
          {
            id: 'Estimated reduction in yearly litter (kg)',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getBarConfig(
                      series,
                      [
                        '1.1 Estimated reduction in yearly plastic litter',
                        '(incl. plastic marine litter) across all product categories (kg)',
                      ],
                      'estimatedReductionInLitter',
                      'kg',
                      'litter_2_1',
                      scenarios
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'Estimated reduction in yearly litter (kg) line',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getLineConfig(
                      getReductionMarineLitter(
                        series,
                        'estimatedReductionInLitter'
                      ),
                      [
                        '1.2 Estimated reduction in yearly plastic litter',
                        '(incl. plastic marine litter) across all product categories (kg)',
                      ],
                      'estimatedReductionInLitter',
                      'kg',
                      'litter_2_2',
                      scenarios
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'Estimated reduction in yearly marine litter (Items)',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getBarConfig(
                      series,
                      [
                        '2.1 Estimated reduction in yearly plastic marine litter',
                        'across all product categories (items)',
                      ],
                      'estimatedReductionInMaritineLitter',
                      'items',
                      'marineLitter_1_1',
                      scenarios
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'Estimated reduction in yearly marine litter (Items) line',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getLineConfig(
                      getReductionMarineLitter(
                        series,
                        'estimatedReductionInMaritineLitter'
                      ),
                      [
                        '2.2 Estimated reduction in yearly plastic marine litter',
                        'across all product categories (items)',
                      ],
                      'estimatedReductionInLitter',
                      'items',
                      'marineLitter_1_2',
                      scenarios
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
        ]}
      />
    );
  }

  function renderSelect(title: string, showEnv: boolean) {
    return (
      <Paper style={{ margin: '10px 0px', padding: 5 }}>
        <Typography variant="body1">{title}</Typography>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel id="simple-select">Product Category</InputLabel>
          <Select
            labelId="simple-select"
            fullWidth
            value={productCategory}
            onChange={handleChangeProductCategory}
          >
            {userProduct.status === 'Success' ? (
              _.uniq(
                userProduct.data.items.map(
                  (item: any) => item.product.productCategory
                )
              ).map((item: any) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))
            ) : (
              <div>Loading....</div>
            )}
          </Select>
        </FormControl>
        {showEnv ? (
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="simple-select">
              Environmental impact category
            </InputLabel>
            <Select
              labelId="simple-select"
              fullWidth
              value={environmentImpact}
              onChange={handleChangeEnvImpact}
            >
              {Object.keys(EnvironmentImpact).map((item: any) => (
                <MenuItem key={item} value={item}>
                  {_.get(EnvironmentImpact, item, '')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        {showEnv ? <Typography>{environmentDesc}</Typography> : null}
      </Paper>
    );
  }
  function renderCost() {
    const useroutputs = _.groupBy(
      userOutput?.data?.items,
      (item: any) => item.scenario._id
    );
    const series = Object.keys(useroutputs).map((key: string) => {
      const output = useroutputs[key];
      return output;
    });
    const scenarios = series.map((item: any) => {
      return item[0]?.scenario?.name;
    });
    const lineData = series.map((item: any[]) => {
      return _.flatten(
        _.uniqBy(item, (e: any) => e.productCategory)
          .filter((e: any) => e.productCategory === productCategory)
          .map((obj: any) => [
            obj.estimatedReductionInCost1stYear,
            obj.estimatedReductionInCost2ndYear,
            obj.estimatedReductionInCost3rdYear,
            obj.estimatedReductionInCost4thYear,
            obj.estimatedReductionInCost5thYear,
          ])
      );
    });
    const lineGraphs: any[] = [];

    lineGraphs.push({
      id: `Cost Savings per catgory ${productCategory}`,
      size: 12,
      element: (
        <React.Fragment>
          {userOutput.status === 'Success' ? (
            <DashboardBuilder
              configuration={getLineConfig(
                lineData,
                [
                  `Projected cost savings for ${productCategory} (cumulative) [EUR]`,
                ],
                'estimatedReductionInLitter',
                '[EUR]',
                `${productCategory}`,
                scenarios
              )}
            />
          ) : (
            <div>loading...</div>
          )}
        </React.Fragment>
      ),
    });
    console.log('line,', lineData);
    // const lineGraphs = userOutput.data.items
    //   .filter((item: any) => item.productCategory === productCategory)
    //   .map((item: any) => {
    //     return {
    //       id: `Cost Savings per catgory ${item.productCategory}`,
    //       size: 12,
    //       element: (
    //         <React.Fragment>
    //           {userOutput.status === 'Success' ? (
    //             <DashboardBuilder
    //               configuration={getLineConfig(
    //                 [
    //                   item.estimatedReductionInCost1stYear,
    //                   item.estimatedReductionInCost2ndYear,
    //                   item.estimatedReductionInCost3rdYear,
    //                   item.estimatedReductionInCost4thYear,
    //                   item.estimatedReductionInCost5thYear,
    //                 ],
    //                 [
    //                   `Projected cost savings for ${item.productCategory} (cumulative) [EUR]`,
    //                 ],
    //                 'estimatedReductionInLitter',
    //                 '[EUR]',
    //                 `${item.productCategory}`
    //               )}
    //             />
    //           ) : (
    //             <div>loading...</div>
    //           )}
    //         </React.Fragment>
    //       ),
    //     };
    //   });

    return (
      <React.Fragment>
        <GridLayout
          elements={[
            {
              id: 'Cost Savings per Category (Year1)',
              size: 12,
              element: (
                <React.Fragment>
                  {userOutput.status === 'Success' ? (
                    <DashboardBuilder
                      configuration={getBarConfig(
                        series,
                        ['Cost savings per product category for Year 1 [EUR]'],
                        'estimatedReductionInCost1stYear',
                        '[EUR]',
                        'cost_per_category_year_1',
                        scenarios
                      )}
                    />
                  ) : (
                    <div>loading...</div>
                  )}
                </React.Fragment>
              ),
            },
          ]}
        />
        {renderSelect(
          'Please select product category to view cost projections across 5 years',
          false
        )}
        <GridLayout
          elements={[
            ...lineGraphs,
            {
              id: 'Total cost savings per category',
              size: 12,
              element: (
                <React.Fragment>
                  {userOutput.status === 'Success' ? (
                    <DashboardBuilder
                      configuration={getLineConfig(
                        getCostByYear(series),
                        [
                          'Total cost savings across all product categories (cumulative) [EUR]',
                        ],
                        'estimatedReductionInLitter',
                        '[EUR]',
                        'cost_all_categories',
                        scenarios
                      )}
                    />
                  ) : (
                    <div>loading...</div>
                  )}
                </React.Fragment>
              ),
            },
          ]}
        />
      </React.Fragment>
    );
  }

  function renderEnvironment() {
    const useroutputs = _.groupBy(
      userOutput?.data?.items,
      (item: any) => item.scenario._id
    );
    const series = Object.keys(useroutputs).map((key: string) => {
      const output = useroutputs[key];
      return output;
    });
    const scenarios = series.map((item: any) => {
      return item[0]?.scenario?.name;
    });
    const columnSeries = Object.keys(useroutputs).map((key: string) => {
      const output = useroutputs[key];

      return output
        .filter((item: any) => item.productCategory === productCategory)
        .map((item: any) => {
          return {
            scenario: item.scenario.name,
            productCategory: item.productCategory,
            climateChange: [
              item.climateChangeSUP,
              item.climateChangeOther,
            ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
            humanToxicity: [
              item.humanToxicitySUP,
              item.humanToxicityOther,
            ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
            acidificationPotential: [
              item.acidificationPotentialSUP,
              item.acidificationPotentialOther,
            ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
            respiratoryEffectsOnHuman: [
              item.respiratoryEffectsOnHumanSUP,
              item.respiratoryEffectsOnHumanOther,
            ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
            carcinogenicEffectsOnHuman: [
              item.carcinogenicEffectsOnHumanSUP,
              item.carcinogenicEffectsOnHumanOther,
            ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
            marineAquaticEcotoxicity: [
              item.marineAquaticEcotoxicitySUP,
              item.marineAquaticEcotoxicityOther,
            ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
            climateChangeImpactsAssociatedWithEOL: [
              item.climateChangeImpactsAssociatedWithEOLSUP,
              item.climateChangeImpactsAssociatedWithEOLOther,
            ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
          };
        });
    });

    const columnCharts: any[] = [];
    const charts = _.flatten(columnSeries).map((item: any) => {
      return {
        name: item.scenario,
        value: item[environmentImpact],
      };
    });
    var units = environmentDesc.match(/\[(.*?)\]/);

    var unit = units ? units[1] : '';
    const chart: IElementsProps = {
      id: environmentImpact,
      size: 6,
      element: (
        <React.Fragment>
          <DashboardBuilder
            configuration={getColumnConfig({
              title: _.get(EnvironmentImpact, environmentImpact, ''),
              series: charts,
              unit: unit,
              id: environmentImpact,
              labels: scenarios,
            })}
          />
        </React.Fragment>
      ),
    };
    columnCharts.push(chart);

    // const names =
    //   columnData.length !== 0
    //     ? Object.keys(columnData[0]).slice(1, Object.keys(columnData[0]).length)
    //     : [];
    // var units = envDescription.match(/\[(.*?)\]/);

    // var unit = units ? units[1] : '';
    // for (let i = 0; i < columnData.length; i++) {
    //   const chartWithName = {
    //     name: columnData[i].productCategory,
    //     data: [] as IElementsProps[],
    //   };
    //   for (let j = 0; j < names.length; j++) {
    //     const chart: IElementsProps = {
    //       id: `${names[j]}`,
    //       size: 6,
    //       element: (
    //         <React.Fragment>
    //           <DashboardBuilder
    //             configuration={getColumnConfig(
    //               columnData[i][names[j]],
    //               _.get(EnvironmentImpact, names[j], ''),
    //               unit,
    //               `${names[j]}`
    //             )}
    //           />
    //         </React.Fragment>
    //       ),
    //     };
    //     if (names[j] === environmentImpact) chartWithName.data.push(chart);
    //   }
    //   columnCharts.push(chartWithName);
    // }

    return (
      <React.Fragment>
        <GridLayout
          elements={[
            {
              id: 'env',
              size: 12,
              element: (
                <React.Fragment>
                  {userOutput.status === 'Success' ? (
                    <>
                      {Object.keys(useroutputs).map(
                        (key: any, index: number) => {
                          const serieData = useroutputs[key];
                          const name =
                            serieData.length > 0
                              ? serieData[0].scenario?.name
                              : '';
                          return (
                            <DashboardBuilder
                              key={index}
                              configuration={getBubbleConfig(
                                serieData,
                                `[Scenario ${name}] Impacts of adopting the use of alternatives to SUPs across all product categories (%)`,
                                '',
                                `impacts_${
                                  serieData.length > 0
                                    ? serieData[0].scenario?._id
                                    : ''
                                }`
                              )}
                            />
                          );
                        }
                      )}
                    </>
                  ) : (
                    <div>loading...</div>
                  )}
                </React.Fragment>
              ),
            },
          ]}
        />
        {renderSelect('To view more detailed results, please select:', true)}
        {columnCharts.map((item: any) => {
          const withDescription = [
            'climateChange',
            'climateChangeImpactsAssociatedWithEOL',
          ];

          const descriptionGrid = withDescription.includes(environmentImpact)
            ? [
                {
                  id: 'description',
                  size: 6,
                  element: <Description />,
                },
              ]
            : [];
          return (
            <div key={environmentImpact}>
              <Typography variant="h5">
                {_.get(EnvironmentImpact, environmentImpact, '')}
              </Typography>
              <GridLayout elements={[item, ...descriptionGrid]} />
            </div>
          );
        })}
        <GridLayout
          elements={[
            {
              id: 'env',
              size: 12,
              element: (
                <React.Fragment>
                  {userOutput.status === 'Success' ? (
                    <>
                      {Object.keys(useroutputs).map(
                        (key: any, index: number) => {
                          const serieData = useroutputs[key];
                          const name =
                            serieData.length > 0
                              ? serieData[0].scenario?.name
                              : '';
                          console.log(
                            'serieData',
                            serieData.filter(
                              (item: any) =>
                                item.productCategory === productCategory
                            )
                          );
                          return (
                            <DashboardBuilder
                              key={index}
                              configuration={getBubbleConfig(
                                serieData.filter(
                                  (item: any) =>
                                    item.productCategory === productCategory
                                ),
                                `[Scenario ${name}] Impacts of adopting the use of alternatives to SUPs for ${productCategory} (%)`,
                                '',
                                `2_impacts_${
                                  serieData.length > 0
                                    ? serieData[0].scenario?._id
                                    : ''
                                }`
                              )}
                            />
                          );
                        }
                      )}
                    </>
                  ) : (
                    <div>loading...</div>
                  )}
                </React.Fragment>
              ),
            },
          ]}
        />
        {/* <Box marginBottom="20px">
          <GridLayout
            elements={[
              {
                id: 'env impatc',
                size: 12,
                element: (
                  <React.Fragment>
                    {userOutput.status === 'Success' ? (
                      <React.Fragment>
                        {userOutput.data.items.filter(
                          (item: any) =>
                            item.productCategory === productCategory
                        ).length > 0 ? (
                          <DashboardBuilder
                            configuration={getBubbleConfig(
                              userOutput.data.items.filter(
                                (item: any) =>
                                  item.productCategory === productCategory
                              ),
                              `Impacts of adopting the use of alternatives to SUPs for ${productCategory} (%)`,
                              '',
                              'impacts_2'
                            )}
                          />
                        ) : null}
                      </React.Fragment>
                    ) : (
                      <div>loading...</div>
                    )}
                  </React.Fragment>
                ),
              },
            ]}
          />
        </Box> */}
      </React.Fragment>
    );
  }

  return (
    <Box marginTop="10px" marginBottom="50px">
      <AppBar position="static">
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="Visualization tab"
        >
          <Tab className={classes.normalCase} label="Environmental impacts" />
          <Tab className={classes.normalCase} label="Littering" />
          <Tab className={classes.normalCase} label="Costs" />
        </Tabs>
      </AppBar>
      {userOutput.status === 'Success' && tab === 1 && (
        <React.Fragment>
          <Box width="100%">
            <Card>
              <CardContent>
                <Typography variant="body2">
                  This set of results displays the potential reduction in
                  plastic littering that could be achieved by switching from
                  single-use plastics to the alternative products you have
                  selected. For the purpose of this tool, litter is defined as
                  that which is ‘in the environment’ – i.e. on the ground or
                  waterways. It excludes ‘litter’ deposited in ‘litter bins’.
                  The potential reduction in marine litter is measured in number
                  of items, while the potential reduction in litter (which
                  include marine litter) is measured in kilograms of litter.
                </Typography>
              </CardContent>
            </Card>
          </Box>
          {renderPotential()}
        </React.Fragment>
      )}
      {tab === 0 && (
        <React.Fragment>
          <Box width="100%">
            <Card>
              <CardContent>
                <Typography variant="body2">
                  This set of results displays the changes in environmental
                  impacts by switching from single-use plastics to the
                  alternative products you have selected. Based on your inputs
                  and by adopting a life-cycle approach that considers the
                  emissions to air associated with each product, the results
                  show the change in environmental impacts across a number of
                  environmental impact categories. Results are shown
                  collectively for the entirety of your product selection and
                  also separately for each of the product categories.
                </Typography>
                <Typography variant="body2">
                  Adopting the selection of alternatives, will result in the
                  following (%) changes in emissions associated with these
                  environmental impact categories:
                </Typography>
              </CardContent>
            </Card>
          </Box>
          {renderEnvironment()}
        </React.Fragment>
      )}
      {tab === 2 && (
        <React.Fragment>
          <Box width="100%">
            <Card>
              <CardContent>
                <Typography variant="body2">
                  This set of results displays the cost implications that occur
                  by switching from single-use plastics to the alternative
                  products you have selected. It provides an estimate of the
                  cost savings (or additional expenses) that might occur based
                  on your selection and displays how that might be projected
                  across a timeframe of 5 years. For single use products, it is
                  assumed that the annual consumption stays the same throughout
                  the 5 years, while for multi-use (MU) products, the
                  calculation assumes a replacement rate for each consecutive
                  year for each product category (as only a relatively small
                  portion of MU products will need to be replaced every year).
                </Typography>
              </CardContent>
            </Card>
          </Box>
          {renderCost()}
        </React.Fragment>
      )}
    </Box>
  );
}
