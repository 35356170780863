import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles';

const useStyles = makeStyles((theme) => ({
  titleLayout: {
    padding: 20,
    // backgroundColor: Colors.APP_COLOR_HIGHLIGHT(),
    color: 'white',
  },
  cardLayout: {
    marginBottom: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
}));

export default useStyles;
