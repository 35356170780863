import React, { useEffect, useRef } from 'react';
import {
  Document,
  View,
  Page,
  Image,
  Text,
  PDFViewer,
  StyleSheet,
  Font,
  pdf,
} from '@react-pdf/renderer';
import LoadingView from 'components/atoms/loading_view';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  Card,
  Box,
  Typography,
  CardContent,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { getBase64FromUrl } from './helper';

import GridLayout from 'components/molecules/grid_layout';
import ApexCharts from 'apexcharts';

import DashboardBuilder from 'components/compounds/dashboard_builder';
import html2canvas from 'html2canvas';

import getBarConfig from 'scenes/userproducts_page/configuration/getBarConfig';
import getLineConfig from 'scenes/userproducts_page/configuration/getLineConfig';
import getColumnConfig from 'scenes/userproducts_page/configuration/getColumnConfig';
import getBubbleConfig, {
  EnvironmentImpact,
  EnvironmentImpactMapping,
} from 'scenes/userproducts_page/configuration/getBubbleConfig';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import Header from './header';
import Footer from './footer';
import Description from '../userproducts_page/components/Description';

import {
  useGetGraphsImg,
  useGetEnvDescSelection,
  useGetEnvImpactSelection,
  useGetProductCategorySelection,
  useGetUserProductsList,
  useGetUserOutputList,
} from 'redux/reducers/userproducts_reducer/selectors';
import {
  useGetScenarioList,
  useGetSelectedScenario,
  useGetScenarioDetails,
} from 'redux/reducers/scenario_reducer/selectors';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import { Environment } from 'config';
import useStyles from 'scenes/userproducts_page/styles';
import Actions from 'redux/reducers/userproducts_reducer/actions';
import ActionsScenario from 'redux/reducers/scenario_reducer/actions';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});
const styles = StyleSheet.create({
  wrapper: { display: 'flex', flexDirection: 'row' },
  highlightText: {
    fontSize: 12,
    color: '#22BAF1',
    marginRight: 5,
    fontFamily: 'Roboto',
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
    fontWeight: '700',
  },

  text: {
    fontSize: 20,
    fontWeight: 700,
  },
  normalText: {
    fontSize: 12,
  },
  underline: {},
  cell: {
    width: '20%',
  },
});

const splitArray = (arr, size) => {
  const result = [];
  for (let i = 0; i < arr.length; i += size) {
    const chunk = arr.slice(i, i + size);
    result.push(chunk);
    // do whatever
  }

  return result;
};

function RenderAddedProducts({ listData, title, isAlternative }) {
  const [convertData, setConvertData] = React.useState([]);
  React.useEffect(() => {
    if (listData?.length > 0) {
      const convertImage = async () => {
        const data = listData?.map(async (item) => {
          const base64 = await getBase64FromUrl(
            `${Environment.API_BASE_URL}/${item.picture.path}`
          );
          return {
            ...item,
            img: base64,
          };
        });
        const dataConverted = await Promise.all(data);
        const convert = splitArray(dataConverted, 12);
        setConvertData(convert);
      };
      convertImage();
    }
  }, [listData]);

  return convertData.map((itemData, index) => {
    return (
      <div
        style={{
          width: '650px',
          height: '1000px',
          backgroundColor: '#fff',
          padding: '10px',
          position: 'relative',
        }}
        id={`details-${index}-${isAlternative ? 'alternative' : 'sup'}`}
      >
        <Header />
        <div style={{ marginTop: '20px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              paddingHorizontal: 10,
              marginBottom: 20,
            }}
          >
            {index === 0 && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <Typography>{title}</Typography>
              </div>
            )}
            {itemData?.map((item, index) => {
              return (
                <>
                  {isAlternative && (
                    <div>
                      <Typography
                        style={{ fontSize: '13px', fontWeight: 'bold' }}
                      >
                        {item?.addTitle ? item?.addTitle : ''}
                      </Typography>
                    </div>
                  )}

                  <div
                    key={index}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginBottom: 5,
                      paddingHorizontal: 10,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                      }}
                    >
                      <Typography style={{ fontSize: '12px' }}>
                        Product Category:
                      </Typography>
                      <Typography style={{ fontSize: '12px' }}>
                        {item.productCategory}
                      </Typography>
                    </div>
                    <div style={{ marginRight: '10px' }}>
                      {item.img ? (
                        <img
                          style={{ height: 50, width: 50 }}
                          src={`${item.img}`}
                        />
                      ) : (
                        <img
                          style={{ height: 50, width: 50 }}
                          src={require('assets/images/no-image-available.png')}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                      }}
                    >
                      <Typography style={{ fontSize: '12px' }}>
                        Product type:
                      </Typography>
                      <Typography style={{ fontSize: '12px' }}>
                        {item.productType}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                      }}
                    >
                      <Typography style={{ fontSize: '12px' }}>
                        Replacement Quantity:
                      </Typography>
                      <Typography style={{ fontSize: '12px' }}>
                        {item.quantity}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                      }}
                    >
                      <Typography style={{ fontSize: '12px' }}>
                        Cost per item (EUR):
                      </Typography>
                      <Typography style={{ fontSize: '12px' }}>
                        €{item.productCost}
                      </Typography>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div style={{ position: 'absolute', bottom: 20 }}>
          <Footer />
        </div>
      </div>
    );
  });
}

function RenderExpectedResult({ listData }) {
  const [convertData, setConvertData] = React.useState([]);
  const data = useGetGraphsImg();
  const listDataConvert = [...listData];
  React.useEffect(() => {
    if (listData?.length > 0) {
      listDataConvert.shift();
      const convert = splitArray(listDataConvert, 3);
      setConvertData(convert);
    }
  }, [listData]);

  return (
    <>
      <div
        style={{
          width: '650px',
          height: '1000px',
          backgroundColor: '#fff',
          padding: '10px',
          position: 'relative',
        }}
        id="expected-results"
      >
        <Header />

        <div style={{ marginTop: '20px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginBottom: '15px',
            }}
          >
            <Typography style={styles.title}>Expected Results</Typography>
          </div>
          <div>
            <Typography style={{ fontSize: '15px' }}>
              Environmental Impacts of adopting the use of alternatives to SUPs
              across all product categories (%)
            </Typography>
          </div>
          <div style={{ marginTop: '20px' }}>
            <img
              src={data.impacts_1}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div>
            <Typography style={{ fontSize: '15px', marginTop: '20px' }}>
              {listData?.[0]} - Climate change
            </Typography>
          </div>
          <div style={{ marginTop: '20px' }}>
            <img
              src={data[`${listData?.[0]}_impacts_3`]}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </div>

        <div style={{ position: 'absolute', bottom: 20 }}>
          <Footer />
        </div>
      </div>
      {convertData.map((itemData, index) => {
        return (
          <div
            style={{
              width: '650px',
              height: '1000px',
              backgroundColor: '#fff',
              padding: '10px',
              position: 'relative',
            }}
            id={`expected-results-${index}`}
          >
            <Header />
            {itemData?.map((item, index) => {
              return (
                <div key={index}>
                  <div>
                    <Typography style={{ fontSize: '15px', marginTop: '20px' }}>
                      {item} - Climate change
                    </Typography>
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <img
                      src={data[`${item}_impacts_3`]}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                </div>
              );
            })}

            <div style={{ position: 'absolute', bottom: 20 }}>
              <Footer />
            </div>
          </div>
        );
      })}
    </>
  );
}

export default () => {
  const history = useHistory();
  const data = useGetGraphsImg();
  const envDescription = useGetEnvDescSelection();
  const environmentImpact = useGetEnvImpactSelection();
  const dispatch = useDispatch();
  const userScenarioDetail = useGetScenarioDetails();

  const productCategory = useGetProductCategorySelection();
  const userProductsData = useGetUserProductsList();
  const userScenarioList = useGetScenarioList();
  const selectedScenario = useGetSelectedScenario();
  const userOutput = useGetUserOutputList();

  const ref = useRef(null);
  const classes = useStyles();

  const [groupByCategory, setGroupByCategory] = React.useState([]);
  const [scenario, setScenario] = React.useState(null);
  const { email } = useSelectUserData();
  const userProduct = useGetUserProductsList();

  React.useEffect(() => {
    userScenarioDetail.status !== 'Success' &&
      dispatch(ActionsScenario.viewScenarioAction({ id: selectedScenario }));
  }, []);

  React.useEffect(() => {
    const grouped = _.groupBy(userProductsData.data?.items, 'productCategory');
    const convertedData = Object.keys(grouped).map((key, rowIndex) => {
      const productList = grouped[key].sort((a, b) => {
        if (a.usageCategory < b.usageCategory) {
          return 1;
        }
        if (a.usageCategory > b.usageCategory) {
          return -1;
        }
        return 0;
      });

      const data = productList?.map((item, index) => {
        if (index === 0) {
          return { ...item, addTitle: item.productCategory };
        } else {
          return {
            ...item,
          };
        }
      });
      return data;
    });
    setGroupByCategory(convertedData.flat());
  }, [userProductsData.data?.items]);

  React.useEffect(() => {
    if (userScenarioDetail.status === 'Success') {
      setScenario(userScenarioDetail.data);
    }
  }, [userScenarioDetail]);
  const [productCategoryOption, setProductCategoryOption] = React.useState([]);
  const [envImpactOption, setEnvImpactOption] = React.useState([]);

  const handleChangeProductCategory = (event) => {
    dispatch(Actions.setProductCategorySelection(event.target.value));
  };
  const handleChangeEnvImpact = (event) => {
    dispatch(Actions.setEnvImpactSelection(event.target.value));
    dispatch(
      Actions.setEnvDescSelection(
        _.get(EnvironmentImpactMapping, event.target.value, '')
      )
    );
  };
  React.useEffect(() => {
    if (userProduct.status === 'Success') {
      const options = _.uniq(
        userProduct.data?.items.map((item) => item.productCategory)
      );
      if (options.length > 0) {
        setProductCategoryOption(options);
      }
    }
  }, [userProduct]);
  function renderAddedProducts() {
    const selectedSupProducts = userProductsData.data?.items.filter((item) => {
      if (item.usageCategory === 'SUP' && item.selected) {
        return true;
      } else {
        return false;
      }
    });
    return selectedSupProducts.map((item) => (
      <View
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          marginBottom: 5,
          paddingHorizontal: 10,
        }}
      >
        <View style={[styles.normalText, styles.cell]}>
          <Text>Product Category: </Text>
          <Text>{item.productCategory}</Text>
        </View>
        <View style={[styles.normalText, styles.cell]}>
          {item.picture ? (
            <Image
              style={{ height: 50, width: 50 }}
              src={`${Environment.API_BASE_URL}/${item.picture.path}`}
            />
          ) : (
            <Image
              style={{ height: 50, width: 50 }}
              src={require('assets/images/no-image-available.png')}
            />
          )}
        </View>
        <View style={[styles.normalText, styles.cell]}>
          <Text>Product type: </Text>
          <Text>{item.productType}</Text>
        </View>
        <View style={[styles.normalText, styles.cell]}>
          <Text>Annual usage: </Text>
          <Text>{item.quantity}</Text>
        </View>
        <View style={[styles.normalText, styles.cell]}>
          <Text> Cost per item (EUR):</Text>
          <Text>€{item.productCost}</Text>
        </View>
      </View>
    ));
  }
  function renderSelect(title, showEnv) {
    return (
      <Box style={{ margin: '10px 20px', padding: 5 }}>
        <Typography style={{ padding: 5 }} variant="body1">
          {title}
        </Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            classes={{ focused: classes.focused }}
            style={{ backgroundColor: '#fff' }}
            id="demo-simple-select-outlined-label"
          >
            Product Category
          </InputLabel>
          <Select
            labelId="simple-select"
            fullWidth
            value={productCategory}
            onChange={handleChangeProductCategory}
          >
            {userProduct.status === 'Success' ? (
              productCategoryOption.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))
            ) : (
              <div>Loading....</div>
            )}
          </Select>
        </FormControl>
        {showEnv ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              classes={{ focused: classes.focused }}
              style={{ backgroundColor: '#fff' }}
              id="demo-simple-select-outlined-label"
            >
              Environmental impact category
            </InputLabel>
            <Select
              labelId="simple-select"
              fullWidth
              value={environmentImpact}
              onChange={handleChangeEnvImpact}
            >
              {userProduct.status === 'Success' ? (
                envImpactOption.map((item) => (
                  <MenuItem key={item} value={item}>
                    {_.get(EnvironmentImpact, item, '')}
                  </MenuItem>
                ))
              ) : (
                <div>Loading....</div>
              )}
            </Select>
          </FormControl>
        ) : null}
        {showEnv ? <Typography>{envDescription}</Typography> : null}
      </Box>
    );
  }
  function renderAlternativeProducts() {
    return (
      <View style={{ paddingHorizontal: 10 }}>
        {Object.keys(groupByCategory).map((key, rowIndex) => {
          const productList = groupByCategory[key].sort((a, b) => {
            if (a.usageCategory < b.usageCategory) {
              return 1;
            }
            if (a.usageCategory > b.usageCategory) {
              return -1;
            }
            return 0;
          });
          return (
            <View>
              <Text style={styles.normalText}>
                {productList.length !== 0 ? productList[0].productCategory : ''}
              </Text>
              {productList
                .filter((item) => item.selected === true)
                .map((item) => (
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginBottom: 5,
                      paddingHorizontal: 10,
                    }}
                  >
                    <View style={[styles.normalText, styles.cell]}>
                      <Text>Product Category: </Text>
                      <Text>{item.productCategory}</Text>
                    </View>
                    <View style={[styles.normalText, styles.cell]}>
                      {item.picture ? (
                        <Image
                          style={{ height: 50, width: 50 }}
                          src={`${Environment.API_BASE_URL}/${item.picture.path}`}
                        />
                      ) : (
                        <Image
                          style={{ height: 50, width: 50 }}
                          src={require('assets/images/no-image-available.png')}
                        />
                      )}
                    </View>
                    <View style={[styles.normalText, styles.cell]}>
                      <Text>Product type: </Text>
                      <Text>{item.productType}</Text>
                    </View>
                    <View style={[styles.normalText, styles.cell]}>
                      <Text>Annual usage: </Text>
                      <Text>{item.quantity}</Text>
                    </View>
                    <View style={[styles.normalText, styles.cell]}>
                      <Text> Cost per item (EUR):</Text>
                      <Text>€{item.productCost}</Text>
                    </View>
                  </View>
                ))}
            </View>
          );
        })}
      </View>
    );
  }
  function renderFooter(marginTop) {
    return (
      <View
        style={{
          display: 'flex',
          fontSize: 14,
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0 20px',
          justifyContent: 'space-between',
        }}
      >
        <Text style={{ fontSize: 10, marginRight: 10 }}>
          The project is implemented by:
        </Text>
        <Image
          style={{ height: 30, width: 100, marginRight: 10 }}
          src={require('assets/images/logos/aspon.jpeg')}
        />
        <Image
          style={{ height: 30, width: 30, marginRight: 10 }}
          src={require('assets/images/logos/anelixis.png')}
        />
        <Image
          style={{ height: 30, width: 100, marginRight: 10 }}
          src={require('assets/images/logos/aisenv.png')}
        />
        <Image
          style={{ height: 30, width: 40, marginRight: 10 }}
          src={require('assets/images/logos/heraklion.png')}
        />
        <Image
          style={{ height: 30, width: 30, marginRight: 10 }}
          src={require('assets/images/logos/logo-1.png')}
        />
        <Image
          style={{ height: 30, width: 100 }}
          src={require('assets/images/logos/cellock.png')}
        />
      </View>
    );
  }
  function renderHeader() {
    return (
      <View style={{ paddingBottom: '20px' }}>
        <Image
          style={{ height: 'auto', width: '100%' }}
          src={require('assets/images/logos/logo_full.png')}
        />
      </View>
    );
  }

  React.useEffect(() => {
    async function download() {
      const keyChart = productCategoryOption?.map(
        (item) => `${item}_impacts_3`
      );
      const graphImgIds = [
        'impacts_1',
        'impacts_2',
        'marineLitter_1_1',
        'marineLitter_1_2',
        'litter_2_1',
        'litter_2_2',
        'cost_per_category_year_1',
        'impacts_3',
        'cost_all_categories',
        'cost_1',
        ...keyChart,
      ];
      const imgData = {};
      for (let index = 0; index < graphImgIds.length; index++) {
        const element = graphImgIds[index];
        const img = await ApexCharts.exec(element, 'dataURI');
        _.set(imgData, element, img?.imgURI);
      }

      dispatch(Actions.setGraphsImg(imgData));
    }
    if (scenario) {
      setTimeout(() => {
        download();
      }, 3000);
    }
  }, [scenario]);

  function renderPotential() {
    const _1stTotalEstimatedReductionMarineLitter =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item) => item.estimatedReductionInMaritineLitter)
            .reduce((a, b) => a + b)
        : 0;

    const _2ndTotalEstimatedReductionMarineLitter =
      _1stTotalEstimatedReductionMarineLitter * 2;
    const _3rdTotalEstimatedReductionMarineLitter =
      _1stTotalEstimatedReductionMarineLitter * 3;
    const _4thTotalEstimatedReductionMarineLitter =
      _1stTotalEstimatedReductionMarineLitter * 4;
    const _5thTotalEstimatedReductionMarineLitter =
      _1stTotalEstimatedReductionMarineLitter * 5;

    const _1stTotalEstimatedReductioninLitter =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item) => item.estimatedReductionInLitter)
            .reduce((a, b) => a + b)
        : 0;
    const _2ndtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 2;
    const _3rdtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 3;
    const _4thtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 4;
    const _5thtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 5;

    return (
      <GridLayout
        elements={[
          {
            id: 'Estimated reduction in yearly litter (kg)',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getBarConfig(
                      userOutput.data.items,
                      '',
                      'estimatedReductionInLitter',
                      'kg',
                      'litter_2_1',
                      ''
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'Estimated reduction in yearly litter (kg) line',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getLineConfig(
                      [
                        _1stTotalEstimatedReductioninLitter,
                        _2ndtTotalEstimatedReductioninLitter,
                        _3rdtTotalEstimatedReductioninLitter,
                        _4thtTotalEstimatedReductioninLitter,
                        _5thtTotalEstimatedReductioninLitter,
                      ],
                      '',
                      'estimatedReductionInLitter',
                      'kg',
                      'litter_2_2',
                      '#F2994A'
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'Estimated reduction in yearly marine litter (Items)',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getBarConfig(
                      userOutput.data.items,
                      '',
                      'estimatedReductionInMaritineLitter',
                      'items',
                      'marineLitter_1_1',
                      '#D9A21B'
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'Estimated reduction in yearly marine litter (Items) line',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getLineConfig(
                      [
                        _1stTotalEstimatedReductionMarineLitter,
                        _2ndTotalEstimatedReductionMarineLitter,
                        _3rdTotalEstimatedReductionMarineLitter,
                        _4thTotalEstimatedReductionMarineLitter,
                        _5thTotalEstimatedReductionMarineLitter,
                      ],
                      '',
                      'estimatedReductionInLitter',
                      'items',
                      'marineLitter_1_2',
                      '#27AE60'
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
        ]}
      />
    );
  }
  function renderCost() {
    const lineGraphs = userOutput.data.items
      .filter((item) => item.productCategory === productCategory)
      .map((item) => {
        return {
          id: `Cost Savings per catgory ${item.productCategory}`,
          size: 12,
          element: (
            <React.Fragment>
              {userOutput.status === 'Success' ? (
                <DashboardBuilder
                  configuration={getLineConfig(
                    [
                      item.estimatedReductionInCost1stYear,
                      item.estimatedReductionInCost2ndYear,
                      item.estimatedReductionInCost3rdYear,
                      item.estimatedReductionInCost4thYear,
                      item.estimatedReductionInCost5thYear,
                    ],
                    '',
                    'estimatedReductionInLitter',
                    '[EUR]',
                    'cost_1',
                    '#D9A21B'
                  )}
                />
              ) : (
                <div>loading...</div>
              )}
            </React.Fragment>
          ),
        };
      });

    const _1stTotalCost =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item) => item.estimatedReductionInCost1stYear)
            .reduce((a, b) => a + b)
        : 0;

    const _2ndTotalCost =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item) => item.estimatedReductionInCost2ndYear)
            .reduce((a, b) => a + b)
        : 0;

    const _3rdtTotalCost =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item) => item.estimatedReductionInCost3rdYear)
            .reduce((a, b) => a + b)
        : 0;

    const _4thtTotalCost =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item) => item.estimatedReductionInCost4thYear)
            .reduce((a, b) => a + b)
        : 0;
    const _5thtTotalCost =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item) => item.estimatedReductionInCost5thYear)
            .reduce((a, b) => a + b)
        : 0;

    return (
      <React.Fragment>
        <GridLayout
          elements={[
            {
              id: 'Cost Savings per Category (Year1)',
              size: 12,
              element: (
                <React.Fragment>
                  {userOutput.status === 'Success' ? (
                    <DashboardBuilder
                      configuration={getBarConfig(
                        userOutput.data.items,
                        '',
                        'estimatedReductionInCost1stYear',
                        '[EUR]',
                        'cost_per_category_year_1',
                        ''
                      )}
                    />
                  ) : (
                    <div>loading...</div>
                  )}
                </React.Fragment>
              ),
            },
          ]}
        />
        <GridLayout
          elements={[
            ...lineGraphs,
            {
              id: 'Total cost savings per category',
              size: 12,
              element: (
                <React.Fragment>
                  {userOutput.status === 'Success' ? (
                    <DashboardBuilder
                      configuration={getLineConfig(
                        [
                          _1stTotalCost,
                          _2ndTotalCost,
                          _3rdtTotalCost,
                          _4thtTotalCost,
                          _5thtTotalCost,
                        ],
                        '',
                        'estimatedReductionInLitter',
                        '[EUR]',
                        'cost_all_categories',
                        '#27AE60'
                      )}
                    />
                  ) : (
                    <div>loading...</div>
                  )}
                </React.Fragment>
              ),
            },
          ]}
        />
      </React.Fragment>
    );
  }
  function renderEnvironment() {
    const columnData = userOutput.data.items
      .filter((item) => item.scenario === selectedScenario)
      .map((output) => {
        return {
          productCategory: output.productCategory,
          climateChange: [
            output.climateChangeSUP,
            output.climateChangeOther,
          ].map((item) => (item ? parseFloat(item.toFixed(9)) : 0)),
          humanToxicity: [
            output.humanToxicitySUP,
            output.humanToxicityOther,
          ].map((item) => (item ? parseFloat(item.toFixed(9)) : 0)),
          acidificationPotential: [
            output.acidificationPotentialSUP,
            output.acidificationPotentialOther,
          ].map((item) => (item ? parseFloat(item.toFixed(9)) : 0)),
          respiratoryEffectsOnHuman: [
            output.respiratoryEffectsOnHumanSUP,
            output.respiratoryEffectsOnHumanOther,
          ].map((item) => (item ? parseFloat(item.toFixed(9)) : 0)),
          carcinogenicEffectsOnHuman: [
            output.carcinogenicEffectsOnHumanSUP,
            output.carcinogenicEffectsOnHumanOther,
          ].map((item) => (item ? parseFloat(item.toFixed(9)) : 0)),
          marineAquaticEcotoxicity: [
            output.marineAquaticEcotoxicitySUP,
            output.marineAquaticEcotoxicityOther,
          ].map((item) => (item ? parseFloat(item.toFixed(9)) : 0)),
          climateChangeImpactsAssociatedWithEOL: [
            output.climateChangeImpactsAssociatedWithEOLSUP,
            output.climateChangeImpactsAssociatedWithEOLOther,
          ].map((item) => (item ? parseFloat(item.toFixed(9)) : 0)),
        };
      });
    const handleColumnsData = _.uniqBy(
      columnData,
      (item) => item.productCategory
    );
    const columnCharts = [];
    const names =
      handleColumnsData.length !== 0
        ? Object.keys(handleColumnsData[0]).slice(
            1,
            Object.keys(handleColumnsData[0]).length
          )
        : [];
    var units = envDescription.match(/\[(.*?)\]/);

    var unit = units ? units[1] : '';
    for (let i = 0; i < handleColumnsData.length; i++) {
      const chartWithName = {
        name: handleColumnsData[i].productCategory,
        data: [],
      };
      for (let j = 0; j < names.length; j++) {
        const chart = {
          id: `${names[j]}`,
          size: 12,
          element: (
            <React.Fragment>
              <DashboardBuilder
                configuration={getColumnConfig(
                  handleColumnsData[i]['climateChange'],
                  ``,
                  unit,
                  `${handleColumnsData[i].productCategory}_impacts_3`
                )}
              />
            </React.Fragment>
          ),
        };
        if (names[j] === environmentImpact) chartWithName.data.push(chart);
      }
      columnCharts.push(chartWithName);
    }

    return (
      <React.Fragment>
        <Typography
          style={{ fontWeight: 500, fontSize: 16, padding: '0 20px' }}
        >
          Impacts of adopting the use of alternatives to SUPs across all product
          categories (%)
        </Typography>
        <Box style={{ padding: '0 20px' }}>
          <GridLayout
            elements={[
              {
                id: 'env',
                size: 12,
                element: (
                  <React.Fragment>
                    {userOutput.status === 'Success' ? (
                      <DashboardBuilder
                        configuration={getBubbleConfig(
                          userOutput.data.items,
                          '',
                          '',
                          'impacts_1'
                        )}
                      />
                    ) : (
                      <div>loading...</div>
                    )}
                  </React.Fragment>
                ),
              },
            ]}
          />
        </Box>
        {renderSelect('To view more detailed results, please select:', true)}
        <Box style={{ margin: '0 20px' }}>
          {columnCharts.map((item) => {
            const withDescription = [
              'climateChange',
              'climateChangeImpactsAssociatedWithEOL',
            ];

            const descriptionGrid = withDescription.includes(environmentImpact)
              ? [
                  {
                    id: 'description',
                    size: 6,
                    element: <Typography> </Typography>,
                  },
                ]
              : [];
            return (
              <div key={item.name}>
                <Typography variant="h5">
                  {_.get(EnvironmentImpact, item.name, '')}
                </Typography>
                <GridLayout elements={[...item.data, ...descriptionGrid]} />
              </div>
            );
          })}
        </Box>

        <Box margin="20px">
          <Typography>
            {`Impacts of adopting the use of alternatives to SUPs for ${productCategory} (%)`}
          </Typography>
          <GridLayout
            elements={[
              {
                id: 'env impatc',
                size: 12,
                element: (
                  <React.Fragment>
                    {userOutput.status === 'Success' ? (
                      <React.Fragment>
                        {userOutput.data.items.filter(
                          (item) => item.productCategory === productCategory
                        ).length > 0 ? (
                          <DashboardBuilder
                            configuration={getBubbleConfig(
                              userOutput.data.items.filter(
                                (item) =>
                                  item.productCategory === productCategory
                              ),
                              ``,
                              '',
                              'impacts_2'
                            )}
                          />
                        ) : null}
                      </React.Fragment>
                    ) : (
                      <div>loading...</div>
                    )}
                  </React.Fragment>
                ),
              },
            ]}
          />
        </Box>
      </React.Fragment>
    );
  }
  async function getDataUri() {
    const graphImgIds = [
      'impacts_1',
      'impacts_2',
      'marineLitter_1_1',
      'marineLitter_1_2',
      'litter_2_1',
      'litter_2_2',
      'cost_per_category_year_1',
      `${productCategory}`,
      'cost_all_categories',
      environmentImpact,
    ];
    const imgData = {};
    for (let index = 0; index < graphImgIds.length; index++) {
      const element = graphImgIds[index];
      const img = await ApexCharts.exec(element, 'dataURI');
      _.set(imgData, element, img.imgURI);
    }

    dispatch(Actions.setGraphsImg(imgData));
  }

  function renderExportModal() {
    return (
      <React.Fragment>
        <React.Fragment>
          <Box width="100%">
            <Card>
              <CardContent classes={{ root: classes.cardContentRoot }}>
                <Box style={{ padding: '0 20px' }}>
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }}>
                    Environmental Impacts
                  </Typography>
                  <Typography variant="body2">
                    This set of results displays the changes in environmental
                    impacts by switching from single-use plastics to the
                    alternative products you have selected. Based on your inputs
                    and by adopting a life-cycle approach that considers the
                    emissions to air associated with each product, the results
                    show the change in environmental impacts across a number of
                    environmental impact categories. Results are shown
                    collectively for the entirety of your product selection and
                    also separately for each of the product categories.
                  </Typography>
                  <Typography variant="body2">
                    Adopting the selection of alternatives, will result in the
                    following (%) changes in emissions associated with these
                    environmental impact categories:
                  </Typography>
                </Box>

                <Box>{renderEnvironment()}</Box>
              </CardContent>
            </Card>
          </Box>
        </React.Fragment>
        <React.Fragment>
          <Box width="100%" marginTop="20px">
            <Card ref={ref}>
              <CardContent>
                <Typography style={{ fontSize: '16px', fontWeight: '500' }}>
                  Littering
                </Typography>
                <Typography variant="body2">
                  This set of results displays the potential reduction in
                  plastic littering that could be achieved by switching from
                  single-use plastics to the alternative products you have
                  selected. For the purpose of this tool, litter is defined as
                  that which is ‘in the environment’ – i.e. on the ground or
                  waterways. It excludes ‘litter’ deposited in ‘litter bins’.
                  The potential reduction in marine litter is measured in number
                  of items, while the potential reduction in litter (which
                  include marine litter) is measured in kilograms of litter.
                </Typography>
                {renderPotential()}
              </CardContent>
            </Card>
          </Box>
        </React.Fragment>

        <React.Fragment>
          <Box width="100%" marginTop="20px">
            <Card>
              <CardContent>
                <Typography style={{ fontSize: '16px', fontWeight: '500' }}>
                  Cost
                </Typography>
                <Typography variant="body2">
                  This set of results displays the cost implications that occur
                  by switching from single-use plastics to the alternative
                  products you have selected. It provides an estimate of the
                  cost savings (or additional expenses) that might occur based
                  on your selection and displays how that might be projected
                  across a timeframe of 5 years. For single use products, it is
                  assumed that the annual consumption stays the same throughout
                  the 5 years, while for multi-use (MU) products, the
                  calculation assumes a replacement rate for each consecutive
                  year for each product category (as only a relatively small
                  portion of MU products will need to be replaced every year).
                </Typography>
                {renderCost()}
              </CardContent>
            </Card>
          </Box>
        </React.Fragment>
      </React.Fragment>
    );
  }

  const renderPdf = () => {
    const selectedSupProducts = userProductsData.data?.items.filter((item) => {
      if (item.usageCategory === 'SUP' && item.selected) {
        return true;
      } else {
        return false;
      }
    });
    return (
      <div>
        <div
          style={{
            width: '650px',
            height: '1000px',
            backgroundColor: '#fff',
            padding: '10px',
            position: 'relative',
          }}
          id="introduction"
        >
          <Header />
          <div
            style={{
              marginBottom: '15px',
            }}
          >
            <Typography style={styles.title}>Introduction</Typography>
          </div>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <Typography
              style={{
                fontFamily: 'Oswald',
                fontSize: '15px',
                fontWeight: '700',
              }}
            >
              <span
                style={{
                  fontFamily: 'Oswald',
                  textDecoration: 'underline',
                }}
              >
                Project:
              </span>
              «Reducing the Consumption and Disposal of Single-use Plastics in
              the Tourism Industry in Cyprus, Greece and Malta»
            </Typography>
          </div>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <Typography
              style={{
                fontFamily: 'Oswald',
                fontSize: '15px',
                fontWeight: '700',
              }}
            >
              <span
                style={{ fontFamily: 'Oswald', textDecoration: 'underline' }}
              >
                Tool:
              </span>
              Decision-Support Tool (DST) to facilitate the reduction in
              consumption and disposal of single-use plastics (SUP) in the
              tourism industry
            </Typography>
          </div>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Oswald',
                fontSize: '15px',
                fontWeight: '700',
              }}
            >
              About this project
            </Typography>
            <Typography style={{ fontFamily: 'Oswald', fontSize: '14px' }}>
              This project is funded by Iceland, Liechtenstein and Norway
              through the EEA and Norway Grants Fund for Regional Cooperation.
              The project aims to reduce the adverse effects of human activities
              on the environment, by supporting tourism establishments in
              Cyprus, Malta and Greece to reduce consumption and disposal of
              single-use plastics (SUP) in line with EU Directive 2019/904, by
              replacing commonly used SUP with environmentally friendlier,
              readily available and affordable alternatives.
            </Typography>
          </div>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Oswald',
                fontSize: '15px',
                fontWeight: '700',
              }}
            >
              Understanding the problem
            </Typography>
            <Typography style={{ fontFamily: 'Oswald', fontSize: '14px' }}>
              While plastic is an important material for our lives and for the
              European economy, it is not widely recycled currently (less than
              30% of plastic waste collected) and is estimated to account for
              over 80% of marine litter. Marine litter found on beaches and in
              the seas causes economic, social and environmental damage. Plastic
              marine litter has adverse effects on ecosystems and biodiversity
              and human health, as plastics enter the food chain in the form of
              microplastics. Socioeconomic impacts include clean up and
              retrieval costs, damage to tourism, shipping, fishing and
              aquaculture, as well as the loss of valuable resources for the
              economy.
            </Typography>
          </div>
          <div
            style={{
              marginBottom: '10px',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Oswald',
                fontSize: '15px',
                fontWeight: '700',
              }}
            >
              About this decision-support tool
            </Typography>
            <Typography style={{ fontFamily: 'Oswald', fontSize: '14px' }}>
              The objective of this DST is to effortlessly support tourist
              establishments with making a sensible transition from SUP products
              to alternative products which are environmentally friendlier. The
              DST considers the environmental impacts of products across their
              life-cycle. This is important, because while in some cases, the
              switch to SUP alternatives makes environmental sense, in other
              cases, alternative products to SUP might not necessarily be
              environmentally friendlier (e.g. they might have higher demands
              for energy and water use). The life-cycle environmental impacts of
              products are balanced against other parameters, such as the
              potential reduction in littering, economic costs and legislative
              obligations, so that this tool can best support decision making
              for users.
            </Typography>
          </div>
          <div style={{ position: 'absolute', bottom: 20 }}>
            <Footer />
          </div>
        </div>
        <div
          style={{
            width: '650px',
            height: '1000px',
            backgroundColor: '#fff',
            padding: '10px',
            position: 'relative',
          }}
          id="details"
        >
          <Header />

          <div style={{ marginTop: '20px' }}>
            {scenario && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  <Typography style={styles.title}>
                    Group and/or Establishment details
                  </Typography>
                </div>
                <div
                  style={{
                    width: 300,
                    height: 50,
                    backgroundColor: '#B3C6E7',
                    border: '1px solod #000',
                    borderRadius: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography style={{ fontSize: 14 }}>
                    {scenario.name}
                  </Typography>
                </div>
                <div
                  style={{
                    width: 300,
                    height: 50,
                    backgroundColor: '#E6F0FF',
                    border: '1px solod #000',
                    borderRadius: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <Typography style={{ fontSize: 13 }}>
                      Country: {scenario.country}
                    </Typography>
                    <Typography style={{ fontSize: 13 }}>
                      E-mail address: {email}
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    width: 300,
                    height: 50,
                    backgroundColor: '#B3C6E7',
                    border: '1px solod #000',
                    borderRadius: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography style={{ fontSize: 14 }}>Date</Typography>
                </div>
                <div
                  style={{
                    width: 300,
                    height: 50,
                    backgroundColor: '#E6F0FF',
                    border: '1px solod #000',
                    borderRadius: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <Typography style={{ fontSize: 13 }}>
                      {`Date created: ${moment().format('DD-MM-YYYY HH:mm')}`}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div style={{ marginTop: '20px' }}>
            {scenario && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  paddingHorizontal: 10,
                  marginBottom: 20,
                }}
              >
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <Typography>Scanario settings</Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <Typography style={styles.normalText}>
                      Type: {scenario.type}
                    </Typography>
                  </div>
                  {['Hotel', 'Motel', 'B&B etc'].includes(scenario.type) && (
                    <React.Fragment>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ width: '50%' }}>
                          <Typography style={styles.normalText}>
                            Total number of guests per year:
                            {scenario.totalNumberOfGuestsPerYear}
                          </Typography>
                        </div>
                        <div style={{ width: '50%' }}>
                          <Typography style={styles.normalText}>
                            Total number of rooms:
                            {scenario.totalNumberOfGuestsPerYear}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ width: '50%' }}>
                          <Text style={styles.normalText}>
                            Hotel guests capacity:
                            {scenario.hotelGuestCapacity}
                          </Text>
                        </div>
                        <div style={{ width: '50%' }}>
                          <Typography style={styles.normalText}>
                            Restaurant/cafe capacity:
                            {scenario.restaurantCafeCapacity}
                          </Typography>
                        </div>
                      </div>

                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ width: '50%' }}>
                          <Typography style={styles.normalText}>
                            Total number of tables across all restaurant:
                            {scenario.totalNumberOfTablesAcrossAllRestaurant}
                          </Typography>
                        </div>
                        <div style={{ width: '50%' }}>
                          <Typography style={styles.normalText}>
                            Beach bar capacity:
                            {scenario.beachBarCapacity}
                          </Typography>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
          <div style={{ position: 'absolute', bottom: 20 }}>
            <Footer />
          </div>
        </div>
        <RenderAddedProducts
          listData={[...selectedSupProducts]}
          title="SUP product selection by the user"
        />

        <RenderAddedProducts
          listData={[...groupByCategory]}
          title="Alternative product selection by the user"
          isAlternative
        />

        <RenderExpectedResult listData={productCategoryOption ?? []} />
        <div
          style={{
            width: '650px',
            height: '1000px',
            backgroundColor: '#fff',
            padding: '10px',
            position: 'relative',
          }}
          id="listing-1"
        >
          <Header />

          <div style={{ marginTop: '20px' }}>
            <div>
              <Typography style={{ fontSize: '15px' }}>
                1.1 Littering estimated reduction in yearly plastic litter',
                (incl. plastic marine litter) across all product categories (kg)
              </Typography>
            </div>
            <div style={{ marginTop: '20px' }}>
              <img
                src={data.litter_2_1}
                style={{ width: '100%', height: '300px' }}
              />
            </div>
            <div>
              <Typography style={{ fontSize: '15px', marginTop: '20px' }}>
                1.2 Littering estimated reduction in yearly plastic litter
                (incl. plastic marine litter) across all product categories (kg)
              </Typography>
            </div>
            <div style={{ marginTop: '20px' }}>
              <img
                src={data.litter_2_2}
                style={{ width: '100%', height: '300px' }}
              />
            </div>
          </div>

          <div style={{ position: 'absolute', bottom: 20 }}>
            <Footer />
          </div>
        </div>
        <div
          style={{
            width: '650px',
            height: '1000px',
            backgroundColor: '#fff',
            padding: '10px',
            position: 'relative',
          }}
          id="listing-2"
        >
          <Header />

          <div style={{ marginTop: '20px' }}>
            <div>
              <Typography style={{ fontSize: '15px', marginTop: '20px' }}>
                2.1 Littering Estimated reduction in yearly plastic marine
                litter across all product categories (items)
              </Typography>
            </div>
            <div style={{ marginTop: '20px' }}>
              <img
                src={data.marineLitter_1_1}
                style={{ width: '100%', height: '300px' }}
              />
            </div>
            <div>
              <Typography style={{ fontSize: '15px', marginTop: '20px' }}>
                2.2 Littering Estimated reduction in yearly plastic marine
                litter across all product categories (items)
              </Typography>
            </div>
            <div style={{ marginTop: '20px' }}>
              <img
                src={data.marineLitter_1_2}
                style={{ width: '100%', height: '300px' }}
              />
            </div>
          </div>

          <div style={{ position: 'absolute', bottom: 20 }}>
            <Footer />
          </div>
        </div>
        <div
          style={{
            width: '650px',
            height: '1000px',
            backgroundColor: '#fff',
            padding: '10px',
            position: 'relative',
          }}
          id="costs"
        >
          <Header />

          <div style={{ marginTop: '20px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginBottom: '15px',
              }}
            >
              <Typography style={styles.title}>Costs</Typography>
            </div>
            <div>
              <Typography style={{ fontSize: '15px', marginTop: '20px' }}>
                Projected cost savings for category (cumulative) [EUR]
              </Typography>
            </div>
            <div style={{ marginTop: '20px' }}>
              <img
                src={data.cost_per_category_year_1}
                style={{ width: '100%', height: '100%', maxHeight: '300px' }}
              />
            </div>
            <div>
              <Typography style={{ fontSize: '15px', marginTop: '20px' }}>
                Cost savings per product category for Year 1 [EUR]
              </Typography>
            </div>
            <div style={{ marginTop: '20px' }}>
              <img
                src={data.cost_1}
                style={{ width: '100%', height: '100%', maxHeight: '300px' }}
              />
            </div>
            <div>
              <Typography style={{ fontSize: '15px', marginTop: '20px' }}>
                Total cost savings across all product category (cumulative)
                [EUR]
              </Typography>
            </div>
            <div style={{ marginTop: '20px' }}>
              <img
                src={data.cost_all_categories}
                style={{ width: '100%', height: '100%', maxHeight: '300px' }}
              />
            </div>
          </div>

          <div style={{ position: 'absolute', bottom: 20 }}>
            <Footer />
          </div>
        </div>
      </div>
    );
  };

  const handleSavePdf = async (dataImage) => {
    const blob = await pdf(
      <Document>
        {dataImage?.introductionBase64 && (
          <Page key={Math.random()} size="A4" style={{ padding: '30px' }}>
            <Image
              src={dataImage?.introductionBase64}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
            />
          </Page>
        )}
        {dataImage?.detailsBase64 && (
          <Page key={Math.random()} size="A4" style={{ padding: '30px' }}>
            <Image
              src={dataImage?.detailsBase64}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
            />
          </Page>
        )}

        {(dataImage?.listSupProductsConverted?.length || 0 > 0) &&
          dataImage?.listSupProductsConverted?.map((newItem, index) => {
            return (
              <Page key={Math.random()} size="A4" style={{ padding: '30px' }}>
                <Image
                  src={newItem}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Page>
            );
          })}
        {(dataImage?.listAlternativeConverted?.length || 0 > 0) &&
          dataImage?.listAlternativeConverted?.map((newItem) => {
            return (
              <Page key={Math.random()} size="A4" style={{ padding: '30px' }}>
                <Image
                  src={newItem}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Page>
            );
          })}
        {dataImage?.expectedResultBase64 && (
          <Page key={Math.random()} size="A4" style={{ padding: '30px' }}>
            <Image
              src={dataImage?.expectedResultBase64}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
            />
          </Page>
        )}

        {(dataImage?.expectedResultListBase64?.length || 0 > 0) &&
          dataImage?.expectedResultListBase64?.map((newItem) => {
            return (
              <Page key={Math.random()} size="A4" style={{ padding: '30px' }}>
                <Image
                  src={newItem}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Page>
            );
          })}
        {dataImage?.listing1Base64 && (
          <Page key={Math.random()} size="A4" style={{ padding: '30px' }}>
            <Image
              src={dataImage?.listing1Base64}
              style={{
                width: '100%',
                objectFit: 'contain',
              }}
            />
          </Page>
        )}
        {dataImage?.listing2Base64 && (
          <Page key={Math.random()} size="A4" style={{ padding: '30px' }}>
            <Image
              src={dataImage?.listing2Base64}
              style={{
                width: '100%',
                objectFit: 'contain',
              }}
            />
          </Page>
        )}
        {dataImage?.costsBase64 && (
          <Page key={Math.random()} size="A4" style={{ padding: '30px' }}>
            <Image
              src={dataImage?.costsBase64}
              style={{
                width: '100%',
                objectFit: 'contain',
              }}
            />
          </Page>
        )}
      </Document>
    ).toBlob();
    await saveAs(blob, 'report.pdf');
    history.goBack();
  };

  const convertToImage = async () => {
    let dataBase64 = {};
    const nodeIntroduction = document.getElementById('introduction');
    const introductionBase64 = await (
      await html2canvas(nodeIntroduction)
    ).toDataURL('image/png');
    const nodeDetails = document.getElementById('details');
    const detailsBase64 = await (await html2canvas(nodeDetails)).toDataURL(
      'image/png'
    );
    dataBase64.introductionBase64 = introductionBase64;
    dataBase64.detailsBase64 = detailsBase64;

    if (groupByCategory?.length > 0) {
      const convert = splitArray(groupByCategory, 12);
      const listAlternative = convert.map(async (item, index) => {
        const nodeAlternative = document.getElementById(
          `details-${index}-alternative`
        );
        return await (await html2canvas(nodeAlternative)).toDataURL(
          'image/png'
        );
      });
      const listAlternativeConverted = await Promise.all(listAlternative);
      dataBase64.listAlternativeConverted = listAlternativeConverted;
    }

    const selectedSupProducts = userProductsData.data?.items.filter((item) => {
      if (item.usageCategory === 'SUP' && item.selected) {
        return true;
      } else {
        return false;
      }
    });

    if (selectedSupProducts?.length > 0) {
      const convert = splitArray(selectedSupProducts, 12);
      const listSupProducts = convert.map(async (item, index) => {
        const nodeSupProducts = document.getElementById(`details-${index}-sup`);
        return await (await html2canvas(nodeSupProducts)).toDataURL(
          'image/png'
        );
      });
      const listSupProductsConverted = await Promise.all(listSupProducts);
      dataBase64.listSupProductsConverted = listSupProductsConverted;
    }
    const nodeExpectedResult = document.getElementById('expected-results');
    const expectedResultBase64 = await (
      await html2canvas(nodeExpectedResult)
    ).toDataURL('image/png');
    dataBase64.expectedResultBase64 = expectedResultBase64;

    const convertData = splitArray(productCategoryOption, 3);
    const listEnvProducts = convertData.map(async (item, index) => {
      const nodeEnvProducts = document.getElementById(
        `expected-results-${index}`
      );
      return await (await html2canvas(nodeEnvProducts)).toDataURL('image/png');
    });
    const listEnvProductsConverted = await Promise.all(listEnvProducts);
    dataBase64.expectedResultListBase64 = listEnvProductsConverted;

    const nodeListing1 = document.getElementById('listing-1');
    const listing1Base64 = await (await html2canvas(nodeListing1)).toDataURL(
      'image/png'
    );
    dataBase64.listing1Base64 = listing1Base64;

    const nodeListing2 = document.getElementById('listing-2');
    const listing2Base64 = await (await html2canvas(nodeListing2)).toDataURL(
      'image/png'
    );
    dataBase64.listing2Base64 = listing2Base64;

    const nodeCosts = document.getElementById('costs');
    const costsBase64 = await (await html2canvas(nodeCosts)).toDataURL(
      'image/png'
    );
    dataBase64.costsBase64 = costsBase64;
    await handleSavePdf(dataBase64);
  };

  useEffect(() => {
    if (data.impacts_1 !== '' && scenario) {
      setTimeout(() => {
        convertToImage();
      }, 3000);
    }
  }, [data, scenario]);

  return (
    <React.Fragment>
      {renderExportModal()}

      {renderPdf()}

      <LoadingView fullScreen />
    </React.Fragment>
  );
};
