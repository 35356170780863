// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import _ from 'lodash';

export const uploadPicture = async (payload: any) => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/upload/`,
    payload
  );
  let output: any = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export const uploadAvatar = async (payload: any) => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/upload/user`,
    payload
  );
  let output: any = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
