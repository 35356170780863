import React from 'react';
import { withRouter } from 'react-router';
import useStyles from './styles';
import PageLayout from 'components/app_common/page_layout';
import { Box, Paper } from '@material-ui/core';
import GridLayout from 'components/molecules/grid_layout';
import CardLabelTitle from 'components/app_common/card_label_title';
import TranslatedText from 'components/atoms/translated_text';
import InputTypesForm from './components/input_types_form';
import GridForm from './components/grid_form';
import ValidateForm from './components/validate_form';
import BaseModal from 'components/molecules/base_modal';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import PrettyPrintJson from 'components/atoms/pretty_print_json';

interface IProps {}

const FormPage: React.FC = (props: IProps) => {
  const classes = useStyles();

  return (
    <PageLayout>
      <FormOutputModal />
      <GridLayout
        spacing={1}
        justify="flex-start"
        elements={[
          {
            id: '1',
            element: (
              <FromElementLayout
                title={<TranslatedText defaultText="Input Types" />}
              >
                <InputTypesForm />
              </FromElementLayout>
            ),
            size: 6,
          },
          {
            id: '2',
            element: [
              {
                id: '1',
                size: 12,
                element: (
                  <FromElementLayout
                    title={<TranslatedText defaultText="Grid Form" />}
                  >
                    <GridForm />
                  </FromElementLayout>
                ),
              },
              {
                id: '2',
                size: 12,
                element: (
                  <FromElementLayout
                    title={<TranslatedText defaultText="Validate Form" />}
                  >
                    <ValidateForm />
                  </FromElementLayout>
                ),
              },
            ],
            size: 6,
          },
        ]}
      />
    </PageLayout>
  );
};

const FormOutputModal = () => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_FORM_WRAPPER}
      modalProps={{ fullWidth: true }}
      renderHeader={(params) => params.title}
      renderContent={(params) => (
        <PrettyPrintJson jsonData={true} data={params.data} />
      )}
    />
  );
};

interface IFromElementLayout {
  children: React.ReactElement;
  title: React.ReactElement;
}

const FromElementLayout = ({ children, title }: IFromElementLayout) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.cardElementLayout}
      elevation={0}
      variant="outlined"
    >
      <CardLabelTitle title={title} />
      <Box>{children}</Box>
    </Paper>
  );
};

export default withRouter(FormPage);
