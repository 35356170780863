import { Box, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import useDebounce from 'custom_hooks/useDebounce';

interface IProps {
  title: string;
  onSearch(search: string): void;
}

export default function FormSearch({ title, onSearch }: IProps) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const textSearch = useDebounce(value, 1500);
  useEffect(() => {
    onSearch(textSearch);
  }, [textSearch]);
  return (
    <Box className={classes.boxSearch}>
      <Typography variant="h5">{title}</Typography>
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        variant="outlined"
        margin="normal"
        placeholder="Search"
        InputProps={{
          endAdornment: <SearchIcon className={classes.iconSearch} />,
        }}
      />
    </Box>
  );
}
