import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as ProductService from '../../../services/api/product';
import * as PictureService from '../../../services/api/picture';
import { Dispatch } from 'redux';
import { showToastAction } from '../../../components/atoms/toast_message';
import Action from 'redux/reducers/gl_auth_reducer/actions';

interface IProduct {
  productCategory: string;
  usageCategory: string;
  productType: string;
  productCost: number;
  Comments: string;
  Compliance: string;
  file: any;
  // Furans: number;
  // Dioxins: number;
  // Formaldehyde: number;
  // PAH: number;
  // Benzene: number;
  // _1_3Butadiene: number;
  // Nickel: number;
  // Chromium: number;
  // Cadmium: number;
  // Arsenic: number;
  // VOCs: number;
  // SO2: number;
  // PM10: number;
  // PM2dot5: number;
  // NOx: number;
  // NH3: number;
  // Methane: number;
  // CO2NonFossil: number;
  // CO2Fossil: number;
  annualReplacementRate: number;
  weight: number;
  // climateChange: number;
  // humanToxicity: number;
  // acidificationPotential: number;
  // respiratoryEffectsOnHuman: number;
  // carcinogenicEffectsOnHuman: number;
  // marineAquaticEcotoxicity: number;
  // climateChangeImpactsAssociatedWithEOL: number;
  SUPLitteringRate: number;
  SUNPLitteringRate: number;
  MULitteringRate: number;
  MarineLittering: number;
}

/** Fetch SchemeTypes  */

class FetchProduct implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PRODUCT,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PRODUCT,
      status: StateStatus.Success,
      data: data,
    };
  }
  onPersist(data: any): IAction {
    return {
      type: Types.SET_PRODUCT_PERSIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PRODUCT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ProductService.getProducts();
        dispatch(this.onSuccess(response.data));
        dispatch(this.onPersist(response.data));
        // if (response.message) {
        // showToastAction(
        //   dispatch,
        //   {
        //     [LanguagesEnum.English]: response.message,
        //     [LanguagesEnum.Greek]: response.message,
        //   },
        //   'success'
        // );
        // }
      } catch (error: any) {
        console.log('FetchProduct Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
        if (
          error.message === 'jwt expired' ||
          error.message === 'jwt malformed'
        ) {
          //logout
          dispatch(Action.logoutAction());
        }
        // showToastAction(
        //   dispatch,
        //   {
        //     [LanguagesEnum.English]: error.message,
        //     [LanguagesEnum.Greek]: error.message,
        //   },
        //   'error'
        // );
      }
    };
  }
}
class InsertProduct implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.INSERT_PRODUCT,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.INSERT_PRODUCT,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.INSERT_PRODUCT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IProduct): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const file = input.file;
        if (file) {
          const formData = new FormData();
          formData.set('product', file);
          const picture = await PictureService.uploadPicture(formData);
          let uploadedPicture = null;
          if (picture.status === 'SUCCESS') {
            uploadedPicture = picture.data._id;
          }
          const productInput = { ...input, picture: uploadedPicture };
          delete productInput.file;

          let response = await ProductService.insertProduct(productInput);
          dispatch(this.onSuccess());
          showToastAction(dispatch, 'Insert Product successful', 'success');
          dispatch(new FetchProduct().action());
          dispatch({
            type: Types.SET_OPEN,
            data: {
              isOpen: false,
              data: null,
              type: 'add',
            },
          });
        } else {
          const productInput = { ...input, picture: null };
          delete productInput.file;
          let response = await ProductService.insertProduct(input);
          dispatch(this.onSuccess());
          showToastAction(dispatch, 'Insert Product successful', 'success');
          dispatch(new FetchProduct().action());
          dispatch({
            type: Types.SET_OPEN,
            data: {
              isOpen: false,
              data: null,
              type: 'add',
            },
          });
        }
      } catch (error: any) {
        console.log('Insert Product Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
        showToastAction(dispatch, 'Insert Product failed', 'error');
      }
    };
  }
}
class UpdateProduct implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_PRODUCT,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.UPDATE_PRODUCT,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_PRODUCT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());

        const file = input.file;
        if (file) {
          const formData = new FormData();
          formData.set('product', file);
          const picture = await PictureService.uploadPicture(formData);
          let uploadedPicture = null;
          if (picture.status === 'SUCCESS') {
            uploadedPicture = picture.data._id;
          }
          const productInput = { ...input, picture: uploadedPicture };
          delete productInput.file;
          let response = await ProductService.updateProduct(productInput);
          dispatch(this.onSuccess());
          showToastAction(dispatch, 'Update Product successful', 'success');
          dispatch(new FetchProduct().action());
          dispatch({
            type: Types.SET_OPEN,
            data: {
              isOpen: false,
              data: null,
              type: 'add',
            },
          });
        } else {
          const productInput = { ...input };
          delete productInput.file;
          let response = await ProductService.updateProduct(productInput);
          dispatch(this.onSuccess());
          showToastAction(dispatch, 'Update Product successful', 'success');
          dispatch(new FetchProduct().action());
          dispatch({
            type: Types.SET_OPEN,
            data: {
              isOpen: false,
              data: null,
              type: 'add',
            },
          });
        }
      } catch (error: any) {
        console.log('Update Product Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
        showToastAction(dispatch, 'Update Product failed', 'error');
      }
    };
  }
}

export default {
  fetchProduct: () => new FetchProduct().action(),
  insertProduct: (input: IProduct) => new InsertProduct().action(input),
  UpdateProduct: (input: any) => new UpdateProduct().action(input),
};
