import { combineReducers } from 'redux';
import AuthReducer from './gl_auth_reducer/reducer';
import TranslationReducer from './gl_translations_reducer/reducer';
import NavigationReducer from './gl_navigation_reducer/reducer';
import ThemeReducer from './gl_theme_reducer/reducer';
import ModalReducer from './gl_modal_reducer/reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// ASYNC REDUCERS
import DashboardBuilderReducer from 'samples/dashboardBuilder_page/store/reducer';
import RegisterReducer from 'scenes/register_page/store/reducer';
// import UserProfileReducer from 'scenes/profile_page/store/reducer';
import SecurityReducer from 'scenes/security_page/store/reducer';
import ResetPasswordReducer from 'scenes/reset_password/store/reducer';
import ForgotPasswordReducer from 'scenes/forgot_password/store/reducer';
import ProductReducer from './product_reducer/reducer';
import UserProductReducer from './userproducts_reducer/reducer';
import UserProfileReducer from './userProfile_reducer/reducer';
import ScenarioReducer from './scenario_reducer/reducer';
import EstablishmentReducer from './establishment_reducer/reducer';
import GroupReducer from './group_reducer/reducer';
import CombineScenariosReducer from './combine_scenario_reducer/reducer';
import UserManagementReducer from './user_management_reducer/reducer';

import { ReducerKeys } from '../config';
import _ from 'lodash';

const authPersistConfig = {
  key: ReducerKeys.AUTH_REDUCER,
  storage: storage,
  blacklist: ['status'],
};

const translationPersistConfig = {
  key: ReducerKeys.TRANSL_REDUCER,
  storage: storage,
};

const navigationPersistConfig = {
  key: ReducerKeys.NAVIGATION_REDUCER,
  storage: storage,
};

const themePersistConfig = {
  key: ReducerKeys.THEME_REDUCER,
  storage: storage,
};

// const dashboardBuilderPersistConfig = {
//   key: ReducerKeys.DASHBOARD_BUILDER_REDUCER,
//   storage: storage,
//   blacklist: ['selectedElement'],
// };

export const asyncReducers = {
  [ReducerKeys.DASHBOARD_BUILDER_REDUCER]: DashboardBuilderReducer,
  [ReducerKeys.REGISTER_REDUCER]: RegisterReducer,
  [ReducerKeys.CHANGE_PASSWORD_REDUCER]: SecurityReducer,
  [ReducerKeys.RESET_PASSOWRD_REDUCER]: ResetPasswordReducer,
  [ReducerKeys.FORGOT_PASSWORD_REDUCER]: ForgotPasswordReducer,
};

export const staticReducers = {
  // global
  [ReducerKeys.AUTH_REDUCER]: persistReducer(authPersistConfig, AuthReducer),
  [ReducerKeys.TRANSL_REDUCER]: persistReducer(
    translationPersistConfig,
    TranslationReducer
  ),
  [ReducerKeys.NAVIGATION_REDUCER]: persistReducer(
    navigationPersistConfig,
    NavigationReducer
  ),
  [ReducerKeys.THEME_REDUCER]: persistReducer(themePersistConfig, ThemeReducer),
  [ReducerKeys.MODAL_REDUCER]: ModalReducer,
  [ReducerKeys.PRODUCT_REDUCER]: ProductReducer,
  [ReducerKeys.USER_PROFILE_REDUCER]: UserProfileReducer,
  [ReducerKeys.USER_PRODUCT_REDUCER]: UserProductReducer,
  [ReducerKeys.SCENARIO_REDUCER]: ScenarioReducer,
  [ReducerKeys.ESTABLISHMENT_REDUCER]: EstablishmentReducer,
  [ReducerKeys.GROUP_REDUCER]: GroupReducer,
  [ReducerKeys.COMBINE_SCENARIOS_REDUCER]: CombineScenariosReducer,
  [ReducerKeys.USER_MANAGEMENT_REDUCER]: UserManagementReducer,
};

// export default function createReducer(asyncReducers = {}, store = {}) {
//   return combineReducers({
//     ...staticReducers,
//     ...asyncReducers,
//   });
// }

// This is like your own combineReducers() with custom behavior
// function _combineReducers(dynamicReducer: any) {
//   // Creates a reducer from the main and a dynamic reducer
//   return function (state: any, action: any) {
//     // Calculate main state
//     let nextState: any = staticReducers;

//     // If specified, calculate dynamic reducer state
//     if (dynamicReducer) {
//       nextState[dynamicReducer.name] = dynamicReducer.reducer(
//         nextState[dynamicReducer.name],
//         action
//       );
//     }

//     return nextState;
//   };
// }

export default function createReducer(asyncReducers = {}, store = {}) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}
