import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  layoutForgot: {
    justifyContent: 'center',
    display: 'flex',
    margin: 25,
  },
  cardViewContainer: {
    paddingTop: 50,
  },
  languageButton: {
    position: 'absolute',
    right: 20,
    top: 8,
  },
}));

export default useStyles;
