import { IAction, IActionMethods, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as RegisterService from 'services/api/register';
import { Dispatch } from 'redux';
import NavigationConfig from 'navigation/config';

/** Register User Action */
interface IRegisterInput {
  history: any;
  data: any;
}
class RegisterUser implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REGISTER_USER,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.REGISTER_USER,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REGISTER_USER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IRegisterInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { data, history } = input;
        dispatch(this.onPending());
        let response = await RegisterService.registerUser(data);
        dispatch(this.onSuccess());
        history.push(NavigationConfig.loginPage().path);
      } catch (error) {
        console.log('RegisterUser Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  registerUserAction: (data: any, history: any) =>
    new RegisterUser().action({ data, history }),
};
