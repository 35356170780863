import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      // padding: 10,
    },
    grow: {
      flexGrow: 1,
    },
  })
);

export default useStyles;
