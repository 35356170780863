import { Button } from '@material-ui/core';
import React from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import ModalActions from '../../../../redux/reducers/gl_modal_reducer/actions';
import Actions from '../../../../redux/reducers/scenario_reducer/actions';

import { ModalIDs } from '../../../../redux/reducers/gl_modal_reducer/types';
import useStyles from './styles';

interface IProps {
  rowIndex: number;
  data: string;
  dataid: string | number;
}

export default function ActionsBlock(props: IProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = (type: 'view' | 'edit') => {
    dispatch(
      Actions.setModal({
        data: props.dataid,
        isOpen: true,
        type: type,
      })
    );
  };
  function handleDelete(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        dispatch(Actions.deleteScenario(id));
        // dispatch(Actions.UpdateProduct({ id: data._id, isDeleted: true }));
        // Swal.fire('Deleted!', 'The product has been deleted.', 'success');
      }
    });
  }

  return (
    <div style={{ display: 'flex' }}>
      <Button
        className={classes.editButton}
        // variant="outlined"
        onClick={() => handleClick('edit')}
      >
        Setup
      </Button>
      <Button
        // variant="outlined"
        className={classes.viewButton}
        onClick={() => {
          dispatch(Actions.setSelectedScenario(props.dataid as string));
          history.push('/user-product');
        }}
      >
        Edit
      </Button>
      <Button
        // variant="outlined"
        className={classes.deleteButton}
        onClick={() => handleDelete(props.dataid as string)}
      >
        Delete
      </Button>
    </div>
  );
}
