import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as UserOutputService from '../../../services/api/user_outputs';
import * as UserProductService from '../../../services/api/user_products';

import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';

const SetEnvImpactSelection = (payload: string) => {
  return {
    type: Types.SET_ENV_IMPACT_SELECTION,
    data: payload,
  };
};
const SetEnvDescSelection = (payload: string) => {
  return {
    type: Types.SET_ENV_DESC_SELECTION,
    data: payload,
  };
};
const SetProductCategorySelection = (payload: string) => {
  return {
    type: Types.SET_PRODUCT_CATEGORY_SELECTION,
    data: payload,
  };
};
/** Fetch FetchEstablishmentListInput  */

interface FetchUserOutputByScenarioInput {
  scanarios: string[];
}

class FetchUserOutputByScenario implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_OUTPUT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_OUTPUT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_OUTPUT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchUserOutputByScenarioInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserOutputService.fetchUserOutputByScenario({
          scenarios: payload.scanarios,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetchUserOutputByScenario Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchUserProductByScenario implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_PRODUCT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_PRODUCT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_PRODUCT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchUserOutputByScenarioInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserProductService.fetchUserProductByScenario({
          scenarios: payload.scanarios,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('fetchUserProductByScenario Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewEstablishment  */

export default {
  fetchUserOutputByScenario: (payload: FetchUserOutputByScenarioInput) =>
    new FetchUserOutputByScenario().action(payload),
  fetchUserProductByScenario: (payload: FetchUserOutputByScenarioInput) =>
    new FetchUserProductByScenario().action(payload),
  setEnvImpactSelection: (payload: string) => SetEnvImpactSelection(payload),
  setEnvDescSelection: (payload: string) => SetEnvDescSelection(payload),
  setProductCategorySelection: (payload: string) =>
    SetProductCategorySelection(payload),
};
