import { Button, IconButton } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import TranslatedText from 'components/atoms/translated_text';
import BaseModal from 'components/molecules/base_modal';
import { AppTextField } from 'components/molecules/form_controls';
import GridLayout from 'components/molecules/grid_layout';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import * as DashboardBuilderSelectors from '../../store/selectors';
import DashboardBuilderActions from '../../store/actions';
import reactLogger from 'utils/logger';
import _ from 'lodash';
import { EventsEnum } from 'components/compounds/dashboard_builder/plugins/types';

export interface IModalParams {}

export default function GlobalsModal() {
  const defaultGlobals = DashboardBuilderSelectors.useGetBuilderGlobals();

  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<IFormData>({
    defaultValues: { ...defaultValues, globals: [] },
    mode: 'onBlur',
  });
  const arrName = 'globals';
  const { isDirty, isValid } = formState;
  const dispatch = useDispatch();
  reactLogger.renderComponent('GlobalsModal');

  React.useEffect(() => {
    if (defaultGlobals && defaultGlobals.length > 0) {
      reset({ ...defaultValues, globals: defaultGlobals });
    }
  }, [reset, defaultGlobals]);

  const onSubmit = (data: IFormData) => {
    // console.log("Submit/////////////")
    dispatch(
      DashboardBuilderActions.setBuilderGlobalsAction({
        globals: data.globals,
      })
    );
    dispatch(
      ModalActions.closeModalAction({
        modalID: ModalIDs.MODAL_DASHBOARD_BUILDER_SETTINGS,
      })
    );
  };

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_DASHBOARD_BUILDER_SETTINGS}
        showExitIcon={true}
        contentProps={{ style: { minHeight: '40vh' } }}
        modalProps={{ fullWidth: true }}
        renderHeader={() => <TranslatedText defaultText={`Global Variables`} />}
        renderContent={() => (
          <GlobalVarForm control={control} arrName={arrName} />
        )}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button
              onClick={() =>
                dispatch(
                  ModalActions.closeModalAction({
                    modalID: ModalIDs.MODAL_DASHBOARD_BUILDER_SETTINGS,
                  })
                )
              }
            >
              <TranslatedText defaultText={`Cancel`} />
            </Button>
            <Button
              disabled={isDirty && !isValid}
              // type="submit"
              onClick={() => {
                // ref.onSubmit();
                onSubmit(getValues());
              }}
              color="primary"
            >
              <TranslatedText defaultText={`Save`} />
            </Button>
          </React.Fragment>
        )}
      />
    </form>
  );
}

interface IFormData {
  globals: Array<{
    name: string;
    value: string | number;
  }>;
}

const defaultValues: IFormData = {
  globals: [],
};

interface IGlobalVarFormProps {
  arrName: string;
  control: any;
}

const GlobalVarForm = ({ arrName, control }: IGlobalVarFormProps) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: arrName,
    }
  );
  reactLogger.renderComponent('GlobalVarForm');

  return (
    <React.Fragment>
      {fields.map((item, index) => (
        <React.Fragment key={item.id}>
          <GridLayout
            justify={'center'}
            elements={[
              {
                id: '1',

                size: 5,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="dense"
                    // error={Boolean(_.get(errors, 'cardTitle', false))}
                    // helperText={_.get(errors, 'cardTitle', '')}
                    rules={{
                      required: <RequiredField />,
                    }}
                    disabled={Object.values(EventsEnum).includes(
                      fields[index].name
                    )}
                    fullWidth
                    size="small"
                    id={`${arrName}[${index}].name`}
                    control={control}
                    label={<TranslatedText defaultText={'Name'} />}
                    autoComplete={`${arrName}[${index}].name`}
                    name={`${arrName}[${index}].name`}
                    controllerExtras={{
                      defaultValue: item.name,
                    }}
                  />
                ),
              },
              {
                id: '2',
                size: 5,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="dense"
                    // error={Boolean(_.get(errors, 'cardTitle', false))}
                    // helperText={_.get(errors, 'cardTitle', '')}
                    rules={{
                      required: <RequiredField />,
                    }}
                    fullWidth
                    size="small"
                    id={`${arrName}[${index}].value`}
                    control={control}
                    label={<TranslatedText defaultText={'Value'} />}
                    autoComplete={`${arrName}[${index}].value`}
                    name={`${arrName}[${index}].value`}
                    controllerExtras={{
                      defaultValue: item.value,
                    }}
                  />
                ),
              },
              {
                id: '3',
                size: 2,
                rowStyle: { marginTop: 'auto', marginBottom: 'auto' },
                element: (
                  <React.Fragment>
                    {!Object.values(EventsEnum).includes(
                      fields[index].name
                    ) && (
                      <Button
                        onClick={() => {
                          remove(index);
                        }}
                        style={{ color: 'red' }}
                      >
                        <TranslatedText defaultText="Remove" />
                      </Button>
                    )}
                  </React.Fragment>
                ),
              },
            ]}
          />
        </React.Fragment>
      ))}
      <Button
        onClick={() => {
          append({ name: '', value: '' });
        }}
        variant="outlined"
        color="secondary"
      >
        <TranslatedText defaultText="Add Variable" />
      </Button>
    </React.Fragment>
  );
};

const RequiredField = React.memo(() => {
  return <TranslatedText defaultText={'Required Value'} />;
});
