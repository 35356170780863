import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Routes } from '../../config/Routes';
import AppbarMenu from './navbar';
import Sidebar from './sidebarList';
import SidebarContent from './sidebar';
import useStyles from './styles';
import reactLogger from 'utils/logger';
import { useTheme } from '@material-ui/core';

interface IProps {
  visible: boolean;
}

export default function Appbar({ visible }: IProps) {
  reactLogger.renderPage('Appbar');

  if (visible) {
    return <DataDrawer />;
  } else {
    return <Routes />;
  }
}

function DataDrawer() {
  reactLogger.renderComponent('DataDrawer');

  const classes = useStyles();
  const theme = useTheme();

  // console.log("THHEEME:", theme.mixins.toolbar);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppbarMenu />
      <Sidebar />
      <SidebarContent />
    </div>
  );
}
