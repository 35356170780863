export enum Roles {
  Guest = '1',
  Admin = '9',
  User = '2',
  GroupAdmin = '3',
  EstablishmentAdmin = '4',
}

const AllUsers = [Roles.Guest, Roles.Admin, Roles.User];
// .map((key: any) =>
//   Number.parseInt(Roles[key])
// );
const RegisteredUsers = [Roles.Admin, Roles.User];

const GroupAdmins = [Roles.GroupAdmin];
const EstablishmentAdmins = [Roles.EstablishmentAdmin];

export default {
  ...Roles,
  All: AllUsers,
  Registered: RegisteredUsers,
  GroupAdmins,
  EstablishmentAdmins,
};
