import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import useStyles from './styles';

export default function Description() {
  const classes = useStyles();
  return (
    <Paper>
      <Box padding="20px" height="405px">
        <Box display="flex" alignItems="flex-end">
          <Typography
            style={{ marginRight: 5 }}
            className={classes.highlightText}
          >{`10000 `}</Typography>
          <Typography>
            KgCO<sub>2</sub> is equivalent to:
          </Typography>
        </Box>
        <ul>
          <li>
            <Box display="flex" alignItems="flex-end">
              <Typography className={classes.highlightText}>
                Yearly emissions from 2
              </Typography>
              <img
                src={require('assets/images/icons/car.png')}
                height="25"
                width="50"
              />
            </Box>
          </li>
          <li>
            <Box display="flex" alignItems="flex-end">
              <Typography className={classes.highlightText}>
                Yearly emissions from ~40,000 km of a
              </Typography>
              <img
                src={require('assets/images/icons/car.png')}
                height="25"
                width="50"
              />
            </Box>
          </li>
          <li>
            <Box display="flex" alignItems="flex-end">
              <Typography className={classes.highlightText}>
                Emissions from ~4,300
              </Typography>

              <img
                src={require('assets/images/icons/gas-station.png')}
                height="50"
                width="50"
              />
            </Box>
          </li>
        </ul>
      </Box>
    </Paper>
  );
}
