import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader } from '@material-ui/core';

interface ISubFormProps {
  children: React.ReactElement;
  style?: React.CSSProperties;
  title: string | React.ReactElement;
}

export default function SubFormLayout(props: ISubFormProps) {
  return (
    <Card style={props.style} variant="outlined" square>
      <CardHeader title={props.title} />
      <CardContent>{props.children}</CardContent>
    </Card>
  );
}
