import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
  Divider,
} from '@material-ui/core';
import PageLayout from 'components/app_common/page_layout';
import { showToastAction } from 'components/atoms/toast_message';
import DashboardBuilder from 'components/compounds/dashboard_builder';
import GridLayout, { IElementsProps } from 'components/molecules/grid_layout';
import _ from 'lodash';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router'; // import the react-router-dom components
import { useHistory } from 'react-router-dom';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ScenarioActions from 'redux/reducers/scenario_reducer/actions';
import { useGetSelectedScenario } from 'redux/reducers/scenario_reducer/selectors';
import Actions from 'redux/reducers/userproducts_reducer/actions';
import {
  useGetEnvDescSelection,
  useGetEnvImpactSelection,
  useGetExportModal,
  useGetGraphsImg,
  useGetProductCategorySelection,
  useGetUserOutputList,
  useGetUserProductsList,
} from 'redux/reducers/userproducts_reducer/selectors';
import UserProductsActions from '../../redux/reducers/userproducts_reducer/actions';
import DatatableList from './components/datatable';
import Description from './components/Description';
import Modal from './components/Modal';
import SelectProduct from './components/selectProduct';
import { TableDefaultFetchValues } from './configuration';
import getBarConfig from './configuration/getBarConfig';
import getBubbleConfig, {
  EnvironmentImpact,
  EnvironmentImpactMapping,
} from './configuration/getBubbleConfig';
import getColumnConfig from './configuration/getColumnConfig';
import getLineConfig from './configuration/getLineConfig';
import useStyles from './styles';

function getSteps() {
  return ['SUP product selection', 'Setup User Product', 'Visualization'];
}

const UserProductsPage = (props: any) => {
  console.log('Render UserProductsPage');
  const selectedScenario = useGetSelectedScenario();
  const exportModal = useGetExportModal();
  const history = useHistory();
  const ref = useRef(null);
  const [imgDemo, setImg] = React.useState<string | null>(null);
  const graphsImg = useGetGraphsImg();
  const { id } = useSelectUserData();

  const userOutput = useGetUserOutputList();
  const userProduct = useGetUserProductsList();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);

  const [envImpactOption, setEnvImpactOption] = React.useState<string[]>([]);
  const [productCategoryOption, setProductCategoryOption] = React.useState<
    string[]
  >([]);
  const environmentImpact = useGetEnvImpactSelection();
  const envDescription = useGetEnvDescSelection();
  const productCategory = useGetProductCategorySelection();

  React.useEffect(() => {
    if (selectedScenario) {
      dispatch(
        UserProductsActions.fetchUserProductsListAction({
          sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
          order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          scenario: selectedScenario,
        })
      );
      dispatch(
        UserProductsActions.fetchUserOutputListAction({
          userid: id,
          sortField: '_id',
          order: 'asc',
          scenario: selectedScenario,
        })
      );
    }

    dispatch(UserProductsActions.fetchProductsListAction());
  }, [dispatch, selectedScenario]);

  React.useEffect(() => {
    if (!selectedScenario) {
      dispatch(
        ModalActions.openModalAction({
          modalID: ModalIDs.TOAST,
          params: {
            message: 'You need to select your scenario first',
            type: 'error',
          },
        })
      );
      history.push('/scenario');
    }
  }, [selectedScenario]);

  React.useEffect(() => {
    if (userProduct.status === 'Success') {
      const options = _.uniq(
        userProduct.data?.items.map((item: any) => item.productCategory)
      );
      if (options.length > 0) {
        dispatch(Actions.setProductCategorySelection(options[0]));
        setProductCategoryOption(options);
        dispatch(
          Actions.setEnvDescSelection(
            _.get(
              EnvironmentImpactMapping,
              Object.keys(EnvironmentImpact)[0],
              ''
            )
          )
        );
        setEnvImpactOption(Object.keys(EnvironmentImpact));
        dispatch(
          Actions.setEnvImpactSelection(Object.keys(EnvironmentImpact)[0])
        );
      }
    }
  }, [userProduct]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  const handleChangeProductCategory = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch(Actions.setProductCategorySelection(event.target.value as string));
  };
  const handleChangeEnvImpact = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch(Actions.setEnvImpactSelection(event.target.value as string));
    dispatch(
      Actions.setEnvDescSelection(
        _.get(EnvironmentImpactMapping, event.target.value as string, '')
      )
    );
  };

  function renderSelect(title: string, showEnv: boolean) {
    return (
      <Box style={{ margin: '10px 20px', padding: 5 }}>
        <Typography style={{ padding: 5 }} variant="body1">
          {title}
        </Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            classes={{ focused: classes.focused }}
            style={{ backgroundColor: '#fff' }}
            id="demo-simple-select-outlined-label"
          >
            Product Category
          </InputLabel>
          <Select
            labelId="simple-select"
            fullWidth
            value={productCategory}
            onChange={handleChangeProductCategory}
          >
            {userProduct.status === 'Success' ? (
              productCategoryOption.map((item: any) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))
            ) : (
              <div>Loading....</div>
            )}
          </Select>
        </FormControl>
        {showEnv ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              classes={{ focused: classes.focused }}
              style={{ backgroundColor: '#fff' }}
              id="demo-simple-select-outlined-label"
            >
              Environmental impact category
            </InputLabel>
            <Select
              labelId="simple-select"
              fullWidth
              value={environmentImpact}
              onChange={handleChangeEnvImpact}
            >
              {userProduct.status === 'Success' ? (
                envImpactOption.map((item: any) => (
                  <MenuItem key={item} value={item}>
                    {_.get(EnvironmentImpact, item, '')}
                  </MenuItem>
                ))
              ) : (
                <div>Loading....</div>
              )}
            </Select>
          </FormControl>
        ) : null}
        {showEnv ? <Typography>{envDescription}</Typography> : null}
      </Box>
    );
  }

  function renderPotential() {
    const _1stTotalEstimatedReductionMarineLitter =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item: any) => item.estimatedReductionInMaritineLitter)
            .reduce((a: number, b: number) => a + b)
        : 0;

    const _2ndTotalEstimatedReductionMarineLitter =
      _1stTotalEstimatedReductionMarineLitter * 2;
    const _3rdTotalEstimatedReductionMarineLitter =
      _1stTotalEstimatedReductionMarineLitter * 3;
    const _4thTotalEstimatedReductionMarineLitter =
      _1stTotalEstimatedReductionMarineLitter * 4;
    const _5thTotalEstimatedReductionMarineLitter =
      _1stTotalEstimatedReductionMarineLitter * 5;

    const _1stTotalEstimatedReductioninLitter =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item: any) => item.estimatedReductionInLitter)
            .reduce((a: any, b: any) => a + b)
        : 0;
    const _2ndtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 2;
    const _3rdtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 3;
    const _4thtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 4;
    const _5thtTotalEstimatedReductioninLitter =
      _1stTotalEstimatedReductioninLitter * 5;

    return (
      <GridLayout
        elements={[
          {
            id: 'Estimated reduction in yearly litter (kg)',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getBarConfig(
                      userOutput.data.items,
                      [
                        '1.1 Estimated reduction in yearly plastic litter',
                        '(incl. plastic marine litter) across all product categories (kg)',
                      ],
                      'estimatedReductionInLitter',
                      'kg',
                      'litter_2_1',
                      ''
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'Estimated reduction in yearly litter (kg) line',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getLineConfig(
                      [
                        _1stTotalEstimatedReductioninLitter,
                        _2ndtTotalEstimatedReductioninLitter,
                        _3rdtTotalEstimatedReductioninLitter,
                        _4thtTotalEstimatedReductioninLitter,
                        _5thtTotalEstimatedReductioninLitter,
                      ],
                      [
                        '1.2 Estimated reduction in yearly plastic litter',
                        '(incl. plastic marine litter) across all product categories (kg)',
                      ],
                      'estimatedReductionInLitter',
                      'kg',
                      'litter_2_2',
                      '#F2994A'
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'Estimated reduction in yearly marine litter (Items)',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getBarConfig(
                      userOutput.data.items,
                      [
                        '2.1 Estimated reduction in yearly plastic marine litter',
                        'across all product categories (items)',
                      ],
                      'estimatedReductionInMaritineLitter',
                      'items',
                      'marineLitter_1_1',
                      '#D9A21B'
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
          {
            id: 'Estimated reduction in yearly marine litter (Items) line',
            size: 6,
            element: (
              <React.Fragment>
                {userOutput.status === 'Success' ? (
                  <DashboardBuilder
                    configuration={getLineConfig(
                      [
                        _1stTotalEstimatedReductionMarineLitter,
                        _2ndTotalEstimatedReductionMarineLitter,
                        _3rdTotalEstimatedReductionMarineLitter,
                        _4thTotalEstimatedReductionMarineLitter,
                        _5thTotalEstimatedReductionMarineLitter,
                      ],
                      [
                        '2.2 Estimated reduction in yearly plastic marine litter',
                        'across all product categories (items)',
                      ],
                      'estimatedReductionInLitter',
                      'items',
                      'marineLitter_1_2',
                      '#27AE60'
                    )}
                  />
                ) : (
                  <div>loading...</div>
                )}
              </React.Fragment>
            ),
          },
        ]}
      />
    );
  }
  function renderCost() {
    const lineGraphs = userOutput.data.items
      .filter((item: any) => item.productCategory === productCategory)
      .map((item: any) => {
        return {
          id: `Cost Savings per catgory ${item.productCategory}`,
          size: 12,
          element: (
            <React.Fragment>
              {userOutput.status === 'Success' ? (
                <DashboardBuilder
                  configuration={getLineConfig(
                    [
                      item.estimatedReductionInCost1stYear,
                      item.estimatedReductionInCost2ndYear,
                      item.estimatedReductionInCost3rdYear,
                      item.estimatedReductionInCost4thYear,
                      item.estimatedReductionInCost5thYear,
                    ],
                    [
                      `Projected cost savings for ${item.productCategory} (cumulative) [EUR]`,
                    ],
                    'estimatedReductionInLitter',
                    '[EUR]',
                    `${item.productCategory}`,
                    '#D9A21B'
                  )}
                />
              ) : (
                <div>loading...</div>
              )}
            </React.Fragment>
          ),
        };
      });

    const _1stTotalCost =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item: any) => item.estimatedReductionInCost1stYear)
            .reduce((a: any, b: any) => a + b)
        : 0;

    const _2ndTotalCost =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item: any) => item.estimatedReductionInCost2ndYear)
            .reduce((a: any, b: any) => a + b)
        : 0;

    const _3rdtTotalCost =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item: any) => item.estimatedReductionInCost3rdYear)
            .reduce((a: any, b: any) => a + b)
        : 0;

    const _4thtTotalCost =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item: any) => item.estimatedReductionInCost4thYear)
            .reduce((a: any, b: any) => a + b)
        : 0;
    const _5thtTotalCost =
      userOutput.data.items.length !== 0
        ? userOutput.data.items
            .map((item: any) => item.estimatedReductionInCost5thYear)
            .reduce((a: any, b: any) => a + b)
        : 0;

    return (
      <React.Fragment>
        <GridLayout
          elements={[
            {
              id: 'Cost Savings per Category (Year1)',
              size: 12,
              element: (
                <React.Fragment>
                  {userOutput.status === 'Success' ? (
                    <DashboardBuilder
                      configuration={getBarConfig(
                        userOutput.data.items,
                        ['Cost savings per product category for Year 1 [EUR]'],
                        'estimatedReductionInCost1stYear',
                        '[EUR]',
                        'cost_per_category_year_1',
                        ''
                      )}
                    />
                  ) : (
                    <div>loading...</div>
                  )}
                </React.Fragment>
              ),
            },
          ]}
        />
        {renderSelect(
          'Please select product category to view cost projections across 5 years',
          false
        )}
        <GridLayout
          elements={[
            ...lineGraphs,
            {
              id: 'Total cost savings per category',
              size: 12,
              element: (
                <React.Fragment>
                  {userOutput.status === 'Success' ? (
                    <DashboardBuilder
                      configuration={getLineConfig(
                        [
                          _1stTotalCost,
                          _2ndTotalCost,
                          _3rdtTotalCost,
                          _4thtTotalCost,
                          _5thtTotalCost,
                        ],
                        [
                          'Total cost savings across all product categories (cumulative) [EUR]',
                        ],
                        'estimatedReductionInLitter',
                        '[EUR]',
                        'cost_all_categories',
                        '#27AE60'
                      )}
                    />
                  ) : (
                    <div>loading...</div>
                  )}
                </React.Fragment>
              ),
            },
          ]}
        />
      </React.Fragment>
    );
  }
  function renderEnvironment() {
    const columnData = userOutput.data.items
      .filter((item: any) => item.scenario === selectedScenario)
      .map((output: any) => {
        return {
          productCategory: output.productCategory,
          climateChange: [
            output.climateChangeSUP,
            output.climateChangeOther,
          ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
          humanToxicity: [
            output.humanToxicitySUP,
            output.humanToxicityOther,
          ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
          acidificationPotential: [
            output.acidificationPotentialSUP,
            output.acidificationPotentialOther,
          ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
          respiratoryEffectsOnHuman: [
            output.respiratoryEffectsOnHumanSUP,
            output.respiratoryEffectsOnHumanOther,
          ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
          carcinogenicEffectsOnHuman: [
            output.carcinogenicEffectsOnHumanSUP,
            output.carcinogenicEffectsOnHumanOther,
          ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
          marineAquaticEcotoxicity: [
            output.marineAquaticEcotoxicitySUP,
            output.marineAquaticEcotoxicityOther,
          ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
          climateChangeImpactsAssociatedWithEOL: [
            output.climateChangeImpactsAssociatedWithEOLSUP,
            output.climateChangeImpactsAssociatedWithEOLOther,
          ].map((item: number) => (item ? parseFloat(item.toFixed(9)) : 0)),
        };
      })
      .filter((item: any) => item.productCategory === productCategory);
    const columnCharts: any[] = [];
    const names =
      columnData.length !== 0
        ? Object.keys(columnData[0]).slice(1, Object.keys(columnData[0]).length)
        : [];
    var units = envDescription.match(/\[(.*?)\]/);

    var unit = units ? units[1] : '';
    for (let i = 0; i < columnData.length; i++) {
      const chartWithName = {
        name: columnData[i].productCategory,
        data: [] as IElementsProps[],
      };
      for (let j = 0; j < names.length; j++) {
        const chart: IElementsProps = {
          id: `${names[j]}`,
          size:
            environmentImpact === 'climateChange' ||
            environmentImpact === 'climateChangeImpactsAssociatedWithEOL'
              ? 6
              : 12,
          element: (
            <React.Fragment>
              <DashboardBuilder
                configuration={getColumnConfig(
                  columnData[i][names[j]],
                  _.get(EnvironmentImpact, names[j], ''),
                  unit,
                  `${names[j]}`
                )}
              />
            </React.Fragment>
          ),
        };
        if (names[j] === environmentImpact) chartWithName.data.push(chart);
      }
      columnCharts.push(chartWithName);
    }

    return (
      <React.Fragment>
        <Typography
          style={{ fontWeight: 500, fontSize: 16, padding: '0 20px' }}
        >
          Impacts of adopting the use of alternatives to SUPs across all product
          categories (%)
        </Typography>
        <Box style={{ padding: '0 20px' }}>
          <GridLayout
            elements={[
              {
                id: 'env',
                size: 12,
                element: (
                  <React.Fragment>
                    {userOutput.status === 'Success' ? (
                      <DashboardBuilder
                        configuration={getBubbleConfig(
                          userOutput.data.items,
                          '',
                          '',
                          'impacts_1'
                        )}
                      />
                    ) : (
                      <div>loading...</div>
                    )}
                  </React.Fragment>
                ),
              },
            ]}
          />
        </Box>
        <Divider style={{ width: '100%', margin: '20px 0' }} />
        {renderSelect('To view more detailed results, please select:', true)}
        <Divider style={{ width: '100%', margin: '20px 0' }} />
        <Box style={{ margin: '0 20px' }}>
          {columnCharts.map((item: any) => {
            const withDescription = [
              'climateChange',
              'climateChangeImpactsAssociatedWithEOL',
            ];

            const descriptionGrid = withDescription.includes(environmentImpact)
              ? [
                  {
                    id: 'description',
                    size: 6,
                    element: <Description />,
                  },
                ]
              : [];
            return (
              <div key={item.name}>
                <Typography variant="h5">
                  {_.get(EnvironmentImpact, item.name, '')}
                </Typography>
                <GridLayout elements={[...item.data, ...descriptionGrid]} />
              </div>
            );
          })}
        </Box>

        <Box margin="20px">
          <Typography>
            {`Impacts of adopting the use of alternatives to SUPs for ${productCategory} (%)`}
          </Typography>
          <GridLayout
            elements={[
              {
                id: 'env impatc',
                size: 12,
                element: (
                  <React.Fragment>
                    {userOutput.status === 'Success' ? (
                      <React.Fragment>
                        {userOutput.data.items.filter(
                          (item: any) =>
                            item.productCategory === productCategory
                        ).length > 0 ? (
                          <DashboardBuilder
                            configuration={getBubbleConfig(
                              userOutput.data.items.filter(
                                (item: any) =>
                                  item.productCategory === productCategory
                              ),
                              ``,
                              '',
                              'impacts_2'
                            )}
                          />
                        ) : null}
                      </React.Fragment>
                    ) : (
                      <div>loading...</div>
                    )}
                  </React.Fragment>
                ),
              },
            ]}
          />
        </Box>
      </React.Fragment>
    );
  }

  async function getDataUri() {
    // const graphImgIds = [
    //   'impacts_1',
    //   'impacts_2',
    //   'marineLitter_1_1',
    //   'marineLitter_1_2',
    //   'litter_2_1',
    //   'litter_2_2',
    //   'cost_per_category_year_1',
    //   `${productCategory}`,
    //   'cost_all_categories',
    //   environmentImpact,
    // ];
    // // const base64Img = await graphImgIds.map(async (id: string) => {
    // //   return await ApexCharts.exec(id, 'dataURI');
    // // });
    // const imgData = {};
    // for (let index = 0; index < graphImgIds.length; index++) {
    //   const element = graphImgIds[index];
    //   const img = await ApexCharts.exec(element, 'dataURI');
    //   _.set(imgData, element, img.imgURI);
    // }

    // dispatch(Actions.setGraphsImg(imgData));
    // console.log('img', imgData);
    history.push('/pdf-export');
  }

  function renderExportModal() {
    return (
      <React.Fragment>
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          margin="10px 0"
        >
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'capitalize', marginRight: 15 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'capitalize', marginRight: 15 }}
            onClick={getDataUri}
          >
            Export
          </Button>
        </Box>

        <Box marginLeft="10px" fontWeight="bold">
          <Typography variant="h6">Environment impacts</Typography>
        </Box>
        <Box width="100%">
          <Card>
            <CardContent>
              <Typography variant="body2">
                This set of results displays the changes in environmental
                impacts by switching from single-use plastics to the alternative
                products you have selected. Based on your inputs and by adopting
                a life-cycle approach that considers the emissions to air
                associated with each product, the results show the change in
                environmental impacts across a number of environmental impact
                categories. Results are shown collectively for the entirety of
                your product selection and also separately for each of the
                product categories.
              </Typography>
              <Typography variant="body2">
                Adopting the selection of alternatives, will result in the
                following (%) changes in emissions associated with these
                environmental impact categories:
              </Typography>
            </CardContent>
          </Card>
        </Box>

        {renderEnvironment()}

        <Box marginLeft="10px" fontWeight="bold">
          <Typography variant="h5">Littering</Typography>
        </Box>
        <Box width="100%">
          <Card ref={ref}>
            <CardContent>
              <Typography variant="body2">
                This set of results displays the potential reduction in plastic
                littering that could be achieved by switching from single-use
                plastics to the alternative products you have selected. For the
                purpose of this tool, litter is defined as that which is ‘in the
                environment’ – i.e. on the ground or waterways. It excludes
                ‘litter’ deposited in ‘litter bins’. The potential reduction in
                marine litter is measured in number of items, while the
                potential reduction in litter (which include marine litter) is
                measured in kilograms of litter.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        {renderPotential()}
        <Box marginLeft="10px" fontWeight="bold">
          <Typography variant="h5">Costs</Typography>
        </Box>
        <Box width="100%">
          <Card>
            <CardContent>
              <Typography variant="body2">
                This set of results displays the cost implications that occur by
                switching from single-use plastics to the alternative products
                you have selected. It provides an estimate of the cost savings
                (or additional expenses) that might occur based on your
                selection and displays how that might be projected across a
                timeframe of 5 years. For single use products, it is assumed
                that the annual consumption stays the same throughout the 5
                years, while for multi-use (MU) products, the calculation
                assumes a replacement rate for each consecutive year for each
                product category (as only a relatively small portion of MU
                products will need to be replaced every year).
              </Typography>
            </CardContent>
          </Card>
        </Box>
        {renderCost()}
      </React.Fragment>
    );
  }

  function handleClose() {
    dispatch(
      Actions.setExportModal({
        isOpen: false,
        data: null,
        type: 'add',
      })
    );
  }
  function handleChangeStep1(step: number) {
    if (userProduct.data?.items.length !== 0) {
      const selectedSupProducts = userProduct.data?.items.filter(
        (item: any) => {
          if (item.usageCategory === 'SUP' && item.selected) {
            return true;
          } else {
            return false;
          }
        }
      );
      if (selectedSupProducts && selectedSupProducts.length > 0) {
        setActiveStep(step);
      } else {
        showToastAction(
          dispatch,
          'Please complete all required fields in order to proceed',
          'error'
        );
      }
    } else {
      showToastAction(
        dispatch,
        'Please complete all required fields in order to proceed',
        'error'
      );
    }
  }
  function handleChangeStep2(step: number) {
    if (userProduct.data?.items.length !== 0) {
      const grouped = _.groupBy(userProduct.data?.items, 'productCategory');

      console.log('grouped', grouped);
      let isValid = true;
      Object.keys(grouped).forEach((productCategory: string) => {
        const products = grouped[productCategory].filter(
          (product: any) =>
            product.usageCategory !== 'SUP' && product.selected === true
        );
        if (products.length === 0) {
          isValid = false;
        }
      });
      if (isValid) {
        setActiveStep(step);
      } else {
        showToastAction(
          dispatch,
          'Please complete all required fields in order to proceed',
          'error'
        );
      }
    } else {
      showToastAction(
        dispatch,
        'Please complete all required fields in order to proceed',
        'error'
      );
    }
  }
  function handleViewResults() {
    handleChangeStep2(2);
    dispatch(
      ScenarioActions.editScenarioAction({
        active: true,
        _id: selectedScenario,
      })
    );
  }

  return (
    <PageLayout>
      <div>
        <Modal />

        <Paper style={{ marginTop: 50 }}>
          <Tabs
            value={activeStep}
            indicatorColor="primary"
            textColor="primary"
            classes={{
              indicator: classes.indicator,
            }}
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
              if (Math.abs(activeStep - newValue) === 1) {
                if (newValue === 1) {
                  handleChangeStep1(1);
                } else if (newValue === 2) {
                  handleChangeStep2(2);
                } else {
                  setActiveStep(newValue);
                }
              }
            }}
          >
            <Tab
              classes={{
                selected: classes.selectedTab,
              }}
              className={classes.normalCase}
              label={
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      backgroundColor: activeStep === 0 ? '#D9A21B' : '#F1F4F5',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 12,
                    }}
                  >
                    <Typography
                      style={{
                        color: activeStep === 0 ? '#fff' : '#828282',
                        fontSize: '8px',
                      }}
                    >
                      1
                    </Typography>
                  </div>
                  <Typography>SUP product selection</Typography>
                </div>
              }
            />
            <Tab
              className={classes.normalCase}
              classes={{
                selected: classes.selectedTab,
              }}
              label={
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      backgroundColor: activeStep === 1 ? '#D9A21B' : '#F1F4F5',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 12,
                    }}
                  >
                    <Typography
                      style={{
                        color: activeStep === 1 ? '#fff' : '#828282',
                        fontSize: '8px',
                      }}
                    >
                      2
                    </Typography>
                  </div>
                  <Typography>Alternative product selection</Typography>
                </div>
              }
            />
            <Tab
              className={classes.normalCase}
              label={
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      backgroundColor: activeStep === 2 ? '#D9A21B' : '#F1F4F5',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 12,
                    }}
                  >
                    <Typography
                      style={{
                        color: activeStep === 2 ? '#fff' : '#828282',
                        fontSize: '8px',
                      }}
                    >
                      3
                    </Typography>
                  </div>
                  <Typography>Results</Typography>
                </div>
              }
              classes={{
                selected: classes.selectedTab,
              }}
            />
          </Tabs>
        </Paper>
        {activeStep === 0 && (
          <Box style={{ marginTop: 10 }}>
            <SelectProduct handleChangeStep1={handleChangeStep1} />
          </Box>
        )}
        {activeStep === 1 && (
          <Box
            height="100px"
            display="flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Box>
              <Typography style={{ color: '#959CA0', fontSize: '14px' }}>
                Alternatives to single-use plastic products
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                style={{
                  textTransform: 'unset',
                  marginRight: 15,
                  height: 40,
                  backgroundColor: '#346CB0',
                  color: '#fff',
                }}
                onClick={() => setActiveStep(0)}
              >
                {'<'} Back
              </Button>
              <Button
                variant="contained"
                style={{
                  textTransform: 'unset',
                  marginRight: 15,
                  height: 40,
                  backgroundColor: '#D9A21B',
                  color: '#fff',
                }}
                onClick={handleViewResults}
              >
                {'>'} View results
              </Button>
            </Box>
          </Box>
        )}
        {activeStep === 1 && (
          <React.Fragment>
            <DatatableList />
          </React.Fragment>
        )}

        {activeStep === 2 && (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '20px 0',
              }}
            >
              <Tabs
                value={tab}
                onChange={handleChange}
                aria-label="Visualization tab"
                classes={{
                  indicator: classes.indicatorStep3,
                }}
                style={{
                  display: 'flex',
                  flex: 1,
                  borderBottom: '2px solid #BDBDBD',
                }}
              >
                <Tab
                  className={classes.normalCase}
                  label={
                    <Typography
                      style={{
                        color: tab === 0 ? '#346CB0' : '#828282',
                        fontSize: '16px',
                      }}
                    >
                      Environmental impacts
                    </Typography>
                  }
                />
                <Tab
                  className={classes.normalCase}
                  label={
                    <Typography
                      style={{
                        color: tab === 1 ? '#346CB0' : '#828282',
                        fontSize: '16px',
                      }}
                    >
                      Littering
                    </Typography>
                  }
                />
                <Tab
                  className={classes.normalCase}
                  label={
                    <Typography
                      style={{
                        color: tab === 2 ? '#346CB0' : '#828282',
                        fontSize: '16px',
                      }}
                    >
                      Costs
                    </Typography>
                  }
                />
              </Tabs>
              <div style={{ display: 'flex', marginLeft: 40 }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: 'capitalize',
                    marginRight: 15,
                    height: 40,
                    backgroundColor: '#346CB0',
                    color: '#fff',
                  }}
                  onClick={() => setActiveStep(1)}
                >
                  {'<'} Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: 'capitalize',
                    height: 40,
                    backgroundColor: '#D9A21B',
                    color: '#fff',
                  }}
                  onClick={() => getDataUri()}
                >
                  Export PDF report
                </Button>
              </div>
            </div>

            {tab === 1 && (
              <React.Fragment>
                <Box width="100%">
                  <Card ref={ref}>
                    <CardContent>
                      <Typography variant="body2">
                        This set of results displays the potential reduction in
                        plastic littering that could be achieved by switching
                        from single-use plastics to the alternative products you
                        have selected. For the purpose of this tool, litter is
                        defined as that which is ‘in the environment’ – i.e. on
                        the ground or waterways. It excludes ‘litter’ deposited
                        in ‘litter bins’. The potential reduction in marine
                        litter is measured in number of items, while the
                        potential reduction in litter (which include marine
                        litter) is measured in kilograms of litter.
                      </Typography>
                      {renderPotential()}
                    </CardContent>
                  </Card>
                </Box>
              </React.Fragment>
            )}
            {tab === 2 && (
              <React.Fragment>
                <Box width="100%">
                  <Card>
                    <CardContent>
                      <Typography variant="body2">
                        This set of results displays the cost implications that
                        occur by switching from single-use plastics to the
                        alternative products you have selected. It provides an
                        estimate of the cost savings (or additional expenses)
                        that might occur based on your selection and displays
                        how that might be projected across a timeframe of 5
                        years. For single use products, it is assumed that the
                        annual consumption stays the same throughout the 5
                        years, while for multi-use (MU) products, the
                        calculation assumes a replacement rate for each
                        consecutive year for each product category (as only a
                        relatively small portion of MU products will need to be
                        replaced every year).
                      </Typography>
                      {renderCost()}
                    </CardContent>
                  </Card>
                </Box>
              </React.Fragment>
            )}
            {tab === 0 && (
              <React.Fragment>
                <Box width="100%">
                  <Card>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <Box style={{ padding: '0 20px' }}>
                        <Typography
                          style={{ fontWeight: 500, fontSize: '16px' }}
                        >
                          Environmental Impacts
                        </Typography>
                        <Typography variant="body2">
                          This set of results displays the changes in
                          environmental impacts by switching from single-use
                          plastics to the alternative products you have
                          selected. Based on your inputs and by adopting a
                          life-cycle approach that considers the emissions to
                          air associated with each product, the results show the
                          change in environmental impacts across a number of
                          environmental impact categories. Results are shown
                          collectively for the entirety of your product
                          selection and also separately for each of the product
                          categories.
                        </Typography>
                        <Typography variant="body2">
                          Adopting the selection of alternatives, will result in
                          the following (%) changes in emissions associated with
                          these environmental impact categories:
                        </Typography>
                      </Box>
                      <Divider style={{ width: '100%', margin: '20px 0' }} />

                      <Box>{renderEnvironment()}</Box>
                    </CardContent>
                  </Card>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {activeStep === 0 && (
          <Box
            width="100%"
            display="flex"
            height="100px"
            justifyContent="flex-end"
            margin="15px 0"
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: 'unset',
                marginRight: 15,
                height: 40,
                fontSize: 16,
                backgroundColor: '#D9A21B',
              }}
              onClick={() => handleChangeStep1(1)}
            >
              {'>'} Explore alternatives
            </Button>
          </Box>
        )}
        {activeStep === 1 && (
          <Box height="100px" display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: 'unset',
                marginRight: 15,
                height: 40,
                backgroundColor: '#346CB0',
                color: '#fff',
              }}
              onClick={() => setActiveStep(0)}
            >
              {'<'} Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: 'unset',
                marginRight: 15,
                height: 40,
                backgroundColor: '#D9A21B',
                color: '#fff',
              }}
              onClick={handleViewResults}
            >
              {'>'} View results
            </Button>
          </Box>
        )}

        {activeStep === 2 && (
          <Box
            width="100%"
            display="flex"
            justifyContent="flex-end"
            margin="10px 0"
            height="100px"
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: 'capitalize',
                marginRight: 15,
                height: 40,
                backgroundColor: '#346CB0',
                color: '#fff',
              }}
              onClick={() => setActiveStep(1)}
            >
              {'<'} Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                textTransform: 'capitalize',
                height: 40,
                backgroundColor: '#D9A21B',
                color: '#fff',
              }}
              onClick={() =>
                dispatch(
                  Actions.setExportModal({
                    isOpen: true,
                    data: null,
                    type: 'add',
                  })
                )
              }
            >
              Export PDF report
            </Button>
          </Box>
        )}
        {/* </Paper> */}
      </div>
    </PageLayout>
  );
};

export default withRouter(UserProductsPage);
