import { Box, Button, CssBaseline, Drawer } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import TranslatedText from 'components/atoms/translated_text';
import {
  AppDatePicker,
  AppTextField,
  AppMultipleSelect,
  AppSelect,
} from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import DashboardBuilderActions from '../../store/actions';
import { useDispatch } from 'react-redux';
import * as DashboardBuilderSelectors from '../../store/selectors';
import { StateStatus } from 'redux/utils/common';
import GridLayout from 'components/molecules/grid_layout';
import * as Configuration from '../../configuration';
import { validate } from '@material-ui/pickers';
import { IDashboardElement } from 'components/compounds/dashboard_builder/plugins/types';
import {
  TBuilderTypes,
  TBuilderSize,
} from 'components/compounds/dashboard_builder/plugins/types';

interface IFormData {
  dataUrl: string | null;
  size: TBuilderSize;
  type: TBuilderTypes;
  // globals: string | null;
  method: Configuration.Methods;
}

// add types or interface
const defaultValues: IFormData = {
  dataUrl: null,
  size: 12,
  type: 'chart',
  method: 'GET',
};

export default function Form() {
  const {
    register,
    control,
    formState,
    watch,
    errors,
    reset,
    handleSubmit,
  } = useForm<IFormData>({
    defaultValues,
    mode: 'onChange',
  });
  const dispatch = useDispatch();
  const initialValuesconfig = DashboardBuilderSelectors.useGetSelectedElementDashboard(
    'element'
  ) as IDashboardElement | null;

  React.useEffect(() => {
    if (initialValuesconfig) {
      reset({
        ...defaultValues,
        dataUrl: initialValuesconfig.url,
        size: initialValuesconfig.size || null,
        type: initialValuesconfig.type,
        method: initialValuesconfig.urlMethod || 'GET',
      });
    } else {
      reset({
        ...defaultValues,
      });
    }
  }, [initialValuesconfig]);

  const onSubmit = (_data: IFormData) => {
    // http://localhost:1337/api/users/nationality
    dispatch(
      DashboardBuilderActions.fetchDataFieldsAction({
        urlPath: _data.dataUrl || '',
        type: _data.type,
        size: _data.size,
        reqMethod: _data.method,
      })
    );
  };

  // console.log("Errors: err", errors);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridLayout
        spacing={1}
        justify="flex-start"
        elements={[
          {
            id: 'url-method',
            element: (
              <AppSelect
                variant="outlined"
                margin="dense"
                error={'method' in errors}
                size="small"
                helperText={errors.method && errors.method.message}
                rules={{
                  required: <RequiredField />,
                }}
                // required
                fullWidth
                options={Configuration.MethodArr}
                id="method"
                control={control}
                label={<TranslatedText defaultText={'Method'} />}
                autoComplete="method"
                name="method"
              />
            ),
            size: 3,
          },
          {
            id: 'data-url',
            element: (
              <AppTextField
                variant="outlined"
                margin="dense"
                error={'dataUrl' in errors}
                size="small"
                helperText={errors.dataUrl && errors.dataUrl.message}
                rules={{
                  required: <RequiredField />,
                }}
                // required
                fullWidth
                id="dataUrl"
                control={control}
                label={<TranslatedText defaultText={'Data URL'} />}
                autoComplete="dataUrl"
                name="dataUrl"
              >
                TEST
              </AppTextField>
            ),
            size: 9,
          },
          // {
          //   id: 'globals',
          //   element: <GlobalsWrapper control={control} errors={errors} />,
          //   size: 12,
          // },
          {
            id: 'element-size',
            element: (
              <AppSelect
                variant="outlined"
                margin="dense"
                rules={
                  {
                    // required: <RequiredField />,
                  }
                }
                fullWidth
                size="small"
                id="size"
                options={Configuration.WidthArr}
                control={control}
                label={<TranslatedText defaultText={'Width'} />}
                autoComplete="size"
                name="size"
              />
            ),
            size: 6,
          },
          {
            id: 'element-type',
            element: (
              <AppSelect
                variant="outlined"
                margin="dense"
                rules={{
                  required: <RequiredField />,
                }}
                fullWidth
                size="small"
                id="type"
                options={Configuration.BuilderTypesArr}
                control={control}
                label={<TranslatedText defaultText={'Type'} />}
                autoComplete="type"
                name="type"
              />
            ),
            size: 6,
          },
          {
            id: 'submit',
            element: <Submit formState={formState} />,
            size: 3,
          },
        ]}
      />
    </form>
  );
}

// interface IGlobalsWrapperProps {
//   control: any;
//   errors: any;
// }

// const GlobalsWrapper = (props: IGlobalsWrapperProps) => {
//   const { errors, control } = props;
//   const globalsArr = DashboardBuilderSelectors.useGetBuilderGlobals();

//   return (
//     <AppMultipleSelect
//       variant="outlined"
//       inputVariant="checkbox"
//       margin="dense"
//       error={'dataUrl' in errors}
//       size="small"
//       helperText={errors.dataUrl && errors.dataUrl.message}
//       rules={{
//         required: <RequiredField />,
//       }}
//       // required
//       fullWidth
//       id="globals"
//       options={(globalsArr || []).map(({ name, value }) => ({
//         id: name,
//         label: `${name}=${value}`,
//       }))}
//       control={control}
//       label={<TranslatedText defaultText={'Globals'} />}
//       autoComplete="globals"
//       name="globals"
//     />
//   );
// };

interface ISubmitProps {
  formState: any;
}

const Submit = (props: ISubmitProps) => {
  const { status } = DashboardBuilderSelectors.useGetLoadDataFields();
  // ✅ read all formState values to subscribe to changes
  const { isDirty, isValid } = props.formState;

  return (
    <Button
      disabled={status === StateStatus.Pending || !isValid || !isDirty}
      variant="outlined"
      color="primary"
      type="submit"
    >
      <TranslatedText defaultText="Load Data" />
    </Button>
  );
};

const RequiredField = () => {
  return <TranslatedText defaultText={'Required Value'} />;
};
