import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Dialog,
  Typography,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import { Translations } from '../../configurations';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Roles } from 'config/authRoles';
import GridLayout from '../../../../components/molecules/grid_layout';
import {
  AppDatePicker,
  AppMultipleSelect,
  AppRadioGroup,
  AppSelect,
  AppTextField,
} from '../../../../components/molecules/form_controls';
import TranslatedText from '../../../../components/atoms/translated_text';
import { useForm } from 'react-hook-form';
import { StateStatus } from '../../../../redux/utils/common';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {
  useGetModal,
  useGetUpdateUserStatus,
  useGetUserDetail,
} from '../../../../redux/reducers/user_management_reducer/selectors';
import Actions from '../../../../redux/reducers/user_management_reducer/actions';

const defaultValues = {
  roles: null,
  password: null,
};

interface IFormData {
  roles: string[] | null;
  password: string | null;
}

const useStyles = makeStyles(() => ({
  root: {},
}));
export default function UpdateModal() {
  console.log('Render UpdateModal:');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { isOpen, type, data } = useGetModal();
  const [roleOptions, setroleOptions] = useState<any>([]);
  const [layout, setLayout] = useState<any>([]);
  const userDetail = useGetUserDetail();

  useEffect(() => {
    const roles = Object.keys(Roles);
    roles.shift();
    const option = roles.map((role: string) => {
      return {
        label: role,
        id: _.get(Roles, [role], ''),
      };
    });
    setroleOptions(option);
  }, [Roles]);

  useEffect(() => {
    const ele = [];

    if (type !== '') {
      if (type === 'roles' && userDetail.status === 'Success') {
        ele.push({
          id: '13',
          size: 12,
          element: (
            <AppMultipleSelect
              variant="outlined"
              margin="normal"
              error={'roles' in errors}
              helperText={errors?.roles && errors?.roles?.message}
              rules={{
                required: <RequiredField />,
              }}
              options={roleOptions}
              fullWidth
              id="roles"
              control={control}
              label={'Roles'}
              autoComplete="roles"
              name="roles"
            />
          ),
        });
      } else if (type === 'password') {
        ele.push({
          id: '-1',
          size: 12,
          element: (
            <AppTextField
              variant="outlined"
              margin="normal"
              type="password"
              error={'password' in errors}
              helperText={errors.password && errors.password.message}
              rules={{
                required: <RequiredField />,
              }}
              required
              fullWidth
              id="password"
              control={control}
              label={'Password'}
              autoComplete="password"
              name="password"
            />
          ),
        });
      }

      setLayout(ele);
    }
  }, [type, roleOptions, userDetail]);

  const dispatch = useDispatch();
  // const userDetailsData = UserProfileSelectors.useGetUserProfile();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
  } = useForm<IFormData>({
    defaultValues: defaultValues,
  });
  useEffect(() => {
    if (userDetail.status === 'Success' && type === 'roles') {
      reset({
        roles: userDetail?.data?.user?.roles,
      });
    }
  }, [userDetail]);
  const onSubmit = (_data: IFormData) => {
    if (type === 'roles') {
      dispatch(
        Actions.updateUser({ id: data, roles: _data?.roles?.join(',') })
      );
    } else if (type === 'password') {
      dispatch(Actions.changePassword({ id: data, password: _data.password }));
    }
  };
  const handleClose = () => {
    dispatch(Actions.setModal({ isOpen: false, data: null, type: '' }));
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          // autoComplete="off"
          // noValidate
          className={clsx(classes.root)}
        >
          <Card variant={'outlined'}>
            <CardHeader
              subheader="You can update user here"
              title="Update user"
            />
            <Divider />
            <CardContent>
              <GridLayout elements={[...layout]} />
            </CardContent>

            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <SubmitWrapper />
            </Box>
          </Card>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const SubmitWrapper = React.memo(() => {
  const submitStatus = useGetUpdateUserStatus();
  const dispatch = useDispatch();

  return (
    <Box display="flex">
      <Button
        // type={'submit'}
        color="primary"
        onClick={() =>
          //   dispatch(ScenarioAction.setModal({ isOpen: false, data: null }))
          dispatch(Actions.setModal({ isOpen: false, data: null, type: '' }))
        }
        variant="contained"
        // disabled={submitStatus === StateStatus.Pending}
      >
        <TranslatedText
          defaultText={'Cancel'}
          // textMap={Translations.form_submit}
        />
      </Button>
      <Button
        style={{ marginLeft: 10 }}
        type={'submit'}
        color="primary"
        variant="contained"
        disabled={submitStatus === StateStatus.Pending}
      >
        <TranslatedText defaultText={'Update'} textMap={Translations.update} />
      </Button>
    </Box>
  );
});

const RequiredField = () => {
  return (
    <TranslatedText
      textMap={Translations.form_error_required}
      defaultText={'Required Value'}
    />
  );
};
