import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { ReactElement } from 'react';
import IntroductionLogin from './introduction_login';
import useStyles from './styles';

interface IProps {
  children: ReactElement;
}

export default function LoginForm({ children }: IProps): ReactElement {
  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6} lg={8} xl={8}>
        <IntroductionLogin />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={4}
        xl={4}
        component={Paper}
        elevation={6}
        square
        className={classes.loginContainer}
      >
        <div className={classes.paper}>
          {children}
          <Box className={classes.boxLogoFirst}>
         
                <img
              className={classes.img_logo}
              src={require('assets/images/logos/logo-5.png')}
            />

<img
              className={classes.img_logo}
              src={require('assets/images/logos/hcci.png')}
            />


            <img
              className={classes.img_logo}
              src={require('assets/images/logos/anelixis.png')}
            />
             

          
          </Box>
          <Box className={classes.boxLogoSecond}>

          <img
              className={classes.img_logo}
              src={require('assets/images/logos/app-logo.png')}
            /> 
              <img
              className={classes.img_logo}
              src={require('assets/images/logos/aisnew.png')}
            />
            <img
              className={classes.img_logo}
              src={require('assets/images/logos/cha.png')}
            />
          
           

          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
