import _ from 'lodash';
import React from 'react';
import { Redirect, withRouter } from 'react-router'; // import the react-router-dom components
import { AuthRoles } from '../../config';
import NavigationConfig from '../../navigation/config';
import { SideBarConfig } from '../../navigation/config/Routes';
import { ReducerKeys } from '../../redux/config';
import { getState } from '../../redux/store';
import reactLogger from 'utils/logger';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';

const MainPage = (props: any) => {
  reactLogger.log('MainPage');
  const roles = AuthSelectors.useSelectUserRoles();
  // replace main page with login Page
  if (roles.includes(AuthRoles.Guest)) {
    return <Redirect to={NavigationConfig.loginPage().path} />;
  } else {
    if (roles.includes(AuthRoles.Admin)) {
      return <Redirect to={NavigationConfig.productPage().path} />;
    } else {
      return <Redirect to={NavigationConfig.userproductPage().path} />;
    }
  }
  // if (
  //   _.get(getState(ReducerKeys.AUTH_REDUCER), 'role', AuthRoles.Guest) ===
  //   AuthRoles.Guest
  // ) {
  //   return <Redirect to={NavigationConfig.loginPage().path} />;
  // } else {
  //   return <Redirect to={NavigationConfig.dashboardV2Page().path} />;
  // }
};

export default withRouter(MainPage);
