import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import * as DashboardBuilderSelectors from '../../store/selectors';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AppTextField } from 'components/molecules/form_controls';
import DashboardBuilderActions from '../../store/actions';

interface IModalParams {}

interface IFormData {
  dashboardName: string;
}

const defaultValues: IFormData = {
  dashboardName: '',
};

const SaveDashboardModal = React.memo(() => {
  const dispatch = useDispatch();
  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<IFormData>({
    defaultValues: { ...defaultValues },
  });

  const dashboardTitle = DashboardBuilderSelectors.useGetDashboardTitle();

  React.useEffect(() => {
    reset({
      dashboardName: dashboardTitle,
    });
  }, [dashboardTitle]);

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_DASHBOARD_SAVE}
        modalProps={{ fullWidth: true }}
        renderHeader={() => <TranslatedText defaultText={`Save Dashboard`} />}
        renderContent={() => {
          return (
            <React.Fragment>
              <AppTextField
                variant="outlined"
                margin="dense"
                // error={Boolean(_.get(errors, 'cardTitle', false))}
                // helperText={_.get(errors, 'cardTitle', '')}
                rules={{ required: true }}
                fullWidth
                required
                size="small"
                id={'dashboardName'}
                control={control}
                label={<TranslatedText defaultText={'Dashboard Name'} />}
                autoComplete={'dashboardName'}
                name={'dashboardName'}
              />
            </React.Fragment>
          );
        }}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button
              onClick={() =>
                dispatch(
                  ModalActions.closeModalAction({
                    modalID: ModalIDs.MODAL_DASHBOARD_SAVE,
                  })
                )
              }
            >
              <TranslatedText defaultText={`Cancel`} />
            </Button>
            <Button
              onClick={() => {
                dispatch(
                  DashboardBuilderActions.saveDashboardAction({
                    dashboardTitle: getValues().dashboardName,
                  })
                );
                dispatch(
                  ModalActions.closeModalAction({
                    modalID: ModalIDs.MODAL_DASHBOARD_SAVE,
                  })
                );
              }}
              color="primary"
            >
              <TranslatedText defaultText={`Save`} />
            </Button>
          </React.Fragment>
        )}
      />
    </form>
  );
});

export default SaveDashboardModal;
