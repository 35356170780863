import { Box, Typography } from '@material-ui/core';
import LanguageDropdown from 'components/app_common/language_dropdown';
import GridLayout from 'components/molecules/grid_layout';
import React, { ReactElement, useState } from 'react';
import useStyles from './styles';

interface IPropShowOnOff {
  title: string;
  content: string | ReactElement;
  isShowDefault?: boolean;
}

function IconUp() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="7.24863"
        transform="matrix(1 0 0 -1 8.1279 7.91018)"
        fill="white"
      />
      <path
        d="M5.12942 9.53112C5.09229 9.49439 5.06284 9.45077 5.04274 9.40275C5.02264 9.35474 5.0123 9.30327 5.0123 9.25129C5.0123 9.19931 5.02264 9.14784 5.04274 9.09983C5.06284 9.05181 5.09229 9.00819 5.12942 8.97145L7.84192 6.28287C7.87901 6.2461 7.92305 6.21692 7.97153 6.19702C8.02001 6.17712 8.07198 6.16687 8.12445 6.16687C8.17693 6.16687 8.2289 6.17712 8.27738 6.19702C8.32586 6.21692 8.3699 6.2461 8.40699 6.28287L11.1195 8.97145C11.1581 9.00785 11.189 9.05154 11.2103 9.09993C11.2316 9.14833 11.2429 9.20046 11.2435 9.25325C11.2441 9.30604 11.2341 9.35842 11.214 9.4073C11.1938 9.45619 11.164 9.50059 11.1263 9.53788C11.0886 9.57518 11.0437 9.60462 10.9943 9.62447C10.9449 9.64431 10.892 9.65417 10.8387 9.65345C10.7854 9.65272 10.7328 9.64144 10.684 9.62026C10.6352 9.59908 10.5911 9.56844 10.5544 9.53013L8.12445 7.12139L5.69448 9.53013C5.65746 9.56697 5.61347 9.59622 5.56503 9.61621C5.51658 9.6362 5.46464 9.64654 5.41216 9.64663C5.35968 9.64672 5.3077 9.63657 5.25918 9.61675C5.21067 9.59693 5.16657 9.56783 5.12942 9.53112Z"
        fill="#346CB0"
      />
    </svg>
  );
}

function IconDown() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.82422" cy="7.89301" r="7.24863" fill="white" />
      <path
        d="M4.82574 6.27205C4.78861 6.30879 4.75915 6.35241 4.73906 6.40043C4.71896 6.44844 4.70862 6.49991 4.70862 6.55189C4.70862 6.60387 4.71896 6.65533 4.73906 6.70335C4.75915 6.75137 4.78861 6.79499 4.82574 6.83172L7.53824 9.5203C7.57533 9.55708 7.61937 9.58625 7.66785 9.60616C7.71633 9.62606 7.7683 9.63631 7.82077 9.63631C7.87325 9.63631 7.92522 9.62606 7.9737 9.60616C8.02218 9.58625 8.06622 9.55708 8.10331 9.5203L10.8158 6.83172C10.8544 6.79533 10.8853 6.75164 10.9066 6.70325C10.9279 6.65485 10.9392 6.60272 10.9398 6.54993C10.9404 6.49714 10.9304 6.44476 10.9103 6.39588C10.8901 6.34699 10.8603 6.30259 10.8226 6.26529C10.7849 6.228 10.74 6.19856 10.6906 6.17871C10.6412 6.15886 10.5883 6.14901 10.535 6.14973C10.4817 6.15045 10.4291 6.16174 10.3803 6.18291C10.3315 6.20409 10.2874 6.23474 10.2507 6.27304L7.82077 8.68179L5.3908 6.27304C5.35378 6.2362 5.30979 6.20695 5.26135 6.18696C5.2129 6.16697 5.16096 6.15664 5.10848 6.15655C5.056 6.15645 5.00402 6.16661 4.9555 6.18643C4.90699 6.20625 4.86289 6.23534 4.82574 6.27205Z"
        fill="#346CB0"
      />
    </svg>
  );
}

function ShowOnOff({ title, content, isShowDefault = false }: IPropShowOnOff) {
  const [isShow, setIsShow] = useState<boolean>(isShowDefault);
  const classes = useStyles();
  return (
    <Box style={{ marginTop: 20 }}>
      <Typography
        style={{
          fontWeight: 'bold',
          cursor: 'pointer',
          color: '#fff',
          fontSize: 20,
          marginBottom: 14,
        }}
        variant="h5"
        onClick={() => setIsShow(!isShow)}
      >
        {title}
        <span style={{ marginLeft: 20 }}>
          {isShow ? <IconUp /> : <IconDown />}
        </span>
      </Typography>
      {isShow && <Box className={classes.boxContent}>{content}</Box>}
    </Box>
  );
}
function UnderstandingProblem() {
  return (
    <GridLayout
      elements={[
        {
          id: '1',
          element: (
            <ShowOnOff
              title="Understanding the problem"
              content={
                <GridLayout
                  elements={[
                    {
                      id: '2',
                      element: (
                        <span>
                          While plastic is an important material for our lives
                          and for the European economy, it is not widely
                          recycled currently (less than 30% of plastic waste
                          collected) and is estimated to account for over 80% of
                          marine litter. Marine litter found on beaches and in
                          the seas causes economic, social and environmental
                          damage. Plastic marine litter has adverse effects on
                          ecosystems and biodiversity and human health, as
                          plastics enter the food chain in the form of
                          microplastics. Socioeconomic impacts include clean up
                          and retrieval costs, damage to tourism, shipping,
                          fishing and aquaculture, as well as the loss of
                          valuable resources for the economy
                        </span>
                      ),
                      size: 7,
                    },
                    {
                      id: '3',
                      element: (
                        <img
                          style={{
                            maxHeight: 250,
                            maxWidth: 250,
                            width: 'auto',
                          }}
                          src={require('assets/images/backgrounds/login-background.jpg')}
                        />
                      ),
                      size: 5,
                      rowStyle: {
                        textAlign: 'right',
                      },
                    },
                  ]}
                />
              }
              isShowDefault
            />
          ),
          size: 12,
        },
      ]}
    />
  );
}
function AboutThisProject() {
  return (
    <GridLayout
      elements={[
        {
          id: '4',
          element: (
            <ShowOnOff
              title="About this project"
              content="This project is funded by Iceland, Liechtenstein and Norway through the EEA and Norway Grants Fund for Regional Cooperation. The project aims to reduce the adverse effects of human activities on the environment, by supporting tourism establishments in Cyprus, Malta and Greece to reduce consumption and disposal of single-use plastics (SUP) in line with EU Directive 2019/904, by replacing commonly used SUP with environmentally friendlier, readily available and affordable alternatives."
            />
          ),
          size: 12,
        },
      ]}
    />
  );
}
function AboutThisTool() {
  return (
    <GridLayout
      elements={[
        {
          id: '5',
          element: (
            <ShowOnOff
              title="About this tool"
              content={
                <span>
              The decision-support tool (DST) aims to support establishments with making a transition from single-use plastic (SUP) products to alternative products, which are environmentally friendlier. Users can enter data on their current consumption of SUP and are provided with suitable alternatives to consider. All products are compared in terms of their environmental impacts across their life-cycle, balanced against other parameters, such as economic costs and legislative obligations, so that this tool can best support decision making for users. This is important because in some cases, SUP alternatives make environmental sense, while in other cases, SUP alternatives might not necessarily be environmentally friendlier (e.g. they might have higher demands for energy and water use).
                </span>
              }
            />
          ),
          size: 12,
        },
      ]}
    />
  );
}

export default function IntroductionLogin(): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.containerBackground}>
      <Box className={classes.boxTopLogo}>
        <img
          src={require('assets/images/logos/supmed_logo.png')}
          className={classes.img_main_logo}
        />
        
      </Box>
 
      <a  target="blank"
        style={{
          textDecoration: 'none',
        }}
        href={'https://www.supmed.eu/en/'}
      >
        <Typography className={classes.boxReduce} variant="h5">
          Reducing the Consumption and Disposal of Single-use Plastics in the
          Tourism Industry in Cyprus, Greece and Malta
        </Typography>
      </a>
      <Typography className={classes.boxDecision} variant="h5">
        Decision Support Tool (DST)
      </Typography>

      <UnderstandingProblem />
      <AboutThisProject />
      <AboutThisTool />
      <img
        className={classes.curveImageBg}
        src={require('assets/images/backgrounds/curve_background.png')}
      />
    </div>
  );
}
