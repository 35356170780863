import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  layout: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    marginTop: 10,
    backgroundColor: '#D9A21B',
    color: '#fff',
    textTransform: 'none',
  },
  title: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default useStyles;
