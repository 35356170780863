// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
interface IUserListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  _id: string | null;
  roles: string[];
  email: string | null;
  firstName: string | null;
  surname: string | null;
  registeredDate: string | Date;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}
interface IUserListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}
export const fetchUserList = async (
  payload: IUserListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let _data: IUserListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
/** getCountries  */
interface IGetCountriesOutput {
  code: string;
  code3: string;
  name: string;
  number: string;
}

export const getCountries = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users/nationality`
  );

  let _data: IGetCountriesOutput[] = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** get user profile  */
interface IGetUserProfileOutput extends Types.ResponseOutput {
  data: {
    email: string;
    firstname: string;
    surname: string;
    nationality: string;
    gender: string;
    birthDate: string;
    registeredDate: string;
    role: string;
  };
}

export const getUserProfile = async (payload: any): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/${payload.id}`
  );

  let output: IGetUserProfileOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
export const getUsernames = async (): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/fetch/usernames`
  );

  let output: IGetUserProfileOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** edit user profile  */
interface IEditUserProfileOutput extends Types.ResponseOutput {
  data: {
    email: string;
    firstName: string;
    surname: string;
    nationality: string;
    gender: string;
    birthDate: string;
    registeredDate: string;
    role: string;
  };
}

interface IEditUserProfileInput {
  email: string;
  firstName: string;
  surname: string;
  nationality: string;
  gender: string;
  birthDate: string;
}

export const editUserProfile = async (
  payload: IEditUserProfileInput
): Promise<IEditUserProfileOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/users/profile`,
    payload
  );

  let output: IEditUserProfileOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getStores  */
interface IGetStoresOutput extends Types.ResponseOutput {
  data: {
    id: string;
    code: string;
    status: string;
  }[];
}

export const getStores = async (): Promise<IGetStoresOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users/stores`
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export const updateUser = async (payload: any): Promise<any> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/user`,
    payload
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
export const changePassword = async (payload: {
  id: string;
  password: string | null;
}): Promise<any> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/user/change-password`,
    payload
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export const getUserDetail = async (payload: { id: string }): Promise<any> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user/${payload.id}`
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
