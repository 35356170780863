import React from 'react';
import { withRouter } from 'react-router'; // import the react-router-dom components
import { Link } from 'react-router-dom';
import NavigationConfig from '../../navigation/config';
import ForgotForm from './components/change_password_form';
import useStyles from './styles';
import clsx from 'clsx';
import LanguageDropdown from '../../components/app_common/language_dropdown';
import TranslatedText from '../../components/atoms/translated_text';
import { Translations } from './configuration';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@material-ui/core';
import { withReducer } from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';

interface IProps {}

const SecurityPage = (props: IProps) => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid
        className={classes.cardViewContainer}
        justify="center"
        spacing={5}
        container
      >
        <Card style={{ padding: '20px 40px' }} variant={'outlined'}>
          <Typography style={{ fontWeight: 500, fontSize: '20px' }}>
            <TranslatedText defaultText="Security" />
          </Typography>
          <Typography style={{ color: '#959CA0', fontSize: '14px' }}>
            <TranslatedText defaultText="Change your password for there" />
          </Typography>
          <CardContent>
            <ForgotForm />
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
};

// export default withRouter(SecurityPage);
const Page = withRouter(SecurityPage);
export default withReducer(ReducerKeys.CHANGE_PASSWORD_REDUCER)(Page);
