import React from 'react';
import { LanguagesEnum } from '../../../config/languages';
import * as LanguageSelectors from '../../../redux/reducers/gl_translations_reducer/selectors';

interface ITextMap {
  [LanguagesEnum.English]: string;
  [LanguagesEnum.Greek]: string;
}

interface IProps {
  textMap?: ITextMap | null;
  defaultText?: string;
}

const TranslatedText = ({ textMap, defaultText = '' }: IProps) => {
  const languageID: LanguagesEnum = LanguageSelectors.useSelectLanguageID();

  const renderTranslatedText = (): string => {
    // if (typeof textMap !== 'object') {

    // }
    return textMap && typeof textMap === 'object' && languageID in textMap
      ? textMap[languageID]
      : defaultText;
  };
  return <React.Fragment>{renderTranslatedText()}</React.Fragment>;
};

export default TranslatedText;
