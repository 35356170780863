import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import DashboardBuilderActions from '../../store/actions';
import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';

interface ModalParams {
  index: number;
}

const RemoveDashboardElementModal = React.memo(() => {
  const dispatch = useDispatch();
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_DASHBOARD_ELEMENT_REMOVE}
      showExitIcon={false}
      // modalProps={{ fullWidth: true }}
      renderHeader={() => (
        <TranslatedText
          defaultText={`Remove Dashboard Element. Are your sure?`}
        />
      )}
      renderFooter={(props: ModalParams) => (
        <React.Fragment>

          <Button
            onClick={() => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_DASHBOARD_ELEMENT_REMOVE,
                })
              );
            }}
            // color="secondary"
          >
            <TranslatedText defaultText={`No`} />
          </Button>
          <Button
            onClick={() => {
              dispatch(
                DashboardBuilderActions.setBuilderConfigurationAction({
                  type: 'delete',
                  index: props.index,
                })
              );
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_DASHBOARD_ELEMENT_REMOVE,
                })
              );
            }}
            color="primary"
          >
            <TranslatedText defaultText={`Yes`} />
          </Button>          
        </React.Fragment>
      )}
    />
  );
});

export default RemoveDashboardElementModal;
