import { Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ButtonShare from 'components/app_common/button_share';
import FormSearch from 'components/app_common/form_search';
import TranslatedText from 'components/atoms/translated_text';
import Datatable from 'components/molecules/datatable';
import React from 'react';
import { useDispatch } from 'react-redux';
import EstablishmentActions from 'redux/reducers/establishment_reducer/actions';
import * as EstablishmentReducer from 'redux/reducers/establishment_reducer/selectors';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { TableColumns, Translations } from '../../configuration';
import ActionsBlock from './ActionsBlock';
import useStyles from './styles';
import { TableDefaultFetchValues } from '../../configuration';

export default function DatatableList() {
  const result = EstablishmentReducer.useGetEstablishmentList();
  const paging = result?.data?.paging;
  const dispatch = useDispatch();
  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  const classes = useStyles();
  const onSearch = (value: string) => {
    if (value) {
      dispatch(
        EstablishmentActions.searchEstablishmentAction({
          sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
          page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
          pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
          order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          name: value,
        })
      );
    } else {
      dispatch(
        EstablishmentActions.fetchEstablishmentListAction({
          sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
          page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
          pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
          order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        })
      );
    }
  };
  return (
    <Box display="flex" flexDirection="column" className={classes.boxContainer}>
      <Box display="flex" className={classes.boxAbove}>
        <Typography
          style={{ fontWeight: 'bold', fontSize: 20, color: '#404756' }}
          variant="h5"
        >
          <TranslatedText
            textMap={Translations.table_title}
            defaultText={Translations.table_title.English}
          />
        </Typography>
        <ButtonShare
          children={
            <>
              <AddIcon style={{ fontSize: 17, marginRight: 5 }} />
              <TranslatedText
                textMap={Translations.addNewRecord}
                defaultText={Translations.addNewRecord.English}
              />
            </>
          }
          onClick={() =>
            dispatch(
              ModalActions.openModalAction({
                modalID: ModalIDs.MODAL_ADD_EDIT_ESTABLISHMENT,
                params: { type: 'add' },
              })
            )
          }
        />
      </Box>
      {/* <Button
        onClick={() =>
          dispatch(
            ModalActions.openModalAction({
              modalID: ModalIDs.MODAL_ADD_EDIT_ESTABLISHMENT,
              params: { type: 'add' },
            })
          )
        }
        className={classes.addButton}
      >
        <Typography component={'p'}>
          <TranslatedText
            textMap={Translations.addNewRecord}
            defaultText={Translations.addNewRecord.English}
          />
        </Typography>
      </Button> */}
      <FormSearch title="Establishments List" onSearch={onSearch} />
      <Datatable
        loading={true}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        paging={paging}
        data={result?.data?.items || []}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          sortType.current = order;
          sortField.current = _sortField;
          dispatch(
            EstablishmentActions.fetchEstablishmentListAction({
              sortField: _sortField,
              page: selectedPage,
              pageSize: pageSize,
              order: order,
            })
          );
        }}
      />
    </Box>
  );
}

export { ActionsBlock };
