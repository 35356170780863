import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  EstablishmentList: {
    status: StateStatus | null;
    data: any;
  };
  addEstablishment: {
    status: StateStatus | null;
  };
  editEstablishment: {
    status: StateStatus | null;
  };
  viewEstablishment: {
    status: StateStatus | null;
    data: any;
  };
  usersname: {
    status: StateStatus | null;
    data: any;
  };
  deleteEstablishment: {
    status: StateStatus | null;
  };
  groups: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  EstablishmentList: {
    status: null,
    data: null,
  },
  addEstablishment: {
    status: null,
  },
  editEstablishment: {
    status: null,
  },
  viewEstablishment: {
    status: null,
    data: null,
  },
  usersname: {
    status: null,
    data: null,
  },
  deleteEstablishment: {
    status: null,
  },
  groups: {
    status: null,
    data: null,
  },
};

const EstablishmentReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_ESTABLISHMENT_LIST:
      return {
        ...state,
        EstablishmentList: {
          ...state.EstablishmentList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.EstablishmentList.data
              : action.data,
        },
      };
    case Types.SET_ADD_ESTABLISHMENT:
      return {
        ...state,
        addEstablishment: {
          ...state.addEstablishment,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_ESTABLISHMENT:
      return {
        ...state,
        editEstablishment: {
          ...state.editEstablishment,
          status: action.status || null,
        },
      };
    case Types.SET_ESTABLISHMENT_DETAIL:
      return {
        ...state,
        viewEstablishment: {
          ...state.viewEstablishment,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewEstablishment.data
              : action.data,
        },
      };
    case Types.SET_USERNAMES:
      return {
        ...state,
        usersname: {
          ...state.usersname,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.usersname.data : action.data,
        },
      };
    case Types.DELETE_ESTABLISHMENT:
      return {
        ...state,
        deleteEstablishment: {
          ...state.deleteEstablishment,
          status: action.status || null,
        },
      };
    case Types.SET_GROUPS:
      return {
        ...state,
        groups: {
          ...state.groups,
          status: action.status || null,
          data: action.data === KEEP_THE_SAME ? state.groups.data : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default EstablishmentReducer;
