import { useSelector } from 'react-redux';
import { ReducerKeys } from 'redux/config';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import { StateStatus } from 'redux/utils/common';

/* selector: useGetRegisterStatus */

export const useGetRegisterStatus = (): StateStatus => {
  const status = useSelector(
    (state: any) => state[ReducerKeys.REGISTER_REDUCER].registerUserStatus
  );
  return status;
};
