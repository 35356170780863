import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { StateStatus } from '../../utils/common';

/* selector: useGetProduct */

interface IProductOutput {
  status: StateStatus;
  data: any | null;
}
interface IOpen {
  isOpen: boolean;
  type: string;
  data: any | null;
}

export const useGetOpen = (): IOpen => {
  const { isOpen, type, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PRODUCT_REDUCER].open
  );
  return { isOpen, type, data };
};
export const useGetProduct = (): IProductOutput => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PRODUCT_REDUCER].productList
  );
  return { status, data };
};
export const useGetProductPersist = (): IProductOutput => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.PRODUCT_REDUCER].productPersist
  );
  return { status, data };
};
