import blue from '@material-ui/core/colors/blue';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    '& >div:nth-child(2)': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      '& >h5': {
        color: '#404756',
        fontSize: 20,
      }
    },
    '& >div:nth-child(3)': {
      border: 'none',
    },
    '& >div:nth-child(4)': {
      marginTop: 0,
      border: 'none',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  addButton: {
    color: '#fff',
    backgroundColor: '#D9A21B',
    '&:hover': {
      backgroundColor: '#d89c08',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#D9A21B',
      },
    },
    alignSelf: 'flex-start',
    margin: 8,
  },
  submit: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: blue[500],
      },
    },
    alignSelf: 'flex-start',
    marginTop: 10,
  },
  editButton: {
    color: '#fff',
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: '#346CB0',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1d5ead',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#346CB0',
      },
    },
    alignSelf: 'flex-start',
    padding: '5px 9px',
  },
  viewButton: {
    marginRight: 5,
    marginLeft: 5,
    color: '#D9A21B',
    alignSelf: 'flex-start',
    border: '1px solid #D9A21B',
    textTransform: 'none',
    padding: '5px 8px',
  },
  form: {},
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 240,
    height: 24,
    padding: '5px 10px',
  },
  padding: {
    padding: '10px !important',
  },
  deleteButton: {
    marginRight: 5,
    marginLeft: 5,
    color: '#fff',
    backgroundColor: '#ff0033',
    '&:hover': {
      backgroundColor: '#ff0033',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#ff0033',
      },
    },
    alignSelf: 'flex-start',
    textTransform: 'none',
    padding: '5px 9px',
  },
  boxAbove: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0',
  },
  dialogContent: {
    padding: '0 !important',
  },
}));

export default useStyles;
