import { Container } from '@material-ui/core';
import React, { ReactElement } from 'react';
import useStyles from './styles';

interface IProps {
  children: ReactElement[] | ReactElement;
  marginTop?: boolean;
}

export default function PageLayout(props: IProps) {
  const { marginTop = true } = props;
  const classes = useStyles({ marginTop });

  return (  
  // <div className={classes.root} {...props} />
  <Container className={classes.root} maxWidth="xl">
    {props.children}
  </Container>
  )
}
