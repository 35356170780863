import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '0 15px',
    '& >div': {
      maxWidth: 300,
      width: '100%',
      '& >fieldset': {
        border: '1px solid #F1F4F5',
      },
    },
  },
  iconSearch: {
    color: '#7E8A9D',
  },
}));

export default useStyles;
