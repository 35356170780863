import React from 'react';
import Datatable from 'components/molecules/datatable';
import * as Colors from 'styles/colors';
import { useTheme } from '@material-ui/core';

export default function EditableDatatable() {
  const sortField = React.useRef<string>(DummyColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  const theme = useTheme();

  const paging = {
    page: 0,
    pageSize: DummyColumns.length,
    total: DummyColumns.length,
  };
  return (
    <Datatable
      paging={paging}
      data={DummyData}
      sorting={{
        sortField: sortField.current,
        order: sortType.current,
      }}
      height={'70vh'}
      disablePaper={false}
      paperProps={{
        style: {
          borderRadius: 2,
          //   backgroundColor: 'rgba(123, 163, 176, .05)',
          overflow: 'hidden',
        },
        square: false,
        variant: 'elevation',
      }}
      tableCellProps={() => ({
        style: {
          //   paddingTop: 0,
          //   paddingBottom: 0,
          borderLeft: 'none',
          borderRight: 'none',
        },
      })}
      tableCellMainRowProps={() => ({
        style: {
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: Colors.APP_COLOR_DARK({
            darkMode: theme.palette.type === 'dark',
          }),
          color: 'white',
        },
      })}
      loading={false}
      columns={DummyColumns}
    />
  );
}

interface IData {
  name: string;
  position: string;
  office: string;
  age: number;
  date: string;
  salary: number;
}

export const DummyColumns = [
  {
    label: 'Name',
    id: 'name',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Position',
    id: 'position',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Office',
    id: 'office',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Age',
    id: 'age',
    // align: 'right',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Date',
    id: 'date',
    // align: 'right',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Salary',
    id: 'salary',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
];

export const DummyData: IData[] = Array.from({ length: 100 }, (_, index) => ({
  name: 'name_' + index,
  position: 'name_' + index,
  office: 'name_' + index,
  age: index + 4,
  date: 'name_' + index,
  salary: index * 1002,
}));
