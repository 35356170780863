import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface UserOutput {
  status: StateStatus;
  data: any | null;
}

export const useGetUserOutput = (): UserOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.COMBINE_SCENARIOS_REDUCER].userOutput
  );
  return data;
};
export const useGetUserProduct = (): UserOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.COMBINE_SCENARIOS_REDUCER].userProduct
  );
  return data;
};
export const useGetEnvImpactSelection = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.COMBINE_SCENARIOS_REDUCER].envImpactSelection
  );
  return data;
};

export const useGetEnvDescSelection = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.COMBINE_SCENARIOS_REDUCER].envDescSelection
  );
  return data;
};
export const useGetProductCategorySelection = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.COMBINE_SCENARIOS_REDUCER].productCategorySelection
  );
  return data;
};
