import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: '#fff',
      backgroundColor: '#D9A21B',
      '&:hover': {
        backgroundColor: '#d89c08',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: '#D9A21B',
        },
      },
      alignSelf: 'flex-start',
      margin: 8,
      textTransform: 'none',
    },
  })
);

export default useStyles;
