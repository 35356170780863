import React from 'react';
import DashboardBuilder from 'components/compounds/dashboard_builder';
import * as Plugins from 'components/compounds/dashboard_builder/plugins';
import * as DashboardBuilderSelectors from '../../store/selectors';
import { Box, Fab } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import DashboardBuilderActions from '../../store/actions';

export default function DashboardView() {
  const { data } = DashboardBuilderSelectors.useGetDashboardConfig();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      {data && (
        <DashboardBuilder
          handleDashboardElement={(element, index) => (
            <div>
              <Toolbar index={index} />
              {element}
            </div>
          )}
          configuration={Plugins.convertPlugin(data, (newGlobals) => {
            dispatch(
              DashboardBuilderActions.setBuilderGlobalsAction({
                globals: Object.keys(newGlobals).map((key) => ({
                  name: key,
                  value: newGlobals[key],
                })),
              })
            );
          })}
        />
      )}
    </React.Fragment>
  );
}

interface IToolbar {
  index: number;
}

const Toolbar = (props: IToolbar) => {
  const dispatch = useDispatch();
  return (
    <Box display="flex" justifyContent="center">
      <ToolbarButton
        onClick={() => {
          dispatch(
            ModalActions.openModalAction({
              modalID: ModalIDs.MODAL_DASHBOARD_ELEMENT_CONFIG,
              params: {
                index: props.index,
              },
            })
          );
        }}
        icon={<CodeIcon />}
        title="Code"
      />
      <ToolbarButton
        onClick={() => {
          dispatch(
            DashboardBuilderActions.setSelectedDashboardElementAction({
              index: props.index,
            })
          );
        }}
        icon={<EditOutlinedIcon />}
        title="Edit"
      />
      <ToolbarButton
        onClick={() => {
          dispatch(
            ModalActions.openModalAction({
              modalID: ModalIDs.MODAL_DASHBOARD_ELEMENT_REMOVE,
              params: {
                index: props.index,
              },
            })
          );
        }}
        icon={<DeleteOutlineOutlinedIcon />}
        title="Remove"
      />
    </Box>
  );
};

interface IToolbarButtonProps {
  title: string | React.ReactElement;
  icon: React.ReactElement;
  onClick: () => void;
}

const ToolbarButton = (props: IToolbarButtonProps) => {
  const classes = useStyles();

  return (
    <div onClick={props.onClick} className={classes.toolsContainer}>
      <div className={classes.jsonButton}>
        {props.icon}
        <span className={classes.text}>{props.title}</span>
      </div>
    </div>
  );
};
