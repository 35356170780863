import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    // marginLeft: 80,
    // marginRight: 80,
    marginTop: (props: any) => (props?.marginTop ? 18 : 0),
    // [theme.breakpoints.down('lg')]: {
    //   marginLeft: 60,
    //   marginRight: 60,
    // },
    // [theme.breakpoints.down('md')]: {
    //   marginLeft: 30,
    //   marginRight: 30,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   marginLeft: 0,
    //   marginRight: 0,
    // },
  },
}));

export default styles;
