import React from 'react';
import { withRouter } from 'react-router'; // import the react-router-dom components
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import PageLayout from 'components/app_common/page_layout';
import Layout from './components/layout';
import DashboardView from './components/dashboard_view';
import BuilderForm from './components/builder_form';
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewConfigModal from './components/view_config_modal';
import RemoveDashboardElementModal from './components/remove_dashboard_element_modal';
import DashboardBuilderActions from './store/actions';
import * as DashboardBuilderSelectors from './store/selectors';
import Fab from '@material-ui/core/Fab';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import GlobalsModal from './components/globals_modal';
import { ReducerKeys } from 'redux/config';
import withReducer from 'redux/withReducer';
import SaveDashboardModal from './components/save_dashboard_modal';
import FullPageContainer from 'components/app_common/fullpage_container';

const drawerWidth = '40vw';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: (props: any) => (props.isSmal ? '80%' : drawerWidth),
      flexShrink: 0,
      zIndex: 100,
    },
    drawerPaper: {
      width: (props: any) => (props.isSmall ? '80%' : drawerWidth),
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
  })
);

// type Anchor = 'top' | 'left' | 'bottom' | 'right';

const DashboardBuilderPage = (props: any) => {
  reactLogger.renderPage('DashboardBuilderPage');
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(DashboardBuilderActions.fetchDashboardsAction());
  }, [dispatch]);

  //  style={{ overflow: 'auto', width: '100%' }}

  return (
    <div className={classes.root}>
      <GlobalsModal />
      <SaveDashboardModal />
      <FullPageContainer>
        <div>
          <PageLayout>
            <DashboardView />
          </PageLayout>
        </div>
      </FullPageContainer>
      <DrawerWrapper />
    </div>
  );
};

const DrawerWrapper = React.memo(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles({ isSmall: !matches });
  const drawerIsOpen = DashboardBuilderSelectors.useGetPalleteVisibility();
  const dispatch = useDispatch();

  // anchor: Anchor, open: boolean
  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch(
      DashboardBuilderActions.setPalleteVisibilityAction({
        visible: open,
      })
    );
  };

  return (
    <React.Fragment>
      <ViewConfigModal />
      <RemoveDashboardElementModal />
      <Fab
        style={{ position: 'fixed', right: 30, bottom: 30 }}
        color="secondary"
        aria-label="edit"
        onClick={toggleDrawer(true)}
      >
        <MenuOpenIcon />
      </Fab>
      <Drawer
        className={classes.drawer}
        variant={matches ? 'permanent' : 'temporary'}
        anchor="right"
        open={drawerIsOpen}
        style={{ display: !drawerIsOpen ? 'none' : 'block' }}
        onClose={toggleDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <BuilderForm />
        </div>
      </Drawer>
    </React.Fragment>
  );
});

const Page = withRouter(DashboardBuilderPage);
export default withReducer(ReducerKeys.DASHBOARD_BUILDER_REDUCER)(Page);
