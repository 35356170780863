// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

export const getProducts = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/product/list`
  );

  let _data: any[] = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const getProductListByUserId = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/product/list-product-by-user-id`
  );

  let _data: any[] = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const insertProduct = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/product`,
    payload
  );

  let _data: any[] = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const updateProduct = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/product`,
    payload
  );

  let _data: any[] = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
