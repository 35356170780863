import { Grid, useTheme } from '@material-ui/core';
import React, { ReactElement } from 'react';
import Row from './components/row';
import useStyles from './styles';

type TSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type TSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;

interface IElement {
  size: TSize;
  md?: TSize;
  element: ReactElement;
  id: string;
  rowStyle?: React.CSSProperties;
}

export interface IElementsProps {
  size: TSize;
  md?: TSize;
  element: ReactElement | IElement[];
  id: string;
  rowStyle?: React.CSSProperties;
}

interface IGridLayout {
  elements: IElementsProps[];
  justify?: 'center' | 'flex-start';
  spacing?: TSpacing;
  style?: React.CSSProperties;
}

export default function GridLayout(props: IGridLayout): ReactElement {
  const classes = useStyles();
  const { style = {} } = props;

  return (
    <Grid
      container
      justify={props.justify ?? 'center'}
      alignItems="flex-start"
      style={style}
      spacing={props.spacing ?? 1}
    >
      {props.elements.map((_props) => (
        <Row
          key={_props.id}
          style={_props.rowStyle || {}}
          widthInLargeScreen={_props.size}
          widthInMediumScreen={_props.md}
        >
          {Array.isArray(_props.element as any)
            ? (_props.element as any).map((element: IElement) => (
                <Grid
                  container
                  key={element.id}
                  justify={props.justify ?? 'center'}
                  alignItems="flex-start"
                >
                  <Row
                    style={{
                      marginBottom: (props.spacing || 1) * 4 * 2,
                      ...(element.rowStyle || {}),
                    }}
                    widthInLargeScreen={element.size}
                    widthInMediumScreen={element.md}
                  >
                    {element.element}
                  </Row>
                </Grid>
              ))
            : (_props.element as any)}
        </Row>
      ))}
    </Grid>
  );
}
