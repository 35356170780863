import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';

enum OpenType {
  add = 'add',
  edit = 'edit',
}

interface Open {
  isOpen: boolean;
  type: OpenType;
  data: any | null;
}

interface IState {
  productList: any;
  productPersist: any;
  open: Open;
}

const initialState: IState = {
  productList: {
    status: null,
    data: null,
  },
  productPersist: {
    status: null,
    data: null,
  },
  open: {
    isOpen: false,
    type: OpenType.add,
    data: null,
  },
};

const MainReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case Types.SET_PRODUCT:
      return {
        ...state,
        productList: {
          ...state.productList,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_PRODUCT_PERSIST:
      return {
        ...state,
        productPersist: {
          ...state.productPersist,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_OPEN:
      return {
        ...state,
        open: {
          ...state.open,
          ...action.data,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default MainReducer;
