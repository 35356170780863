import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchUserProductsList  */
interface IUserProductsListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  quantity: string | null;
  suggested: string | null;
  productcost: string | null;
  product: string | null;
  user: string | null;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IUserProductsListInput {
  sortField: string;
  order: 'asc' | 'desc';
  scenario: string;
}

export const fetchUserProductsList = async (
  payload: IUserProductsListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user_product/list?sortField=${payload.sortField}&order=${payload.order}&scenario=${payload.scenario}`
  );

  let _data: IUserProductsListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
export const addUserProduct = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/user_product`,
    payload
  );

  let _data: IUserProductsListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const editUserProduct = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/user_product/${payload.id}`,
    payload
  );

  let _data: IUserProductsListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const deleteUserProduct = async (
  payload: any
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/user_product/${payload.id}`
  );

  let _data: IUserProductsListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const fetchUserProductByScenario = async (payload: {
  scenarios: string[];
}): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${
      Environment.API_BASE_URL
    }/api/user_product/list-by-scenarios?scenarios=${payload.scenarios.join(
      ','
    )}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
