import * as Types from './types';
import * as RegisterService from '../../../services/api/register';
import { Dispatch } from 'redux';
import { LanguagesEnum } from '../../../config/languages';
import NavigationConfig from '../../../navigation/config';
import {
  IAction,
  IActionMethods,
  StateStatus,
} from '../../../redux/utils/common';
import * as UserServices from '../../../services/api/user';
import * as PictureServices from '../../../services/api/picture';
import * as UserRegister from '../../../services/api/user';
import { ReducerKeys } from 'redux/config';
import Action from 'redux/reducers/gl_auth_reducer/actions';

/** Fetch SchemeTypes  */

class FetchUserProfile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_PROFILE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_PROFILE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_PROFILE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserServices.getUserProfile(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetchUserProfile Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
        if (
          error.message === 'jwt expired' ||
          error.message === 'jwt malformed'
        ) {
          //logout
          dispatch(Action.logoutAction());
        }
      }
    };
  }
}

/** Fetch Countries */
class FetchUserProfileCountries implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_COUNTRIES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserRegister.getCountries();
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetchUserProfileCountries Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
        if (
          error.message === 'jwt expired' ||
          error.message === 'jwt malformed'
        ) {
          //logout
          dispatch(Action.logoutAction());
        }
      }
    };
  }
}

/** Edit Profile */

interface IEditUserProfileInput {
  email: string;
  firstName: string;
  surname: string;
  nationality: string;
  gender: string;
  birthDate: string;
}
class EditUserProfile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_PROFILE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.EDIT_PROFILE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_PROFILE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IEditUserProfileInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserRegister.editUserProfile(payload);
        dispatch(this.onSuccess(response.data));
        // dispatch(new FetchUserProfile().action());
      } catch (error: any) {
        console.log('EditProfile Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class UpdateUserSetup implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_SETUP,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_SETUP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_SETUP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserServices.updateUser(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchUserProfile().action({ id: payload.id }));
      } catch (error: any) {
        console.log('Update setup Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class UploadAvatar implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPLOAD_AVATAR,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPLOAD_AVATAR,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPLOAD_AVATAR,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>, getStore: any) => {
      const store = getStore();
      const userId = store[ReducerKeys.AUTH_REDUCER].data.id;
      try {
        dispatch(this.onPending());
        const formData = new FormData();
        formData.set('avatar', payload.file);
        let response = await PictureServices.uploadAvatar(formData);
        dispatch(this.onSuccess(response.data));

        dispatch(new FetchUserProfile().action({ id: userId }));
      } catch (error: any) {
        console.log('Upload avatar Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  editUserProfileAction: (payload: IEditUserProfileInput) =>
    new EditUserProfile().action(payload),
  fetchUserProfileAction: (payload: any) =>
    new FetchUserProfile().action(payload),
  fetchUserProfileCountriesAction: () =>
    new FetchUserProfileCountries().action(),
  updateUserSetupAction: (payload: any) =>
    new UpdateUserSetup().action(payload),
  uploadAvatarAction: (payload: any) => new UploadAvatar().action(payload),
};
