import _axios from 'axios';
import { ReducerKeys } from '../../redux/config';
import store, { getState } from '../../redux/store';
import AuthActions from '../../redux/reducers/gl_auth_reducer/actions';
import { showToastAction } from '../../components/atoms/toast_message';
import { LanguagesEnum } from '../../config/languages';
import Configuration from './configuration';

const axios = _axios.create();

// for multiple requests
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// request pre execute -> add token in headers
axios.interceptors.request.use(
  async (config) => {
    // get refresh token
    const token = getState(ReducerKeys.AUTH_REDUCER).token;
    config.headers['x-access-token'] = `${token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// request post execute
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['x-access-token'] = token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      // const refreshToken = getState(ReducerKeys.AUTH_REDUCER).refreshToken;
      return new Promise(function (resolve, reject) {
        //    axios.get(`${Environment.API_BASE_URL}/api/users/auth/access-token?token=${refreshToken}`)
        Configuration.executeRequestParser()
          .then(({ data }) => {
            const { refreshToken, token } = Configuration.authTokenParser(data);
            store.dispatch(AuthActions.refreshTokenAction(refreshToken, token));
            processQueue(null, token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            store.dispatch(AuthActions.logoutAction());
            showToastAction(
              store.dispatch,
              {
                [LanguagesEnum.English]: 'Session Expired',
                [LanguagesEnum.Greek]: 'Η σύνδεση σας έληξε',
              },
              'info'
            );
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
