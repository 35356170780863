import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import {
  AppGroupText,
  AppMultipleSelect,
  AppSelect,
  AppDatePicker,
  AppTextField,
  AppRadioGroup,
  AppSwitch,
  AppColorPicker,
} from 'components/molecules/form_controls';
import { Control, Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import TranslatedText from 'components/atoms/translated_text';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import GridLayout from 'components/molecules/grid_layout';

interface IFormData {
  textInput: string | null;
  groupInput: string[] | null;
  colorPicker: string | null;
  datePicker: string | null;
  select: number | string | null;
  multiSelect: (number | string)[] | null;
  switch: boolean | null;
  radioGroup: number | string | null;
  textArea: string | null;
}

// add types or interface
const defaultValues = {
  textInput: 'Sample Default Input',
  groupInput: ['sampleText1', 'sampleText2', 'sampleText3'],
  colorPicker: '#4129b8',
  datePicker: '03-09-2021',
  select: 1,
  multiSelect: [2, 3],
  switch: true,
  radioGroup: '2',
  textArea: 'Sample Text Area',
};

export default function RegisterForm(): ReactElement {
  console.log('Render RegisterForm:');

  const classes = useStyles();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (_data: any) => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_FORM_WRAPPER,
        params: {
          data: _data,
          title: 'Input Types Form Output',
        },
      })
    );
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'textInput' in errors}
        helperText={errors.textInput && errors.textInput.message}
        rules={{}}
        fullWidth
        id="textInput"
        control={control}
        label={<TranslatedText defaultText={'TextInput'} />}
        autoComplete="textInput"
        name="textInput"
      />
      <AppGroupText
        variant="outlined"
        margin="normal"
        error={'groupInput' in errors}
        helperText={errors.groupInput && errors.groupInput.message}
        rules={{}}
        fullWidth
        id="groupInput"
        label={<TranslatedText defaultText={'GroupInput'} />}
        control={control}
        autoComplete="groupInput"
        name="groupInput"
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'textArea' in errors}
        helperText={errors.textArea && errors.textArea.message}
        rules={{}}
        multiline
        rows={5}
        fullWidth
        id="textArea"
        label={<TranslatedText defaultText={'Text Area'} />}
        control={control}
        autoComplete="textArea"
        name="textArea"
      />            
      <AppDatePicker
        fullWidth
        margin="normal"
        label={<TranslatedText defaultText={'Sample Date'} />}
        autoOk
        helperText={errors.datePicker && errors.datePicker.message}
        error={'datePicker' in errors}
        control={control}
        rules={{}}
        name="datePicker"
      />
      <AppSelect
        control={control}
        variant="outlined"
        error={'select' in errors}
        name="select"
        helperText={errors.select && errors.select.message}
        rules={{}}
        options={[
          {
            id: 1,
            label: 'Sample Selection 1',
          },
          {
            id: 2,
            label: 'Sample Selection 2',
          },
          {
            id: 3,
            label: 'Sample Selection 3',
          },
          {
            id: 4,
            label: 'Sample Selection 4',
          },
          {
            id: 5,
            label: 'Sample Selection 1',
          },
        ]}
        fullWidth
        margin="normal"
        label={<TranslatedText defaultText={'Select'} />}
      />
      <AppMultipleSelect
        control={control}
        inputVariant="checkbox"
        variant="outlined"
        error={'multiSelect' in errors}
        name="multiSelect"
        helperText={errors.multiSelect && errors.multiSelect.message}
        rules={{}}
        options={[
          {
            id: 1,
            label: 'Sample Selection 1',
          },
          {
            id: 2,
            label: 'Sample Selection 2',
          },
          {
            id: 3,
            label: 'Sample Selection 3',
          },
          {
            id: 4,
            label: 'Sample Selection 4',
          },
          {
            id: 5,
            label: 'Sample Selection 1',
          },
        ]}
        fullWidth
        margin="normal"
        label={<TranslatedText defaultText={'Multi Select'} />}
      />
      <AppColorPicker
        variant="outlined"
        margin="normal"
        setValue={setValue}
        rules={{}}
        fullWidth
        id={`colorPicker`}
        control={control}
        label={<TranslatedText defaultText={'Color Picker'} />}
        autoComplete={`colorPicker`}
        name={`colorPicker`}
      />
      <AppRadioGroup
        row
        helperText={errors.radioGroup && errors.radioGroup.message}
        error={'radioGroup' in errors}
        control={control}
        rules={{}}
        margin="normal"
        values={[
          {
            value: '1',
            label: 'Option1',
          },
          {
            value: '2',
            label: 'Option2',
          },
          {
            value: '3',
            label: 'Option3',
          },
          {
            value: '4',
            label: 'Option4',
          },
        ]}
        name="radioGroup"
        label={<TranslatedText defaultText={'Radio Group'} />}
      />
      <AppSwitch
        rules={{}}
        size="medium"
        label={<TranslatedText defaultText={'Switch'} />}
        id="switch"
        control={control}
        name="switch"
      />
      <GridLayout
        justify="flex-start"
        elements={[
          {
            size: 3,
            id: 'submit',
            element: (
              <SubmitWrapper
                type="submit"
                variant="contained"
                className={classes.submit}
                color="secondary"
                fullWidth
              >
                <TranslatedText defaultText={'Submit'} />
              </SubmitWrapper>
            ),
          },
        ]}
      />
    </form>
  );
}

interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  return <Button {...props} color="primary" />;
};
