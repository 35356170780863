export enum ReducerKeys {
  AUTH_REDUCER = 'AuthReducer',
  TRANSL_REDUCER = 'TranslationReducer',
  NAVIGATION_REDUCER = 'NavigationReducer',
  MODAL_REDUCER = 'ModalReducer',
  THEME_REDUCER = 'ThemeReducer',
  RESET_PASSOWRD_REDUCER = 'ResetPasswordReducer',
  REGISTER_REDUCER = 'RegisterReducer',
  CHANGE_PASSWORD_REDUCER = 'ChangePasswordReducer',
  USER_PROFILE_REDUCER = 'UserProfileReducer',
  DASHBOARD_BUILDER_REDUCER = 'DashboardBuilderReducer',
  FORGOT_PASSWORD_REDUCER = 'ForgotPasswordReducer',
  PRODUCT_REDUCER = 'ProductReducer',
  USER_PRODUCT_REDUCER = 'UserProductReducer',
  SCENARIO_REDUCER = 'ScenarioReducer',
  ESTABLISHMENT_REDUCER = 'EstablishmentReducer',
  GROUP_REDUCER = 'GroupReducer',
  COMBINE_SCENARIOS_REDUCER = 'CombineScenariosReducer',
  USER_MANAGEMENT_REDUCER = 'UserManagementReducer',
}
