import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 389,
  },
  titleLayout: {
    padding: 20,
    backgroundColor: Colors.APP_COLOR_HIGHLIGHT,
    color: 'white',
  },
  cardLayout: {
    height: 10,
  },
  input: {
    height: 8,
  },
  'mr-12': {
    marginRight: 12,
  },
  modal: {
    width: '80%',
  },
  gridPane: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBtn: {
    height: 46,
    textTransform: 'capitalize',
    width: '45%',
  },
  addBtn: {
    background: '#292E5F',
    color: '#fff',
  },
  cancelBtn: {
    color: '#292E5F',
    background: '#fff',
    '&:hover': {
      color: '#292E5F',
      background: '#fff',
    },
  },
  normalCase: {
    textTransform: 'unset',
  },
  indicator: {
    backgroundColor: '#fff',
  },
  indicatorStep3: {
    backgroundColor: '#346CB0',
  },
  selectedTab: {
    color: '#D9A21B !important',
  },
  cardContentRoot: {
    padding: '10px 0',
  },
  focused: {
    // color: '#fff !important',
  },
}));

export default useStyles;
