import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as UserService from '../../../services/api/user';

import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';

interface IModal {
  isOpen: boolean;
  type: string;
  data: any;
}
const SetModal = (payload: IModal) => {
  return {
    type: Types.SET_MODAL,
    data: payload,
  };
};

interface FetchUserListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
}

class FetchUserList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchUserListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.fetchUserList({
          ...payload,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetchUserList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface IUpdateUser {
  id: string;
  emailConfirmed?: boolean;
  roles?: string;
}
class UpdateUser implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_USER,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_USER,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_USER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IUpdateUser): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.updateUser({
          ...payload,
        });
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchUserList().action({
            page: 0,
            pageSize: 10,
            order: 'desc',
            sortField: '_id',
          })
        );
        dispatch(
          SetModal({
            isOpen: false,
            data: null,
            type: '',
          })
        );
      } catch (error: any) {
        console.log('Update user Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface IChangePassword {
  id: string;
  password: string | null;
}
class ChangePassword implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IChangePassword): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.changePassword({
          ...payload,
        });
        dispatch(this.onSuccess(response.data));

        dispatch(
          SetModal({
            isOpen: false,
            data: null,
            type: '',
          })
        );
      } catch (error: any) {
        console.log('Change user password Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
interface IFetchUserDetail {
  id: string;
}
class FetchUserDetail implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_DETAIL,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IFetchUserDetail): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getUserDetail({
          ...payload,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('get user Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewEstablishment  */

export default {
  fetchUserList: (payload: FetchUserListInput) =>
    new FetchUserList().action(payload),
  updateUser: (payload: IUpdateUser) => new UpdateUser().action(payload),
  changePassword: (payload: IChangePassword) =>
    new ChangePassword().action(payload),
  setModal: (payload: IModal) => SetModal(payload),
  fetchUserDetail: (payload: IFetchUserDetail) =>
    new FetchUserDetail().action(payload),
};
