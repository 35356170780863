import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getBase64FromUrl } from './helper';

const Header = () => {
  const logo = require('assets/images/logos/logo_full.png');
  const [imageBase64, setImageBase64] = useState('');

  useEffect(() => {
    const convert = async () => {
      const data: any = await getBase64FromUrl(logo);
      setImageBase64(data);
    };
    convert();
  }, []);
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        marginTop: '20px',
      }}
    >
      <Box>
        <img src={imageBase64} style={{ width: '100%', height: '100%' }} />
      </Box>
    </Box>
  );
};

export default React.memo(Header);
