import React from 'react';
import { withRouter } from 'react-router';
import useStyles from './styles';
import PageLayout from 'components/app_common/page_layout';
import { Paper, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import GridLayout from 'components/molecules/grid_layout';
import SimpleTable from './components/simple_table';
import CustomTable from './components/custom_table';

interface IProps {}

const Datatables = (props: IProps) => {
  const classes = useStyles();

  return (
    <PageLayout>
      <GridLayout
        elements={[
          {
            id: 'SimpleTable',
            size: 6,
            element: (
              <ElementWrapper title="Zero Configuration">
                <SimpleTable />
              </ElementWrapper>
            ),
          },
          {
            id: 'CustomTable',
            size: 6,
            element: (
              <ElementWrapper title="Custom Table">
                <CustomTable />
              </ElementWrapper>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

interface IElementWrapperProps {
  children: React.ReactElement;
  title: string;
}

const ElementWrapper = ({ children, title }: IElementWrapperProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.elementContainer} square variant="outlined">
      <Typography className={classes.title} variant="h5">
        <TranslatedText defaultText={title} />
      </Typography>
      {children}
    </Paper>
  );
};

export default withRouter(Datatables);
