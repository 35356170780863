import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface GroupListElement {
  name: string;
  address: string;
  establishments: string;
  admin: string;
}

interface GroupListData {
  paging: GroupListPaging;
  items: GroupListElement[];
}

interface GroupListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface GroupistOutput {
  status: StateStatus;
  data: GroupListData | null;
}

export const useGetGroupList = (): GroupistOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.GROUP_REDUCER].GroupList
  );
  return data;
};

/* selector: useGetGroupDetails */
interface GroupDetailsData {
  name: string;
  address: string;
  establishments: string;
  admin: string;
}

interface GroupDetailsOutput {
  status: StateStatus;
  data: GroupDetailsData | null;
}

export const useGetGroupDetails = (): GroupDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.GROUP_REDUCER].viewGroup
  );
  return data;
};
interface fetchUsernamesOutput {
  status: StateStatus;
  data: Usernames[] | null;
}
export interface Usernames {
  _id: string;
  firstName: string;
  surname: string;
}
interface fetchEstablishmentsnameOutput {
  status: StateStatus;
  data: EstablishmentName[] | null;
}
export interface EstablishmentName {
  _id: string;
  name: string;
}

export const useGetUsernames = (): fetchUsernamesOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.GROUP_REDUCER].usersname
  );
  return data;
};

export const useGetEstablishmentsname = (): fetchEstablishmentsnameOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.GROUP_REDUCER].establishmentsName
  );
  return data;
};
