import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import _ from 'lodash';
import { numberWithCommas } from 'utils/strings';

function getRevenueConfig(data: any, title: string, unit: string, id: string) {
  let min = 0;
  min = Math.min(...data) > 0 ? 0 : Math.min(...data) * 1.2; // 3  Number(_.min(data)) > 0 ? 0 : Number(_.min(data)) - 10;
  const barChart: IGridConf[] = [
    // 'Estimated Reduction in yearly marine litter'
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'bar',
        height: 350,

        series: [
          {
            name: title,
            data: data.map((value: number) => Number(value).toFixed(9)),
          },
        ],
        options: {
          chart: {
            id,
            type: 'bar',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            //   stacked: true,
            // height: 350,
          },
          title: {
            text: title,
          },
          colors: ['#008FFB', '#FF4560'],
          plotOptions: {
            bar: {
              borderRadius: 4,
              // horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: ['Current usage', 'Alternative selection'],
          },
          yaxis: {
            min: min,
            forceNiceScale: true,
            max: Math.max(...data) || 10, //_.max(data) ? (_.max(data) as number) : 10,
            show: true,
            labels: {
              formatter: (value: number) => {
                let result: string = value.toString();
                //var countDecimals = 0;
                //  let val=Math.abs(value) *10
                //  while( ( val <=10 )   ){
                //    val *=10
                //    countDecimals++ }

                //let countDecimals :number =Math.floor(Math.abs(value)) === value ? 0 : value.toString().split(".")[1].length || 0// function (value:number) {
                let countDecimals = 0;

                //console.log('number of digit ' + value + ' - ' + countDecimals)
                //if(Math.floor(value) === value) return 0;
                //return value.toString().split(".")[1].length || 0;
                // }
                if (value > 1000) {
                  result = numberWithCommas(parseFloat(value.toFixed(2)));
                } else {
                  //  if(countDecimals>0)                result = value.toFixed(countDecimals);
                  if (Math.abs(value) * 100000000 >= 1) {
                    countDecimals = 9;
                  }

                  if (Math.abs(value) * 10000000 >= 1) {
                    countDecimals = 8;
                  }
                  if (Math.abs(value) * 1000000 >= 1) {
                    countDecimals = 7;
                  }
                  if (Math.abs(value) * 100000 >= 1) {
                    countDecimals = 6;
                  }
                  if (Math.abs(value) * 100000 >= 1) {
                    countDecimals = 5;
                  }
                  if (Math.abs(value) * 10000 >= 1) {
                    countDecimals = 4;
                  }
                  if (Math.abs(value) * 1000 >= 1) {
                    countDecimals = 3;
                  }
                  if (Math.abs(value) * 100 >= 1) {
                    countDecimals = 2;
                  }
                  if (Math.abs(value) * 10 >= 1) {
                    countDecimals = 1;
                  }
                  ////
                  if (Math.abs(value) * 1 >= 1) {
                    countDecimals = 0;
                  }

                  if (value === 0) {
                    result = value.toString();
                  }
                  if (countDecimals > 0) result = value.toFixed(countDecimals);

                  console.log(
                    'number of digit ' + value + ' - ' + countDecimals
                  );
                }
                return result;
              },
            },
            title: {
              text: unit,
            },
          },
        },
      },
      fetch: null,
      // fetch: `/api/dashboard-pub/general-publisher-revenue-areachart/${userid}?appid=${appid}&publisherid=${publisherid}&datefrom=${datefrom}&dateto=${dateto}`,
    },
  ];
  return barChart;
}

export default getRevenueConfig;
