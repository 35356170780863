import React from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import CardLabelTitle from 'components/app_common/card_label_title';
import useStyles from '../../styles';
import { colors, Theme } from '@material-ui/core';
import ChartOptions from 'chart.js';
import { ModalConfigWrapper } from 'components/app_common/modal_config_wrapper';

const MultiTypeChart = () => {
  const classes = useStyles();
  return (
    <ModalConfigWrapper name="MultiTypeChart" >
      <CustomChart
        title={
          <CardLabelTitle
            title={
              <TranslatedText defaultText="Visitors Current Vs Previous Week" />
            }
          />
        }
        layoutProps={{
          variant: 'outlined',
        }}
        data={DummyDataset()}
        labels={DummyLabels}
        options={DummyChartOptions}
        chartType={ChartTypesEnum.COLUMN}
      />
    </ModalConfigWrapper>
  );
};

export default MultiTypeChart;

export const DummyLabels = [
  '27/11/2020',
  '28/11/2020',
  '29/11/2020',
  '30/11/2020',
  '01/12/2020',
  '02/12/2020',
];

export const DummyDataset = (
  currentWeekData: number[] = [11, -7, 12, 29, -3, 5, -3],
  persentageData: number[] = [18, 5, 19, 27, 29, 19, 20]
) => [
  {
    // backgroundColor: colors.grey[200],
    data: currentWeekData,
    label: 'Last year',
    format: {
      label: 'Visitors',
      type: 'line',
      fill: false,
      lineTension: 0.1, // make the lines without radius
      borderColor: '#5C80CC',
      backgroundColor: '#5C80CC',
    },
  },
  {
    data: persentageData,
    label: 'This year',
    format: {
      label: '%SDLW',
      fill: false,
      borderColor: persentageData.map((value) =>
        value < 0 ? 'rgba(255, 120, 152, 0.9)' : 'rgba(65, 254, 116, 0.9)'
      ),
      backgroundColor: persentageData.map((value) =>
        value < 0 ? 'rgba(255, 120, 152, 0.9)' : 'rgba(65, 254, 116, 0.9)'
      ),
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
    },
  },
];

export const DummyChartOptions = (theme: Theme): any => ({
  legend: {
    display: true,
    align: 'start',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});
