import React from 'react';
import _ from 'lodash';
import Datatable from '../datatableCollapse';
import { TableColumns, NestedTableColumns } from '../../configuration';
import * as UserProductsReducer from '../../../../redux/reducers/userproducts_reducer/selectors';
import { useDispatch } from 'react-redux';
import UserProductsActions from '../../../../redux/reducers/userproducts_reducer/actions';
import {
  Box,
  Button,
  Fab,
  Card,
  CardContent,
  Typography,
  CardHeader,
} from '@material-ui/core';
import useStyles from './styles';

export default function DatatableList() {
  const result = UserProductsReducer.useGetUserProductsList();
  const paging = result.data?.paging;
  const dispatch = useDispatch();
  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  const classes = useStyles();
  const data = result.data?.items.filter(
    (item: any) => item.usageCategory === 'SUP'
  );

  const [groupByCategory, setGroupByCategory] = React.useState<any>([]);

  React.useEffect(() => {
    const grouped = _.groupBy(result.data?.items, 'productCategory');
    setGroupByCategory(grouped);
  }, [result.data?.items]);

  return (
    <React.Fragment>
      {Object.keys(groupByCategory).map((key: any, rowIndex: number) => {
        const productList = groupByCategory[key].sort((a: any, b: any) => {
          if (a.usageCategory < b.usageCategory) {
            return 1;
          }
          if (a.usageCategory > b.usageCategory) {
            return -1;
          }
          return 0;
        });
        return (
          <Box marginBottom="20px">
            <Card key={key}>
              <CardHeader
                title={
                  productList.length !== 0 ? productList[0].productCategory : ''
                }
              />

              <CardContent>
                <Datatable data={productList} columns={TableColumns} />
              </CardContent>
            </Card>
          </Box>
        );
      })}
    </React.Fragment>
  );
}
