import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& >div': {
      boxShadow: 'none',
    },
  },
  titleBox: {
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonCancel: {
    color: '#959CA0',
    border: '1px solid #959CA0',
    '&:hover': {
      border: '1px solid #959CA0',
    },
    textTransform: 'none',
  },
  buttonAdd: {
    textTransform: 'none',
    backgroundColor: '#346CB0',
    '&:hover': {
      backgroundColor: '#2060af',
    },
  },
}));

export default useStyles;
