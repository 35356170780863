import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import _, { zip } from 'lodash';
import { numberWithCommas } from 'utils/strings';

interface IYRange {
  min: number;
  max: number;
}
function generateData(baseval: number, count: number, yrange: IYRange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

    series.push([x, y, z]);
    baseval += 86400000;
    i++;
  }
  return series;
}

export enum EnvironmentImpact {
  acidificationPotential = 'Acidification potential',
  carcinogenicEffectsOnHuman = 'Carcinogenic effects on humans',
  climateChange = 'Climate change',
  climateChangeImpactsAssociatedWithEOL = 'Climate change impacts associated with EOL',
  humanToxicity = 'Human toxicity',
  marineAquaticEcotoxicity = 'Marine aquatic ecotoxicity',
  respiratoryEffectsOnHuman = 'Respiratory effects on humans',
}
export const EnvironmentImpactMapping = {
  climateChange:
    "Climate change refers to the long-term change in the average weather patterns of the Earth’s local, regional and global climates. It includes both global warming driven by human-induced emissions of greenhouse gases and also any naturally occurring large-scale shifts in weather patterns. Consequently, this category concerns the global warming aspect of climate change, which refers to the gradual increase in the overall temperature of the earth's atmosphere, which is generally attributed to the greenhouse effect caused by increased levels of carbon dioxide, CFCs, and other pollutants. Global warming can result in adverse effects upon ecosystem and human health. Relevant impacts are expressed as kilograms of carbon dioxide equivalent [kg CO2 eq].",
  acidificationPotential:
    'Acidifying substances cause a wide range of impacts on soil, groundwater, surface water, organisms, ecosystems and materials (buildings). Acidification potential is expressed as kilograms of sulphur dioxide equivalent [kg SO2 eq].',
  carcinogenicEffectsOnHuman:
    'This category refers to carcinogenic affects due to emissions of carcinogenic substances to air, water, and soil. Relevant impactss are expressed in disability adjusted life years [DALY], which is a measure of overall disease burden, expressed as the number of years lost due to ill-health, disability or early death.',
  climateChangeImpactsAssociatedWithEOL:
    "Climate change refers to the long-term change in the average weather patterns of the Earth’s local, regional and global climates. It includes both global warming driven by human-induced emissions of greenhouse gases and also any naturally occurring large-scale shifts in weather patterns. Consequently, this category concerns the global warming aspect of climate change, which refers to the gradual increase in the overall temperature of the earth's atmosphere, which is generally attributed to the greenhouse effect caused by increased levels of carbon dioxide, CFCs, and other pollutants. Global warming can result in adverse effects upon ecosystem and human health. Relevant impacts are expressed as kilograms of carbon dioxide equivalent [kg CO2 eq].",
  humanToxicity:
    'This category refers to the imapcts  of toxic substances on the human environment. Relevant impacts are expressed as kilograms of 1.4-dichlorobenzene equivalent [kg 1,4-DB eq].',
  marineAquaticEcotoxicity:
    'This category refers to the impact on marine water ecosystems, as a result of emissions of toxic substances to air, water and soil. Relevant impacts are expressed as kilograms of 1,4-dichlorobenzene equivalent [kg 1,4-DB eq].',
  respiratoryEffectsOnHuman:
    'This category refers to adverse respiratory effects resulting from smog, due to emissions of organic substances to air or emissions of dust, sulphur and nitrogen oxides to air. Relevant impacts are expressed in disability adjusted life years [DALY], which is a measure of overall disease burden, expressed as the number of years lost due to ill-health, disability or early death.',
};

interface ISeries {
  name: string;
  data: number[][];
}

function getSize(value: number) {
  if (value >= 100) {
    return 100;
  } else {
    return value;
  }
}

function sum(a: number, b: number) {
  return a + b;
}

function getRevenueConfig(
  data: any,
  title: string,
  projectedField: string,
  id: string
) {
  const climateChangeSUP =
    data.length > 0
      ? data.map((item: any) => item.climateChangeSUP).reduce(sum)
      : 0;
  const climateChangeOther =
    data.length > 0
      ? data.map((item: any) => item.climateChangeOther).reduce(sum)
      : 0;
  const humanToxicitySUP =
    data.length > 0
      ? data.map((item: any) => item.humanToxicitySUP).reduce(sum)
      : 0;
  const humanToxicityOther =
    data.length > 0
      ? data.map((item: any) => item.humanToxicityOther).reduce(sum)
      : 0;
  const acidificationPotentialSUP =
    data.length > 0
      ? data.map((item: any) => item.acidificationPotentialSUP).reduce(sum)
      : 0;
  const acidificationPotentialOther =
    data.length > 0
      ? data.map((item: any) => item.acidificationPotentialOther).reduce(sum)
      : 0;
  const carcinogenicEffectsOnHumanSUP =
    data.length > 0
      ? data.map((item: any) => item.carcinogenicEffectsOnHumanSUP).reduce(sum)
      : 0;
  const carcinogenicEffectsOnHumanOther =
    data.length > 0
      ? data
          .map((item: any) => item.carcinogenicEffectsOnHumanOther)
          .reduce(sum)
      : 0;
  const respiratoryEffectsOnHumanSUP =
    data.length > 0
      ? data.map((item: any) => item.respiratoryEffectsOnHumanSUP).reduce(sum)
      : 0;
  const respiratoryEffectsOnHumanOther =
    data.length > 0
      ? data
          .map((item: any) => item.carcinogenicEffectsOnHumanOther)
          .reduce(sum)
      : 0;
  const marineAquaticEcotoxicitySUP =
    data.length > 0
      ? data.map((item: any) => item.marineAquaticEcotoxicitySUP).reduce(sum)
      : 0;
  const marineAquaticEcotoxicityOther =
    data.length > 0
      ? data.map((item: any) => item.marineAquaticEcotoxicityOther).reduce(sum)
      : 0;
  const climateChangeImpactsAssociatedWithEOLSUP =
    data.length > 0
      ? data
          .map((item: any) => item.climateChangeImpactsAssociatedWithEOLSUP)
          .reduce(sum)
      : 0;
  const climateChangeImpactsAssociatedWithEOLOther =
    data.length > 0
      ? data
          .map((item: any) => item.climateChangeImpactsAssociatedWithEOLOther)
          .reduce(sum)
      : 0;
  //console.log('carcinogenicEffectsOnHumanOther' + carcinogenicEffectsOnHumanOther)
  //console.log('carcinogenicEffectsOnHumanSUP'+carcinogenicEffectsOnHumanSUP)
  //console.log('carcinogenicEffectsOnHuman ' + Number(
  //   ((carcinogenicEffectsOnHumanOther - carcinogenicEffectsOnHumanSUP) /
  //     carcinogenicEffectsOnHumanSUP) *
  //     100
  // ).toFixed(2),)

  const series = [
    {
      name: EnvironmentImpact.acidificationPotential,
      data: [
        1,
        Number(
          acidificationPotentialSUP == 0
            ? 0
            : ((acidificationPotentialOther - acidificationPotentialSUP) /
               Math.abs( acidificationPotentialSUP)) *
                100
        ).toFixed(2),
      ],
    },
    {
      name: EnvironmentImpact.carcinogenicEffectsOnHuman,
      data: [
        2,
        Number(
          carcinogenicEffectsOnHumanSUP == 0
            ? 0
            : ((carcinogenicEffectsOnHumanOther -
                carcinogenicEffectsOnHumanSUP) /
                Math.abs( carcinogenicEffectsOnHumanSUP)) *
                100
        ).toFixed(2),
      ].map((item: any) => parseFloat(item)),
    },
    {
      name: EnvironmentImpact.climateChange,
      data: [
        3,
        Number(
          climateChangeSUP == 0
            ? 0
            : ((climateChangeOther - climateChangeSUP) /Math.abs( climateChangeSUP) )* 100
        ).toFixed(2),
      ],
    },
    {
      name: EnvironmentImpact.climateChangeImpactsAssociatedWithEOL,
      data: [
        4,
        Number(
          climateChangeImpactsAssociatedWithEOLSUP == 0
            ? 0
            : ((climateChangeImpactsAssociatedWithEOLOther -
                climateChangeImpactsAssociatedWithEOLSUP) /
                Math.abs(climateChangeImpactsAssociatedWithEOLSUP)) *
                100
        ).toFixed(2),
      ].map((item: any) => parseFloat(item)),
    },
    {
      name: EnvironmentImpact.humanToxicity,
      data: [
        5,
        Number(
          humanToxicitySUP == 0
            ? 0
            : ((humanToxicityOther - humanToxicitySUP) /Math.abs( humanToxicitySUP)) * 100
        ).toFixed(2),
      ],
    },
    {
      name: EnvironmentImpact.marineAquaticEcotoxicity,
      data: [
        6,
        Number(
          marineAquaticEcotoxicitySUP == 0
            ? 0
            : ((marineAquaticEcotoxicityOther - marineAquaticEcotoxicitySUP) /
            Math.abs(marineAquaticEcotoxicitySUP)) *
                100
        ).toFixed(2),
      ].map((item: any) => parseFloat(item)),
    },
    {
      name: EnvironmentImpact.respiratoryEffectsOnHuman,
      data: [
        7,
        Number(
          respiratoryEffectsOnHumanSUP == 0
            ? 0
            : ((respiratoryEffectsOnHumanOther - respiratoryEffectsOnHumanSUP) /
            Math.abs( respiratoryEffectsOnHumanSUP)) *
                100
        ).toFixed(2),
      ].map((item: any) => parseFloat(item)),
    },
  ];

  //   const series = data.map((item: any, index: number) => {
  //     return {
  //       name: names[index],
  //       data: [[item, item, 45]],
  //     };
  // //   });
  // const series = [];
  // const numerics = [];
  // const names = data.length !== 0 ? Object.keys(data[0]) : [];
  // const sumOfOutput = [
  //   data.reduce((a: any, b: any) => {
  //     return {
  //       acidificationPotential:
  //         parseFloat(a.acidificationPotential) +
  //         parseFloat(b.acidificationPotential),
  //       carcinogenicEffectsOnHuman:
  //         parseFloat(a.carcinogenicEffectsOnHuman) +
  //         parseFloat(b.carcinogenicEffectsOnHuman),
  //       climateChange:
  //         parseFloat(a.climateChange) + parseFloat(b.climateChange),
  //       climateChangeImpactsAssociatedWithEOL:
  //         parseFloat(a.climateChangeImpactsAssociatedWithEOL) +
  //         parseFloat(b.climateChangeImpactsAssociatedWithEOL),
  //       humanToxicity:
  //         parseFloat(a.humanToxicity) + parseFloat(b.humanToxicity),
  //       marineAquaticEcotoxicity:
  //         parseFloat(a.marineAquaticEcotoxicity) +
  //         parseFloat(b.marineAquaticEcotoxicity),
  //       respiratoryEffectsOnHuman:
  //         parseFloat(a.respiratoryEffectsOnHuman) +
  //         parseFloat(b.respiratoryEffectsOnHuman),
  //     };
  //   }),
  // ];

  // for (let i = 0; i < names.length; i++) {
  //   const dataset: ISeries = {
  //     name: names[i],
  //     data: [],
  //   };
  //   for (let j = 0; j < sumOfOutput.length; j++) {
  //     if (sumOfOutput[j][dataset.name]) {
  //       console.log('value', sumOfOutput[j][dataset.name]);
  //       dataset.data.push([
  //         i + 1,
  //         sumOfOutput[j][dataset.name]
  //           ? parseFloat(sumOfOutput[j][dataset.name])
  //           : 0,
  //         // getSize(parseFloat(sumOfOutput[j][dataset.name])),
  //       ]);
  //     }
  //   }
  //   numerics.push(dataset.data);
  //   series.push(dataset);
  // }
  // const maxValue = _.max(_.flattenDeep(numerics));
  // console.log('series', series);

  const bubbleChart: IGridConf[] = [
    // 'Estimated Reduction in yearly marine litter'
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'bubble',
        height: 350,

        series: _.sortBy(series, 'name').map((item: any) => {
          return {
            name: item.name,
            data: [item.data],
          };
        }),

        // series: fake_data,
        options: {
          chart: {
            id,
            height: 350,
            type: 'bubble',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          colors: [
            '#EF4444',
            '#F59E0B',
            '#10B981',
            '#3B82F6',
            '#6366F1',
            '#8B5CF6',
            '#EC4899',
          ],
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            labels: {
              show: true,
            },
          },
          title: {
            text: title,
          },
          markers: {
            size: 30,
            strokeWidth: 0,
            hover: {
              sizeOffset: 0,
            },
          },
          fill: {
            type: 'gradient',
          },
          theme: {
            palette: 'palette2',
          },

          // fill: {
          //   type: 'image',
          //   image: {
          //     src: [
          //       require('../../../assets/images/icons/red-bubble.png'),
          //       require('../../../assets/images/icons/uk_flag.png'),
          //       require('../../../assets/images/icons/uk_flag.png'),
          //       require('../../../assets/images/icons/uk_flag.png'),
          //       require('../../../assets/images/icons/uk_flag.png'),
          //       require('../../../assets/images/icons/uk_flag.png'),
          //       require('../../../assets/images/icons/uk_flag.png'),
          //     ],
          //     width: 40,
          //     height: 40,
          //   },
          // },
          // xaxis: {
          //   tickAmount: 10,
          //   labels: {
          //     formatter: function (val: string) {
          //       return parseFloat(val).toFixed(1);
          //     },
          //   },
          // },
          yaxis: {
            // max: 100,
            labels: {
              offsetX: 10,
              formatter: (value: number) => {
                return numberWithCommas(value);
              },
            },
            title: {
              text: '%',
            },
          },
          grid: {
            padding: {
              left: 60,
              right: 60,
            },
          },
        },
      },
      fetch: null,
      // fetch: `/api/dashboard-pub/general-publisher-revenue-areachart/${userid}?appid=${appid}&publisherid=${publisherid}&datefrom=${datefrom}&dateto=${dateto}`,
    },
  ];
  return bubbleChart;
}

export default getRevenueConfig;
