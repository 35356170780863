import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router'; // import the react-router-dom components
import EstablishmentActions from 'redux/reducers/establishment_reducer/actions';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import DatatableList from './components/datatable';
import EstablishmentModal from './components/datatable/ViewEditData';
import { TableDefaultFetchValues } from './configuration';
import useStyles from './styles';

const EstablishmentPage = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useSelectUserData();

  React.useEffect(() => {
    dispatch(
      EstablishmentActions.fetchEstablishmentListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
      })
    );
    dispatch(EstablishmentActions.fetchUsernames());
    dispatch(EstablishmentActions.fetchGroupByUserId(id));
  }, [dispatch, id]);

  return (
    <div>
      <EstablishmentModal />
      <DatatableList />
    </div>
  );
};

export default withRouter(EstablishmentPage);
