import React from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import * as ReduxSelectors from 'react-redux';
import Days, { DaysEnum } from 'config/days';
import { AppSelect } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import CardLabelTitle from 'components/app_common/card_label_title';
import { colors, Theme } from '@material-ui/core';
import * as RandomUtils from 'utils/randomize';
import { ModalConfigWrapper } from 'components/app_common/modal_config_wrapper';

export default function LineChart() {
  return (
    <ModalConfigWrapper name="LineChart">
      <CustomChart
        title={
          <CardLabelTitle
            title={<TranslatedText defaultText="Sample Line Chart" />}
          />
        }
        onOverview={() => <DaySelectorWrapper />}
        layoutProps={{
          variant: 'outlined',
        }}
        data={DummyDataset()}
        labels={DummyLabels}
        options={DummyChartOptions}
        chartType={ChartTypesEnum.LINE}
      />
    </ModalConfigWrapper>
  );
}

interface IFormData {
  day: DaysEnum;
}

const DaySelectorWrapper = () => {
  const dispatch = ReduxSelectors.useDispatch();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues: {
      day: new Date().getDay().toString() as DaysEnum,
    },
  });

  const options = Days;
  return (
    <div style={{ marginTop: 8 }}>
      <AppSelect
        margin="none"
        fullWidth
        label={<TranslatedText defaultText={'Select Day'} />}
        name={'day'}
        size="small"
        required
        control={control}
        rules={{}}
        style={{ minWidth: 180 }}
        variant="outlined"
        options={options || []}
      />
    </div>
  );
};

export const DummyLabels = ['6', '8', '10', '12', '14', '16'];

export const DummyDataset = (
  dataset1 = {
    label: 'DummyDataset1',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  },
  dataset2 = {
    label: 'DummyDataset2',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  },
  dataset3 = {
    label: 'DummyDataset3',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  },
  dataset4 = {
    label: 'DummyDataset4',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  }
) => [
  {
    data: dataset4.data,
    label: 'Label4',
    format: {
      label: dataset4.label,
      type: 'line',
      fill: false,
      borderColor: '#FF6100',
      lineTension: 0.1, // make the lines without radius
      backgroundColor: '#FF6100',
      pointBorderColor: '#FF6100',
      pointBackgroundColor: '#FF6100',
      pointHoverBackgroundColor: '#FF6100',
      pointHoverBorderColor: '#FF6100',
    },
    // #DF0494
  },
  {
    data: dataset3.data,
    label: 'Label3',
    format: {
      label: dataset3.label,
      type: 'line',
      fill: false,
      lineTension: 0.1, // make the lines without radius
      borderColor: '#DF0494',
      backgroundColor: '#DF0494',
      pointBorderColor: '#DF0494',
      pointBackgroundColor: '#DF0494',
      pointHoverBackgroundColor: '#DF0494',
      pointHoverBorderColor: '#DF0494',
    },
  },
  {
    data: dataset2.data,
    label: 'Label2',
    format: {
      label: dataset2.label,
      type: 'line',
      fill: false,
      lineTension: 0.1, // make the lines without radius
      borderColor: '#4668C2',
      backgroundColor: '#4668C2',
      pointBorderColor: '#4668C2',
      pointBackgroundColor: '#4668C2',
      pointHoverBackgroundColor: '#4668C2',
      pointHoverBorderColor: '#4668C2',
    },
  },
  {
    data: dataset1.data,
    label: 'Label1',
    format: {
      label: dataset1.label,
      type: 'line',
      fill: false,
      lineTension: 0.1, // make the lines without radius
      borderColor: '#9E018F',
      backgroundColor: '#9E018F',
      pointBorderColor: '#9E018F',
      pointBackgroundColor: '#9E018F',
      pointHoverBackgroundColor: '#9E018F',
      pointHoverBorderColor: '#9E018F',
    },
  },
];

export const DummyChartOptions = (theme: Theme): any => ({
  legend: {
    display: true,
    align: 'start',
    labels: {
      usePointStyle: true,
      padding: 14,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          // maxRotation: 90,
          // minRotation: 90,
        },
      },
    ],
    yAxes: [
      {
        // stacked: true,
        // ticks: {
        //   beginAtZero: true,
        // },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
});
