import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: 'yellow',
        // height: '100vh',
    }
}));

export default useStyles;
