import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    '& >div:nth-child(2)': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      '& >h5': {
        color: '#404756',
        fontSize: 20,
      },
    },
    '& >div:nth-child(3)': {
      border: 'none',
    },
    '& >div:nth-child(4)': {
      marginTop: 0,
      border: 'none',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  submit: {
    color: theme.palette.getContrastText('#292E5F'),
    backgroundColor: '#292E5F',
    '&:hover': {
      backgroundColor: '#292E5F',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#292E5F',
      },
    },
    alignSelf: 'flex-start',
    marginTop: 10,
  },
  form: {},
  boxAbove: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0',
  },
  headerModal: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 24,
    color: '#404756',
    '& >span': {
      fontSize: 16,
      color: '#959CA0',
      fontWeight: 400,
    },
  },
  containerModal: {
    '& .MuiPaper-root': {
      padding: 8,
      '& >div:nth-child(2)': {
        borderBottom: 'none',
      },
    },
  },
  buttonCancel: {
    color: '#959CA0',
    border: '1px solid #959CA0',
    '&:hover': {
      border: '1px solid #959CA0',
    },
    textTransform: 'none',
    width: '100%',
  },
  buttonAdd: {
    textTransform: 'none',
    backgroundColor: '#346CB0',
    '&:hover': {
      backgroundColor: '#2060af',
    },
    width: '100%',
  },
  editButton: {
    color: '#fff',
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: '#346CB0',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1d5ead',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#346CB0',
      },
    },
    alignSelf: 'flex-start',
    padding: '5px 9px',
  },
  viewButton: {
    marginRight: 5,
    marginLeft: 5,
    color: '#D9A21B',
    alignSelf: 'flex-start',
    border: '1px solid #D9A21B',
    textTransform: 'none',
    padding: '5px 8px',
  },
  deleteButton: {
    marginRight: 5,
    marginLeft: 5,
    color: '#fff',
    backgroundColor: '#ff0033',
    '&:hover': {
      backgroundColor: '#ff0033',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#ff0033',
      },
    },
    alignSelf: 'flex-start',
    textTransform: 'none',
    padding: '5px 9px',
  },
}));

export default useStyles;
