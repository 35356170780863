import { IAction, StateStatus } from 'redux/utils/common';
import * as Types from './types';

interface IState {
  forgotPasswordStatus: boolean | null;
  resetPasswordStatus: boolean | null;
}

const initialState: IState = {
  forgotPasswordStatus: null,
  resetPasswordStatus: null,
};

const ForgotPasswordReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // FORGOT_PASSOWRD
    case Types.FORGOT_PASSOWRD:
      return {
        ...state,
        forgotPasswordStatus: action.data.status,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ForgotPasswordReducer;
