import React from 'react';
import { withRouter } from 'react-router'; // import the react-router-dom components
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import GraphsLayout from './components/graphs_layout';
import PageLayout from 'components/app_common/page_layout';
import { withReducer } from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import { ConfigWrapperModal } from 'components/app_common/modal_config_wrapper';

const ChartsPage = (props: any) => {
  reactLogger.renderPage('ChartsPage');

  return (
    <PageLayout>
      <ConfigWrapperModal />
      <GraphsLayout />
    </PageLayout>
  );
};

export default withRouter(ChartsPage);
