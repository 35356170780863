import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as EstablishmentService from '../../../services/api/establishment';
import * as GroupService from '../../../services/api/group';
import * as UserService from '../../../services/api/user';

import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';

/** Fetch FetchEstablishmentListInput  */

interface FetchEstablishmentListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  name?: string;
}

class FetchEstablishmentList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ESTABLISHMENT_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ESTABLISHMENT_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ESTABLISHMENT_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchEstablishmentListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await EstablishmentService.fetchEstablishmentList(
          payload
        );
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetcEstablishmentList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class SearchEstablishment implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ESTABLISHMENT_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ESTABLISHMENT_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ESTABLISHMENT_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchEstablishmentListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await EstablishmentService.searchEstablishment(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetcEstablishmentList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewEstablishment  */

interface AddNewEstablishmentInput {
  name: string | null;
  address: string | null;
  users: string | null;
  admin: string[] | null;
  group?: string | null;
  fetchEstablishmentInput: FetchEstablishmentListInput;
}

class AddNewEstablishment implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_ESTABLISHMENT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_ESTABLISHMENT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_ESTABLISHMENT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewEstablishmentInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchEstablishmentInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await EstablishmentService.addEstablishment(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchEstablishmentList().action(fetchEstablishmentInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_ESTABLISHMENT,
          })
        );
      } catch (error: any) {
        console.log('AddNewEstablishment Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt Establishment  */

interface EditEstablishmentInput {
  id: string;
  name: string | null;
  address: string | null;
  users: string | null;
  group?: string | null;
  admin: string[] | null;
  fetchEstablishmentInput: FetchEstablishmentListInput;
}

interface DeleteEstablishmentInput {
  id: string;
  fetchEstablishmentInput: FetchEstablishmentListInput;
}
class EditEstablishment implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_ESTABLISHMENT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_ESTABLISHMENT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_ESTABLISHMENT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditEstablishmentInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchEstablishmentInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await EstablishmentService.editEstablishment(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchEstablishmentList().action(fetchEstablishmentInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_ESTABLISHMENT,
          })
        );
      } catch (error: any) {
        console.log('EditNotification Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View Establishment  */

interface ViewEstablishmentInput {
  id: string;
}

class ViewEstablishment implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ESTABLISHMENT_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ESTABLISHMENT_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ESTABLISHMENT_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewEstablishmentInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await EstablishmentService.fetchEstablishmentDetail({
          id: payload.id,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('ViewEstablishment Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchUsernames implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USERNAMES,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USERNAMES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USERNAMES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getUsernames();
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('Fetch usernames Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class DeleteEstablishment implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.DELETE_ESTABLISHMENT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.DELETE_ESTABLISHMENT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.DELETE_ESTABLISHMENT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: DeleteEstablishmentInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchEstablishmentInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await EstablishmentService.deleteEstablishment({
          id: payload.id,
        });
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchEstablishmentList().action(fetchEstablishmentInput));
      } catch (error: any) {
        console.log('Delete establishment Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

class FetchGroupByUserId implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_GROUPS,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_GROUPS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_GROUPS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(id: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await GroupService.fetchGroupsByUserId({ userId: id });
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('Fetch groups Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
export default {
  fetchEstablishmentListAction: (payload: FetchEstablishmentListInput) =>
    new FetchEstablishmentList().action(payload),
  searchEstablishmentAction: (payload: FetchEstablishmentListInput) =>
    new SearchEstablishment().action(payload),
  addNewEstablishmentAction: (payload: AddNewEstablishmentInput) =>
    new AddNewEstablishment().action(payload),
  editEstablishmentAction: (payload: EditEstablishmentInput) =>
    new EditEstablishment().action(payload),
  deleteEstablishment: (payload: DeleteEstablishmentInput) =>
    new DeleteEstablishment().action(payload),
  viewEstablishmentAction: (payload: ViewEstablishmentInput) =>
    new ViewEstablishment().action(payload),
  fetchUsernames: () => new FetchUsernames().action(),
  fetchGroupByUserId: (id: string) => new FetchGroupByUserId().action(id),
};
