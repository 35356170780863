import { makeStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  addButton: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: blue[500],
      },
    },
    alignSelf: 'flex-start',
    margin: 8,
  },
  submit: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: blue[500],
      },
    },
    alignSelf: 'flex-start',
    marginTop: 10,
  },
  editButton: {
    textTransform: 'none',

    color: theme.palette.getContrastText(grey[800]),
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: '#6EE7B7',
    '&:hover': {
      backgroundColor: '#6EE7B7',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#6EE7B7',
      },
    },
    alignSelf: 'flex-start',
  },
  viewButton: {
    textTransform: 'none',

    marginRight: 5,
    marginLeft: 5,
    color: theme.palette.getContrastText(blueGrey[800]),
    backgroundColor: '#FCD34D',
    '&:hover': {
      backgroundColor: '#FCD34D',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#FCD34D',
      },
    },
    alignSelf: 'flex-start',
  },
  form: {},
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 240,
    height: 24,
    padding: '5px 10px',
  },
  padding: {
    padding: '10px !important',
  },
  verifyButton: {
    textTransform: 'none',
    marginRight: 5,
    marginLeft: 5,
    color: '#fff',
    backgroundColor: '#60A5FA',
    '&:hover': {
      backgroundColor: '#60A5FA',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#60A5FA',
      },
    },
    alignSelf: 'flex-start',
  },
}));

export default useStyles;
