import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

interface IUserOutListInput {
  sortField: string;
  order: 'asc' | 'desc';
  scenario: string;
  userid: string;
}

export const fetchUserOutputList = async (
  payload: IUserOutListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/user_output/list?sortField=${payload.sortField}&order=${payload.order}&scenario=${payload.scenario}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const fetchUserOutputByScenario = async (payload: {
  scenarios: string[];
}): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${
      Environment.API_BASE_URL
    }/api/user_output/list-by-scenarios?scenarios=${payload.scenarios.join(
      ','
    )}`
  );

  let _data: any = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};
