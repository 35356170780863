import { Box, Button, ButtonProps } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import TranslatedText from 'components/atoms/translated_text';
import BaseModal from 'components/molecules/base_modal';
import { AppTextField } from 'components/molecules/form_controls';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import GroupActions from 'redux/reducers/group_reducer/actions';
import * as GroupSelectors from 'redux/reducers/group_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import { TableDefaultFetchValues, Translations } from '../../configuration';
import useStyles from './styles';

export default function GroupModal() {
  const classes = useStyles();
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_ADD_EDIT_GROUP}
      renderHeader={({ type, id }: IModalProps) => (
        <Box className={classes.headerModal}>
          <GroupHeader id={id} type={type} />
          <span>You can provide your information here</span>
        </Box>
      )}
      renderContent={({ type, id }: IModalProps) => (
        <React.Fragment>
          <GroupForm id={id} type={type} />
        </React.Fragment>
      )}
      modalProps={{
        className: classes.containerModal,
      }}
    />
  );
}

interface IModalProps {
  type: 'add' | 'edit' | 'view';
  id: string | number;
}

const GroupHeader = ({ type, id }: IModalProps) => {
  return (
    <TranslatedText
      textMap={
        type === 'add'
          ? Translations.GroupModalAddTitle
          : type === 'edit'
          ? Translations.GroupModalEditTitle
          : Translations.GroupModalViewTitle
      }
      defaultText={type === 'add' ? 'Add' : type === 'edit' ? 'Edit' : 'View'}
    />
  );
};

interface FormTypes {
  name: string | null;
  address: string | null;
  establishments: string | null;
  admin: string | null;
}

const defaultValues = {
  name: null,
  address: null,
  establishments: null,
  admin: null,
};

const GroupForm = React.memo(({ type, id }: IModalProps) => {
  const { status, data } = GroupSelectors.useGetGroupDetails();
  const user = useSelectUserData();

  const classes = useStyles();
  const { reset, watch, errors, control, handleSubmit } = useForm<FormTypes>({
    defaultValues,
  });
  const dispatch = useDispatch();

  const onSubmit = (_data: FormTypes) => {
    switch (type) {
      case 'add':
        dispatch(
          GroupActions.addNewGroupAction({
            name: _data.name || null,
            address: _data.address || null,
            establishments: [],
            admin: [user.id],
            fetchGroupInput: {
              sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
              page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
              pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
              order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
            },
          })
        );
        break;
      case 'edit':
        dispatch(
          GroupActions.editGroupAction({
            name: _data.name || null,
            address: _data.address || null,
            establishments: _data.establishments || null,
            admin: _data.admin || null,
            fetchGroupInput: {
              sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
              page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
              pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
              order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
            },
          })
        );
        break;
    }
  };

  React.useEffect(() => {
    if (['edit', 'view'].includes(type)) {
      dispatch(
        GroupActions.viewGroupAction({
          id: id.toString(),
        })
      );
    }
  }, [dispatch, id, type]);

  React.useEffect(() => {
    if (status === StateStatus.Success && type !== 'add') {
      reset({
        name: data?.name || null,
        address: data?.address || null,
        establishments: data?.establishments || null,
        admin: data?.admin || null,
      });
    }
  }, [status, dispatch, type]);

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="name"
        label={<TranslatedText defaultText={'name'} />}
        error={'name' in errors}
        helperText={errors.name && errors.name.message}
        rules={{}}
        name="name"
        autoComplete="name"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="address"
        label={<TranslatedText defaultText={'address'} />}
        error={'address' in errors}
        helperText={errors.address && errors.address.message}
        rules={{}}
        name="address"
        autoComplete="address"
        control={control}
      />
      {/* <AppMultipleSelect
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        options={
          establishmentsname?.data?.map(
            (item: GroupSelectors.EstablishmentName) => {
              return {
                id: item._id,
                label: item.name,
              };
            }
          ) || []
        }
        id="establishments"
        label={<TranslatedText defaultText={'establishments'} />}
        error={'establishments' in errors}
        helperText={errors.establishments && errors.establishments.message}
        rules={{ required: 'Required Field' }}
        name="establishments"
        autoComplete="establishments"
        control={control}
      />
      <AppMultipleSelect
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        options={
          usernames?.data?.map((item: GroupSelectors.Usernames) => {
            return {
              id: item._id,
              label: `${item.firstName} ${item.surname}`,
            };
          }) || []
        }
        fullWidth
        id="admin"
        label={<TranslatedText defaultText={'admin'} />}
        error={'admin' in errors}
        helperText={errors.admin && errors.admin.message}
        rules={{ required: 'Required Field' }}
        name="admin"
        autoComplete="admin"
        control={control}
      /> */}

      {type !== 'view' && (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          style={{ gap: 15, margin: '30px 0 10px 0' }}
        >
          <Button
            // type={'submit'}
            color="primary"
            // onClick={() =>
            //   dispatch(ScenarioAction.setModal({ isOpen: false, data: null }))
            // }
            variant="outlined"
            // disabled={submitStatus === StateStatus.Pending}
            className={classes.buttonCancel}
          >
            <TranslatedText
              defaultText={'Cancel'}
              // textMap={Translations.form_submit}
            />
          </Button>
          <Button
            type={'submit'}
            color="primary"
            variant="contained"
            className={classes.buttonAdd}
          >
            <TranslatedText
              textMap={Translations.addEditModalButton}
              defaultText={'Submit'}
            />
          </Button>
        </Box>
      )}
    </form>
  );
});

const SubmitWrapper = (props: ButtonProps) => {
  return <Button {...props} />;
};
