import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_USER_LIST: string = typeCreatorV2(
  'UserManagementReducer',
  'SetUserList'
);
export const UPDATE_USER: string = typeCreatorV2(
  'UserManagementReducer',
  'UpdateUser'
);
export const CHANGE_PASSWORD: string = typeCreatorV2(
  'UserManagementReducer',
  'ChangePassword'
);
export const SET_USER_DETAIL: string = typeCreatorV2(
  'UserManagementReducer',
  'SetUserDetail'
);
export const SET_MODAL: string = typeCreatorV2(
  'UserManagementReducer',
  'SetModal'
);
