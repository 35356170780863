import { typeCreatorV2 } from '../../../redux/utils/common';

export const SET_USER_OUTPUT: string = typeCreatorV2(
  'CombineScenarioReducer',
  'SetUserOutput'
);
export const SET_USER_PRODUCT: string = typeCreatorV2(
  'CombineScenarioReducer',
  'SetUserProduct'
);
export const SET_ENV_IMPACT_SELECTION: string = typeCreatorV2(
  'CombineScenarioReducer',
  'SetEnvImpactSelection'
);
export const SET_ENV_DESC_SELECTION: string = typeCreatorV2(
  'CombineScenarioReducer',
  'SetEnvDescSelection'
);
export const SET_PRODUCT_CATEGORY_SELECTION: string = typeCreatorV2(
  'CombineScenarioReducer',
  'SetProductCategorySelection'
);
