import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import TranslatedText from 'components/atoms/translated_text';
import {
  AppTextField,
  AppSelect,
  AppColorPicker,
  AppSwitch,
} from 'components/molecules/form_controls';
import { useForm, useFieldArray, Control } from 'react-hook-form';
import GridLayout from 'components/molecules/grid_layout';
import * as Configuration from '../../configuration';
import SubFormLayout from './SubFormLayout';
import reactLogger from 'utils/logger';
import {
  IChartConfig,
  IDataset,
  GenericChartTypes,
} from 'components/compounds/dashboard_builder/plugins/types/chart';
import {
  TBuilderTypes,
  IDashboardElement,
} from 'components/compounds/dashboard_builder/plugins/types';
import DividerLine from 'components/atoms/divider_line';
import * as DashboardBuilderSelectors from '../../store/selectors';
import DashboardBuilderActions from '../../store/actions';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import * as RandomUtils from 'utils/randomize';

interface IFormData extends IChartConfig {
  selectedDatasetsNumber: number;
  // width: number;
  // genericChartType: GenericChartTypes;
}

const defaultDatasets: IDataset[] = [
  {
    type: 'line',
    y_axis_field: '',
    color: RandomUtils.getRandomColor(),
    border_color: RandomUtils.getRandomColor(),
    name: '',
  },
];

const defaultValues = (
  urlPath: string,
  initial: IChartConfig | {} = {}
): IFormData => ({
  genericChartType: 'combo-bar-line',
  x_axis_field: '',
  loadData: {
    type: 'api-load',
    url: urlPath,
  },
  legend: {
    visible: false,
    position: 'bottom-center',
  },
  gridLines: {
    enableX: false,
    enableY: false,
  },
  tooltips: {
    enable: true,
  },
  datasets: defaultDatasets,
  startAxis: {
    min: 5,
    max: 5,
  },
  selectedDatasetsNumber: _.get(initial, 'datasets.length', 1),
  height: Configuration.HeightArr[0].id, // small
  cardTitle: '',
  ...initial,
});

interface IFormProps {
  urlPath: string;
  initialValuesconfig: IChartConfig;
  type: 'edit' | 'add';
  itemIndex?: number;
  dashboardElementType: TBuilderTypes;
  width: number;
  reqMethod: 'GET' | 'POST';
}

export default function Form(props: IFormProps) {
  reactLogger.renderComponent('DatasetsForm');
  const { urlPath, initialValuesconfig, reqMethod } = props;

  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues: defaultValues(urlPath || ''),
  });
  const formRef: any = React.useRef();
  const dispatch = useDispatch();
  reactLogger.renderComponent('Render Datasets Form');
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'datasets',
    }
  );

  React.useEffect(() => {
    reset(defaultValues(urlPath || '', initialValuesconfig || {}));
  }, [urlPath, initialValuesconfig]);

  const onSubmit = (_data: IFormData, submitType: 'add' | 'edit') => {
    // console.log('Daaaaaaaaaa:', _data);
    // let data = DefaultDashboard();
    const { selectedDatasetsNumber, ...config } = _data;
    let element: IDashboardElement = {
      type: props.dashboardElementType,
      config: config,
      size: props.width as any,
      url: urlPath || '',
      urlMethod: reqMethod,
    };

    if (submitType === 'add') {
      dispatch(
        DashboardBuilderActions.setBuilderConfigurationAction({
          configuration: element,
          type: 'add',
        })
      );
    } else {
      dispatch(
        DashboardBuilderActions.setBuilderConfigurationAction({
          configuration: element,
          type: 'edit',
          index: props.itemIndex || 0,
        })
      );
    }
  };

  const handleDatasetsNumber = async (value: any) => {
    let arr = [];
    for (let i = 0; i < parseInt(value); i++) {
      arr.push({
        ...defaultDatasets[0],
      });
    }
    reset({
      ...getValues(),
      datasets: arr,
      selectedDatasetsNumber: parseInt(value),
    });
  };

  const handleChartGenericValue = (value: any) => {
    const options =
      Configuration.ChartTypeSelectorMap[value as GenericChartTypes];
    for (let index in fields) {
      setValue(`datasets[${index}].type`, options[0].id);
    }
  };

  return (
    <form ref={formRef}>
      <GridLayout
        spacing={1}
        justify="flex-start"
        elements={[
          {
            id: 'generic-chart-type',
            element: (
              <AppSelect
                variant="outlined"
                margin="dense"
                error={Boolean(_.get(errors, 'genericChartType', false))}
                helperText={_.get(errors, 'genericChartType', '')}
                rules={{}}
                handleChange={handleChartGenericValue}
                options={Configuration.GenericChartTypesArr}
                fullWidth
                size="small"
                id={'genericChartType'}
                control={control}
                label={<TranslatedText defaultText={'Generic Chart Type'} />}
                autoComplete={'genericChartType'}
                name={'genericChartType'}
              />
            ),
            size: 12,
          },
          {
            id: 'chart-title',
            element: (
              <AppTextField
                variant="outlined"
                margin="dense"
                error={Boolean(_.get(errors, 'cardTitle', false))}
                helperText={_.get(errors, 'cardTitle', '')}
                rules={{}}
                fullWidth
                size="small"
                id={'cardTitle'}
                control={control}
                label={<TranslatedText defaultText={'Card Title'} />}
                autoComplete={'cardTitle'}
                name={'cardTitle'}
              />
            ),
            size: 12,
          },
          {
            id: 'legend-subform',
            size: 12,
            element: (
              <GridLayout
                // style={{ paddingLeft: 15, paddingRight: 15 }}
                spacing={1}
                elements={[
                  {
                    id: '1',
                    element: (
                      <AppSwitch
                        rules={
                          {
                            // required: <RequiredField />,
                          }
                        }
                        size="medium"
                        label={<TranslatedText defaultText={'Enable Legend'} />}
                        id="legend.visible"
                        control={control}
                        name="legend.visible"
                      />
                    ),
                    size: 12,
                  },
                  {
                    id: '2',
                    element: (
                      <AppSelect
                        variant="outlined"
                        margin="dense"
                        rules={{
                          required: <RequiredField />,
                        }}
                        fullWidth
                        size="small"
                        id="legend.position"
                        options={Configuration.LegendPositionArr}
                        control={control}
                        label={
                          <TranslatedText defaultText={'Legend Position'} />
                        }
                        autoComplete="legend.position"
                        name="legend.position"
                      />
                    ),
                    size: 12,
                  },
                ]}
              />
            ),
          },
          {
            id: 'labels-subform',
            size: 12,
            element: (
              <GridLayout
                // style={{ paddingLeft: 15, paddingRight: 15 }}
                spacing={1}
                elements={[
                  {
                    id: '1',
                    element: (
                      <DropdownDataFields
                        name={'x_axis_field'}
                        text={'X Axis Field'}
                        control={control}
                        errors={errors}
                        controllerExtras={{}}
                      />
                    ),
                    size: 12,
                  },
                ]}
              />
            ),
          },
          {
            id: 'grid-lines-subform',
            size: 12,
            element: (
              <GridLayout
                // style={{ paddingLeft: 15, paddingRight: 15 }}
                spacing={1}
                elements={[
                  {
                    id: '1',
                    element: (
                      <AppSwitch
                        // error={'x_axis_field' in errors}
                        // helperText={errors.x_axis_field && errors.x_axis_field.message}
                        rules={
                          {
                            // required: <RequiredField />,
                          }
                        }
                        size="medium"
                        label={<TranslatedText defaultText={'EnableX'} />}
                        id="gridLines.enableX"
                        control={control}
                        name="gridLines.enableX"
                      />
                    ),
                    size: 6,
                  },
                  {
                    id: '2',
                    element: (
                      <AppSwitch
                        // error={'x_axis_field' in errors}
                        // helperText={errors.x_axis_field && errors.x_axis_field.message}
                        rules={
                          {
                            // required: <RequiredField />,
                          }
                        }
                        size="medium"
                        label={<TranslatedText defaultText={'EnableY'} />}
                        id="gridLines.enableY"
                        control={control}
                        name="gridLines.enableY"
                      />
                    ),
                    size: 6,
                  },
                ]}
              />
            ),
          },
          {
            id: 'size-subform',
            size: 12,
            element: (
              <GridLayout
                // style={{ paddingLeft: 15, paddingRight: 15 }}
                spacing={1}
                elements={[
                  {
                    id: '1',
                    element: (
                      <AppSelect
                        variant="outlined"
                        margin="dense"
                        error={'height' in errors}
                        helperText={errors.height && errors.height.message}
                        rules={{}}
                        options={Configuration.HeightArr}
                        fullWidth
                        size="small"
                        id="height"
                        control={control}
                        label={<TranslatedText defaultText={'Height'} />}
                        autoComplete="height"
                        name="height"
                      />
                    ),
                    size: 6,
                  },
                ]}
              />
            ),
          },
          {
            id: 'datasets-subform',
            size: 12,
            element: (
              <GridLayout
                // style={{ paddingLeft: 15, paddingRight: 15 }}
                spacing={1}
                elements={[
                  {
                    id: '1',
                    size: 12,
                    element: (
                      <AppSelect
                        variant="outlined"
                        margin="normal"
                        rules={{}}
                        handleChange={handleDatasetsNumber}
                        options={Configuration.DatasetsNumberArr}
                        fullWidth
                        size="small"
                        id="selectedDatasetsNumber"
                        control={control}
                        label={
                          <TranslatedText defaultText={'Datasets Number'} />
                        }
                        autoComplete="selectedDatasetsNumber"
                        name="selectedDatasetsNumber"
                      />
                    ),
                  },
                  {
                    id: '2',
                    element: (
                      <React.Fragment>
                        {fields.map((item, index) => (
                          <DatasetSubForm
                            errors={errors}
                            key={item.id}
                            setValue={setValue}
                            control={control}
                            watch={watch}
                            arrName={'datasets'}
                            id={index}
                            item={item}
                          />
                        ))}
                      </React.Fragment>
                    ),
                    size: 12,
                  },
                ]}
              />
            ),
          },
          {
            id: 'submit',
            element: (
              <GridLayout
                spacing={1}
                justify="flex-start"
                elements={[
                  {
                    id: '1',
                    element: (
                      <Button
                        // type="submit"
                        variant="outlined"
                        fullWidth
                        color="primary"
                        onClick={handleSubmit((data: IFormData) =>
                          onSubmit(data, 'add')
                        )}
                      >
                        <TranslatedText defaultText={'Add New'} />
                      </Button>
                    ),
                    size: 3,
                  },
                  {
                    id: '2',
                    element: (
                      <ButtonEditWrapper
                        onSubmit={handleSubmit((data: IFormData) =>
                          onSubmit(data, 'edit')
                        )}
                      />
                    ),
                    size: 3,
                  },
                  {
                    id: '3',
                    element: (
                      <Button
                        // type="submit"
                        variant="outlined"
                        fullWidth
                        color="primary"
                      >
                        <TranslatedText defaultText={'Clear'} />
                      </Button>
                    ),
                    size: 3,
                  },
                ]}
              />
            ),
            size: 12,
          },
        ]}
      />
    </form>
  );
}

interface IButtonEditWrapperProps {
  onSubmit: any;
}

const ButtonEditWrapper = (props: IButtonEditWrapperProps) => {
  const selectElement = DashboardBuilderSelectors.useGetSelectedElementDashboard(
    'element'
  );
  const isSelected = Boolean(selectElement);

  return (
    <Button
      onClick={props.onSubmit}
      variant="outlined"
      fullWidth
      disabled={!isSelected}
      color="primary"
    >
      <TranslatedText defaultText={'Edit'} />
    </Button>
  );
};

interface IDatasetSubFormProps {
  id: number;
  errors: any;
  control: any;
  arrName: string;
  item: any;
  setValue: any;
  watch: any;
}

const DatasetSubForm = (props: IDatasetSubFormProps) => {
  const { id, errors, control, arrName, item, setValue, watch } = props;

  const idName = `${arrName}[${id}]`;
  return (
    <React.Fragment>
      <SubFormLayout
        title={<TranslatedText defaultText={`Dataset ${id}`} />}
        style={{ marginBottom: 15 }}
      >
        <GridLayout
          spacing={1}
          justify="flex-start"
          elements={[
            {
              id: '1',
              element: (
                <DatasetChartTypesWrapper
                  idName={idName}
                  item={item}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                />
              ),
              size: 6,
            },
            {
              id: '2',
              element: (
                <AppColorPicker
                  variant="outlined"
                  margin="dense"
                  setValue={setValue}
                  rules={{}}
                  fullWidth
                  size="small"
                  id={`${idName}.color`}
                  control={control}
                  label={<TranslatedText defaultText={'Background Color'} />}
                  autoComplete={`${idName}.color`}
                  name={`${idName}.color`}
                  controllerExtras={{
                    defaultValue: item.color,
                  }}
                />
              ),
              size: 6,
            },
            {
              id: '3',
              element: (
                <AppColorPicker
                  variant="outlined"
                  margin="dense"
                  setValue={setValue}
                  rules={{}}
                  fullWidth
                  size="small"
                  id={`${idName}.border_color`}
                  control={control}
                  label={<TranslatedText defaultText={'Border Color'} />}
                  autoComplete={`${idName}.border_color`}
                  name={`${idName}.border_color`}
                  controllerExtras={{
                    defaultValue: item.border_color,
                  }}
                />
              ),
              size: 6,
            },
            {
              id: '4',
              element: (
                <DropdownDataFields
                  name={`${idName}.y_axis_field`}
                  text={'Y Axis Field'}
                  control={control}
                  errors={errors}
                  controllerExtras={{
                    defaultValue: item.y_axis_field,
                  }}
                />
              ),
              size: 6,
            },
            {
              id: '5',
              element: (
                <AppTextField
                  variant="outlined"
                  margin="dense"
                  error={Boolean(_.get(errors, `${idName}.name`, false))}
                  helperText={_.get(errors, `${idName}.name.message`, '')}
                  rules={
                    {
                      // required: <RequiredField />,
                    }
                  }
                  fullWidth
                  size="small"
                  id={`${idName}.name`}
                  control={control}
                  label={<TranslatedText defaultText={'Dataset Name'} />}
                  autoComplete={`${idName}.name`}
                  name={`${idName}.name`}
                  controllerExtras={{
                    defaultValue: item.name,
                  }}
                />
              ),
              size: 12,
            },
          ]}
        />
      </SubFormLayout>
    </React.Fragment>
  );
};

interface IDatasetChartTypesWrapperProps {
  idName: string;
  item: any;
  watch: any;
  control: Control;
  setValue: any;
}

const DatasetChartTypesWrapper = ({
  idName,
  item,
  watch,
  control,
  setValue,
}: IDatasetChartTypesWrapperProps) => {
  const selectedGenericChartType: GenericChartTypes = watch('genericChartType');
  const options = Configuration.ChartTypeSelectorMap[selectedGenericChartType];

  return (
    <AppSelect
      variant="outlined"
      margin="dense"
      rules={{}}
      options={options || []}
      fullWidth
      size="small"
      id={`${idName}.type`}
      control={control}
      label={<TranslatedText defaultText={'Chart Type'} />}
      autoComplete={`${idName}.type`}
      name={`${idName}.type`}
      controllerExtras={{
        defaultValue: item.type,
      }}
    />
  );
};

interface IDropdownDataFieldsProps {
  name: string;
  text: string;
  controllerExtras: any;
  errors: any;
  control: any;
}

const DropdownDataFields = (props: IDropdownDataFieldsProps) => {
  const { name, errors, control, controllerExtras, text } = props;
  const { data } = DashboardBuilderSelectors.useGetLoadDataFields();

  return (
    <AppSelect
      variant="outlined"
      margin="dense"
      error={Boolean(_.get(errors, name, false))}
      helperText={_.get(errors, `${name}.message`, '')}
      rules={{
        required: <RequiredField />,
      }}
      required
      fullWidth
      size="small"
      id={name}
      control={control}
      options={data || []}
      label={<TranslatedText defaultText={text} />}
      autoComplete={name}
      name={name}
      controllerExtras={controllerExtras}
    />
  );
};

const RequiredField = React.memo(() => {
  return <TranslatedText defaultText={'Required Value'} />;
});
