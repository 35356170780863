import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, withRouter } from 'react-router'; // import the react-router-dom components
import { AuthRoles } from '../../config';
import NavigationConfig from '../../navigation/config';
import { SideBarConfig } from '../../navigation/config/Routes';
import { ReducerKeys } from '../../redux/config';
import { getState } from '../../redux/store';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import ProductTable from './components/Table';
import Header from './components/Header';
import Dialog from './components/Dialog';
import reactLogger from 'utils/logger';
import PageLayout from 'components/app_common/page_layout';

import Actions from 'redux/reducers/product_reducer/actions';

const MainPage = (props: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Actions.fetchProduct());
  }, []);

  reactLogger.log('product page');

  // replace main page with login Page
  // if (
  //   _.get(getState(ReducerKeys.AUTH_REDUCER), 'role', AuthRoles.Guest) ===
  //   AuthRoles.Guest
  // ) {
  //   return <Redirect to={NavigationConfig.loginPage().path} />;
  // } else {
  //   return <Redirect to={NavigationConfig.storeParformancePage().path} />;
  // }
  return (
    <PageLayout>
      <Dialog />
      <Card>
        <CardContent>
          <Header />
          <ProductTable />
        </CardContent>
      </Card>
    </PageLayout>
  );
};

export default withRouter(MainPage);
