import React from 'react';
import { Button, Checkbox, TextField, Tooltip, Box } from '@material-ui/core';
import { DeleteOutline, InfoOutlined, AddCircle } from '@material-ui/icons';
import * as DatesUtils from '../../utils/dates';
import { Languages, AuthRoles } from '../../config';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Environment from 'config/environment';
import TranslatedText from 'components/atoms/translated_text';

import Actions from 'redux/reducers/userproducts_reducer/actions';
import { useGetSelected } from 'redux/reducers/userproducts_reducer/selectors';
import { useGetSelectedScenario } from 'redux/reducers/scenario_reducer/selectors';

const CONFIGURATION = {};

export const allowedActions = {};

export default CONFIGURATION;

function ActionsBlock(props: any) {
  const dispatch = useDispatch();
  const handleDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(Actions.deleteUserProductAction({ id: props.data._id }));
        // Swal.fire('Deleted!', 'The product has been deleted.', 'success');
      }
    });
  };

  return (
    <div>
      {/* <Button
        // className={classes.editButton}
        style={{ marginRight: 12 }}
        variant="contained"
        color="primary"
        onClick={() =>
          dispatch(
            Actions.setModalAction({
              isOpen: true,
              type: 'edit',
              data: props.data,
            })
          )
        }
      >
        <TranslatedText textMap={Translations.button_edit} />
      </Button> */}
      <DeleteOutline
        // className={classes.editButton}
        color="error"
        onClick={handleDelete}
      />
    </div>
  );
}

function EditableCell(props: any) {
  const dispatch = useDispatch();
  const selectedScenario = useGetSelectedScenario();

  const [value, setValue] = React.useState<number | null>(0);
  const [timer, setTimer] = React.useState<any>(null);

  React.useEffect(() => {
    if (props.value !== undefined) setValue(props.value);
  }, [props.value]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = parseFloat(event.target.value);
    setValue(newValue);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        dispatch(
          Actions.updateUserProductAction({
            id: props.row._id,
            [props.name]: newValue,
            scenario: selectedScenario,
          })
        );
      }, 3000)
    );
  }

  return (
    <TextField
      value={value}
      type="number"
      onChange={handleChange}
      variant="outlined"
      disabled={!props.row.selected}
    />
  );
}

function CheckboxCell(props: {
  selected: boolean;
  row: any;
  openModal: boolean;
  id: string;
}) {
  const dispatch = useDispatch();
  const selectedScenario = useGetSelectedScenario();
  const { row, openModal, id } = props;
  const [selected, setSelected] = React.useState(props.selected || false);

  React.useEffect(() => {
    setSelected(row.selected || false);
  }, [row]);

  function handleSelect(event: React.ChangeEvent<HTMLInputElement>) {
    setSelected(event.target.checked);

    if (openModal && event.target.checked !== undefined) {
      event.target.checked &&
        dispatch(
          Actions.setModalAction({
            type: 'add',
            isOpen: true,
            data: row,
          })
        );
      dispatch(
        Actions.updateProduct({
          id: row._id,
          selected: event.target.checked,
        })
      );
    }
    if (!openModal && event.target.checked !== undefined) {
      dispatch(
        Actions.updateUserProductAction({
          id: row._id,
          selected: event.target.checked,
          scenario: selectedScenario,
        })
      );
    }
  }
  return <Checkbox id={id} checked={selected} onChange={handleSelect} />;
}
function PlusCell({ rowIndex, columnIndex, cellValue, data }: any) {
  const dispatch = useDispatch();
  return (
    <div style={{ paddingLeft: 30 }}>
      <Tooltip title="Press to select product" placement="top-end">
        <AddCircle
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(
              Actions.setModalAction({
                isOpen: true,
                type: 'add',
                data: data[rowIndex],
              })
            );
          }}
        />
      </Tooltip>
    </div>
  );
}

function ImageCell({ rowIndex, columnIndex, cellValue, data }: any) {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      {cellValue ? (
        <Tooltip title={data[rowIndex].hoverdescription} placement="top-end">
          <img
            onClick={() =>
              dispatch(
                Actions.setModalAction({
                  isOpen: true,
                  type: 'add',
                  data: data[rowIndex],
                })
              )
            }
            height="80px"
            width="80px"
            src={`${Environment.API_BASE_URL}/${cellValue.path}`}
          />
        </Tooltip>
      ) : (
        <Tooltip title={data[rowIndex].hoverdescription} placement="top-end">
          <img
            onClick={() =>
              dispatch(
                Actions.setModalAction({
                  isOpen: true,
                  type: 'add',
                  data: data[rowIndex],
                })
              )
            }
            height="80px"
            width="80px"
            src={require('../../assets/images/no-image-available.png')}
          />
        </Tooltip>
      )}
    </React.Fragment>
  );
}

function getToolTipByUsageCategory(usageCategory: string) {
  if (usageCategory === 'SUP') {
    return 'Single-use plastic (SUP) products refer to goods that are made primarily from fossil fuel–based chemicals (petrochemicals) and are typically intended to be used just once or for a short period of time, before they are thrown away. Plastic pollution causes significant economic, social and environmental damage and therefore we need to reduce our reliance on these.';
  }
  if (usageCategory === 'SUNP') {
    return 'Single-use non-plastic (SUNP) products refer to items which are made from non-plastic materials, though are still designed to be used in the same way as conventional SUP products (i.e. to be disposed of after one use). Products may include bottles, cups, cutlery, food containers and other packaging. The materials used can include, though are not limited to, wood, cardboard, paper, bamboo, metal and glass.';
  }
  if (usageCategory === 'MUNP' || usageCategory === 'MUP') {
    return 'Multiple use (MU) products are those that are designed for more than one use and can be made from any material. Generally, MU products are made to a higher quality and durability than single-use products, which increases the environmental impact of their manufacture and the cost of their production. Their environmental performance relative to SUP products therefore improves, the more these items are reused. A key advantage of MU items is that, because of their reusable nature, they tend not to be discarded carelessly as litter, nor are they disposed of after just one use. This has significant implications for waste and litter prevention, compared to single use products.';
  }
}

export const SelectProductTableColumns = [
  // {
  //   label: '',
  //   id: 'selected',
  //   render: (
  //     rowIndex: number,
  //     cellValue: any,
  //     columnIndex: number,
  //     data: any
  //   ) => {
  //     // return <CheckboxCell selected={cellValue} row={data[rowIndex]} />;
  //     return (
  //       <CheckboxCell
  //         id={data[rowIndex]._id}
  //         selected={data[rowIndex].selected}
  //         row={data[rowIndex]}
  //         openModal
  //       />
  //     );
  //   },
  //   inData: true,
  // },
  {
    label: '',
    id: '',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <PlusCell
        rowIndex={rowIndex}
        cellValue={cellValue}
        columnIndex={columnIndex}
        data={data}
      />
    ),
    inData: true,
  },
  {
    label: 'Product image',
    id: 'picture',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ImageCell
        rowIndex={rowIndex}
        cellValue={cellValue}
        columnIndex={columnIndex}
        data={data}
      />
    ),
    inData: true,
  },
  {
    label: 'Product category',
    id: 'productCategory',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Product type',
    id: 'productType',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
];

export const TableColumns = [
  {
    label: 'Select',
    id: 'selected',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <React.Fragment>
        {data[rowIndex].usageCategory !== 'SUP' ? (
          <CheckboxCell
            id={data[rowIndex]._id}
            selected={data[rowIndex].selected}
            row={data[rowIndex]}
            openModal={false}
          />
        ) : null}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: '',
    id: 'usage',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <React.Fragment>
        {data[rowIndex].usageCategory === 'SUP'
          ? 'Current usage'
          : 'Alternatives'}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: '',
    id: 'picture',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {cellValue ? (
          <img
            height="50px"
            width="50px"
            src={`${Environment.API_BASE_URL}/${cellValue.path}`}
          />
        ) : (
          <img
            height="50px"
            width="50px"
            src={require('../../assets/images/no-image-available.png')}
          />
        )}
      </React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Product type',
    id: 'productType',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <Box display="flex" alignItems="center">
        {cellValue}
        {data[rowIndex].usageCategory !== 'SUP' ? (
          <Box marginLeft="10px">
            <Tooltip
              placement="right-end"
              title={data[rowIndex].hoverdescription}
            >
              <InfoOutlined />
            </Tooltip>
          </Box>
        ) : null}
      </Box>
    ),
    inData: true,
  },
  {
    label: 'Usage category',
    id: 'usageCategory',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <Box display="flex" alignItems="center">
        {cellValue}
        <Box marginLeft="10px">
          <Tooltip
            placement="right-end"
            title={
              getToolTipByUsageCategory(data[rowIndex].usageCategory) || ''
            }
          >
            <InfoOutlined />
          </Tooltip>
        </Box>
      </Box>
    ),
    inData: true,
  },
  {
    label: 'Annual usage (number)',
    id: 'quantity',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Replacement quantity (number)',
    id: 'suggested',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => {
      return (
        <React.Fragment>
          {data[rowIndex].usageCategory !== 'SUP' ? (
            <Box display="flex" alignItems="center">
              <EditableCell
                value={cellValue}
                row={data[rowIndex]}
                name="suggested"
              />
              <Box marginLeft="10px">
                <Tooltip
                  placement="right-end"
                  title="The suggested replacement quantity provided is an estimate based on a number of parameters related to your establishment's size, capacity etc., as these are defined in your user profile. Please edit the replacement quantity as needed to reflect your circumstances more accurately."
                >
                  <InfoOutlined />
                </Tooltip>
              </Box>
            </Box>
          ) : null}
        </React.Fragment>
      );
    },
    inData: true,
  },
  {
    label: 'Cost per item (EUR)',
    id: 'productCost',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <React.Fragment>
        {data[rowIndex].usageCategory !== 'SUP' ? (
          <Box display="flex" alignItems="center">
            <EditableCell
              value={cellValue}
              row={data[rowIndex]}
              name="productCost"
            />
            <Box marginLeft="10px">
              <Tooltip
                placement="right-end"
                title="The unit costs provided are based on average unit costs (EUR) of items from retailers and wholesalers across Europe. Please edit the unit cost as needed to reflect your circumstances more accurately."
              >
                <InfoOutlined />
              </Tooltip>
            </Box>
          </Box>
        ) : (
          cellValue
        )}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Actions',
    align: 'center',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <React.Fragment>
        {data[rowIndex].usageCategory === 'SUP' ? (
          <ActionsBlock rowIndex={rowIndex} data={data[rowIndex]} />
        ) : null}
      </React.Fragment>
    ),
    inData: false,
  },
];

export const NestedTableColumns = [
  {
    label: 'Picture',
    id: 'picture',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {cellValue ? (
          <img
            height="50px"
            width="50px"
            src={`${Environment.API_BASE_URL}/${cellValue.path}`}
          />
        ) : (
          <img
            height="50px"
            width="50px"
            src={require('../../assets/images/no-image-available.png')}
          />
        )}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Product category',
    id: 'productCategory',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Product type',
    id: 'productType',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Usage category',
    id: 'usageCategory',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Quantity',
    id: 'quantity',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Suggested',
    id: 'suggested',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      // <React.Fragment>{cellValue} nested</React.Fragment>
      <EditableCell value={cellValue} row={data[rowIndex]} name="suggested" />
    ),
    inData: true,
  },
  {
    label: 'Product cost',
    id: 'productCost',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      // <React.Fragment>{cellValue} nested</React.Fragment>
      <EditableCell value={cellValue} row={data[rowIndex]} name="productCost" />
    ),
    inData: true,
  },
  {
    label: 'Actions',
    align: 'center',
    id: 'action',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => <ActionsBlock rowIndex={rowIndex} data={data[rowIndex]} />,
    inData: false,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 25,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};

export const Translations = {
  page_title: {
    [Languages.Greek]: 'UserProducts',
    [Languages.English]: 'UserProducts',
  },
  manage_registration_title: {
    [Languages.Greek]: 'UserProducts Title',
    [Languages.English]: 'UserProducts Title',
  },
  manage_registration_description: {
    [Languages.Greek]: 'UserProducts Description',
    [Languages.English]: 'UserProducts Description',
  },
  table_title: {
    [Languages.Greek]: 'Title',
    [Languages.English]: 'Title',
  },
  UserProductsModalAddTitle: {
    [Languages.Greek]: 'Προσθήκη',
    [Languages.English]: 'Add',
  },
  UserProductsModalEditTitle: {
    [Languages.Greek]: 'Επεξεργασία',
    [Languages.English]: 'Edit',
  },
  UserProductsModalViewTitle: {
    [Languages.Greek]: 'Προεπισκόπηση',
    [Languages.English]: 'View',
  },
  addEditModalButton: {
    [Languages.Greek]: 'Submit',
    [Languages.English]: 'Submit',
  },
  button_edit: {
    [Languages.Greek]: 'Edit',
    [Languages.English]: 'Edit',
  },
  button_delete: {
    [Languages.Greek]: 'Delete',
    [Languages.English]: 'Delete',
  },
};
