import React from 'react';
import createReducer, { asyncReducers, staticReducers } from './reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ReducerKeys } from './config';

// Middleware: Redux Persist Persisted Reducer
const middlewares = [applyMiddleware(thunk)];
const store = createStore(
  createReducer(),
  composeWithDevTools(compose(...middlewares))
);

export const getState = (reducerKey: ReducerKeys): any => {
  if (reducerKey in store.getState())
    return (store.getState() as any)[reducerKey];
  return store.getState();
};

let _store: any = store;
_store.asyncReducers = {};
export const injectReducer = (
  reducerKey: ReducerKeys,
  action: 'add' | 'remove'
) => {
  try {
    if (reducerKey in _store.asyncReducers && action === 'remove') {
      delete _store.asyncReducers[reducerKey];
      let reducer = createReducer(_store.asyncReducers);
      store.replaceReducer(reducer as any);
      if (_store.dispatch) {
        _store?.persistor && _store.persistor.persist();
      }
    } else if (reducerKey in asyncReducers && action === 'add') {
      _store.asyncReducers[reducerKey] = (asyncReducers as any)[reducerKey];
      let reducer = createReducer(_store.asyncReducers);
      store.replaceReducer(reducer as any);
      if (_store.dispatch) {
        _store?.persistor && _store.persistor.persist();
      }
    } else {
      console.log('Reducer Key not found...');
    }
  } catch (error: any) {
    console.log('Inject Reducer Error:', error.message);
  }
};

export const persistor = persistStore(store as any);
// _store.persistor = persistor;
export default store;
