import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import _ from 'lodash';
import { AuthRoles } from '../../../config';
import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { Roles } from 'config/authRoles';

export const useSelectUserRoles = (): Array<Roles> => {
  return useSelector((state: any) => state[ReducerKeys.AUTH_REDUCER].roles);
};

export const useSelectUserData = () => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].data
  );
  return data;
};
 

export const useSelectAuthStatus = () => {
  const status = useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].status
  );
  return status;
};
