import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import {
  AppGroupText,
  AppMultipleSelect,
  AppSelect,
  AppDatePicker,
  AppTextField,
  AppRadioGroup,
} from 'components/molecules/form_controls';
import { Control, Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import TranslatedText from 'components/atoms/translated_text';
import { useDispatch } from 'react-redux';
import { AuthRoles, Gender } from 'config';
import GridLayout from 'components/molecules/grid_layout';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';

interface IFormData {
  state: string | null;
  email: string | null;
  zip: string | null;
  password: string | null;
  city: string | null;
  address: string | null;
  address2: string | null;
}

// add types or interface
const defaultValues = {
  state: null,
  email: null,
  zip: null,
  password: null,
  city: null,
  address: null,
  address2: null,
};

export default function RegisterForm(): ReactElement {
  console.log('Render RegisterForm:');

  const classes = useStyles();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (_data: any) => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_FORM_WRAPPER,
        params: {
          data: _data,
          title: 'Grid Form Output',
        },
      })
    );
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <GridLayout
        justify="flex-start"
        elements={[
          {
            id: 'email',
            element: (
              <AppTextField
                variant="outlined"
                margin="normal"
                size="small"
                error={'email' in errors}
                helperText={errors.email && errors.email.message}
                rules={{}}
                required
                fullWidth
                id="email"
                control={control}
                label={<TranslatedText defaultText={'Email'} />}
                autoComplete="email"
                name="email"
              />
            ),
            size: 6,
          },
          {
            id: 'password',
            element: (
              <AppTextField
                variant="outlined"
                size="small"
                margin="normal"
                error={'password' in errors}
                helperText={errors.password && errors.password.message}
                rules={{}}
                required
                fullWidth
                id="password"
                label={<TranslatedText defaultText={'Password'} />}
                control={control}
                autoComplete="password"
                name="password"
              />
            ),
            size: 6,
          },
          {
            id: 'address',
            element: (
              <AppTextField
                variant="outlined"
                required
                size="small"
                margin="normal"
                fullWidth
                control={control}
                error={'address' in errors}
                rules={{}}
                label={<TranslatedText defaultText={'Address'} />}
                helperText={errors.address && errors.address.message}
                name="address"
                type="address"
                id="address"
              />
            ),
            size: 12,
          },
          {
            id: 'address2',
            element: (
              <AppTextField
                variant="outlined"
                required
                size="small"
                margin="normal"
                fullWidth
                control={control}
                error={'address2' in errors}
                rules={{}}
                label={<TranslatedText defaultText={'Address2'} />}
                helperText={errors.address2 && errors.address2.message}
                name="address2"
                type="address2"
                id="address2"
              />
            ),
            size: 12,
          },
          {
            id: 'city',
            element: (
              <AppTextField
                variant="outlined"
                required
                margin="normal"
                size="small"
                fullWidth
                name="city"
                error={'city' in errors}
                label={<TranslatedText defaultText={'City'} />}
                helperText={errors.city && errors.city.message}
                id="city"
                control={control}
                rules={{}}
              />
            ),
            size: 5,
          },
          {
            id: 'zip',
            element: (
              <AppTextField
                variant="outlined"
                required
                margin="normal"
                size="small"
                fullWidth
                name="zip"
                error={'zip' in errors}
                label={<TranslatedText defaultText={'Zip'} />}
                helperText={errors.zip && errors.zip.message}
                id="zip"
                control={control}
                rules={{}}
              />
            ),
            size: 3,
          },
          {
            id: 'state',
            element: (
              <AppSelect
                variant="outlined"
                required
                fullWidth
                margin="normal"
                size="small"
                id="state"
                options={[
                  {
                    id: 'state_1',
                    label: 'State1',
                  },
                  {
                    id: 'state_2',
                    label: 'State2',
                  },
                  {
                    id: 'state_3',
                    label: 'State3',
                  },
                  {
                    id: 'state_4',
                    label: 'State4',
                  },
                ]}
                label={<TranslatedText defaultText={'State'} />}
                name="state"
                autoComplete="state"
                control={control}
                error={'state' in errors}
                helperText={errors.state && errors.state.message}
                rules={{}}
              />
            ),
            size: 4,
          },
          {
            id: 'submit',
            md: 6,
            element: (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <SubmitWrapper
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                >
                  <TranslatedText defaultText={'Submit'} />
                </SubmitWrapper>
              </Box>
            ),
            size: 3,
          },
        ]}
      />
    </form>
  );
}

const RequiredField = () => {
  return <TranslatedText defaultText={'Required Value'} />;
};

interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  return <Button {...props} color="primary" />;
};
