import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  elementContainer: {
    marginBottom: 15,
    padding: '1.2rem',
  },
  title: {
    marginBottom: 15,
  },
}));

export default useStyles;
