import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import InfoRounded from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useForm } from 'react-hook-form';
import {
  AppTextField,
  AppSelect,
  AppSwitch,
  AppDatePicker,
} from '../../../../components/molecules/form_controls';
import TranslatedText from '../../../../components/atoms/translated_text';

import { useTheme } from '@material-ui/core/styles';
import {
  useGetModal,
  useGetProductsList,
} from '../../../../redux/reducers/userproducts_reducer/selectors';
import Actions from '../../../../redux/reducers/userproducts_reducer/actions';
import useStyles from '../../styles';
import { useSelectUserData } from '../../../../redux/reducers/gl_auth_reducer/selectors';
import { useGetSelectedScenario } from '../../../../redux/reducers/scenario_reducer/selectors';

import { Environment } from 'config';

interface IFormData {
  quantity: number | null;
  suggested: number | null;
  productCost: number | null;
  product: string | null;
}
const defaultValues: IFormData = {
  quantity: null,
  productCost: null,
  suggested: null,
  product: '607942892aa77f1b5465708c',
};

export default function ResponsiveDialog() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const productsList = useGetProductsList();
  const [productOption, setProductOption] = useState([]);
  const { isOpen, data, type } = useGetModal();
  const { id } = useSelectUserData();
  const selectedScenario = useGetSelectedScenario();

  const handleClose = () => {
    dispatch(
      Actions.setModalAction({ isOpen: false, type: 'add', data: null })
    );
    reset(defaultValues);
  };

  useEffect(() => {
    if (productsList.status === 'Success') {
      setProductOption(
        productsList?.data.map((item: any) => {
          return {
            id: item._id,
            label: item.productCategory,
          };
        })
      );
    }
  }, [productsList]);

  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });
  useEffect(() => {
    if (type === 'edit' && data) {
      reset({
        quantity: data.quantity || null,
        suggested: data.suggested || null,
        productCost: data.productCost || null,
        product: data.product._id || null,
      });
    }
    return () => {
      reset(defaultValues);
    };
  }, [data, type]);

  useEffect(() => {
    if (data && type === 'edit') {
      const product: { id: string; label: string } | any = productOption.find(
        (item: any) => item.id === data.product._id
      );
      product &&
        reset({
          ...getValues(),
          productCost: data.productCost,
          quantity: data.quantity,
          // product: product?.id,
        });
    } else if (data && type === 'add') {
      const product: { id: string; label: string } | any = productOption.find(
        (item: any) => item.id === data._id
      );
      product &&
        reset({
          ...getValues(),
          product: product?.id,
        });
    }
  }, [data]);

  useEffect(() => {
    if (type === 'add' && productsList.status === 'Success') {
      const productFilter = productsList.data.filter((item: any) => {
        return item._id === watch('product');
      });
      const productCost =
        productFilter.length !== 0 ? productFilter[0].productCost : 0;
      reset({
        ...watch(),
        productCost,
      });
    }
  }, [watch('product'), productsList, type]);

  const onSubmit = (_data: IFormData) => {
    type === 'add'
      ? dispatch(
          Actions.addUserProductAction({
            user: id,
            scenario: selectedScenario,
            ..._data,
          })
        )
      : dispatch(
          Actions.updateUserProductAction({
            id: data._id,
            scenario: selectedScenario,
            ..._data,
          })
        );

    dispatch(
      Actions.setModalAction({
        isOpen: false,
        type: 'add',
        data: null,
      })
    );
    reset(defaultValues);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      classes={{ paper: classes.modal }}
      aria-labelledby="responsive-dialog-title"
    >
      {/* <DialogTitle id="responsive-dialog-title">
        {type === 'add' ? 'Add New User Product' : 'Edit User Product'}
      </DialogTitle> */}
      <DialogContent>
        <Box marginBottom="20px">
          <Typography variant="body1">
            You have selected: {data?.productCategory} ({data?.productType})
          </Typography>
        </Box>
        <Box marginBottom="30px">
          <Typography variant="body1">
            Please enter the number numbers of {data?.productType} consumed
            annually
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body1">
              Additionally, please enter cost per {data?.productType}, in EUR
              (if not known, use average cost provided)
            </Typography>
            <Box marginLeft="10px">
              <Tooltip
                placement="right-end"
                title="The unit costs provided are based on average unit costs (EUR) of items from retailers and wholesalers across Europe. Please edit the unit cost as needed to reflect your circumstances more accurately."
              >
                <InfoRounded />
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <form
          // className={classes.modal}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container>
            <Grid className={classes.gridPane} item xs={12} md={2}>
              {data?.picture?.path ? (
                <img
                  height="150px"
                  width="150px"
                  src={`${Environment.API_BASE_URL}/${data?.picture?.path}`}
                />
              ) : (
                <img
                  height="150px"
                  width="150px"
                  src={require('../../../../assets/images/no-image-available.png')}
                />
              )}
            </Grid>
            {type === 'add' && (
              <Grid className={classes.gridPane} item xs={12} md={2}>
                <Box width="90%" height="46px">
                  <InputLabel>Product</InputLabel>
                  <AppSelect
                    variant="outlined"
                    error={Boolean(_.get(errors, 'product', false))}
                    disabled
                    // helperText={_.get(errors, 'product', '')}
                    rules={{
                      required: <RequiredField />,
                    }}
                    inputProps={{
                      className: classes.input,
                    }}
                    InputProps={{
                      classes: {
                        input: classes.input,
                        root: classes.input,
                      },
                    }}
                    fullWidth
                    options={productOption}
                    id={'product'}
                    control={control}
                    // label="Product"
                    autoComplete={'product'}
                    name={'product'}
                  />
                </Box>
              </Grid>
            )}
            <Grid className={classes.gridPane} item xs={12} md={3}>
              {data && type === 'edit' && data.usageCategory !== 'SUP' ? (
                <Box width="90%" height="46px">
                  <AppTextField
                    variant="outlined"
                    type="number"
                    error={Boolean(_.get(errors, 'suggested', false))}
                    helperText={errors.suggested ? 'suggested is required' : ''}
                    inputProps={{
                      className: classes.cardLayout,
                      type: 'number',
                    }}
                    rules={{
                      required: <RequiredField />,
                    }}
                    fullWidth
                    id={'suggested'}
                    control={control}
                    label="Suggested"
                    autoComplete={'suggested'}
                    name={'suggested'}
                  />
                </Box>
              ) : (
                <Box width="90%" height="46px">
                  <InputLabel>Items consumed annually</InputLabel>
                  <AppTextField
                    variant="outlined"
                    autoFocus
                    type="number"
                    error={Boolean(_.get(errors, 'quantity', false))}
                    helperText={
                      errors.quantity
                        ? 'Items consumed annually is required'
                        : ''
                    }
                    inputProps={{
                      className: classes.cardLayout,
                      type: 'number',
                    }}
                    rules={{
                      required: <RequiredField />,
                    }}
                    fullWidth
                    id={'quantity'}
                    control={control}
                    // label="Items consumed annually"
                    autoComplete={'quantity'}
                    name={'quantity'}
                  />
                </Box>
              )}
            </Grid>

            <Grid className={classes.gridPane} item xs={12} md={2}>
              <Box width="90%" height="46px">
                <InputLabel>Cost per item (EUR)</InputLabel>
                <AppTextField
                  variant="outlined"
                  type="number"
                  inputProps={{
                    className: classes.cardLayout,
                    type: 'number',
                    inputmode: 'numeric',
                    pattern: '[0-9]*',
                    step: '0.0001',
                  }}
                  error={Boolean(_.get(errors, 'productCost', false))}
                  helperText={
                    errors.productCost ? 'Cost per item (EUR) is required' : ''
                  }
                  className={clsx(classes.cardLayout)}
                  rules={{
                    required: <RequiredField />,
                  }}
                  fullWidth
                  id={'productCost'}
                  control={control}
                  // label="Cost per item (EUR)"
                  autoComplete={'productCost'}
                  name={'productCost'}
                />
              </Box>
            </Grid>
            <Grid className={classes.gridPane} item xs={12} md={3}>
              <Box display="flex" width="100%" marginTop="32px">
                <Button
                  className={clsx(
                    classes.modalBtn,
                    classes['mr-12'],
                    classes.addBtn
                  )}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {type === 'add' ? 'Add' : 'Save'}
                </Button>
                <Button
                  className={clsx(
                    classes.modalBtn,
                    classes['mr-12'],
                    classes.cancelBtn
                  )}
                  onClick={() => {
                    dispatch(
                      Actions.setModalAction({
                        isOpen: false,
                        type: 'add',
                        data: null,
                      })
                    );
                    reset(defaultValues);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* {type === 'add' && (
            <AppSelect
              variant="outlined"
              error={Boolean(_.get(errors, 'product', false))}
              // helperText={_.get(errors, 'product', '')}
              className={clsx(classes.cardLayout)}
              rules={{
                required: <RequiredField />,
              }}
              fullWidth
              options={productOption}
              id={'product'}
              control={control}
              label="Product"
              autoComplete={'product'}
              name={'product'}
            />
          )} */}
          {/* {data && type === 'edit' && data.usageCategory !== 'SUP' ? (
            <AppTextField
              variant="outlined"
              type="number"
              error={Boolean(_.get(errors, 'suggested', false))}
              helperText={errors.suggested ? 'suggested is required' : ''}
              className={clsx(classes.cardLayout)}
              rules={{
                required: <RequiredField />,
              }}
              fullWidth
              id={'suggested'}
              control={control}
              label="Suggested"
              autoComplete={'suggested'}
              name={'suggested'}
            />
          ) : (
            <AppTextField
              variant="outlined"
              type="number"
              error={Boolean(_.get(errors, 'quantity', false))}
              helperText={errors.quantity ? 'Quantity is required' : ''}
              className={clsx(classes.cardLayout)}
              rules={{
                required: <RequiredField />,
              }}
              fullWidth
              id={'quantity'}
              control={control}
              label="Quantity"
              autoComplete={'quantity'}
              name={'quantity'}
            />
          )}
          <AppTextField
            variant="outlined"
            type="number"
            error={Boolean(_.get(errors, 'productCost', false))}
            helperText={errors.productCost ? 'ProductCost is required' : ''}
            className={clsx(classes.cardLayout)}
            rules={{
              required: <RequiredField />,
            }}
            fullWidth
            id={'productCost'}
            control={control}
            label="Product Cost"
            autoComplete={'productCost'}
            name={'productCost'}
          />

          <Button
            className={classes['mr-12']}
            variant="contained"
            color="primary"
            type="submit"
          >
            {type === 'add' ? 'Add' : 'Save'}
          </Button>
          <Button
            onClick={() => {
              reset(defaultValues);
              dispatch(
                Actions.setModalAction({
                  isOpen: false,
                  type: 'add',
                  data: null,
                })
              );
            }}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button> */}
        </form>
      </DialogContent>
    </Dialog>
  );
}

const RequiredField = () => {
  return <TranslatedText defaultText={'Required Value'} />;
};
