import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import {
  AppGroupText,
  AppMultipleSelect,
  AppSelect,
  AppDatePicker,
  AppTextField,
  AppRadioGroup,
} from 'components/molecules/form_controls';
import { Control, Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useStyles from './styles';
import TranslatedText from 'components/atoms/translated_text';
// import Configuration, { Translations } from '../../configuration';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useDispatch } from 'react-redux';
import { AuthRoles, Gender } from 'config';
import GridLayout from 'components/molecules/grid_layout';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';

interface IFormData {
  password_1: string | null;
  confirmPassword_1: string | null;
  email_1: string | null;
}

// add types or interface
const defaultValues: IFormData = {
  email_1: null,
  password_1: null,
  confirmPassword_1: null,
};

export default function RegisterForm(): ReactElement {
  console.log('Render RegisterForm:');

  const classes = useStyles();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (_data: any) => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_FORM_WRAPPER,
        params: {
          data: _data,
          title: 'Validate Form Output',
        },
      })
    );
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        control={control}
        error={'password_1' in errors}
        autoComplete="confirmPassword_1"
        rules={{
          required: <RequiredField />,
          minLength: {
            value: 8,
            message: 'Password must have at least 8 characters',
          },
        }}
        label={<TranslatedText defaultText={'Password'} />}
        helperText={errors.password_1 && errors.password_1.message}
        name="password_1"
        type="password"
        id="password_1"
        icon={{
          position: 'right',
          Component: <LockOutlinedIcon />,
        }}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="confirmPassword_1"
        error={'confirmPassword_1' in errors}
        label={<TranslatedText defaultText={'Confirm Password'} />}
        helperText={errors.confirmPassword_1 && errors.confirmPassword_1.message}
        type="password"
        id="confirmPassword_1"
        autoComplete="confirmPassword_1"
        control={control}
        rules={{
          required: <RequiredField />,
          validate: () =>
            getValues('password_1') === getValues('confirmPassword_1') ||
            'The passwords do not match',
        }}
        icon={{
          position: 'right',
          Component: <LockOutlinedIcon />,
        }}
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email_1"
        label={<TranslatedText defaultText={'Email Address'} />}
        name="email_1"
        autoComplete="email_1"
        control={control}
        error={'email_1' in errors}
        helperText={errors.email_1 && errors.email_1.message}
        rules={{
          required: <RequiredField />,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Enter a valid e-mail address',
          },
        }}
        icon={{
          position: 'right',
          Component: <EmailOutlinedIcon />,
        }}
      />

      <GridLayout
        justify="flex-start"
        elements={[
          {
            size: 3,
            id: 'submit',
            element: (
              <SubmitWrapper
                type="submit"
                variant="contained"
                className={classes.submit}
                color="secondary"
                fullWidth
              >
                <TranslatedText defaultText={'Submit'} />
              </SubmitWrapper>
            ),
          },
        ]}
      />
    </form>
  );
}

const RequiredField = () => {
  return <TranslatedText defaultText={'Required Value'} />;
};

interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  return <Button {...props} color="primary" />;
};
