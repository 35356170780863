import { LanguagesEnum } from '../../config/languages';

const Configuration = {
  backgroundImage: require('../../assets/images/backgrounds/background_forgot_password.jpg'),
};

export const Translations = {
  recover_title: {
    [LanguagesEnum.English]: 'Change Password',
    [LanguagesEnum.Greek]: 'Αλλαγή Κωδικού',
  },
  form_field_email: {
    [LanguagesEnum.English]: 'Username',
    [LanguagesEnum.Greek]: 'Username',
  },
  form_button_recover: {
    [LanguagesEnum.English]: 'Recover',
    [LanguagesEnum.Greek]: 'Επαναφορά',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'This is required',
    [LanguagesEnum.Greek]: 'Υποχρεωτικό πεδίο',
  },
  back_to_login: {
    [LanguagesEnum.English]: 'Back to login',
    [LanguagesEnum.Greek]: 'Επιστροφή στο Login',
  },
  form_button_register: {
    [LanguagesEnum.English]: 'Submit',
    [LanguagesEnum.Greek]: 'Υποβολή',
  },  
};

export default Configuration;
