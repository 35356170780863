import React from 'react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import Datatable from 'components/molecules/datatable';
import { Environment } from 'config';
import { SelectProductTableColumns } from '../../configuration';
import * as UserProductsReducer from '../../../../redux/reducers/userproducts_reducer/selectors';
import Actions from '../../../../redux/reducers/userproducts_reducer/actions';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch } from 'react-redux';
import UserProductsActions from '../../../../redux/reducers/userproducts_reducer/actions';
import {
  Box,
  Button,
  Card,
  Typography,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import Delete from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';
import { AnyARecord } from 'dns';
import { classNames } from 'react-select/src/utils';

const useStyles = makeStyles((theme) => ({
  outlined: {
    border: 0,
  },
  cardContentRoot: {
    padding: 0,
  },
}));

export default function DatatableList({ handleChangeStep1 }: any) {
  const result = UserProductsReducer.useGetProductsList();
  const userProductsData = UserProductsReducer.useGetUserProductsList();
  const classes = useStyles();

  const dispatch = useDispatch();
  const [userProducts, setUserProducts] = React.useState<any>([]);
  const [data, setData] = React.useState<any>([]);

  React.useEffect(() => {
    if (result.status === 'Success') {
      if (userProductsData.data?.items.length !== 0) {
        const selectedSupProducts = userProductsData.data?.items.filter(
          (item: any) => {
            if (item.usageCategory === 'SUP' && item.selected) {
              return true;
            } else {
              return false;
            }
          }
        );

        selectedSupProducts && setUserProducts(selectedSupProducts);
      } else {
        setUserProducts([]);
      }
      // const selectedProducts = result.data
      //   ?.filter((item: any) => item.selected)
      //   .map((item: any) => item.productCategory);
    }
    if (result.data && result.data.length !== 0) {
      const supProducts = result.data;
      const sortedSUPProducts = _.sortBy(supProducts, 'productCategory');
      setData(sortedSUPProducts);
    }
  }, [result.data, userProductsData.data?.items]);

  const handleDelete = (id: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: { isConfirmed: boolean }) => {
      if (result.isConfirmed) {
        dispatch(Actions.deleteUserProductAction({ id }));
      }
    });
  };

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column">
        <Card>
          <CardContent classes={{ root: classes.cardContentRoot }}>
            <Box
              width="100%"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginLeft: '20px',
                }}
              >
                <Typography style={{ fontWeight: 500 }}>SUP Product</Typography>
                <Typography
                  style={{ fontWeight: 400, color: '#959CA0' }}
                  variant="body2"
                >
                  Select the single-use plastic product you would like to
                  replace:
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end" margin="13px 13px">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: 'unset',
                    marginRight: 15,
                    height: 40,
                    fontSize: 16,
                    backgroundColor: '#D9A21B',
                  }}
                  onClick={() => handleChangeStep1(1)}
                >
                  {'>'} Explore alternatives
                </Button>
              </Box>
            </Box>
            {data.length > 0 && (
              <Datatable
                loading={true}
                data={data}
                columns={SelectProductTableColumns}
                paperProps={{
                  classes: {
                    outlined: classes.outlined,
                  },
                }}
              />
            )}
          </CardContent>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <CardContent>
            <Box style={{ marginBottom: 20 }}>
              <Typography style={{ fontWeight: 500, fontSize: '18px' }}>
                Added products
              </Typography>
            </Box>
            {userProducts.map((item: any) => {
              return (
                <Box key={item._id} marginBottom="20px">
                  <Box>
                    <Typography style={{ fontSize: '16px' }}>
                      {item.productCategory}
                    </Typography>
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    padding="20px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box marginRight="30px">
                      {item.picture ? (
                        <img
                          height="50px"
                          width="50px"
                          src={`${Environment.API_BASE_URL}/${item.picture.path}`}
                        />
                      ) : (
                        <img
                          height="50px"
                          width="50px"
                          src={require('../../../../assets/images/no-image-available.png')}
                        />
                      )}
                    </Box>
                    <Typography>{item.productType}</Typography>
                    <Typography>Annual usage: {item.quantity}</Typography>
                    <Typography>
                      Cost per item (EUR): €{item.productCost}
                    </Typography>

                    <Box
                      display="flex"
                      justifyContent="space-around"
                      minWidth="80px"
                    >
                      <Delete
                        onClick={() => handleDelete(item._id)}
                        color="error"
                      />
                      <Edit
                        onClick={() => {
                          dispatch(
                            Actions.setModalAction({
                              isOpen: true,
                              type: 'edit',
                              data: item,
                            })
                          );
                        }}
                      />
                    </Box>
                  </Box>
                  {/* </CardContent> */}
                  {/* </Card> */}
                </Box>
              );
            })}
          </CardContent>
        </Card>
      </Box>
    </React.Fragment>
  );
}
