import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as ScenarioSelectors from 'redux/reducers/scenario_reducer/selectors';
import { useGetModal } from 'redux/reducers/scenario_reducer/selectors';
import { AppTextField } from 'components/molecules/form_controls';
import { StateStatus } from 'redux/utils/common';
import { Translations } from '../../configuration';
import SetupProfile from '../setup_profile';
import useStyles from './styles';

export default function ScenarioModal() {
  const modal = useGetModal();
  const classes = useStyles();
  return (
    <Dialog open={modal.isOpen}>
      <DialogContent className={classes.dialogContent}>
        <SetupProfile />
      </DialogContent>
    </Dialog>
  );
}

interface IModalProps {
  type: 'add' | 'edit' | 'view';
  id: string | number;
}

const ScenarioHeader = ({ type, id }: IModalProps) => {
  console.log('Header::', type);
  return (
    <TranslatedText
      textMap={
        type === 'add'
          ? Translations.ScenarioModalAddTitle
          : type === 'edit'
          ? Translations.ScenarioModalEditTitle
          : Translations.ScenarioModalViewTitle
      }
      defaultText={type === 'add' ? 'Add' : type === 'edit' ? 'Edit' : 'View'}
    />
  );
};

interface FormTypes {
  type: string | null;
  totalnumberofguestsperyear: string | null;
  totalnumberofrooms: string | null;
  hotelguestcapacity: string | null;
  restaurantcafecapacity: string | null;
  totalnumberoftablesacrossallrestaurant: string | null;
  beachbarcapacity: string | null;
  totalnumberoftables: string | null;
  totalcapacity: string | null;
  user: string | null;
}

const defaultValues = {
  type: null,
  totalnumberofguestsperyear: null,
  totalnumberofrooms: null,
  hotelguestcapacity: null,
  restaurantcafecapacity: null,
  totalnumberoftablesacrossallrestaurant: null,
  beachbarcapacity: null,
  totalnumberoftables: null,
  totalcapacity: null,
  user: null,
};

const ScenarioForm = React.memo(({ type, id }: IModalProps) => {
  console.log('Render ScenarioForm:');
  const { status, data } = ScenarioSelectors.useGetScenarioDetails();

  const classes = useStyles();
  const { reset, watch, errors, control, handleSubmit } = useForm<FormTypes>({
    defaultValues,
  });
  const dispatch = useDispatch();

  const onSubmit = (_data: FormTypes) => {
    // switch (type) {
    //   case 'add':
    //     dispatch(
    //       ScenarioActions.addNewScenarioAction({
    //         type: _data.type || null,
    //         totalnumberofguestsperyear:
    //           _data.totalnumberofguestsperyear || null,
    //         totalnumberofrooms: _data.totalnumberofrooms || null,
    //         hotelguestcapacity: _data.hotelguestcapacity || null,
    //         restaurantcafecapacity: _data.restaurantcafecapacity || null,
    //         totalnumberoftablesacrossallrestaurant:
    //           _data.totalnumberoftablesacrossallrestaurant || null,
    //         beachbarcapacity: _data.beachbarcapacity || null,
    //         totalnumberoftables: _data.totalnumberoftables || null,
    //         totalcapacity: _data.totalcapacity || null,
    //         user: _data.user || null,
    //         fetchScenarioInput: {
    //           sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
    //           page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
    //           pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
    //           order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
    //         },
    //       })
    //     );
    //     break;
    //   case 'edit':
    //     dispatch(
    //       ScenarioActions.editScenarioAction({
    //         type: _data.type || null,
    //         totalnumberofguestsperyear:
    //           _data.totalnumberofguestsperyear || null,
    //         totalnumberofrooms: _data.totalnumberofrooms || null,
    //         hotelguestcapacity: _data.hotelguestcapacity || null,
    //         restaurantcafecapacity: _data.restaurantcafecapacity || null,
    //         totalnumberoftablesacrossallrestaurant:
    //           _data.totalnumberoftablesacrossallrestaurant || null,
    //         beachbarcapacity: _data.beachbarcapacity || null,
    //         totalnumberoftables: _data.totalnumberoftables || null,
    //         totalcapacity: _data.totalcapacity || null,
    //         user: _data.user || null,
    //         fetchScenarioInput: {
    //           sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
    //           page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
    //           pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
    //           order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
    //         },
    //       })
    //     );
    //     break;
    // }
  };

  // React.useEffect(() => {
  //   if (['edit', 'view'].includes(type)) {
  //     dispatch(
  //       ScenarioActions.viewScenarioAction({
  //         : typeof id === 'string' ? parseInt(id) : id,
  //       })
  //     );
  //   }
  // }, [dispatch, id, type]);

  React.useEffect(() => {
    if (status === StateStatus.Success && type !== 'add') {
      reset({
        type: data?.type || null,
        totalnumberofguestsperyear: data?.totalnumberofguestsperyear || null,
        totalnumberofrooms: data?.totalnumberofrooms || null,
        hotelguestcapacity: data?.hotelguestcapacity || null,
        restaurantcafecapacity: data?.restaurantcafecapacity || null,
        totalnumberoftablesacrossallrestaurant:
          data?.totalnumberoftablesacrossallrestaurant || null,
        beachbarcapacity: data?.beachbarcapacity || null,
        totalnumberoftables: data?.totalnumberoftables || null,
        totalcapacity: data?.totalcapacity || null,
        user: data?.user || null,
      });
    }
  }, [status, dispatch, type]);

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="type"
        label={<TranslatedText defaultText={'type'} />}
        error={'type' in errors}
        helperText={errors.type && errors.type.message}
        rules={{ required: 'Required Field' }}
        name="type"
        autoComplete="type"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="totalnumberofguestsperyear"
        label={<TranslatedText defaultText={'totalnumberofguestsperyear'} />}
        error={'totalnumberofguestsperyear' in errors}
        helperText={
          errors.totalnumberofguestsperyear &&
          errors.totalnumberofguestsperyear.message
        }
        rules={{ required: 'Required Field' }}
        name="totalnumberofguestsperyear"
        autoComplete="totalnumberofguestsperyear"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="totalnumberofrooms"
        label={<TranslatedText defaultText={'totalnumberofrooms'} />}
        error={'totalnumberofrooms' in errors}
        helperText={
          errors.totalnumberofrooms && errors.totalnumberofrooms.message
        }
        rules={{ required: 'Required Field' }}
        name="totalnumberofrooms"
        autoComplete="totalnumberofrooms"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="hotelguestcapacity"
        label={<TranslatedText defaultText={'hotelguestcapacity'} />}
        error={'hotelguestcapacity' in errors}
        helperText={
          errors.hotelguestcapacity && errors.hotelguestcapacity.message
        }
        rules={{ required: 'Required Field' }}
        name="hotelguestcapacity"
        autoComplete="hotelguestcapacity"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="restaurantcafecapacity"
        label={<TranslatedText defaultText={'restaurantcafecapacity'} />}
        error={'restaurantcafecapacity' in errors}
        helperText={
          errors.restaurantcafecapacity && errors.restaurantcafecapacity.message
        }
        rules={{ required: 'Required Field' }}
        name="restaurantcafecapacity"
        autoComplete="restaurantcafecapacity"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="totalnumberoftablesacrossallrestaurant"
        label={
          <TranslatedText
            defaultText={'totalnumberoftablesacrossallrestaurant'}
          />
        }
        error={'totalnumberoftablesacrossallrestaurant' in errors}
        helperText={
          errors.totalnumberoftablesacrossallrestaurant &&
          errors.totalnumberoftablesacrossallrestaurant.message
        }
        rules={{ required: 'Required Field' }}
        name="totalnumberoftablesacrossallrestaurant"
        autoComplete="totalnumberoftablesacrossallrestaurant"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="beachbarcapacity"
        label={<TranslatedText defaultText={'beachbarcapacity'} />}
        error={'beachbarcapacity' in errors}
        helperText={errors.beachbarcapacity && errors.beachbarcapacity.message}
        rules={{ required: 'Required Field' }}
        name="beachbarcapacity"
        autoComplete="beachbarcapacity"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="totalnumberoftables"
        label={<TranslatedText defaultText={'totalnumberoftables'} />}
        error={'totalnumberoftables' in errors}
        helperText={
          errors.totalnumberoftables && errors.totalnumberoftables.message
        }
        rules={{ required: 'Required Field' }}
        name="totalnumberoftables"
        autoComplete="totalnumberoftables"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="totalcapacity"
        label={<TranslatedText defaultText={'totalcapacity'} />}
        error={'totalcapacity' in errors}
        helperText={errors.totalcapacity && errors.totalcapacity.message}
        rules={{ required: 'Required Field' }}
        name="totalcapacity"
        autoComplete="totalcapacity"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="user"
        label={<TranslatedText defaultText={'user'} />}
        error={'user' in errors}
        helperText={errors.user && errors.user.message}
        rules={{ required: 'Required Field' }}
        name="user"
        autoComplete="user"
        control={control}
      />

      {type !== 'view' && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <SubmitWrapper
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            <TranslatedText
              textMap={Translations.addEditModalButton}
              defaultText={'Submit'}
            />
          </SubmitWrapper>
        </Box>
      )}
    </form>
  );
});

const SubmitWrapper = (props: ButtonProps) => {
  return <Button {...props} />;
};
