import { Box, Card, CardContent, Container, Grid } from '@material-ui/core';
import React from 'react';
import { useParams, withRouter } from 'react-router'; // import the react-router-dom components
import { Link } from 'react-router-dom';
import { ReducerKeys } from 'redux/config';
import { withReducer } from 'redux/withReducer';
import LanguageDropdown from '../../components/app_common/language_dropdown';
import TranslatedText from '../../components/atoms/translated_text';
import NavigationConfig from '../../navigation/config';
import ResetPasswordForm from './components/reset_password_form';
import { Translations } from './configuration';
import useStyles from './styles';

interface IProps {}

const ResetPassword: React.FC<IProps> = (props) => {
  console.log('Render ForgotPassword');
  const classes = useStyles();
  const { token } = useParams<any>();
  // (props as any).match.params;
  return (
    <Box
      style={{
        backgroundColor: '#356CB0',
        height: '100vh',
        position: 'relative',
      }}
    >
      <Container maxWidth="lg">
        <div className={classes.languageButton}>
          <LanguageDropdown />
        </div>
        <Grid
          className={classes.cardViewContainer}
          justify="center"
          spacing={5}
          container
        >
          <Card
            style={{
              padding: '20px 40px',
              marginTop: 50,
              zIndex: 1,
            }}
            variant={'outlined'}
          >
            <CardContent>
              <ResetPasswordForm token={token} />
              <div className={classes.layoutForgot}>
                <Link
                  className="font-medium"
                  to={NavigationConfig.loginPage().path}
                >
                  <TranslatedText textMap={Translations.back_to_login} />
                </Link>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Container>
      <img
        className={classes.curveImageBg}
        src={require('assets/images/backgrounds/curve_background.png')}
      />
    </Box>
  );
};

// export default withRouter(ResetPassword);
const Page = withRouter(ResetPassword);
export default withReducer(ReducerKeys.RESET_PASSOWRD_REDUCER)(Page);
