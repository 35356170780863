export enum DaysEnum {
  Sunday = '0',
  Monday = '1',
  Tuesday = '2',
  Wednesday = '3',
  Thursday = '4',
  Friday = '5',
  Saturday = '6',
}
const Days = [
  {
    id: DaysEnum.Sunday,
    label: 'Sunday',
  },
  {
    id: DaysEnum.Monday,
    label: 'Monday',
  },
  {
    id: DaysEnum.Tuesday,
    label: 'Tuesday',
  },
  {
    id: DaysEnum.Wednesday,
    label: 'Wednesday',
  },
  {
    id: DaysEnum.Thursday,
    label: 'Thursday',
  },
  {
    id: DaysEnum.Friday,
    label: 'Friday',
  },
  {
    id: DaysEnum.Saturday,
    label: 'Saturday',
  },
];

export default Days;
