import { LanguagesEnum } from 'config/languages';
import {
  ChartTypes,
  GenericChartTypes,
  LegendPosition,
} from 'components/compounds/dashboard_builder/plugins/types/chart';
import {
  TBuilderTypes,
  TBuilderSize,
  IDashboard,
} from 'components/compounds/dashboard_builder/plugins/types';
import { ChartTypesEnum } from 'components/molecules/custom_chart';

const CONFIGURATION = {};

export const Translations = {};

export interface IChartGenericTypesElement {
  label: string;
  id: GenericChartTypes;
}

export const GenericChartTypesArr: Array<IChartGenericTypesElement> = [
  {
    id: 'combo-bar-line',
    label: 'Combo (Bar & Line)',
  },
  {
    id: 'bubble',
    label: 'Bubble',
  },
  {
    id: 'doughnut',
    label: 'Doughnut',
  },
  {
    id: 'pie',
    label: 'Pie',
  },
  {
    id: 'scatter',
    label: 'Scatter',
  },
];

// export const ChartTypesArr: Array<IChartTypesElement> = [
//   {
//     id: 'bar',
//     label: 'Bar',
//   },
//   {
//     id: 'line',
//     label: 'Line',
//   },
// ];

interface IHeightArr {
  label: string;
  id: string;
}

export const HeightArr: Array<IHeightArr> = [
  {
    id: '300px',
    label: 'Small',
  },
  {
    id: '500px',
    label: 'Medium',
  },
  {
    id: '700px',
    label: 'Large',
  },
];

export interface IWidthArr {
  label: string;
  id: TBuilderSize;
}

export const WidthArr: Array<IWidthArr> = [
  {
    id: 1,
    label: '1 of 12',
  },
  {
    id: 2,
    label: '2 of 12',
  },
  {
    id: 3,
    label: '3 of 12',
  },
  {
    id: 4,
    label: '4 of 12',
  },
  {
    id: 5,
    label: '5 of 12',
  },
  {
    id: 6,
    label: '6 of 12',
  },
  {
    id: 7,
    label: '7 of 12',
  },
  {
    id: 8,
    label: '8 of 12',
  },
  {
    id: 9,
    label: '9 of 12',
  },
  {
    id: 10,
    label: '10 of 12',
  },
  {
    id: 11,
    label: '11 of 12',
  },
  {
    id: 12,
    label: '12 of 12',
  },
];

interface ILegendPositionArr {
  id: LegendPosition;
  label: string;
}

export const LegendPositionArr: Array<ILegendPositionArr> = [
  {
    id: 'bottom-center',
    label: 'Bottom Center',
  },
  {
    id: 'bottom-left',
    label: 'Bottom Left',
  },
  {
    id: 'bottom-right',
    label: 'Bottom Right',
  },
  {
    id: 'top-center',
    label: 'Top Center',
  },
  {
    id: 'top-left',
    label: 'Top Left',
  },
  {
    id: 'top-right',
    label: 'Top Right',
  },
];

interface IDatasetsNumberArr {
  id: string;
  label: string;
}

export const DatasetsNumberArr: Array<IDatasetsNumberArr> = [
  {
    id: '1',
    label: '1',
  },
  {
    id: '2',
    label: '2',
  },
  {
    id: '3',
    label: '3',
  },
  {
    id: '4',
    label: '4',
  },
  {
    id: '5',
    label: '5',
  },
];

export interface IBuilderTypesElement {
  label: string;
  id: TBuilderTypes;
}

export const BuilderTypesArr: Array<IBuilderTypesElement> = [
  {
    id: 'chart',
    label: 'Chart',
  },
  {
    id: 'map',
    label: 'Map',
  },
  {
    id: 'statistic-card',
    label: 'Statistic Card',
  },
];

export type Methods = 'GET' | 'POST';
interface IMethodsElem {
  id: Methods;
  label: Methods;
}

export const MethodArr: Array<IMethodsElem> = [
  {
    id: 'GET',
    label: 'GET',
  },
  {
    id: 'POST',
    label: 'POST',
  },
];

interface IChartTypesElement {
  label: string;
  id: ChartTypes;
}

interface IChartTypeSelectorMap {
  bubble: IChartTypesElement[];
  'combo-bar-line': IChartTypesElement[];
  doughnut: IChartTypesElement[];
  pie: IChartTypesElement[];
  scatter: IChartTypesElement[];
}

export const ChartTypeSelectorMap: IChartTypeSelectorMap = {
  'combo-bar-line': [
    {
      id: 'line',
      label: 'Line',
    },
    {
      id: 'bar',
      label: 'Bar',
    },
  ],
  bubble: [
    {
      id: 'bubble',
      label: 'Bubble',
    },
  ],
  doughnut: [
    {
      id: 'doughnut',
      label: 'Doughnut',
    },
  ],
  pie: [
    {
      id: 'pie',
      label: 'Pie',
    },
  ],
  scatter: [
    {
      id: 'scatter',
      label: 'Scatter',
    },
  ],
};

export default CONFIGURATION;

export const DummyDashboardsConfig = [
  {
    label: 'Dashboard1',
    id: 'd1',
  },
  {
    label: 'Dashboard2',
    id: 'd2',
  },
  {
    label: 'Dashboard3',
    id: 'd3',
  },
];

export const DummyDashboardsMap = {
  d1: {
    dashboardElements: [
      {
        type: 'chart',
        config: {
          genericChartType: 'doughnut',
          cardTitle: 'Chart Title',
          legend: {
            visible: false,
            position: 'bottom-center',
          },
          x_axis_field: 'age_numb',
          gridLines: {
            enableX: false,
            enableY: false,
          },
          height: '300px',
          datasets: [
            {
              type: 'doughnut',
              color: '#4730c2',
              border_color: '#2802e6',
              y_axis_field: 'age_numb',
              name: 'First',
            },
            {
              type: 'doughnut',
              color: '#6048e0',
              border_color: '#42922a',
              y_axis_field: 'number_numb',
              name: 'Second',
            },
            {
              type: 'doughnut',
              color: '#8a7eb9',
              border_color: '#87878b',
              y_axis_field: 'number_numb',
              name: 'Third',
            },
          ],
        },
        size: 12,
        url: '/api/users/fakeData/{{dataSize}}/7',
        urlMethod: 'GET',
      },
    ],
    globals: {
      'map.onClick.key': 'id',
      'map.onClick.value': '-',
      'map.zoom.key': 'id',
      'map.zoom.value': '-',
      dataSize: '50',
      newglobals: 'newGlobals',
      TEST_URL: '/api/users/fakeData/{{dataSize}}/7',
    },
  },
  d2: {
    dashboardElements: [
      {
        type: 'chart',
        config: {
          genericChartType: 'combo-bar-line',
          cardTitle: 'Chart Title',
          legend: {
            visible: false,
            position: 'bottom-center',
          },
          x_axis_field: 'age_numb',
          gridLines: {
            enableX: false,
            enableY: false,
          },
          height: '300px',
          datasets: [
            {
              type: 'line',
              color: '#3918e4',
              border_color: '#19cf4a',
              y_axis_field: 'number_numb',
              name: 'First Dataset',
            },
          ],
        },
        size: 12,
        url: '/api/users/fakeData/{{dataSize}}/7',
        urlMethod: 'GET',
      },
    ],
    globals: {
      'map.onClick.key': 'id',
      'map.onClick.value': '-',
      'map.zoom.key': 'id',
      'map.zoom.value': '-',
      dummyGlobal2: '???',
      dataSize: '50',
      TEST_URL: '/api/users/fakeData/{{dataSize}}/7',
    },
  },
  d3: {
    dashboardElements: [
      {
        type: 'chart',
        config: {
          genericChartType: 'pie',
          cardTitle: 'Chart Title',
          legend: {
            visible: false,
            position: 'bottom-center',
          },
          x_axis_field: 'age_numb',
          gridLines: {
            enableX: false,
            enableY: false,
          },
          height: '300px',
          datasets: [
            {
              type: 'pie',
              color: '#3918e4',
              border_color: '#19cf4a',
              y_axis_field: 'number_numb',
              name: 'First Dataset',
            },
          ],
        },
        size: 12,
        url: '/api/users/fakeData/{{dataSize}}/7',
        urlMethod: 'GET',
      },
      {
        type: 'chart',
        config: {
          genericChartType: 'doughnut',
          cardTitle: 'Chart Title',
          legend: {
            visible: false,
            position: 'bottom-center',
          },
          x_axis_field: 'age_numb',
          gridLines: {
            enableX: false,
            enableY: false,
          },
          height: '300px',
          datasets: [
            {
              type: 'doughnut',
              color: '#4730c2',
              border_color: '#2802e6',
              y_axis_field: 'age_numb',
              name: 'First',
            },
            {
              type: 'doughnut',
              color: '#6048e0',
              border_color: '#42922a',
              y_axis_field: 'number_numb',
              name: 'Second',
            },
            {
              type: 'doughnut',
              color: '#8a7eb9',
              border_color: '#87878b',
              y_axis_field: 'number_numb',
              name: 'Third',
            },
          ],
        },
        size: 12,
        url: '/api/users/fakeData/{{dataSize}}/7',
        urlMethod: 'GET',
      },
    ],
    globals: {
      'map.onClick.key': 'id',
      'map.onClick.value': '-',
      'map.zoom.key': 'id',
      'map.zoom.value': '-',
      dataSize: '30',
      dummyGlobal2: '4',
      TEST_URL: '/api/users/fakeData/{{dataSize}}/7',
    },
  },
};
