import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import './tooltip.css';
import { numberWithCommas } from 'utils/strings';

function getRevenueConfig(
  data: any,
  title: string[],
  projectedField: string,
  unit: string,
  id: string,
  color: string
) {
  const lineChart: IGridConf[] = [
    // 'Estimated Reduction in yearly marine litter'
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'line',
        height: 350,

        series: [
          {
            name: title,
            data: data.map((value: number) => Number(value).toFixed(2)),
            color: color || '#599FF4',
          },
        ],
        options: {
          chart: {
            id,
            height: 350,
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            text: title,
            align: 'left',
          },
          grid: {
            row: {
              colors: ['#F2F2F2', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          yaxis: {
            labels: {
              formatter: (value: number) => {
                return `${numberWithCommas(parseFloat(value.toFixed(2)))}`;
              },
            },
            title: {
              text: unit,
            },
          },
          xaxis: {
            categories: ['Year 1', ' Year 2', 'Year 3', 'Year 4', 'Year 5'],
          },
          tooltip: {
            shared: false,
            y: {
              formatter: function (
                value: number,
                { series, seriesIndex, dataPointIndex, w }: any
              ) {
                return value;
              },
              title: {
                formatter: (seriesName: string) =>
                  seriesName.length > 20
                    ? seriesName.substring(0, 20) + ' ...'
                    : seriesName,
              },
            },
          },
        },
      },
      fetch: null,
    },
  ];
  return lineChart;
}

export default getRevenueConfig;
