import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as UserProductsService from '../../../services/api/user_products';
import * as ProductsService from '../../../services/api/product';
import * as UserOutputService from '../../../services/api/user_outputs';

import { IModal } from './reducer';

const SetModal = (payload: IModal) => {
  return {
    type: Types.SET_MODAL,
    data: payload,
  };
};
const SetExportModal = (payload: IModal) => {
  return {
    type: Types.SET_EXPORT_MODAL,
    data: payload,
  };
};
const SetSelected = (payload: string | null) => {
  return {
    type: Types.SET_SELECTED,
    data: payload,
  };
};
const SetGraphsImg = (payload: any) => {
  return {
    type: Types.SET_GRAPHS_IMG,
    data: payload,
  };
};
const SetEnvImpactSelection = (payload: string) => {
  return {
    type: Types.SET_ENV_IMPACT_SELECTION,
    data: payload,
  };
};
const SetEnvDescSelection = (payload: string) => {
  return {
    type: Types.SET_ENV_DESC_SELECTION,
    data: payload,
  };
};
const SetProductCategorySelection = (payload: string) => {
  return {
    type: Types.SET_PRODUCT_CATEGORY_SELECTION,
    data: payload,
  };
};
/** Fetch FetchUserProductsListInput  */

interface FetchUserProductsListInput {
  sortField: string;
  order: 'asc' | 'desc';
  scenario: string;
}

class FetchUserProductsList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USERPRODUCTS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USERPRODUCTS_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USERPRODUCTS_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchUserProductsListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserProductsService.fetchUserProductsList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetcUserProductsList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface FetchUserOutputListInput {
  sortField: string;
  order: 'asc' | 'desc';
  scenario: string;
  userid: string;
}

class FetchUserOutputList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_OUTPUT_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_OUTPUT_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_OUTPUT_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchUserOutputListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserOutputService.fetchUserOutputList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetchUserOutputList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchProductsList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PRODUCT_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PRODUCT_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PRODUCT_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ProductsService.getProductListByUserId();
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('getProductListByUserId Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class AddUserProduct implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_USER_PRODUCT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_USER_PRODUCT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_USER_PRODUCT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>, getStore: any) => {
      const store = getStore();
      const selectedScenario = store['ScenarioReducer'].selectedScenario;
      const userid = store['AuthReducer'].data.id;
      try {
        dispatch(this.onPending());
        let response = await UserProductsService.addUserProduct(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchUserProductsList().action({
            sortField: '_id',
            order: 'desc',
            scenario: selectedScenario,
          })
        );
        dispatch(
          new FetchUserOutputList().action({
            userid: userid,
            sortField: '_id',
            order: 'asc',
            scenario: selectedScenario,
          })
        );
        dispatch(
          SetModal({
            isOpen: false,
            data: null,
            type: 'add',
          })
        );
      } catch (error: any) {
        console.log('AddUserProduct Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class UpdateUserProduct implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_USER_PRODUCT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_USER_PRODUCT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_USER_PRODUCT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>, getStore: any) => {
      const store = getStore();
      const selectedScenario = store['ScenarioReducer'].selectedScenario;
      const userid = store['AuthReducer'].data.id;

      try {
        dispatch(this.onPending());
        let response = await UserProductsService.editUserProduct(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchUserProductsList().action({
            sortField: '_id',
            order: 'desc',
            scenario: selectedScenario,
          })
        );
        dispatch(
          new FetchUserOutputList().action({
            userid,
            sortField: '_id',
            order: 'asc',
            scenario: selectedScenario,
          })
        );
      } catch (error: any) {
        console.log('UpdateUserProduct Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class UpdateProduct implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_PRODUCT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_PRODUCT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_PRODUCT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ProductsService.updateProduct(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchProductsList().action());
      } catch (error: any) {
        console.log('UpdateProduct Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

// fetch
class DeleteUserProduct implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.DELETE_USER_PRODUCT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.DELETE_USER_PRODUCT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.DELETE_USER_PRODUCT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>, getStore: any) => {
      const store = getStore();
      const selectedScenario = store['ScenarioReducer'].selectedScenario;
      const userid = store['AuthReducer'].data.id;

      try {
        dispatch(this.onPending());
        let response = await UserProductsService.deleteUserProduct(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(
          new FetchUserProductsList().action({
            sortField: '_id',
            order: 'desc',
            scenario: selectedScenario,
          })
        );
        dispatch(
          new FetchUserOutputList().action({
            userid,
            sortField: '_id',
            order: 'asc',
            scenario: selectedScenario,
          })
        );
      } catch (error: any) {
        console.log('DeleteUserProduct Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
export default {
  fetchUserProductsListAction: (payload: FetchUserProductsListInput) =>
    new FetchUserProductsList().action(payload),
  fetchUserOutputListAction: (payload: FetchUserOutputListInput) =>
    new FetchUserOutputList().action(payload),
  setModalAction: (payload: IModal) => SetModal(payload),
  setExportModal: (payload: IModal) => SetExportModal(payload),
  fetchProductsListAction: () => new FetchProductsList().action(),
  addUserProductAction: (payload: any) => new AddUserProduct().action(payload),
  deleteUserProductAction: (payload: any) =>
    new DeleteUserProduct().action(payload),
  updateUserProductAction: (payload: any) =>
    new UpdateUserProduct().action(payload),
  setSelectedAction: (payload: string | null) => SetSelected(payload),
  updateProduct: (payload: any) => new UpdateProduct().action(payload),
  setGraphsImg: (payload: any) => SetGraphsImg(payload),
  setEnvImpactSelection: (payload: string) => SetEnvImpactSelection(payload),
  setEnvDescSelection: (payload: string) => SetEnvDescSelection(payload),
  setProductCategorySelection: (payload: string) =>
    SetProductCategorySelection(payload),
};
