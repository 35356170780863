import { IAction, StateStatus } from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  profileData: {
    status: StateStatus | null | undefined;
    data: any;
  };
  countriesList: {
    status: StateStatus | null | undefined;
    data: any;
  };
  editProfile: {
    status: StateStatus | null | undefined;
  };
  updateSetup: {
    status: StateStatus | null | undefined;
  };
  uploadAvatar: {
    status: StateStatus | null | undefined;
  };
}

const initialState: IState = {
  profileData: {
    status: null,
    data: null,
  },
  countriesList: {
    status: null,
    data: null,
  },
  editProfile: {
    status: null,
  },
  updateSetup: {
    status: null,
  },
  uploadAvatar: {
    status: null,
  },
};

const UserPorifleReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USER_PROFILE:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_COUNTRIES:
      return {
        ...state,
        countriesList: {
          ...state.countriesList,
          status: action.status,
          data: action.data,
        },
      };
    case Types.EDIT_PROFILE:
      return {
        ...state,
        editProfile: {
          ...state.countriesList,
          status: action.status,
        },
      };
    case Types.UPDATE_SETUP:
      return {
        ...state,
        updateSetup: {
          ...state.updateSetup,
          status: action.status,
        },
      };
    case Types.UPLOAD_AVATAR:
      return {
        ...state,
        uploadAvatar: {
          ...state.uploadAvatar,
          status: action.status,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default UserPorifleReducer;
