import React from 'react';
import moment from 'moment';
import ActionsBlock from './components/datatable/ActionsBlock';
import * as DatesUtils from '../../utils/dates';
import { Languages, AuthRoles, Environment } from '../../config';
import { Button } from '@material-ui/core';

const CONFIGURATION = {};

export const allowedActions = {};

export default CONFIGURATION;
//Columns
/*
     
   type 
    , totalnumberofguestsperyear 
    , totalnumberofrooms 
    , hotelguestcapacity 
    , restaurantcafecapacity 
    , totalnumberoftablesacrossallrestaurant 
    , beachbarcapacity 
    , totalnumberoftables 
    , totalcapacity 
    , user
     */
//table key
//
export const TableColumns = [
  {
    label: 'Avatar',
    id: 'avatarURL',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        <img
          style={{ width: 50, height: 50 }}
          alt="avatar"
          src={`${Environment.API_BASE_URL}/${cellValue}`}
          onError={(e: any) => {
            e.target.src = require('assets/images/no-image-available.png');
          }}
        />
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Email',
    id: 'email',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Name',
    id: 'username',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Roles',
    id: 'roles',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue.join(', ')}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Created at',
    id: 'registeredDate',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {moment(cellValue).format('DD-MM-YYYY hh:mm:ss')}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Verified',
    id: 'emailConfirmed',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {cellValue ? (
          <Button
            variant="text"
            style={{
              width: 100,
              height: 30,
              background: '#6EE7B7',
              color: '#fff',
              textTransform: 'none',
            }}
          >
            Verified
          </Button>
        ) : (
          <Button
            style={{
              width: 100,
              height: 30,
              background: '#ff0033',
              color: '#fff',
              textTransform: 'none',
            }}
          >
            Not verified
          </Button>
        )}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Actions',
    align: 'center',
    id: 'action1',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ActionsBlock
        rowIndex={rowIndex}
        data={data}
        dataid={data[rowIndex]._id}
      />
    ),
    inData: false,
  },
  //   {
  //     label: 'Actions',
  //     align: 'center',
  //     id: 'action1',
  //     render: (
  //       rowIndex: number,
  //       cellValue: any,
  //       columnIndex: number,
  //       data: any
  //     ) => (
  //       <ActionsBlock
  //         rowIndex={rowIndex}
  //         data={data}
  //         dataid={data[rowIndex]._id}
  //       />
  //     ),
  //     inData: false,
  //   },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[1].id,
};

export const Translations = {
  page_title: {
    [Languages.Greek]: 'Scenario',
    [Languages.English]: 'Scenario',
  },
  manage_registration_title: {
    [Languages.Greek]: 'Scenario Title',
    [Languages.English]: 'Scenario Title',
  },
  manage_registration_description: {
    [Languages.Greek]: 'Scenario Description',
    [Languages.English]: 'Scenario Description',
  },
  table_title: {
    [Languages.Greek]: 'Title',
    [Languages.English]: 'Title',
  },
  ScenarioModalAddTitle: {
    [Languages.Greek]: 'Προσθήκη',
    [Languages.English]: 'Add',
  },
  ScenarioModalEditTitle: {
    [Languages.Greek]: 'Επεξεργασία',
    [Languages.English]: 'Edit',
  },
  ScenarioModalViewTitle: {
    [Languages.Greek]: 'Προεπισκόπηση',
    [Languages.English]: 'View',
  },
  addEditModalButton: {
    [Languages.Greek]: 'Submit',
    [Languages.English]: 'Submit',
  },
  update: {
    [Languages.Greek]: 'Update',
    [Languages.English]: 'Update',
  },
  form_error_required: {
    [Languages.Greek]: 'This field is required',
    [Languages.English]: 'This field is required',
  },
};
