export const capitalizeFirstLetter = (s: string | undefined) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const replaceElementWith = (
  inputString: string,
  replaceThis: string,
  withThis: string
) => {
  let re = new RegExp(`${replaceThis}`, 'gi');
  return inputString.replace(re, withThis);
};

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
