import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import { useHistory } from 'react-router';
import TranslatedText from '../../../../components/atoms/translated_text';
import { Translations } from '../../configuration';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import ChangePasswordActions from '../../store/actions';
import * as ChangePasswordSelectors from '../../store/selectors';
import { StateStatus } from '../../../../redux/utils/common';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Box } from '@material-ui/core';
import { AppTextField } from '../../../../components/molecules/form_controls';
import GridLayout from 'components/molecules/grid_layout';

interface IFormData {
  oldpassword: string | null;
  confirmOldpassword: string | null;
  newpassword: string | null;
  confirmNewpassword: string | null;
}

// add types or interface
const defaultValues = {
  oldpassword: null,
  confirmOldpassword: null,
  newpassword: null,
  confirmNewpassword: null,
};

export default function ChangePasswordForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const onSubmit = (data: any) => {
    dispatch(
      ChangePasswordActions.changePasswordAction({ ...data, history: history })
    );
  };

  return (
    <div className={clsx(classes.layout)}>
      <Typography
        variant="h5"
        className={clsx(classes.title, 'text-center md:w-full mb-48')}
      >
        <TranslatedText textMap={Translations.recover_title} />
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridLayout
          elements={[
            {
              element: (
                <AppTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  control={control}
                  error={'oldpassword' in errors}
                  rules={{
                    required: <RequiredField />,
                    minLength: {
                      value: 8,
                      message: 'Password must have at least 8 characters',
                    },
                  }}
                  label={
                    <TranslatedText
                      defaultText={'Old Password'}
                      // textMap={Translations.form_field_password}
                    />
                  }
                  helperText={errors.oldpassword && errors.oldpassword.message}
                  name="oldpassword"
                  type="password"
                  id="oldpassword"
                />
              ),
              size: 7,
              id: '1',
            },
            {
              element: (
                <AppTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmOldpassword"
                  error={'confirmOldpassword' in errors}
                  label={
                    <TranslatedText
                      defaultText={'Confirm Old Password'}
                      // textMap={Translations.form_field_confirm_password}
                    />
                  }
                  helperText={
                    errors.confirmOldpassword &&
                    errors.confirmOldpassword.message
                  }
                  type="password"
                  id="confirmOldpassword"
                  control={control}
                  rules={{
                    required: <RequiredField />,
                    validate: () =>
                      getValues('oldpassword') ===
                        getValues('confirmOldpassword') ||
                      'The passwords do not match',
                  }}
                />
              ),
              size: 7,
              id: '2',
            },
            {
              element: (
                <AppTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newpassword"
                  error={'newpassword' in errors}
                  label={
                    <TranslatedText
                      defaultText={'Password'}
                      // textMap={Translations.form_field_confirm_password}
                    />
                  }
                  helperText={errors.newpassword && errors.newpassword.message}
                  type="password"
                  id="newpassword"
                  control={control}
                  rules={{
                    required: <RequiredField />,
                    validate: () =>
                      getValues('newpassword') === getValues('newpassword') ||
                      'The passwords do not match',
                  }}
                />
              ),
              size: 7,
              id: '3',
            },
            {
              element: (
                <AppTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmNewpassword"
                  error={'confirmNewpassword' in errors}
                  label={
                    <TranslatedText
                      defaultText={'Confirm Password'}
                      // textMap={Translations.form_field_confirm_password}
                    />
                  }
                  helperText={
                    errors.confirmNewpassword &&
                    errors.confirmNewpassword.message
                  }
                  type="password"
                  id="confirmNewpassword"
                  control={control}
                  rules={{
                    required: <RequiredField />,
                    validate: () =>
                      getValues('newpassword') ===
                        getValues('confirmNewpassword') ||
                      'The passwords do not match',
                  }}
                />
              ),
              size: 7,
              id: '4',
            },
            {
              element: (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <SubmitWrapper
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    <TranslatedText
                      textMap={Translations.form_button_register}
                    />
                  </SubmitWrapper>
                </Box>
              ),
              size: 7,
              id: '5',
            },
          ]}
        />
      </form>
    </div>
  );
}

const RequiredField = () => {
  return (
    <TranslatedText
      textMap={Translations.form_error_required}
      defaultText={'Required Value'}
    />
  );
};

interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  const changePasswordStatus = ChangePasswordSelectors.useSelectChangePasswordStatus();

  return (
    <Button
      {...props}
      disabled={changePasswordStatus === StateStatus.Pending}
    />
  );
};
