import React, { Fragment, ReactElement } from 'react';
import CodeIcon from '@material-ui/icons/Code';
import useStyles from './styles';
import _ from 'lodash';
import BaseModal from 'components/molecules/base_modal';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import TranslatedText from 'components/atoms/translated_text';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import PrettyPrintJson from 'components/atoms/pretty_print_json';

interface IProps {
  children: React.ReactElement;
  name: string;
}
export const ModalConfigWrapper = ({ children, name }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <div className={classes.toolsContainer}>
        <div
          onClick={() => {
            dispatch(
              ModalActions.openModalAction({
                modalID: ModalIDs.MODAL_CONFIG_WRAPPER,
                params: {
                  config: children.props,
                  name: name,
                },
              })
            );
          }}
          className={classes.jsonButton}
        >
          <CodeIcon />
          <span className={classes.text}>Props</span>
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export const ConfigWrapperModal = () => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_CONFIG_WRAPPER}
      showExitIcon={true}
      contentProps={{ style: { minHeight: '40vh' } }}
      modalProps={{ fullWidth: true }}
      renderHeader={(params) => (
        <TranslatedText defaultText={params.name || `Global Variables`} />
      )}
      renderContent={(params) => <PrettyPrintJson data={params.config} />}
    />
  );
};


// export const withInspect = (Component: any, name: string): any => {
//   const ComposedComponent = () => {
//     return (
//       <ModalConfigWrapper name={name}>
//         {/* <Component /> */}
//         {Component}
//       </ModalConfigWrapper>
//     );
//   };

//   return ComposedComponent;
// };
