import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_USERPRODUCTS_LIST: string = typeCreatorV2(
  'UserProductsReducer',
  'SetUserProductsList'
);

export const SET_MODAL: string = typeCreatorV2(
  'UserProductsReducer',
  'SetModal'
);
export const SET_PRODUCT_LIST: string = typeCreatorV2(
  'UserProductsReducer',
  'SetProductList'
);
export const SET_USER_OUTPUT_LIST: string = typeCreatorV2(
  'UserProductsReducer',
  'SetUserOutputList'
);

export const SET_ADD_USER_PRODUCT: string = typeCreatorV2(
  'UserProductsReducer',
  'SetAddProduct'
);

export const UPDATE_USER_PRODUCT: string = typeCreatorV2(
  'UserProductsReducer',
  'UpdateUserProduct'
);

export const DELETE_USER_PRODUCT: string = typeCreatorV2(
  'UserProductsReducer',
  'DeleteUserProduct'
);

export const SET_SELECTED: string = typeCreatorV2(
  'UserProductsReducer',
  'SetSelected'
);

export const UPDATE_PRODUCT: string = typeCreatorV2(
  'UserProductsReducer',
  'UpdateProduct'
);

export const SET_GRAPHS_IMG: string = typeCreatorV2(
  'UserProductsReducer',
  'SetGraphsImg'
);

export const SET_ENV_IMPACT_SELECTION: string = typeCreatorV2(
  'UserProductsReducer',
  'SetEnvImpactSelection'
);
export const SET_ENV_DESC_SELECTION: string = typeCreatorV2(
  'UserProductsReducer',
  'SetEnvDescSelection'
);
export const SET_PRODUCT_CATEGORY_SELECTION: string = typeCreatorV2(
  'UserProductsReducer',
  'SetProductCategorySelection'
);
export const SET_EXPORT_MODAL: string = typeCreatorV2(
  'UserProductsReducer',
  'SetExportModal'
);
