import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as AuthenticationService from '../../../services/api/authenticate';
import { Dispatch } from 'redux';
import NavigationConfig from '../../../navigation/config';
import { AuthRoles } from '../../../config';
import { getState } from '../../store';
import { ReducerKeys } from '../../config';
import _ from 'lodash';

/** Authenticate Action  */

interface IAutheticateInput {
  email: string;
  password: string;
  stayLogin: boolean;
}

interface IAutheticateOutput {
  token: string;
  refreshToken: string;
  roles: Array<string | number>;
  id: string;
  stayLogin: boolean;
  email: string;
  username: string;
  type: string | null;
}

class Authenticate implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(result: IAutheticateOutput): IAction {
    const data = {
      roles: result.roles,
      token: result.token,
      refreshToken: result.refreshToken,
      stayLogin: result.stayLogin,
      data: {
        username: result.username,
        email: result.email,
        id: result.id,
        type: result.type,
      },
    };

    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IAutheticateInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const { data } = await AuthenticationService.authenticateUser({
          email: input.email,
          password: input.password,
        });
        let result = data;

        // console.log("result.user:", result.user);
        dispatch(
          this.onSuccess({
            token: result.access_token,
            roles: result.user.roles,
            id: result.user.uuid,
            stayLogin: input.stayLogin,
            refreshToken: result.access_token,
            email: _.get(result.user, 'data.email', null),
            username: _.get(result.user, 'data.username', null),
            type: _.get(result.user, 'data.type', null),
          })
        );
      } catch (error) {
        console.log('Authenticate Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Logout Action  */

class Logout implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(): IAction {
    return {
      type: Types.LOGOUT_TYPE,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.LOGOUT_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(history?: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onSuccess());
      } catch (error) {
        console.log('Logout Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        localStorage.clear();
        if (history) {
          history.push(NavigationConfig.loginPage().path);
        }
      }
    };
  }
}

/** Refresh Token Action  */

interface IRefreshTokenInput {
  token: string;
  refreshToken: string;
}

interface IRefreshTokenOutput {
  token: string;
  refreshToken: string;
}

class RefreshToken implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(data: IRefreshTokenOutput): IAction {
    throw new Error('Method not implemented.');
  }

  onFailed(): IAction {
    throw new Error('Method not implemented.');
  }

  action(data: IRefreshTokenInput): IAction {
    return new Authenticate().onSuccess({
      token: data.token,
      refreshToken: data.refreshToken,
      stayLogin: true,
      roles: getState(ReducerKeys.AUTH_REDUCER).roles,
      id: getState(ReducerKeys.AUTH_REDUCER).id,
      username: getState(ReducerKeys.AUTH_REDUCER).data.username,
      email: getState(ReducerKeys.AUTH_REDUCER).data.email,
      type: getState(ReducerKeys.AUTH_REDUCER).data.type,
    });
  }
}

export default {
  authenticateAction: (data: IAutheticateInput) =>
    new Authenticate().action(data),
  logoutAction: (history?: any) => new Logout().action(history),
  refreshTokenAction: (refreshToken: string, token: string) =>
    new RefreshToken().action({ refreshToken, token }),
};
