import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import * as DashboardBuilderSelectors from '../../store/selectors';
import PrettyPrintJson from 'components/atoms/pretty_print_json';

interface ModalParams {
  index: number;
}

const ViewCodeModal = React.memo(() => {
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_DASHBOARD_ELEMENT_CONFIG}
      modalProps={{ fullWidth: true }}
      renderHeader={() => (
        <TranslatedText defaultText={`Element Json Config`} />
      )}
      renderContent={(props: ModalParams) => (
        <PrettyPrintJson
          data={
            typeof props.index === 'number'
              ? DashboardBuilderSelectors.getDashboardElementConfig(props.index)
              : (DashboardBuilderSelectors.getDashboardConfig() as any)
          }
          jsonData={true}
        />
      )}
    />
  );
});

export default ViewCodeModal;
