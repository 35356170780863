import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { StateStatus } from 'redux/utils/common';
import swal from 'sweetalert2';
import TranslatedText from '../../../../components/atoms/translated_text';
import { AppTextField } from '../../../../components/molecules/form_controls';
import { AuthRoles } from '../../../../config';
import { Translations } from '../../configuration';
import RegisterActions from '../../store/actions';
import * as RegisterSelectors from '../../store/selectors';
import NavigationConfig from 'navigation/config';
import { Link } from 'react-router-dom';
import useStyles from './styles';

interface IFormData {
  firstName: string | null;
  surname: string | null;
  email: string | null;
  password: string | null;
  confirmPassword: string | null;
}

// add types or interface
const defaultValues = {
  firstName: null,
  surname: null,
  email: null,
  password: null,
  confirmPassword: null,
};

function TermConditions(props: { open: boolean; handleClose: () => void }) {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>{'Term & conditions: '}</DialogTitle>
      <DialogContent style={{ width: 600, height: 800 }}>
        <iframe
          style={{ width: '100%', height: '90%' }}
          src={require('assets/term_and_service.pdf')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{ textTransform: 'none' }}
          color="primary"
          variant="contained"
          onClick={props.handleClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function RegisterForm(): ReactElement {
  const classes = useStyles();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const [openTermConditions, setOpenTermConditions] = React.useState(false);
  const [isAcceptTerm, setisAcceptTerm] = React.useState(false);

  const onSubmit = (_data: any) => {
    if (isAcceptTerm) {
      const { confirmPassword, ..._newdata } = _data;
      let payload = {
        ..._newdata,
        roles: [AuthRoles.User].map((item: string) => parseInt(item)),
      };
      dispatch(RegisterActions.registerUserAction(payload, history));
    } else {
      swal.fire({
        title: 'Error Terms',
        text: 'Please read and accept our terms & conditions',
        icon: 'error',
      });
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <TermConditions
        open={openTermConditions}
        handleClose={() => setOpenTermConditions(false)}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'firstName' in errors}
        helperText={errors.firstName && errors.firstName.message}
        rules={{
          required: <RequiredField />,
        }}
        required
        fullWidth
        id="firstName"
        control={control}
        label={
          <TranslatedText
            defaultText={'Firstname'}
            textMap={Translations.form_field_firstname}
          />
        }
        autoComplete="firstName"
        name="firstName"
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        error={'surname' in errors}
        helperText={errors.surname && errors.surname.message}
        rules={{
          required: <RequiredField />,
        }}
        required
        fullWidth
        id="surname"
        label={
          <TranslatedText
            defaultText={'Surname'}
            textMap={Translations.form_field_surname}
          />
        }
        control={control}
        autoComplete="surname"
        name="surname"
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={
          <TranslatedText
            defaultText={'Email Address'}
            textMap={Translations.form_email}
          />
        }
        name="email"
        autoComplete="email"
        control={control}
        error={'email' in errors}
        helperText={errors.email && errors.email.message}
        rules={{
          required: <RequiredField />,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Enter a valid e-mail address',
          },
        }}
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        control={control}
        error={'password' in errors}
        rules={{
          required: <RequiredField />,
          minLength: {
            value: 8,
            message: 'Password must have at least 8 characters',
          },
        }}
        label={
          <TranslatedText
            defaultText={'Password'}
            textMap={Translations.form_field_password}
          />
        }
        helperText={errors.password && errors.password.message}
        name="password"
        type="password"
        id="password"
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="confirmPassword"
        error={'confirmPassword' in errors}
        label={
          <TranslatedText
            defaultText={'Confirm Password'}
            textMap={Translations.form_field_confirm_password}
          />
        }
        helperText={errors.confirmPassword && errors.confirmPassword.message}
        type="password"
        id="confirmPassword"
        control={control}
        rules={{
          required: <RequiredField />,
          validate: () =>
            getValues('password') === getValues('confirmPassword') ||
            'The passwords do not match',
        }}
      />
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        style={{ marginLeft: -12 }}
      >
        <Checkbox
          checked={isAcceptTerm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setisAcceptTerm(event.target.checked)
          }
        />
        <Typography
          style={{ cursor: 'pointer', fontSize: 15, color: '#666E7D' }}
          onClick={() => setOpenTermConditions(true)}
        >
          <TranslatedText
            defaultText={'Accept terms & conditions'}
            textMap={Translations.form_field_accept_term}
          />
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <SubmitWrapper
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
        >
          <TranslatedText textMap={Translations.form_button_register} />
        </SubmitWrapper>
      </Box>
      <Box className={classes.signUpHere}>
        <Link
          style={{ color: 'inherit' }}
          to={NavigationConfig.loginPage().path}
        >
          <TranslatedText textMap={Translations.have_an_account} />
          <span>
            <TranslatedText textMap={Translations.login} />
          </span>
        </Link>
      </Box>
    </form>
  );
}

const RequiredField = () => {
  return (
    <TranslatedText
      textMap={Translations.form_error_required}
      defaultText={'Required Value'}
    />
  );
};

interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  const registerStatus = RegisterSelectors.useGetRegisterStatus();

  return (
    <Button
      {...props}
      color="primary"
      disabled={registerStatus === StateStatus.Pending}
    />
  );
};
