import React from 'react';
import { withRouter } from 'react-router';
import useStyles from './styles';
import { Container, Grid, makeStyles } from '@material-ui/core';
import ProfileDetails from './components/profile_details';
import SetupProfile from './components/setup_profile';
import { AuthRoles } from 'config';
import Profile from './components/profile';
import { useDispatch } from 'react-redux';
import UserProfileActions from '../../redux/reducers/userProfile_reducer/actions';

import reactLogger from 'utils/logger';
import {
  useSelectUserData,
  useSelectUserRoles,
} from 'redux/reducers/gl_auth_reducer/selectors';
import { withReducer } from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';

// import * as AuthSelectors from '../../redux/reducers/gl_auth_reducer/selectors';

interface IProps {
  history: any;
}

const ProfilePage = (props: IProps) => {
  reactLogger.renderPage('ProfilePage');
  const classes = useStyles();
  const { id } = useSelectUserData();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(UserProfileActions.fetchUserProfileAction({ id }));
    // dispatch(UserProfileActions.fetchUserProfileCountriesAction());
  }, [id]);

  return (
    <Container maxWidth="lg">
      <Grid spacing={5} container>
        <Grid item lg={4} md={6} xs={12}>
          <Profile />
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <ProfileDetails />
          {/* <SetupProfile /> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(ProfilePage);
// const Page = withRouter(ProfilePage);
// export default withReducer(ReducerKeys.USER_PROFILE_REDUCER)(Page);
