import React from 'react';
import _ from 'lodash';
import ActionsBlock from './components/datatable/ActionsBlock';
import * as DatesUtils from '../../utils/dates';
import { Languages, AuthRoles } from '../../config';

const CONFIGURATION = {};

export const allowedActions = {};

export default CONFIGURATION;
//Columns
/*
     
   name 
    , address 
    , users 
    , admin
     */
//table key
//
export const TableColumns = [
  {
    label: 'Group',
    id: 'group',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue?.name || ''}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Name',
    id: 'name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Address',
    id: 'address',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Users',
    id: 'emails',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {_.isArray(cellValue) ? cellValue.join(', ') : ''}
      </React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Admin',
    id: 'admin',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {cellValue
          .map((user: any) => `${user.firstName} ${user.surname}`)
          .join(', ')}
      </React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Actions',
    align: 'center',
    id: 'action1',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <ActionsBlock
        rowIndex={rowIndex}
        data={data}
        dataid={data[rowIndex]._id}
      />
    ),
    inData: false,
  },
];

interface ITableDefaultFetchValues {
  TABLE_DEFAULT_PAGE: number;
  TABLE_DEFAULT_PAGE_SIZE: number;
  TABLE_DEFAULT_ORDER: 'desc' | 'asc';
  TABLE_DEFAULT_SORT_KEY: string;
}

export const TableDefaultFetchValues: ITableDefaultFetchValues = {
  TABLE_DEFAULT_PAGE: 0,
  TABLE_DEFAULT_PAGE_SIZE: 10,
  TABLE_DEFAULT_ORDER: 'desc',
  TABLE_DEFAULT_SORT_KEY: TableColumns[0].id,
};

export const Translations = {
  page_title: {
    [Languages.Greek]: 'Establishment',
    [Languages.English]: 'Establishment',
  },
  manage_registration_title: {
    [Languages.Greek]: 'Establishment Title',
    [Languages.English]: 'Establishment Title',
  },
  manage_registration_description: {
    [Languages.Greek]: 'Establishment Description',
    [Languages.English]: 'Establishment Description',
  },
  table_title: {
    [Languages.Greek]: 'Establishments Management',
    [Languages.English]: 'Establishments Management',
  },
  EstablishmentModalAddTitle: {
    [Languages.Greek]: 'Προσθήκη',
    [Languages.English]: 'Add Establishments',
  },
  EstablishmentModalEditTitle: {
    [Languages.Greek]: 'Επεξεργασία',
    [Languages.English]: 'Edit Establishments',
  },
  EstablishmentModalViewTitle: {
    [Languages.Greek]: 'Προεπισκόπηση',
    [Languages.English]: 'View',
  },
  addEditModalButton: {
    [Languages.Greek]: 'Submit',
    [Languages.English]: 'Submit',
  },
  addNewRecord: {
    [Languages.Greek]: 'Add new establishment',
    [Languages.English]: 'Add new establishment',
  },
};
