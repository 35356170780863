import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
  useGetCombine,
  useGetCombineScenarios,
} from '../../../../redux/reducers/scenario_reducer/selectors';
import ScenarioActions from '../../../../redux/reducers/scenario_reducer/actions';
import Table from './Table';
import { showToastAction } from 'components/atoms/toast_message';
import NavigationConfig from 'navigation/config';

export default function ResponsiveDialog() {
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { isOpen, data } = useGetCombine();
  const combinedScenario = useGetCombineScenarios();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(ScenarioActions.FetchScenarioActiveList());
  }, []);

  const handleClose = () => {
    dispatch(ScenarioActions.setCombine({ isOpen: false, data: null }));
  };
  function handleCombine() {
    if (combinedScenario.length > 0) {
      handleClose();
      history.push(NavigationConfig.combineScenarioPage().path);
    } else {
      showToastAction(
        dispatch,
        'Please select at least 1 scenario to combine',
        'error'
      );
    }
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Combine scenario results
        </DialogTitle>
        <DialogContent>
          <Table />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCombine} color="primary" autoFocus>
            Combine
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
