import React, { ReactElement } from 'react';
import { withRouter } from 'react-router';
import useStyles from './styles';
import PageLayout from 'components/app_common/page_layout';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader } from '@material-ui/core';
import CardLabelTitle from 'components/app_common/card_label_title';
import TranslatedText from 'components/atoms/translated_text';
import {
  GeoJsonMap,
  geoJsonMapDefaultParser,
} from 'components/molecules/leaflet_map';
import * as dummy_data from 'components/molecules/leaflet_map/fake_data/dummy_data.json';
import * as cyprus_data from 'components/molecules/leaflet_map/fake_data/cyprus.json';
import { Layer } from 'leaflet';

interface IProps {}

const MapsPage = (props: IProps): ReactElement => {
  const classes = useStyles();

  const onEachFeature = (element: any, layer: Layer) => {
    // layer.options.fillColor = element?.properties?.color || 'red';
    const name = element.properties.id;
    const confirmedText = element?.properties?.name_en;
    layer.bindPopup(`${name} ${confirmedText}`);

    layer.on({
      click: (event) => {
        try {
          event.target.setStyle({
            color: 'green',
            fillColor: 'blue',
            fillOpacity: 1,
          });
        } catch (ex) {}
      },
    });
  };

  return (
    <PageLayout>
      <Card>
        {/* <Temp /> */}
        <CardHeader title={<TranslatedText defaultText="Leaflet Maps" />} />
        <CardContent>
          <GeoJsonMap
            height="75vh"
            scrollWheelZoom={true}
            onEachFeature={onEachFeature}
            geoJsonProps={{
              data: dummy_data.features as any,

              // geoJsonMapDefaultParser((cyprus_data as any).default)
              //   .features as any,
              // dummy_data.features as any,

              //  dummy_data.features as any,
            }}
          />
        </CardContent>
      </Card>
    </PageLayout>
  );
};

export default withRouter(MapsPage);
