import { Box, Button, ButtonProps } from '@material-ui/core';
import { showToastAction } from 'components/atoms/toast_message';
import TranslatedText from 'components/atoms/translated_text';
import BaseModal from 'components/molecules/base_modal';
import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import EstablishmentActions from 'redux/reducers/establishment_reducer/actions';
import * as EstablishmentSelectors from 'redux/reducers/establishment_reducer/selectors';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import {
  AppSelect, AppTextField
} from '../../../../components/molecules/form_controls';
import { StateStatus } from '../../../../redux/utils/common';
import { TableDefaultFetchValues, Translations } from '../../configuration';
import useStyles from './styles';


export default function EstablishmentModal() {
  const classes = useStyles();
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_ADD_EDIT_ESTABLISHMENT}
      renderHeader={({ type, id }: IModalProps) => (
        <Box className={classes.headerModal}>
          <EstablishmentHeader id={id} type={type} />
          <span>You can provide your information here</span>
        </Box>
      )}
      renderContent={({ type, id }: IModalProps) => (
        <React.Fragment>
          <EstablishmentForm id={id} type={type} />
        </React.Fragment>
      )}
      modalProps={{
        className: classes.containerModal,
      }}
    />
  );
}

interface IModalProps {
  type: 'add' | 'edit' | 'view';
  id: string | number;
}

const EstablishmentHeader = ({ type, id }: IModalProps) => {
  return (
    <TranslatedText
      textMap={
        type === 'add'
          ? Translations.EstablishmentModalAddTitle
          : type === 'edit'
          ? Translations.EstablishmentModalEditTitle
          : Translations.EstablishmentModalViewTitle
      }
      defaultText={type === 'add' ? 'Add' : type === 'edit' ? 'Edit' : 'View'}
    />
  );
};

interface FormTypes {
  name: string | null;
  address: string | null;
  users: string | null;
  admin: string[] | null;
  group: string | null;
}

const defaultValues = {
  name: null,
  address: null,
  users: null,
  admin: [],
  group: null,
};
function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
const EstablishmentForm = React.memo(({ type, id }: IModalProps) => {
  const { status, data } = EstablishmentSelectors.useGetEstablishmentDetails();
  const groups = EstablishmentSelectors.useGetGroups();
  const user = useSelectUserData();
  const classes = useStyles();
  const { reset, watch, errors, control, handleSubmit } = useForm<FormTypes>({
    defaultValues,
  });
  const dispatch = useDispatch();

  const onSubmit = (_data: FormTypes) => {
    switch (type) {
      case 'add':
        let users: string = _data.users ? _data.users : '';
        const usersEstablishment = users
          .split(',')
          .map((email: string) => email.replace(' ', ''));
        let isValid = true;
        usersEstablishment.forEach((email: string) => {
          if (!validateEmail(email)) {
            isValid = false;
          }
        });
        if (users === '') isValid = true;
        isValid
          ? dispatch(
              EstablishmentActions.addNewEstablishmentAction({
                name: _data.name || null,
                address: _data.address || null,
                users: _data.users || '',
                admin: [user.id],
                group: _data.group,
                fetchEstablishmentInput: {
                  sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
                  page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
                  pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
                  order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
                },
              })
            )
          : showToastAction(dispatch, 'Invalid email', 'error');
        break;
      case 'edit':
        dispatch(
          EstablishmentActions.editEstablishmentAction({
            id: id.toString(),
            name: _data.name || null,
            address: _data.address || null,
            users: _data.users || null,
            admin: data?.admin || null,
            group: _data.group,
            fetchEstablishmentInput: {
              sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
              page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
              pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
              order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
            },
          })
        );
        break;
    }
  };

  React.useEffect(() => {
    if (['edit', 'view'].includes(type)) {
      dispatch(
        EstablishmentActions.viewEstablishmentAction({
          id: id.toString(),
        })
      );
    }
  }, [dispatch, id, type]);

  React.useEffect(() => {
    if (status === StateStatus.Success && type !== 'add') {
      reset({
        name: data?.name || null,
        address: data?.address || null,
        users: _.isArray(data?.emails) ? data?.emails.join(', ') : null,
        admin: data?.admin || null,
        group: data?.group || null,
      });
    }
  }, [status, dispatch, type]);

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="name"
        label={<TranslatedText defaultText={'name'} />}
        error={'name' in errors}
        helperText={errors.name && errors.name.message}
        rules={{}}
        name="name"
        autoComplete="name"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="address"
        label={<TranslatedText defaultText={'address'} />}
        error={'address' in errors}
        helperText={errors.address && errors.address.message}
        rules={{}}
        name="address"
        autoComplete="address"
        control={control}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        fullWidth
        id="users"
        label={<TranslatedText defaultText={'users'} />}
        error={'users' in errors}
        helperText={errors.users && errors.users.message}
        rules={{}}
        name="users"
        autoComplete="users"
        control={control}
      />
      <AppSelect
        variant="outlined"
        margin="normal"
        disabled={Boolean(type === 'view')}
        required
        fullWidth
        id="group"
        options={
          groups?.data?.map((item: EstablishmentSelectors.IGroup) => {
            return {
              id: item._id,
              label: `${item.name}`,
            };
          })
            ? [
                {
                  id: '',
                  label: 'None',
                },
                ...groups?.data?.map((item: EstablishmentSelectors.IGroup) => {
                  return {
                    id: item._id,
                    label: `${item.name}`,
                  };
                }),
              ]
            : [
                {
                  id: '',
                  label: 'None',
                },
              ]
        }
        label={<TranslatedText defaultText={'group'} />}
        error={'group' in errors}
        helperText={errors.group && errors.group.message}
        rules={{}}
        name="group"
        autoComplete="group"
        control={control}
      />

      {type !== 'view' && (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          style={{ gap: 15, margin: '30px 0 10px 0' }}
        >
          <Button
            // type={'submit'}
            color="primary"
            // onClick={() =>
            //   dispatch(ScenarioAction.setModal({ isOpen: false, data: null }))
            // }
            variant="outlined"
            // disabled={submitStatus === StateStatus.Pending}
            className={classes.buttonCancel}
          >
            <TranslatedText
              defaultText={'Cancel'}
              // textMap={Translations.form_submit}
            />
          </Button>
          <Button
            type={'submit'}
            color="primary"
            variant="contained"
            className={classes.buttonAdd}
          >
            <TranslatedText
              textMap={Translations.addEditModalButton}
              defaultText={'Submit'}
            />
          </Button>
        </Box>
      )}
    </form>
  );
});

const SubmitWrapper = (props: ButtonProps) => {
  return <Button {...props} />;
};
