import React, { ReactElement } from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import CardLabelTitle from 'components/app_common/card_label_title';
import { colors, Theme } from '@material-ui/core';
import * as RandomUtils from 'utils/randomize';
import { ModalConfigWrapper } from 'components/app_common/modal_config_wrapper';

export default function PieChart() {
  return (
    <ModalConfigWrapper name="PieChart">
      <CustomChart
        title={
          <CardLabelTitle
            title={<TranslatedText defaultText="Sample Pie Chart" />}
          />
        }
        layoutProps={{
          variant: 'outlined',
        }}
        labels={DummyLabels}
        data={DummyDataset()}
        options={DummyChartOptions}
        chartType={ChartTypesEnum.PIE}
      />
    </ModalConfigWrapper>
  );
}

export const DummyLabels = [
  '1 Monday',
  '2 Tuesday',
  '3 Wednesday',
  '4 Thursday',
];

export const DummyDataset = (
  data = RandomUtils.generateRandomSequence(1, 25, DummyLabels.length)
) => [
  {
    label: '# of Votes',
    data: data,
    format: {
      label: '# of Votes',
      backgroundColor: ['#1387FB', '#13239D', '#E56C37', '#9E018F'],
      borderColor: ['#1387FB', '#13239D', '#E56C37', '#9E018F'],
      borderWidth: 1,
    },
  },
];

export const DummyChartOptions = (theme: Theme): any => ({
  legend: {
    display: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
  },
});
