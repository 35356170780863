import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerBackground: {
    width: '100%',
    height: '100%',
    padding: 24,
    paddingBottom: 40,
    backgroundColor: '#356CB0',
    position: 'relative',
    zIndex: 0,
  },
  boxTopLogo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  boxLanguage: {
    '& >button': {
      color: '#fff',
      borderColor: '#F2F2F2',
      '&:hover': {
        borderColor: '#F2F2F2',
      },
    },
  },
  boxReduce: {
    fontWeight: 500,
    fontSize: 20,
    marginTop: 100,
    color: '#fff',
  },
  boxDecision: {
    fontWeight: 500,
    fontSize: 24,
    marginTop: 36,
    marginBottom: 20,
    color: '#fff',
  },
  img_main_logo: {
    height: 60,
    width: 'auto',
  },
  boxContent: {
    fontSize: 16,
    color: '#fff',
    textAlign: 'left',
  },
  curveImageBg: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: -1,
    left: 0,
  },
}));

export default useStyles;
