import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import TranslatedText from 'components/atoms/translated_text';
import {
  AppDatePicker,
  AppSelect,
  AppTextField,
} from 'components/molecules/form_controls';
import GridLayout from 'components/molecules/grid_layout';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ScenarioAction from 'redux/reducers/scenario_reducer/actions';
import {
  useGetEstablishmentsname,
  useGetModal,
  useGetScenarioList,
  useGetScenarioOption,
} from 'redux/reducers/scenario_reducer/selectors';
import * as UserProfileSelectors from 'redux/reducers/userProfile_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import { Translations } from 'scenes/profile_page/configuration';
import { TableDefaultFetchValues } from 'scenes/scenario_page/configuration';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

const defaultValues = {
  name: '',
  type: '',
  totalNumberOfGuestsPerYear: null,
  totalNumberOfRooms: null,
  hotelGuestCapacity: null,
  restaurantCafeCapacity: null,
  totalNumberOfTablesAcrossAllRestaurant: null,
  beachBarCapacity: null,
  totalNumberOfTables: null,
  totalCapacity: null,
  start_date: moment().format(),
  end_date: moment().format(),
  country: null,
  year: null,
  establishment: null,
};

interface IFormData {
  name: string;
  type: string;
  totalNumberOfGuestsPerYear: number | null;
  totalNumberOfRooms: number | null;
  hotelGuestCapacity: number | null;
  restaurantCafeCapacity: number | null;
  totalNumberOfTablesAcrossAllRestaurant: number | null;
  beachBarCapacity: number | null;
  totalNumberOfTables: number | null;
  totalCapacity: number | null;
  start_date: string | null;
  end_date: string | null;
  country: string | null;
  year: string | null;
  establishment: string | null;
}

const SetupProfile = () => {
  const classes = useStyles();
  const { isOpen, type, data } = useGetModal();
  const scenariosOption = useGetScenarioOption();
  const scenariosList = useGetScenarioList();
  const establishmentsname = useGetEstablishmentsname();

  const dispatch = useDispatch();
  // const userDetailsData = UserProfileSelectors.useGetUserProfile();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
  } = useForm<IFormData>({
    defaultValues: defaultValues,
  });

  const [hotelSetup, setHotelSetup] = useState<any[]>([]);
  const [isShowNote, setisShowNote] = useState(false);

  React.useEffect(() => {
    if (type === 'edit' && scenariosList.status === 'Success') {
      const scenarioFiltered = scenariosList.data?.items.filter(
        (item: any) => item._id === data
      );
      if (scenarioFiltered) {
        const scenario =
          scenarioFiltered?.length > 0 ? scenarioFiltered[0] : null;

        if (scenario) {
          reset({
            beachBarCapacity: Number(_.get(scenario, 'beachBarCapacity', '0')),
            hotelGuestCapacity: Number(
              _.get(scenario, 'hotelGuestCapacity', '0')
            ),
            name: _.get(scenario, 'name', ''),
            restaurantCafeCapacity: Number(
              _.get(scenario, 'restaurantCafeCapacity', '0')
            ),
            totalNumberOfGuestsPerYear: Number(
              _.get(scenario, 'totalNumberOfGuestsPerYear', '0')
            ),
            totalNumberOfRooms: Number(
              _.get(scenario, 'totalNumberOfRooms', '0')
            ),
            totalNumberOfTablesAcrossAllRestaurant: Number(
              _.get(scenario, 'totalNumberOfTablesAcrossAllRestaurant', '0')
            ),
            type: scenario.type || '',
            start_date: _.get(scenario, 'start_date', null),
            end_date: _.get(scenario, 'end_date', null),
            country: _.get(scenario, 'country', ''),
            year: _.get(scenario, 'year', null),
            establishment: _.get(scenario, 'establishment', null),
          });
        }
      }
    }
  }, [type, scenariosList, isOpen, data]);

  React.useEffect(() => {
    const _data = watch();
    if (['Hotel', 'Motel', 'B&B etc'].includes(_data.type)) {
      if (
        !_data.totalNumberOfGuestsPerYear ||
        !_data.totalNumberOfRooms ||
        !_data.hotelGuestCapacity ||
        !_data.restaurantCafeCapacity ||
        !_data.totalNumberOfTablesAcrossAllRestaurant ||
        !_data.beachBarCapacity
      ) {
        setisShowNote(true);
      } else {
        setisShowNote(false);
      }
    }
    if (['Restaurant', 'Bar'].includes(_data.type)) {
      if (!_data.totalNumberOfTables || !_data.totalCapacity) {
        setisShowNote(true);
      } else {
        setisShowNote(false);
      }
    }
    if (['', 'Other'].includes(_data.type)) {
      setisShowNote(false);
    }
  }, [watch]);

  // React.useEffect(() => {
  //   if (userDetailsData.status === StateStatus.Success) {
  //     reset({
  //       type: userDetailsData.data?.user?.type || '',
  //       totalNumberOfGuestsPerYear:
  //         userDetailsData.data?.user?.totalNumberOfGuestsPerYear || null,
  //       totalNumberOfRooms:
  //         userDetailsData.data?.user?.totalNumberOfRooms || null,
  //       hotelGuestCapacity:
  //         userDetailsData.data?.user?.hotelGuestCapacity || null,
  //       restaurantCafeCapacity:
  //         userDetailsData.data?.user?.restaurantCafeCapacity || null,
  //       totalNumberOfTablesAcrossAllRestaurant:
  //         userDetailsData.data?.user?.totalNumberOfTablesAcrossAllRestaurant ||
  //         null,
  //       beachBarCapacity: userDetailsData.data?.user?.beachBarCapacity || null,
  //       totalNumberOfTables:
  //         userDetailsData.data?.user?.totalNumberOfTables || null,
  //       totalCapacity: userDetailsData.data?.user?.totalCapacity || null,
  //     });
  //   }
  // }, [userDetailsData.status]);

  useEffect(() => {
    const type = watch('type');
    const shared = [
      {
        id: '9',
        size: 12,
        element: (
          <AppDatePicker
            variant="dialog"
            margin="normal"
            fullWidth
            id="start"
            rules={{}}
            control={control}
            label={'Start period of applicability'}
            name="start_date"
          />
        ),
      },
      {
        id: '10',
        size: 12,
        element: (
          <AppDatePicker
            variant="dialog"
            margin="normal"
            fullWidth
            id="end"
            rules={{}}
            control={control}
            label={'End period of applicability'}
            name="end_date"
          />
        ),
      },
      {
        id: '11',
        size: 12,
        element: (
          <AppTextField
            variant="outlined"
            rules={{}}
            margin="normal"
            fullWidth
            id="country"
            control={control}
            label={'Establishment country'}
            autoComplete="country"
            name="country"
          />
        ),
      },
      {
        id: '12',
        size: 12,
        element: (
          <AppTextField
            type="number"
            variant="outlined"
            margin="normal"
            rules={{}}
            fullWidth
            id="year"
            control={control}
            label={'Yearly Turnover'}
            autoComplete="year"
            name="year"
          />
        ),
      },
    ];

    if (['Hotel', 'Motel', 'B&B etc'].includes(type)) {
      setHotelSetup([
        {
          id: '1',
          size: 6,
          element: (
            <AppTextField
              variant="outlined"
              margin="normal"
              type="number"
              error={'totalNumberOfGuestsPerYear' in errors}
              helperText={
                errors.totalNumberOfGuestsPerYear &&
                errors.totalNumberOfGuestsPerYear.message
              }
              rules={
                {
                  // required: <RequiredField />,
                }
              }
              fullWidth
              id="totalNumberOfGuestsPerYear"
              control={control}
              label={'Total Number Of Guests Per Year'}
              autoComplete="Total Number Of Guests Per Year"
              name="totalNumberOfGuestsPerYear"
            />
          ),
        },
        {
          id: '2',
          size: 6,
          element: (
            <AppTextField
              variant="outlined"
              margin="normal"
              type="number"
              error={'totalNumberOfRooms' in errors}
              helperText={
                errors.totalNumberOfRooms && errors.totalNumberOfRooms.message
              }
              rules={
                {
                  // required: <RequiredField />,
                }
              }
              fullWidth
              id="totalNumberOfRooms"
              control={control}
              label={'Total Number Of Rooms'}
              autoComplete="totalNumberOfRooms"
              name="totalNumberOfRooms"
            />
          ),
        },
        {
          id: '3',
          size: 6,
          element: (
            <AppTextField
              variant="outlined"
              margin="normal"
              type="number"
              error={'hotelGuestCapacity' in errors}
              helperText={
                errors.hotelGuestCapacity && errors.hotelGuestCapacity.message
              }
              rules={
                {
                  // required: <RequiredField />,
                }
              }
              fullWidth
              id="hotelGuestCapacity"
              control={control}
              label={'Hotel Guest Capacity'}
              autoComplete="hotelGuestCapacity"
              name="hotelGuestCapacity"
            />
          ),
        },
        {
          id: '4',
          size: 6,
          element: (
            <AppTextField
              variant="outlined"
              margin="normal"
              type="number"
              error={'restaurantCafeCapacity' in errors}
              helperText={
                errors.restaurantCafeCapacity &&
                errors.restaurantCafeCapacity.message
              }
              rules={
                {
                  // required: <RequiredField />,
                }
              }
              fullWidth
              id="restaurantCafeCapacity"
              control={control}
              label={'Restaurant/Cafe Capacity'}
              autoComplete="restaurantCafeCapacity"
              name="restaurantCafeCapacity"
            />
          ),
        },
        {
          id: '5',
          size: 6,
          element: (
            <AppTextField
              variant="outlined"
              margin="normal"
              type="number"
              error={'totalNumberOfTablesAcrossAllRestaurant' in errors}
              helperText={
                errors.totalNumberOfTablesAcrossAllRestaurant &&
                errors.totalNumberOfTablesAcrossAllRestaurant.message
              }
              rules={
                {
                  // required: <RequiredField />,
                }
              }
              fullWidth
              id="totalNumberOfTablesAcrossAllRestaurant"
              control={control}
              label={'Total Number Of Tables Across All Restaurant'}
              autoComplete="totalNumberOfTablesAcrossAllRestaurant"
              name="totalNumberOfTablesAcrossAllRestaurant"
            />
          ),
        },
        {
          id: '6',
          size: 6,
          element: (
            <AppTextField
              variant="outlined"
              margin="normal"
              type="number"
              error={'beachBarCapacity' in errors}
              helperText={
                errors.beachBarCapacity && errors.beachBarCapacity.message
              }
              rules={
                {
                  // required: <RequiredField />,
                }
              }
              fullWidth
              id="beachBarCapacity"
              control={control}
              label={'Beach Bar Capacity'}
              name="beachBarCapacity"
            />
          ),
        },
        ...shared,
      ]);
    }
    if (['Restaurant', 'Bar'].includes(type)) {
      setHotelSetup([
        {
          id: '7',
          size: 6,
          element: (
            <AppTextField
              variant="outlined"
              type="number"
              margin="normal"
              error={'totalNumberOfTables' in errors}
              helperText={
                errors.totalNumberOfTables && errors.totalNumberOfTables.message
              }
              rules={
                {
                  // required: <RequiredField />,
                }
              }
              fullWidth
              id="totalNumberOfTables"
              control={control}
              label={'Total Number Of Tables'}
              autoComplete="totalNumberOfTables"
              name="totalNumberOfTables"
            />
          ),
        },
        {
          id: '8',
          size: 6,
          element: (
            <AppTextField
              variant="outlined"
              margin="normal"
              type="number"
              error={'totalCapacity' in errors}
              helperText={errors.totalCapacity && errors.totalCapacity.message}
              rules={
                {
                  // required: <RequiredField />,
                }
              }
              fullWidth
              id="totalCapacity"
              control={control}
              label={'Total Capacity'}
              autoComplete="totalCapacity"
              name="totalCapacity"
            />
          ),
        },
        ...shared,
      ]);
    }
    if (type === '' || type === 'Other') {
      setHotelSetup([]);
    }
  }, [watch('type')]);
  // console.log('Data:', userDetailsData.data);

  const onSubmit = (_data: IFormData) => {
    if (type === 'add') {
      dispatch(
        ScenarioAction.addNewScenarioAction({
          // id,
          fetchScenarioInput: ScenarioAction.fetchScenarioListAction({
            sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
            page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
            pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
            order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          }),
          name: _data.name,
          type: _data.type,
          totalNumberOfGuestsPerYear: _data.totalNumberOfGuestsPerYear || 0,
          totalNumberOfRooms: _data.totalNumberOfRooms || 0,
          hotelGuestCapacity: _data.hotelGuestCapacity || 0,
          restaurantCafeCapacity: _data.restaurantCafeCapacity || 0,
          totalNumberOfTablesAcrossAllRestaurant:
            _data.totalNumberOfTablesAcrossAllRestaurant || 0,
          beachBarCapacity: _data.beachBarCapacity || 0,
          totalNumberOfTables: _data.totalNumberOfTables || 0,
          totalCapacity: _data.totalCapacity || 0,
          start_date: _data.start_date,
          end_date: _data.end_date,
          country: _data.country,
          year: _data.year,
          establishment: _data.establishment,
        })
      );
    } else {
      dispatch(
        ScenarioAction.editScenarioAction({
          _id: data,
          fetchScenarioInput: ScenarioAction.fetchScenarioListAction({
            sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
            page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
            pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
            order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
          }),
          name: _data.name,
          type: _data.type,
          totalNumberOfGuestsPerYear: _data.totalNumberOfGuestsPerYear || 0,
          totalNumberOfRooms: _data.totalNumberOfRooms || 0,
          hotelGuestCapacity: _data.hotelGuestCapacity || 0,
          restaurantCafeCapacity: _data.restaurantCafeCapacity || 0,
          totalNumberOfTablesAcrossAllRestaurant:
            _data.totalNumberOfTablesAcrossAllRestaurant || 0,
          beachBarCapacity: _data.beachBarCapacity || 0,
          totalNumberOfTables: _data.totalNumberOfTables || 0,
          totalCapacity: _data.totalCapacity || 0,
          start_date: _data.start_date,
          end_date: _data.end_date,
          country: _data.country,
          year: _data.year,
          establishment: _data.establishment,
        })
      );
    }

    dispatch(
      ScenarioAction.setModal({
        isOpen: false,
        type: 'add',
        data: null,
      })
    );
  };
  const typeOptions = [
    { id: 'Hotel', label: 'Hotel' },
    { id: 'Motel', label: 'Motel' },
    { id: 'B&B etc', label: 'B&B etc' },
    { id: 'Restaurant', label: 'Restaurant' },
    { id: 'Bar', label: 'Bar' },
    { id: 'Other', label: 'Other' },
  ];
  const typeIds = typeOptions.map((item: any) => item.id);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      // autoComplete="off"
      // noValidate
      className={clsx(classes.root)}
    >
      <Card>
        <Box className={classes.titleBox}>
          <CardHeader
            subheader="You can update your information here"
            title="Scenario setup"
            style={{ padding: 0 }}
          />
          <CloseIcon
            style={{ color: '#828282', cursor: 'pointer' }}
            onClick={() =>
              dispatch(ScenarioAction.setModal({ isOpen: false, data: null }))
            }
          />
        </Box>
        <Divider />
        <CardContent style={{ padding: 24 }}>
          <GridLayout
            elements={[
              {
                id: '-1',
                size: 12,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    error={'name' in errors}
                    helperText={errors.name && errors.name.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="name"
                    control={control}
                    label={'Scenario title'}
                    autoComplete="name"
                    name="name"
                  />
                ),
              },
              {
                id: '0',
                size: 12,
                element: (
                  <AppSelect
                    variant="outlined"
                    margin="normal"
                    error={'type' in errors}
                    helperText={errors.type && errors.type.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    options={typeOptions}
                    fullWidth
                    id="type"
                    control={control}
                    label={'Type'}
                    autoComplete="type"
                    name="type"
                  />
                ),
              },
              ..._.uniqBy([...hotelSetup], 'id'),

              {
                id: '13',
                size: 12,
                element: (
                  <AppSelect
                    variant="outlined"
                    margin="normal"
                    error={'establishment' in errors}
                    helperText={
                      errors.establishment && errors.establishment.message
                    }
                    rules={{}}
                    options={
                      establishmentsname?.data?.map(
                        (esta: { _id: string; name: string }) => {
                          return {
                            label: esta.name,
                            id: esta._id,
                          };
                        }
                      ) || []
                    }
                    fullWidth
                    id="establishment"
                    control={control}
                    label={'Establishment'}
                    autoComplete="establishment"
                    name="establishment"
                  />
                ),
              },
            ]}
          />
        </CardContent>
        {isShowNote && (
          <Box padding="24px">
            <Typography variant="body1" style={{ color: 'red' }}>
              Please note that proceeding without completing all fields will
              limit the DST’s ability to provide suggestions for your selections
              e.g. suggested quantities
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end" p={2} padding="24px">
          <SubmitWrapper />
        </Box>
      </Card>
    </form>
  );
};

const SubmitWrapper = React.memo(() => {
  const submitStatus = UserProfileSelectors.useGetEditUserStatus();
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box display="flex">
      <Button
        // type={'submit'}
        color="primary"
        onClick={() =>
          dispatch(ScenarioAction.setModal({ isOpen: false, data: null }))
        }
        variant="outlined"
        // disabled={submitStatus === StateStatus.Pending}
        className={classes.buttonCancel}
      >
        <TranslatedText
          defaultText={'Cancel'}
          // textMap={Translations.form_submit}
        />
      </Button>
      <Button
        style={{ marginLeft: 10 }}
        type={'submit'}
        color="primary"
        variant="contained"
        disabled={submitStatus === StateStatus.Pending}
        className={classes.buttonAdd}
      >
        <TranslatedText
          defaultText={'Save Setup'}
          textMap={Translations.form_submit}
        />
      </Button>
    </Box>
  );
});

const RequiredField = () => {
  return (
    <TranslatedText
      textMap={Translations.form_error_required}
      defaultText={'Required Value'}
    />
  );
};

SetupProfile.propTypes = {
  className: PropTypes.string,
};

export default SetupProfile;
