import React from 'react';
import HeatmapChart from 'components/molecules/custom_chart/components/apex_charts';
import * as RandomUtils from 'utils/randomize';
import { ModalConfigWrapper } from 'components/app_common/modal_config_wrapper';

const generateData = (quantity: number, { min, max }: any) =>
  RandomUtils.generateRandomSequence(min, max, quantity);

export default function HeatMapChart() {
  return (
    <ModalConfigWrapper name="HeatMapChart">
      <HeatmapChart
        type="heatmap"
        options={{
          chart: {
            height: 350,
            type: 'heatmap',
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#008FFB'],
          title: {
            text: 'HeatMap Chart (Single color)',
          },
        }}
        series={[
          {
            name: 'Metric1',
            data: generateData(18, {
              min: 0,
              max: 90,
            }),
          },
          {
            name: 'Metric2',
            data: generateData(18, {
              min: 0,
              max: 90,
            }),
          },
          {
            name: 'Metric3',
            data: generateData(18, {
              min: 0,
              max: 90,
            }),
          },
          {
            name: 'Metric4',
            data: generateData(18, {
              min: 0,
              max: 90,
            }),
          },
          {
            name: 'Metric5',
            data: generateData(18, {
              min: 0,
              max: 90,
            }),
          },
          {
            name: 'Metric6',
            data: generateData(18, {
              min: 0,
              max: 90,
            }),
          },
          {
            name: 'Metric7',
            data: generateData(18, {
              min: 0,
              max: 90,
            }),
          },
          {
            name: 'Metric8',
            data: generateData(18, {
              min: 0,
              max: 90,
            }),
          },
          {
            name: 'Metric9',
            data: generateData(18, {
              min: 0,
              max: 90,
            }),
          },
        ]}
      />
    </ModalConfigWrapper>
  );
}
