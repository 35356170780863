import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import _ from 'lodash';
import { numberWithCommas } from 'utils/strings';

function getRevenueConfig(
  data: any,
  title: string[],
  projectedField: string,
  unit: string,
  id: string,
  color: string
) {
  const barChart: IGridConf[] = [
    // 'Estimated Reduction in yearly marine litter'
    {
      width: 12,
      type: ComponentTypeEnum.apexChart,
      props: {
        type: 'bar',
        height: 350,

        series: [
          {
            name: title,
            data: data.map((item: any) => {
              return item[projectedField] !== null
                ? Number(item[projectedField]).toFixed(4)
                : 0;
            }),
            color: color || '#599FF4',
          },
        ],
        options: {
          chart: {
            id,
            type: 'bar',
            toolbar: {
              show: false,
            },
            stacked: true,
            zoom: {
              enabled: false,
            },
            // height: 350,
          },
          title: {
            text: title,
          },
          colors: ['#008FFB', '#FF4560'],
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: data.map((item: any) => item.productCategory),
            title: {
              text: unit,
            },
          },
          yaxis: {
            // min: _.min(data.map((item: any) => item[projectedField]))
            //   ? Math.round(
            //       Number(_.min(data.map((item: any) => item[projectedField]))) /
            //         10
            //     ) * 10
            //   : 0,
            // max: _.max(data.map((item: any) => item[projectedField]))
            //   ? Math.round(
            //       Number(_.max(data.map((item: any) => item[projectedField])))
            //     )
            //   : 10,
            labels: {
              formatter: (value: number) => {
                if (value > 1000) {
                  console.log('test', numberWithCommas(value));
                  return numberWithCommas(value);
                } else {
                  return value;
                }
              },
            },
          },
          tooltip: {
            shared: false,
            y: {
              formatter: function (
                value: number,
                { series, seriesIndex, dataPointIndex, w }: any
              ) {
                return value;
              },
              title: {
                formatter: (seriesName: string) =>
                  seriesName.length > 20
                    ? seriesName.substring(0, 20) + ' ...'
                    : seriesName,
              },
            },
          },
        },
      },
      fetch: null,
      // fetch: `/api/dashboard-pub/general-publisher-revenue-areachart/${userid}?appid=${appid}&publisherid=${publisherid}&datefrom=${datefrom}&dateto=${dateto}`,
    },
  ];
  return barChart;
}

export default getRevenueConfig;
