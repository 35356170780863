import {
  ILeafletMapProps,
  geoJsonMapDefaultParser,
  IGeoJsonData,
} from 'components/molecules/leaflet_map';
import * as DummyData from 'components/molecules/leaflet_map/fake_data/dummy_data.json';
import * as Cyprus from 'components/molecules/leaflet_map/fake_data/cyprus.json';
import { Layer } from 'leaflet';
import { IGlobal, IGlobals, EventsEnum } from '.';
import React from 'react';
import { Map } from 'leaflet';

export interface IMapsConfig {
  geoJsonData: IGeoJsonData;
}
export type MapsTypes = 'googleMaps' | 'leafletMaps';

const innerBounds = [
  [49.505, -2.09],
  [53.505, 2.09],
];
const outerBounds = [
  [50.505, -29.09],
  [52.505, 29.09],
];

export const convert = (
  data: IMapsConfig,
  globals: IGlobals,
  handleUpdateGlobals: (newGlobals: IGlobals) => void
): ILeafletMapProps => {
  return {
    scrollWheelZoom: true,
    onEachFeature: (element: any, layer: Layer, map: Map) => {
      // console.log("KKKEYYY:", globals, element?.properties, globals[EventsEnum.MAP_ONCLICK_KEY]);
      let value = globals[EventsEnum.MAP_ONCLICK_KEY];
      layer.on({
        click: (event) => {
          // console.log("EVVE:", event);
          // const { current = {} } = mapRef;
          // const { leafletElement: map } = current;
          // setBounds(outerBounds);
          // map.fitBounds([event.latlng.lat as any, event.latlng.lng as any]);
          map.setMaxZoom(10);
          map.fitBounds([[event.latlng.lat as any, event.latlng.lng as any]]);
          handleUpdateGlobals({
            ...globals,
            [EventsEnum.MAP_ONCLICK_VALUE]:
              element?.properties[value] ||
              globals[EventsEnum.MAP_ONCLICK_VALUE],
          });
        },
      });
    },
    geoJsonProps: {
      data: data.geoJsonData.features as any,
    },
  };
};
