import { LanguagesEnum } from '../../config/languages';

const Configuration = {
  backgroundImage: require('../../assets/images/backgrounds/background_login_register.png'),
};

export const Translations = {
  recover_title: {
    [LanguagesEnum.English]: 'Reset your password',
    [LanguagesEnum.Greek]: 'Επαναφορά κωδικού',
  },
  form_field_email: {
    [LanguagesEnum.English]: 'Email',
    [LanguagesEnum.Greek]: 'Email',
  },
  form_button_recover: {
    [LanguagesEnum.English]: 'Reset',
    [LanguagesEnum.Greek]: 'Επαναφορά',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'This is required',
    [LanguagesEnum.Greek]: 'Υποχρεωτικό πεδίο',
  },
  back_to_login: {
    [LanguagesEnum.English]: 'Back to login',
    [LanguagesEnum.Greek]: 'Επιστροφή στο Login',
  },
  invalid_email_format: {
    [LanguagesEnum.English]: 'Invalid email format',
    [LanguagesEnum.Greek]: 'Λανθεσμένο email',
  },
};

export default Configuration;
