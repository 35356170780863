import React from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import CardLabelTitle from 'components/app_common/card_label_title';
import { colors, Theme } from '@material-ui/core';
import * as RandomUtils from 'utils/randomize';
import { ModalConfigWrapper } from 'components/app_common/modal_config_wrapper';

export default function ColumnStackedChart() {
  return (
    <ModalConfigWrapper name="ColumnStackedChart">
      <CustomChart
        title={
          <CardLabelTitle
            title={<TranslatedText defaultText="Sample Column Chart" />}
          />
        }
        layoutProps={{
          variant: 'outlined',
        }}
        data={DummyDataset()}
        labels={DummyLabels}
        options={DummyChartOptions}
        chartType={ChartTypesEnum.COLUMN}
      />
    </ModalConfigWrapper>
  );
}

export const DummyLabels = [
  '1 Monday',
  '2 Tuesday',
  '3 Wednesday',
  '4 Thursday',
  '5 Friday',
  '6 Suturday',
  '7 Sunday',
];
export const DummyDataset = (
  dataset1 = {
    label: 'DummyDataset1',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  },
  dataset2 = {
    label: 'DummyDataset2',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  },
  dataset3 = {
    label: 'DummyDataset3',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  },
  dataset4 = {
    label: 'DummyDataset4',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  }
) => [
  {
    data: dataset1.data,
    label: 'Last year',
    format: {
      label: dataset1.label,
      fill: false,
      borderColor: '#0094E8',
      backgroundColor: '#0094E8',
      pointBorderColor: '#0094E8',
      pointBackgroundColor: '#0094E8',
      pointHoverBackgroundColor: '#0094E8',
      pointHoverBorderColor: '#0094E8',
    },
  },
  {
    data: dataset2.data,
    label: 'This year',
    format: {
      label: dataset2.label,
      fill: false,
      borderColor: '#4668C2',
      backgroundColor: '#4668C2',
      pointBorderColor: '#4668C2',
      pointBackgroundColor: '#4668C2',
      pointHoverBackgroundColor: '#4668C2',
      pointHoverBorderColor: '#4668C2',
    },
  },
  {
    data: dataset3.data,
    label: 'Last year',
    format: {
      label: dataset3.label,
      fill: false,
      borderColor: '#FF6100',
      backgroundColor: '#FF6100',
      pointBorderColor: '#FF6100',
      pointBackgroundColor: '#FF6100',
      pointHoverBackgroundColor: '#FF6100',
      pointHoverBorderColor: '#FF6100',
    },
  },
  {
    data: dataset4.data,
    label: 'Last year',
    format: {
      label: dataset4.label,
      fill: false,
      borderColor: '#9E018F',
      backgroundColor: '#9E018F',
      pointBorderColor: '#9E018F',
      pointBackgroundColor: '#9E018F',
      pointHoverBackgroundColor: '#9E018F',
      pointHoverBorderColor: '#9E018F',
    },
  },
];

export const DummyChartOptions = (theme: Theme): any => ({
  legend: {
    display: true,
    align: 'start',
    labels: {
      usePointStyle: true,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          // min: 0,
          // max: 100,
          callback: function (label: any, index: any, labels: any) {
            return `${label} %`;
          },
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});
