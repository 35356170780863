import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  GroupList: {
    status: StateStatus | null;
    data: any;
  };
  addGroup: {
    status: StateStatus | null;
  };
  editGroup: {
    status: StateStatus | null;
  };
  viewGroup: {
    status: StateStatus | null;
    data: any;
  };
  usersname: {
    status: StateStatus | null;
    data: any;
  };
  establishmentsName: {
    status: StateStatus | null;
    data: any;
  };
  deleteGroup: {
    status: StateStatus | null;
  };
}

const initialState: IState = {
  GroupList: {
    status: null,
    data: null,
  },
  addGroup: {
    status: null,
  },
  editGroup: {
    status: null,
  },
  viewGroup: {
    status: null,
    data: null,
  },
  usersname: {
    status: null,
    data: null,
  },
  establishmentsName: {
    status: null,
    data: null,
  },
  deleteGroup: {
    status: null,
  },
};

const GroupReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_GROUP_LIST:
      return {
        ...state,
        GroupList: {
          ...state.GroupList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.GroupList.data : action.data,
        },
      };
    case Types.SET_ADD_GROUP:
      return {
        ...state,
        addGroup: {
          ...state.addGroup,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_GROUP:
      return {
        ...state,
        editGroup: {
          ...state.editGroup,
          status: action.status || null,
        },
      };
    case Types.SET_GROUP_DETAIL:
      return {
        ...state,
        viewGroup: {
          ...state.viewGroup,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.viewGroup.data : action.data,
        },
      };
    case Types.SET_USERNAMES:
      return {
        ...state,
        usersname: {
          ...state.usersname,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.usersname.data : action.data,
        },
      };
    case Types.SET_ESTABLISHMENTS_NAME:
      return {
        ...state,
        establishmentsName: {
          ...state.establishmentsName,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.establishmentsName.data
              : action.data,
        },
      };
    case Types.DELETE_GROUP:
      return {
        ...state,
        deleteGroup: {
          ...state.deleteGroup,
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default GroupReducer;
