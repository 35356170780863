import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import ScenarioActions from 'redux/reducers/scenario_reducer/actions';
import { useGetCombineScenarios } from 'redux/reducers/scenario_reducer/selectors';
import { showToastAction } from 'components/atoms/toast_message';

//table key
//

function CheckBoxCell(props: { scenarioId: string }) {
  const combinedScenarios = useGetCombineScenarios();
  const dispatch = useDispatch();
  const [checked, setchecked] = React.useState(false);
  React.useEffect(() => {
    if (combinedScenarios.includes(props.scenarioId)) {
      setchecked(true);
    } else {
      setchecked(false);
    }
  }, [combinedScenarios, props.scenarioId]);

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    if (combinedScenarios.length === 5 && event.target.checked) {
      showToastAction(
        dispatch,
        'Only 5 scenarios are allowed. Please unselect first',
        'error'
      );
    } else {
      setchecked(event.target.checked);
      const currentScenarios = [...combinedScenarios];
      if (event.target.checked) {
        dispatch(
          ScenarioActions.setCombineScenarios(
            _.uniq([...currentScenarios, event.target.value])
          )
        );
      } else {
        dispatch(
          ScenarioActions.setCombineScenarios(
            currentScenarios.filter((id: string) => id !== event.target.value)
          )
        );
      }
    }
  }

  return (
    <Checkbox
      checked={checked}
      value={props.scenarioId}
      onChange={handleCheck}
    />
  );
}

export const TableColumns = [
  {
    label: 'Select Scenario',
    id: 'checkbox',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: any
    ) => (
      <React.Fragment>
        {<CheckBoxCell scenarioId={data[rowIndex]._id} />}
      </React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Scenario title',
    id: 'name',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },

  {
    label: 'Type',
    id: 'type',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Establishment country',
    id: 'country',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Yearly Turnover',
    id: 'year',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Start period of applicability',
    id: 'start_date',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {moment(cellValue).format('DD-MM-YYYY hh:mm:ss')}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'End period of applicability',
    id: 'end_date',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {moment(cellValue).format('DD-MM-YYYY hh:mm:ss')}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Created at',
    id: 'createdAt',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {moment(cellValue).format('DD-MM-YYYY hh:mm:ss')}
      </React.Fragment>
    ),
    inData: true,
  },
];
