import { Box } from '@material-ui/core';
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  scrollingContainer: {
    maxHeight: `calc(100vh - 56px)`,
    overflow: 'auto',
    width: '100%',
    '@media (min-width:600px)': {
      maxHeight: `calc(100vh - 64px)`,
    },
  },
  simpleContainer: {
    height: `calc(100vh - 56px)`,
    width: '100%',
    '@media (min-width:600px)': {
      height: `calc(100vh - 64px)`,
    },
  },
}));

interface IFullPage {
  children?: React.ReactElement;
  nestedScrolling?: boolean;
}

export default function FullPageContainer({
  children,
  nestedScrolling = true,
}: IFullPage) {
  const classes = useStyles();
  return (
    <Box
      className={clsx({
        [classes.scrollingContainer]: nestedScrolling,
        [classes.simpleContainer]: !nestedScrolling,
      })}
    >
      {children}
    </Box>
  );
}
