import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      background: 'red'
      // height: `calc(100vh - 96px)`,
      // height: '100vh',
    },
    grow: {
      flexGrow: 1,
      // background: 'red',
      // flexDirection: 'column',
    },
  })
);

export default useStyles;
