import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  userList: {
    status: StateStatus | null;
    data: any;
  };
  updateUser: {
    status: StateStatus | null;
  };
  changePassword: {
    status: StateStatus | null;
  };
  modal: {
    isOpen: boolean;
    type: string;
    data: any;
  };
  userDetail: {
    status: StateStatus | null;
    data: any;
  };
}

const initialState: IState = {
  userList: {
    status: null,
    data: null,
  },
  updateUser: {
    status: null,
  },
  changePassword: {
    status: null,
  },
  modal: {
    isOpen: false,
    type: '',
    data: null,
  },
  userDetail: {
    status: null,
    data: null,
  },
};

const UserManagementReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USER_LIST:
      return {
        ...state,
        userList: {
          ...state.userList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.userList.data : action.data,
        },
      };

    case Types.UPDATE_USER:
      return {
        ...state,
        updateUser: {
          ...state.updateUser,
          status: action.status || null,
        },
      };
    case Types.CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          status: action.status || null,
        },
      };
    case Types.SET_MODAL:
      return {
        ...state,
        modal: action.data,
      };
    case Types.SET_USER_DETAIL:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.userDetail.data : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default UserManagementReducer;
