import React from 'react';
import { withRouter } from 'react-router'; // import the react-router-dom components
import useStyles from './styles';
import PageLayout from 'components/app_common/page_layout';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import DashboardBuilder, {
  CustomConfig,
  SimpleConfig,
} from 'components/compounds/dashboard_builder';
import * as Configuration from './configuration';
import { Grid, Paper } from '@material-ui/core';
import GridLayout from 'components/molecules/grid_layout';

const DashboardV2Page = (props: any) => {
  reactLogger.renderPage('DashboardV2Page');
  const classes = useStyles();
  const dipsatch = useDispatch();

  return (
    <PageLayout>
      <GridLayout
        elements={[
          {
            id: 'left-size',
            size: 7,
            element: (
              <DashboardBuilder
                configuration={Configuration.DashboardBuilderConfigLeftSide}
              />
            ),
          },
          {
            id: 'right-size',
            size: 5,
            element: (
              <DashboardBuilder
                configuration={Configuration.DashboardBuilderConfigRightSide}
              />
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

export default withRouter(DashboardV2Page);
