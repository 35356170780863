import React, { ReactElement } from 'react';
import {
  Select as MuiSelect,
  MenuItem,
  TextField,
  StandardTextFieldProps,
  InputAdornment,
} from '@material-ui/core';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';

interface IIcon {
  position: 'left' | 'right';
  Component: ReactElement;
}

interface IOptions {
  id: string | number;
  label: string | ReactElement;
}

interface Iprops
  extends Omit<
    StandardTextFieldProps,
    'onChange' | 'value' | 'variant' | 'name'
  > {
  includeNone?: boolean;
  options: IOptions[];
  control: Control;
  rules: any;
  variant: 'standard' | 'outlined' | 'filled';
  icon?: IIcon;
  name: string;
  handleChange?: (data: string) => void;
  controllerExtras?: any;
}

export default function Select(props: Iprops) {
  const {
    control,
    rules,
    includeNone,
    options,
    icon,
    handleChange,
    controllerExtras = {},
    ...other
  } = props;

  return (
    <Controller
      render={({ onChange, value }) => (
        <TextField
          select
          value={value || ''}
          onChange={(event) => {
            handleChange && handleChange(event.target.value);
            onChange(event);
          }}
          {...other}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                {icon && icon.position === 'right' && (
                  <InputAdornment position="start">
                    {icon.Component}
                  </InputAdornment>
                )}
              </React.Fragment>
            ),
          }}
        >
          {includeNone && <MenuItem value="">None</MenuItem>}
          {options.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
