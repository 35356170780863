import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  rememberMe: {
    color: '#666E7D',
    '& >span:last-child': {
      fontSize: 15,
    },
  },
  submit: {
    width: '100%',
    textTransform: 'none',
    backgroundColor: '#346CB0',
    marginTop: 30,
    '&:hover': {
      backgroundColor: '#2667b7',
    },
  },
  forgotPassword: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 13,
    color: '#404756',
    fontSize: 14,
    '& >a': {
      textDecoration: 'none',
    },
  },
  signUpHere: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 80,
    fontSize: 16,
    color: '#404756',
    '& >a': {
      textDecoration: 'none',
      '& >span': {
        color: '#5f93c9',
      },
    },
  },
}));

export default useStyles;
