import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** fetchGroupList  */
interface IGroupListOutput {
  items: IDataItems[];
  paging: IPaging;
}

interface IDataItems {
  name: string | null;
  address: string | null;
  establishments: string | null;
  admin: string | null;
}

interface IPaging {
  page: string;
  pageSize: string;
  total: number;
}

interface IGroupListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  name?: string;
}

export const fetchGroupList = async (
  payload: IGroupListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/group/list?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}`
  );

  let _data: IGroupListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

export const searchGroup = async (
  payload: IGroupListInput
): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/group/searchByName?page=${payload.page}&pageSize=${payload.pageSize}&sortField=${payload.sortField}&order=${payload.order}&name=${payload.name}`
  );

  let _data: IGroupListOutput = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** Group Detail */
interface IGroupDetailInput {
  id: string;
}

interface IGroupDetailOutput extends Types.ResponseOutput {
  data: {
    name: string | null;
    address: string | null;
    establishments: string | null;
    admin: string | null;
  };
}

export const fetchGroupDetail = async (
  payload: IGroupDetailInput
): Promise<IGroupDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/group/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Add Group */
interface IGroupAddInput {
  name: string | null;
  address: string | null;
  establishments: string[];
  admin: string[];
}

interface IGroupAddOutput extends Types.ResponseOutput {
  data: {};
}

export const addGroup = async (
  payload: IGroupAddInput
): Promise<IGroupAddOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/group `,

    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Edit Group */
interface IGroupEditInput {
  name: string | null;
  address: string | null;
  establishments: string | null;
  admin: string | null;
}

interface IGroupEditOutput extends Types.ResponseOutput {
  data: {};
}

export const editGroup = async (
  payload: IGroupEditInput
): Promise<IGroupEditOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/group `,
    payload
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
export const deleteGroup = async (payload: IGroupDetailInput): Promise<any> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/group/${payload.id}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

export const fetchGroupsByUserId = async (payload: {
  userId: string;
}): Promise<IGroupDetailOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/group/get-by-user/${payload.userId}`
  );

  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};
