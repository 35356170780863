import * as Types from './types';
import { IGridConf } from '../configuration';
import { Sample as ChartSample } from './types/chart';

export const Sample: Types.IDashboard = {
  dashboardElements: [
    {
      type: 'chart',
      config: ChartSample,
      size: 6,
      url: '/api/users/nationality',
    },
    {
      type: 'chart',
      config: ChartSample,
      size: 6,
      url: '/api/users/nationality',
    },
    {
      type: 'chart',
      config: ChartSample,
      size: 12,
      url: '/api/users/nationality',
    },
  ],
  globals: {
    user_id: '1234',
    nationality_id: 5,
  },
  title: '',
};

export const convertPlugin = Types.convert;

export const SampleFinalData: IGridConf[] = Types.convert(Sample, () => { });
