import React, { useEffect } from 'react';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useGetProduct } from 'redux/reducers/product_reducer/selectors';
import { SET_OPEN } from 'redux/reducers/product_reducer/types';
import Actions from 'redux/reducers/product_reducer/actions';
import Environment from 'config/environment';

interface Data {
  picture: any;
  productCategory: string;
  productType: string;
  usageCategory: string;
  climateChange: number;
  humanToxicity: number;
  acidificationPotential: number;
  respiratoryEffectsOnHuman: number;
  carcinogenicEffectsOnHuman: number;
  marineAquaticEcotoxicity: number;
  climateChangeImpactsAssociatedWithEOL: number;
  SUPLitteringRate: number;
  SUNPLitteringRate: number;
  MULitteringRate: number;
  MarineLittering: number;
  weight: number;
  annualReplacementRate: number;
  Comments: string;
  Compliance: string;
  // Furans: number;
  // Dioxins: number;
  // Formaldehyde: number;
  // PAH: number;
  // Benzene: number;
  // _1_3Butadiene: number;
  // Nickel: number;
  // Chromium: number;
  // Cadmium: number;
  // Arsenic: number;
  // VOCs: number;
  // SO2: number;
  // PM10: number;
  // PM2dot5: number;
  // NOx: number;
  // NH3: number;
  // Methane: number;
  // CO2NonFossil: number;
  // CO2Fossil: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'picture',
    numeric: false,
    disablePadding: false,
    label: 'Product picture',
  },
  {
    id: 'productCategory',
    numeric: false,
    disablePadding: false,
    label: 'Product Category',
  },
  {
    id: 'usageCategory',
    numeric: true,
    disablePadding: false,
    label: 'Usage Category',
  },
  {
    id: 'productType',
    numeric: true,
    disablePadding: false,
    label: 'Product Type',
  },
  {
    id: 'climateChange',
    numeric: false,
    disablePadding: false,
    label: 'Climate Change',
  },
  {
    id: 'humanToxicity',
    numeric: true,
    disablePadding: false,
    label: 'Human Toxicity',
  },
  {
    id: 'acidificationPotential',
    numeric: true,
    disablePadding: false,
    label: 'Acidification Potential',
  },
  {
    id: 'respiratoryEffectsOnHuman',
    numeric: false,
    disablePadding: false,
    label: 'Respiratory EffectsOnHuman',
  },
  {
    id: 'carcinogenicEffectsOnHuman',
    numeric: true,
    disablePadding: false,
    label: 'Carcinogenic Effects OnHuman',
  },
  {
    id: 'marineAquaticEcotoxicity',
    numeric: true,
    disablePadding: false,
    label: 'Marine Aquatic Ecotoxicity',
  },

  {
    id: 'climateChangeImpactsAssociatedWithEOL',
    numeric: true,
    disablePadding: false,
    label: 'climate Change Impacts Associated With EOL',
  },
  {
    id: 'SUPLitteringRate',
    numeric: false,
    disablePadding: false,
    label: 'SUP Littering Rate',
  },
  {
    id: 'SUNPLitteringRate',
    numeric: true,
    disablePadding: false,
    label: 'SUNP Littering Rate',
  },
  {
    id: 'MULitteringRate',
    numeric: true,
    disablePadding: false,
    label: 'MU Littering Rate',
  },
  {
    id: 'MarineLittering',
    numeric: false,
    disablePadding: false,
    label: 'Marine Littering',
  },
  {
    id: 'weight',
    numeric: true,
    disablePadding: false,
    label: 'Weight',
  },
  {
    id: 'annualReplacementRate',
    numeric: true,
    disablePadding: false,
    label: 'Annual Replacement Rate',
  },
  {
    id: 'Comments',
    numeric: true,
    disablePadding: false,
    label: 'Comments',
  },
  {
    id: 'Compliance',
    numeric: true,
    disablePadding: false,
    label: 'Compliance',
  },

  // { id: 'Furans', numeric: true, disablePadding: false, label: 'Furans' },
  // { id: 'Dioxins', numeric: true, disablePadding: false, label: 'Dioxins' },
  // {
  //   id: 'Formaldehyde',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Formaldehyde',
  // },
  // { id: 'PAH', numeric: true, disablePadding: false, label: 'PAH' },
  // { id: 'Benzene', numeric: true, disablePadding: false, label: 'Benzene' },
  // {
  //   id: '_1_3Butadiene',
  //   numeric: true,
  //   disablePadding: false,
  //   label: '_1_3Butadiene',
  // },
  // { id: 'Nickel', numeric: true, disablePadding: false, label: 'Nickel' },
  // { id: 'Chromium', numeric: true, disablePadding: false, label: 'Chromium' },
  // { id: 'Cadmium', numeric: true, disablePadding: false, label: 'Cadmium' },
  // { id: 'Arsenic', numeric: true, disablePadding: false, label: 'Arsenic' },
  // { id: 'VOCs', numeric: true, disablePadding: false, label: 'VOCs' },
  // { id: 'SO2', numeric: true, disablePadding: false, label: 'SO2' },
  // { id: 'PM10', numeric: true, disablePadding: false, label: 'PM10' },
  // { id: 'PM2dot5', numeric: true, disablePadding: false, label: 'PM2.5' },
  // { id: 'NOx', numeric: true, disablePadding: false, label: 'NOx' },
  // { id: 'NH3', numeric: true, disablePadding: false, label: 'NH3' },
  // { id: 'Methane', numeric: true, disablePadding: false, label: 'Methane' },
  // {
  //   id: 'CO2NonFossil',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'CO2NonFossil',
  // },
  // { id: 'CO2Fossil', numeric: true, disablePadding: false, label: 'CO2Fossil' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: '#FBFBFB' }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key={'Actions'} align={'left'} padding={'default'}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actions: {
      display: 'flex',
      minWidth: 100,
      justifyContent: 'space-between',
    },
    actionBtn: {
      width: 20,
      height: 20,
      cursor: 'pointer',
    },
  })
);

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('productCategory');
  const [page, setPage] = React.useState(0);
  const { status, data } = useGetProduct();
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    if (status === 'Success' && data) {
      setRows(data.items);
    }
  }, [data, status]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDelete = (data: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(Actions.UpdateProduct({ id: data._id, isDeleted: true }));
        // Swal.fire('Deleted!', 'The product has been deleted.', 'success');
      }
    });
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      //   onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                    >
                      <TableCell align="right">
                        <div style={{ width: '100%', display: 'flex' }}>
                          {row.picture ? (
                            <img
                              height="50px"
                              width="50px"
                              onError={(e: any) => {
                                e.target.src = require('assets/images/no-image-available.png');
                              }}
                              src={`${Environment.API_BASE_URL}/${row.picture.path}`}
                            />
                          ) : (
                            <img
                              height="50px"
                              width="50px"
                              src={require('../../../assets/images/no-image-available.png')}
                            />
                          )}
                        </div>
                      </TableCell>

                      <TableCell align="right">{row.productCategory}</TableCell>
                      <TableCell align="right">{row.usageCategory}</TableCell>
                      <TableCell align="right">{row.productType}</TableCell>
                      <TableCell align="right">{row.climateChange}</TableCell>
                      <TableCell align="right">{row.humanToxicity}</TableCell>
                      <TableCell align="right">
                        {row.acidificationPotential}
                      </TableCell>
                      <TableCell align="right">
                        {row.respiratoryEffectsOnHuman}
                      </TableCell>
                      <TableCell align="right">
                        {row.carcinogenicEffectsOnHuman}
                      </TableCell>
                      <TableCell align="right">
                        {row.marineAquaticEcotoxicity}
                      </TableCell>

                      <TableCell align="right">
                        {row.climateChangeImpactsAssociatedWithEOL}
                      </TableCell>
                      <TableCell align="right">
                        {row.SUPLitteringRate}
                      </TableCell>
                      <TableCell align="right">
                        {row.SUNPLitteringRate}
                      </TableCell>

                      <TableCell align="right">{row.MULitteringRate}</TableCell>
                      <TableCell align="right">{row.MarineLittering}</TableCell>

                      <TableCell align="right">{row.weight}</TableCell>
                      <TableCell align="right">
                        {row.annualReplacementRate}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title={row.Comments || ''}>
                          <Typography variant="body1">
                            {row.Comments
                              ? row.Comments.slice(0, 30) + '...'
                              : ''}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title={row.Compliance || ''}>
                          <Typography variant="body1">
                            {row.Compliance
                              ? row.Compliance.slice(0, 30) + '...'
                              : ''}
                          </Typography>
                        </Tooltip>
                      </TableCell>

                      <TableCell
                        className={classes.actions}
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        <img
                          className={classes.actionBtn}
                          src={require('../../../assets/images/icons/deleteicon.png')}
                          onClick={() => handleDelete(row)}
                        />
                        <img
                          className={classes.actionBtn}
                          src={require('../../../assets/images/icons/editicon.png')}
                          onClick={() => {
                            dispatch({
                              type: SET_OPEN,
                              data: {
                                isOpen: true,
                                data: row,
                                type: 'edit',
                              },
                            });
                          }}
                        />
                        {/* <Button
                          onClick={() => {
                            dispatch({
                              type: SET_OPEN,
                              data: {
                                isOpen: true,
                                data: row,
                                type: 'edit',
                              },
                            });
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Edit
                        </Button> */}
                        {/* <Button
                          onClick={() => handleDelete(row)}
                          variant="contained"
                          color="secondary"
                        >
                          Delete
                        </Button> */}
                      </TableCell>

                      {/* <TableCell align="right">{row.Furans}</TableCell>
                      <TableCell align="right">{row.Dioxins}</TableCell>
                      <TableCell align="right">{row.Formaldehyde}</TableCell>
                      <TableCell align="right">{row.PAH}</TableCell>
                      <TableCell align="right">{row.Benzene}</TableCell>
                      <TableCell align="right">{row._1_3Butadiene}</TableCell>
                      <TableCell align="right">{row.Nickel}</TableCell>
                      <TableCell align="right">{row.Chromium}</TableCell>
                      <TableCell align="right">{row.Cadmium}</TableCell>
                      <TableCell align="right">{row.Arsenic}</TableCell>
                      <TableCell align="right">{row.VOCs}</TableCell>
                      <TableCell align="right">{row.SO2}</TableCell>
                      <TableCell align="right">{row.PM10}</TableCell>
                      <TableCell align="right">{row.PM2dot5}</TableCell>
                      <TableCell align="right">{row.NOx}</TableCell>
                      <TableCell align="right">{row.NH3}</TableCell>
                      <TableCell align="right">{row.Methane}</TableCell>
                      <TableCell align="right">{row.CO2NonFossil}</TableCell>
                      <TableCell align="right">{row.CO2Fossil}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
