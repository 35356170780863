import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface UserList {
  status: StateStatus;
  data: any | null;
}
interface IModal {
  isOpen: boolean;
  type: string;
  data: any;
}
export const useGetUserList = (): UserList => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_MANAGEMENT_REDUCER].userList
  );
  return data;
};
export const useGetModal = (): IModal => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_MANAGEMENT_REDUCER].modal
  );
  return data;
};

export const useGetUpdateUserStatus = (): StateStatus | null => {
  const status = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_MANAGEMENT_REDUCER].updateUser.status
  );
  return status;
};
export const useGetUserDetail = (): UserList => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_MANAGEMENT_REDUCER].userDetail
  );
  return data;
};
