import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router'; // import the react-router-dom components
import GroupActions from '../../redux/reducers/group_reducer/actions';
import DatatableList from './components/datatable';
import GroupModal from './components/datatable/ViewEditData';
import { TableDefaultFetchValues } from './configuration';

const GroupPage = (props: any) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      GroupActions.fetchGroupListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
      })
    );
  }, [dispatch]);

  return (
    <div>
      <GroupModal />
      <DatatableList />
    </div>
  );
};

export default withRouter(GroupPage);
