import React, { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import DataList from 'components/molecules/data_list';
import useStyles from './styles';
import {
  Button,
  Divider,
  List,
  ListItem,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { IProps, Order } from '../../configuration';
import { tableSort, getComparator } from '../../utils';

interface ITableProps extends IProps {
  orderBy: string;
  page: number;
  // order: Order;
  rowsPerPage: number;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  createSortHandler: (
    property: string,
    inData: boolean
  ) => (event: React.MouseEvent<unknown>) => void;
}

export default function MobileList(props: ITableProps): ReactElement {
  const classes = useStyles();
  const {
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    createSortHandler,
  } = props;

  return (
    <Hidden smUp>
      <DataList
        data={props.data}
        renderElement={(row, index) => (
          <Paper elevation={1} className={classes.cardView}>
            <List key={index}>
              {props.columns.map((column, columnIndex) => {
                const value = row[column.id];
                return (
                  <React.Fragment key={columnIndex}>
                    <ListItem>
                      {column.renderListItem ? (
                        column.renderListItem(
                          index,
                          value,
                          columnIndex,
                          props.data
                        )
                      ) : (
                        <React.Fragment>
                          <div className={classes.listItem}>
                            <div>
                              <Typography variant="h5" component="h5">
                                {column.label}
                              </Typography>
                            </div>
                            <div>
                              <Typography variant="h6" component="h6">
                                {column.render(
                                  index,
                                  value,
                                  columnIndex,
                                  props.data
                                )}
                              </Typography>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </ListItem>
                    <Divider light />
                  </React.Fragment>
                );
              })}
            </List>
          </Paper>
        )}
      />
      {props.paging && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 200]}
          component="div"
          count={props.paging?.total || props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Hidden>
  );
}
