import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '100%',
    textTransform: 'none',
    backgroundColor: '#346CB0',
    marginTop: 30,
    '&:hover': {
      backgroundColor: '#2667b7',
    },
  },
  formControl: {},
  layout_links: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 25,
  },
  signUpHere: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    fontSize: 16,
    color: '#404756',
    '& >a': {
      textDecoration: 'none',
      '& >span': {
        color: '#5f93c9',
      },
    },
  },
}));

export default useStyles;
