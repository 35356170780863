import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as ScenarioService from 'services/api/scenario';
import * as UserService from 'services/api/user';
import * as EstablishmentService from 'services/api/establishment';

import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';
import { AnyKindOfDictionary } from 'lodash';

/** Fetch FetchScenarioListInput  */

interface FetchScenarioListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  name?: string;
}
const SetModal = (payload: any) => {
  return {
    type: Types.SET_SET_MODAL,
    data: payload,
  };
};
const SetCombine = (payload: { isOpen: boolean; data: any }) => {
  return {
    type: Types.SET_COMBINE,
    data: payload,
  };
};
const SetSelectedScenario = (payload: string | null) => {
  return {
    type: Types.SET_SELECTED_SCENARIO,
    data: payload,
  };
};
const SetCombineScenarios = (payload: string[]) => {
  return {
    type: Types.SET_COMBINE_SCENARIOS,
    data: payload,
  };
};

class FetchScenarioList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_SCENARIO_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_SCENARIO_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_SCENARIO_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchScenarioListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ScenarioService.fetchScenarioList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetcScenarioList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class searchScenario implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_SCENARIO_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_SCENARIO_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_SCENARIO_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchScenarioListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ScenarioService.searchScenario(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetcScenarioList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchScenarioActiveList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ACTIVE_SCENARIO,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ACTIVE_SCENARIO,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ACTIVE_SCENARIO,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ScenarioService.fetchActiveScenario();
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('fetchActiveScenario Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchScenarioOption implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_SCENARIO_OPTION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_SCENARIO_OPTION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_SCENARIO_OPTION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ScenarioService.fetchScenarioOption();
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetcScenarioOption Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewScenario  */

interface AddNewScenarioInput {
  type: string | null;
  totalnumberofguestsperyear: string | null;
  totalnumberofrooms: string | null;
  hotelguestcapacity: string | null;
  restaurantcafecapacity: string | null;
  totalnumberoftablesacrossallrestaurant: string | null;
  beachbarcapacity: string | null;
  totalnumberoftables: string | null;
  totalcapacity: string | null;
  fetchScenarioInput: FetchScenarioListInput;
}

class AddNewScenario implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_SCENARIO,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_SCENARIO,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_SCENARIO,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchScenarioInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await ScenarioService.addScenario(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchScenarioList().action(fetchScenarioInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_SCENARIO,
          })
        );
      } catch (error: any) {
        console.log('AddNewScenario Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt Scenario  */

interface EditScenarioInput {
  _id: string;
  type: string | null;
  totalnumberofguestsperyear: string | null;
  totalnumberofrooms: string | null;
  hotelguestcapacity: string | null;
  restaurantcafecapacity: string | null;
  totalnumberoftablesacrossallrestaurant: string | null;
  beachbarcapacity: string | null;
  totalnumberoftables: string | null;
  totalcapacity: string | null;
  user: string | null;
  name: string | null;
  fetchScenarioInput: FetchScenarioListInput;
}

class EditScenario implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_SCENARIO,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_SCENARIO,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_SCENARIO,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchScenarioInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await ScenarioService.editScenario(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchScenarioList().action(fetchScenarioInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_SCENARIO,
          })
        );
      } catch (error: any) {
        console.log('EditNotification Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View Scenario  */

interface ViewScenarioInput {
  id: number | string;
}

class ViewScenario implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_SCENARIO_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_SCENARIO_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_SCENARIO_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewScenarioInput): any {
    console.log('payload');
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ScenarioService.fetchScenarioDetail({
          id: payload.id,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('ViewScenario Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchEstablishmentsname implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ESTABLISHMENTS_NAME,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ESTABLISHMENTS_NAME,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ESTABLISHMENTS_NAME,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await EstablishmentService.fetchEstablishmentName();
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('Fetch establishmentnames Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class DeleteScenario implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.DELETE_SCENARIO,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.DELETE_SCENARIO,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.DELETE_SCENARIO,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(id: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await ScenarioService.deleteScenario({ _id: id });
        dispatch(
          new FetchScenarioList().action({
            sortField: '_id',
            order: 'desc',
            page: 0,
            pageSize: 10,
          })
        );
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('Delete Scenario Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class GetUserScenario implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.GET_USER_SCENARIO,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.GET_USER_SCENARIO,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.GET_USER_SCENARIO,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(id: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getUserProfile({ id: id });
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('Get user Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class UpdateUserScenario implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_USER_SCENARIO,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.UPDATE_USER_SCENARIO,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_USER_SCENARIO,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.updateUser(data);
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('Update user Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class ResetUserScenario implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.RESET_USER_SCENARIO,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.RESET_USER_SCENARIO,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.RESET_USER_SCENARIO,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: Types.RESET_USER_SCENARIO });
    };
  }
}

export default {
  fetchScenarioListAction: (payload: FetchScenarioListInput) =>
    new FetchScenarioList().action(payload),
  searchScenario: (payload: FetchScenarioListInput) =>
    new searchScenario().action(payload),
  addNewScenarioAction: (payload: any) => new AddNewScenario().action(payload),
  editScenarioAction: (payload: any) => new EditScenario().action(payload),
  viewScenarioAction: (payload: ViewScenarioInput) =>
    new ViewScenario().action(payload),
  setModal: (payload: any) => SetModal(payload),
  setCombine: (payload: { isOpen: boolean; data: any }) => SetCombine(payload),
  setSelectedScenario: (payload: string | null) => SetSelectedScenario(payload),
  fetchScenarioOption: () => new FetchScenarioOption().action(),
  fetchEstablishmentsname: () => new FetchEstablishmentsname().action(),
  FetchScenarioActiveList: () => new FetchScenarioActiveList().action(),
  setCombineScenarios: (payload: string[]) => SetCombineScenarios(payload),
  deleteScenario: (payload: string) => new DeleteScenario().action(payload),
  getUserScenario: (payload: string) => new GetUserScenario().action(payload),
  updateUserScenario: (payload: AnyKindOfDictionary) =>
    new UpdateUserScenario().action(payload),
  resetUserScenario: () => new ResetUserScenario().action(),
};
