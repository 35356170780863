import React from 'react';
import * as Configuration from './configuration';
import GridLayout from 'components/molecules/grid_layout';
import reactLogger from 'utils/logger';

export { default as CustomConfig } from './samples/CustomConfig';
export { default as SimpleConfig } from './samples/SimpleConfig';

export interface IDashboardGeneratorProps {
  configuration: Configuration.IGridConf[];
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;
  handleDashboardElement?: (
    component: React.ReactElement,
    index: number
  ) => React.ReactElement;
}

export default function DashboardGenerator(props: IDashboardGeneratorProps) {
  return (
    <React.Fragment>
      <GridLayout
        spacing={props.spacing ?? 1}
        elements={buildElements(
          props.configuration,
          props.handleDashboardElement
        )}
      />
    </React.Fragment>
  );
}

// add loading, ....
interface IElementWrapperProps extends Configuration.IGridConf {
  handleDashboardElement:
  | ((component: React.ReactElement, index: number) => React.ReactElement)
  | undefined;
  index: number;
}
const ElementWrapper = (props: IElementWrapperProps) => {
  reactLogger.renderComponent('ElementWrapper' + props.index);
  const [data, setData] = React.useState<null | any>(null);
  const _ = React.useState<any>(props);

  React.useEffect(() => {
    (async () => {
      let result =
        typeof props.fetch === 'string'
          ? await Configuration.fetchData(props.fetch)
          : typeof props.fetch === 'function'
            ? await props.fetch()
            : null;
      if (result) {
        setData(result);
      }
    })();
  }, [props.fetch]);

  let Component = Configuration.ComponentsMap[props.type].component;
  let parser = Configuration.ComponentsMap[props.type].parser;
  let _props: any = parser(props.props as any, data);

  return (
    <React.Fragment>
      {props.handleDashboardElement ? (
        props.handleDashboardElement(<Component {..._props} />, props.index)
      ) : (
        <Component {..._props} />
      )}
      {/* <Toolbar /> */}
    </React.Fragment>
  );
};

const buildElements = (
  gridConfig: Configuration.IGridConf[],
  handleDashboardElement:
    | undefined
    | ((component: React.ReactElement, index: number) => React.ReactElement)
) =>
  gridConfig.map((props, index) => {
    // console.log("Props:", props);
    return {
      size: props.width,
      md: props.widthMed,
      element: (
        <ElementWrapper
          {...props}
          index={index}
          handleDashboardElement={handleDashboardElement}
        />
      ),
      id: index.toString(),
    };
  });
