import { Box, Typography } from '@material-ui/core';
import React from 'react';

const Footer = () => {
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        marginTop: '20px',
        flexDirection: 'column',
      }}
    >
      <div>
        <Typography style={{ fontSize: '10px', fontStyle: 'italic' }}>
          The project “Reducing the Consumption and Disposal of Single-use
          Plastics in the Tourism Industry in Cyprus, Greece and Malta” is
          funded by Iceland, Liechtenstein and Norway through the EEA and Norway
          Grants Fund for Regional Cooperation.
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          fontSize: 14,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ minWidth: '150px' }}>
          <Typography style={{ fontSize: 10, fontStyle: 'italic' }}>
            The project is implemented by:
          </Typography>
        </div>
        <img
          style={{ height: 27, width: '100%', marginRight: 10 }}
          src={require('assets/images/logos/aspon.jpeg')}
        />
        <img
          style={{ height: 27, width: '100%', marginRight: 10 }}
          src={require('assets/images/logos/anelixis.png')}
        />
        <img
          style={{ height: 27, width: '100%', marginRight: 10 }}
          src={require('assets/images/logos/aisenv.png')}
        />
        <img
          style={{ height: 27, width: '100%', marginRight: 10 }}
          src={require('assets/images/logos/heraklion.png')}
        />
        <img
          style={{ height: 27, width: '100%', marginRight: 10 }}
          src={require('assets/images/logos/logo-1.png')}
        />
        <img
          style={{ height: 27, width: '100%' }}
          src={require('assets/images/logos/cellock.png')}
        />
      </div>
    </Box>
  );
};

export default React.memo(Footer);
