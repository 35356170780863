import React from 'react';
import { useSelector } from 'react-redux';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { StateStatus } from 'redux/utils/common';
import {
  TBuilderTypes,
  IDashboard,
  IDashboardElement,
  IGlobals,
  IGlobal,
} from 'components/compounds/dashboard_builder/plugins/types';
import _ from 'lodash';
import { getState } from 'redux/store';
import { IChartConfig } from 'components/compounds/dashboard_builder/plugins/types/chart';
import { IMapsConfig } from 'components/compounds/dashboard_builder/plugins/types/maps';

/* selector: useGetLoadDataFields */
interface ILoadDataOutput {
  status: StateStatus | null;
  data: Array<{
    id: string;
    label: string;
  }> | null;
}

export const useGetLoadDataFields = (): ILoadDataOutput => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.DASHBOARD_BUILDER_REDUCER].dataFields
  );
  return { status, data: _.get(data, 'fields', null) };
};

/* selector: useGetDataUrl */
interface IDataURLOutput {
  status: StateStatus | null;
  urlPath: string | null;
  size: number | null;
  type: TBuilderTypes | null;
  reqMethod: 'GET' | 'POST';
}

export const useGetDataUrl = (): IDataURLOutput => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.DASHBOARD_BUILDER_REDUCER].dataFields
  );
  return {
    status,
    urlPath: _.get(data, 'urlPath', null),
    size: _.get(data, 'size', null),
    type: _.get(data, 'type', null),
    reqMethod: _.get(data, 'reqMethod', 'GET'),
  };
};

/* selector: useGetDashboardConfig */
interface IDashboardConfigOutput {
  status: StateStatus | null;
  data: IDashboard | null;
}

export const useGetDashboardConfig = (): IDashboardConfigOutput => {
  const { status, data } = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.DASHBOARD_BUILDER_REDUCER].builderConfiguration
  );
  return { status, data };
};

/* selector: useGetSelectedElementDashboard */
export const useGetSelectedElementDashboard = (
  type: 'element' | 'config'
): null | IDashboardElement | IChartConfig | IMapsConfig => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.DASHBOARD_BUILDER_REDUCER].selectedElement
  );
  let index = data?.index;
  if (typeof index === 'number') {
    let dashboardConfig: IDashboard | undefined | null = getState(
      ReducerKeys.DASHBOARD_BUILDER_REDUCER
    ).builderConfiguration.data;
    if (dashboardConfig && dashboardConfig.dashboardElements.length > index) {
      return type === 'element'
        ? dashboardConfig.dashboardElements[index]
        : type === 'config'
          ? dashboardConfig.dashboardElements[index].config
          : null;
    }
  }
  return null;
};

/* selector: useGetSelectedElementDashboard */
export const useGetSelectedElementDashboardIndex = (): number | undefined => {
  const { data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.DASHBOARD_BUILDER_REDUCER].selectedElement
  );
  return data?.index;
};


/** getter: getDashboardElementConfig */
export const getDashboardElementConfig = (
  index: number
): IDashboardElement | null => {
  let config: IDashboard | null = getState(
    ReducerKeys.DASHBOARD_BUILDER_REDUCER
  ).builderConfiguration.data;
  if (
    config &&
    Array.isArray(config.dashboardElements) &&
    config.dashboardElements.length > index
  ) {
    return config.dashboardElements[index];
  } else {
    return null;
  }
};

/** getter: getDashboardConfig */
export const getDashboardConfig = (): IDashboard | null => {
  let config: IDashboard | null = getState(
    ReducerKeys.DASHBOARD_BUILDER_REDUCER
  ).builderConfiguration.data;
  if (config) {
    return config;
  } else {
    return null;
  }
};

/* selector: useGetPalleteVisibility */
export const useGetPalleteVisibility = (): boolean => {
  const visible = useSelector(
    (state: any) => state[ReducerKeys.DASHBOARD_BUILDER_REDUCER].palleteIsOpen
  );
  return visible;
};

/* selector: useGetDashboards */
interface IDashboardsOuput {
  status: StateStatus;
  data: Array<{
    id: string;
    label: string;
  }>;
}
export const useGetDashboards = (): IDashboardsOuput => {
  const { status, data } = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.DASHBOARD_BUILDER_REDUCER].dashboardsConfig
  );
  return { status, data };
};

/* selector: useGetSaveDashboardStatus */
export const useGetSaveDashboardStatus = (): StateStatus => {
  const status = useSelector(
    (state: any) =>
      state[ReducerKeys.DASHBOARD_BUILDER_REDUCER].saveDashboard.status
  );
  return status;
};

/* selector: useGetSaveDashboardStatus */
export const useGetDashboardTitle = (): string => {
  const title = useSelector(
    (state: any) =>
      state[ReducerKeys.DASHBOARD_BUILDER_REDUCER].builderConfiguration.data
        ?.title || ''
  );
  // console.log("???????????????", title);
  return title;
};

/* selector: useGetBuilderGlobals */
export const useGetBuilderGlobals = (): IGlobal[] | null => {
  const result: IDashboard | null = useSelector(
    (state: any) =>
      state[ReducerKeys.DASHBOARD_BUILDER_REDUCER].builderConfiguration.data
  );

  const cal = React.useMemo(() => {
    let res: IGlobal[] | null = null;
    if (!result) {
      res = [];
    } else {
      let globalsMap = result.globals || {};
      let keys = Object.keys(globalsMap);
      let arr = [];
      for (let key of keys) {
        arr.push({
          name: key,
          value: globalsMap[key],
        });
      }
      res = arr;
    }
    return res;
  }, [result]);

  return cal;
};

export const getDashboardBuilderGlobals = (): IGlobals => {
  let dashboardConfig: IDashboard = getState(
    ReducerKeys.DASHBOARD_BUILDER_REDUCER
  ).builderConfiguration.data;
  let globals: IGlobals = dashboardConfig?.globals || {};
  return globals;
};
