import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  toolsContainer: {
    // marginLeft: 'auto',
    // width: 'fit-content',
    marginBottom: '.2rem',
    marginLeft: '.2rem',
  },
  jsonButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '.25rem',
    padding: '.25rem .5rem',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    background: 'white',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.12)',
    },
  },
  text: {
    fontWeight: 'bold',
    marginLeft: '.5rem',
  },
}));

export default useStyles;
