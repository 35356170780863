import * as Types from './types';
import * as RegisterService from 'services/api/register';
import { Action, Dispatch } from 'redux';
import { LanguagesEnum } from 'config/languages';
import NavigationConfig from 'navigation/config';
import {
  IAction,
  IActionMethods,
  StateStatus,
  KEEP_THE_SAME,
} from 'redux/utils/common';
import {
  IDashboard,
  IDashboardElement,
  IGlobal,
  IGlobals,
  parseVariablesToUrl,
  TBuilderTypes,
} from 'components/compounds/dashboard_builder/plugins/types';
import reactLogger from 'utils/logger';
import request from 'utils/request';
import Environment from 'config/environment';
import _ from 'lodash';
import store, { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';
import * as DashboardBuilderSelectors from './selectors';
import { showToastAction } from 'components/atoms/toast_message';
import { DummyDashboardsConfig, DummyDashboardsMap } from '../configuration';
import * as DashboardBuilderService from 'services/api/dashboard_builder';

/** Fetch FetchDataFields  */

interface IDataFieldsInput {
  urlPath: string;
  size: number;
  type: TBuilderTypes;
  reqMethod: 'GET' | 'POST';
}

class FetchDataFields implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DATASETS_FIELDS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DATASETS_FIELDS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DATASETS_FIELDS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IDataFieldsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let globals = DashboardBuilderSelectors.getDashboardBuilderGlobals();
        let convertedUrl = parseVariablesToUrl(input.urlPath, globals);
        let result = await request(
          input.reqMethod.toLocaleLowerCase() as any,
          `${Environment.API_BASE_URL}${convertedUrl}`
        );
        // console.log('Result:', result);
        let fields: any = ['']; //remove it
        if (input.type === 'chart') {
          fields = Object.keys(
            _.get(result, 'data.data', [])[0] || {}
          ).map((element) => ({ id: element, label: element }));

          if (fields.length === 0) {
            showToastAction(
              dispatch,
              'Data is Empty. Can not retrieve Datafields.',
              'warning'
            );
            throw new Error('Data is Empty. Can not retrieve Datafields.');
          }
        }

        let data = {
          fields,
          urlPath: input.urlPath,
          size: input.size,
          type: input.type,
          reqMethod: input.reqMethod,
        };

        dispatch(this.onSuccess(data));
      } catch (error) {
        reactLogger.error('FetchDataFields Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Set Builder Configuration  */

interface IBuilderConfigurationInput {
  configuration?: IDashboardElement;
  type: 'add' | 'delete' | 'edit';
  index?: number;
}

class SetBuilderConfiguration implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_BUILDER_CONFIGURATION,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_BUILDER_CONFIGURATION,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_BUILDER_CONFIGURATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IBuilderConfigurationInput): IAction {
    try {
      const { type, configuration, index } = input;

      let currentDashboard: IDashboard | null = getState(
        ReducerKeys.DASHBOARD_BUILDER_REDUCER
      ).builderConfiguration.data;
      if (!currentDashboard) {
        currentDashboard = {
          dashboardElements: [],
          title: '',
        };
      } else {
        currentDashboard = { ...currentDashboard };
      }

      if (type === 'add' && configuration) {
        currentDashboard.dashboardElements.push(configuration);
      } else if (
        type === 'edit' &&
        typeof index === 'number' &&
        configuration
      ) {
        currentDashboard.dashboardElements[index] = configuration;
      } else if (type === 'delete' && typeof index === 'number') {
        currentDashboard.dashboardElements.splice(index, 1);
      }

      return this.onSuccess(currentDashboard);
    } catch (error) {
      reactLogger.error('SetBuilderConfiguration Error:', error.message); // '<ClassName> Error: <error>'
      return this.onFailed();
    }
  }
}

/** Select Dashboard Element  */

interface ISelectedDashboardElementInput {
  index: number | null;
}

class SetSelectedDashboardElement implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ELEMENT_SELECTED,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ELEMENT_SELECTED,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ELEMENT_SELECTED,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: ISelectedDashboardElementInput): IAction {
    try {
      // let dashbordConfig = getState(ReducerKeys.DASHBOARD_BUILDER_REDUCER).
      // set datafields

      let selectedElement: IDashboardElement | null =
        typeof input.index === 'number'
          ? DashboardBuilderSelectors.getDashboardElementConfig(input.index)
          : null;

      if (selectedElement !== null) {
        let tempEl = selectedElement as IDashboardElement;
        store.dispatch(
          new FetchDataFields().action({
            urlPath: tempEl.url,
            type: tempEl.type,
            size: tempEl.size,
            reqMethod: tempEl.urlMethod || 'GET',
          })
        );
      }

      return this.onSuccess(input);
    } catch (error) {
      reactLogger.error('SetBuilderConfiguration Error:', error.message); // '<ClassName> Error: <error>'
      return this.onFailed();
    }
  }
}

/** Select Pallete Vsibility  */
interface ISetPalleteVisibilityInput {
  visible: boolean;
}

class SetPalleteVisibility implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_PALLETE_VISIBILITY,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_PALLETE_VISIBILITY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_PALLETE_VISIBILITY,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: ISetPalleteVisibilityInput): IAction {
    try {
      return this.onSuccess(input.visible);
    } catch (error) {
      reactLogger.error('SetPalleteVisibility Error:', error.message); // '<ClassName> Error: <error>'
      return this.onFailed();
    }
  }
}

/** Set Builder Globals  */
interface ISetBuilderGlobalsInput {
  globals: IGlobal[];
}

class SetBuilderGlobals implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_BUILDER_CONFIGURATION,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_BUILDER_CONFIGURATION,
      status: StateStatus.Success,
      data: data,
    };
  }
  onFailed(): IAction {
    return {
      type: Types.SET_BUILDER_CONFIGURATION,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: ISetBuilderGlobalsInput): IAction {
    try {
      let currentDashboard: IDashboard | null = getState(
        ReducerKeys.DASHBOARD_BUILDER_REDUCER
      ).builderConfiguration.data;
      if (!currentDashboard) {
        currentDashboard = {
          dashboardElements: [],
          title: '',
        };
      } else {
        currentDashboard = { ...currentDashboard };
      }

      let globalsMap: IGlobals = {};
      for (let global of input.globals) {
        globalsMap[global.name] = global.value;
      }
      currentDashboard.globals = globalsMap;

      return this.onSuccess(currentDashboard);
    } catch (error) {
      reactLogger.error('SetBuilderGlobals Error:', error.message); // '<ClassName> Error: <error>'
      return this.onFailed();
    }
  }
}

interface IDashboardBySuccessInput {
  dashboard: IDashboard;
  id: string | null;
}

class GetDashboardByID implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_SELECTED_DASHBOARD,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: IDashboardBySuccessInput): IAction {
    // console.log("DData:", data);
    return {
      type: Types.SET_SELECTED_DASHBOARD,
      status: StateStatus.Success,
      data: data,
    };
  }
  onFailed(): IAction {
    return {
      type: Types.SET_SELECTED_DASHBOARD,
      status: StateStatus.Failed,
      data: KEEP_THE_SAME,
    };
  }

  action(dashboardID?: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const dashboard: IDashboard = {
          dashboardElements: [],
          title: '',
        };
        // console.log("DData:", dashboardID);
        if (!dashboardID) {
          dispatch(
            this.onSuccess({
              dashboard,
              id: null,
            })
          );
        } else {
          dispatch(this.onPending());
          let dashboardData = await DashboardBuilderService.getDashboardByID(
            dashboardID || ''
          );
          dispatch(
            this.onSuccess({
              dashboard: dashboardData.data.dashboardConfig,
              id: dashboardID,
            })
          );
        }
      } catch (error) {
        reactLogger.error('GetDashboardByID Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class FetchDashboards implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DASHBOARDS,
      status: StateStatus.Pending,
      data: [],
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DASHBOARDS,
      status: StateStatus.Success,
      data: data,
    };
  }
  onFailed(): IAction {
    return {
      type: Types.SET_DASHBOARDS,
      status: StateStatus.Failed,
      data: [],
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        // setTimeout(() => {

        //   // dispatch(this.onSuccess(DummyDashboardsConfig));
        // }, 1000);
        let result = await DashboardBuilderService.getDashboardsConfig();
        dispatch(this.onSuccess(result.data));
      } catch (error) {
        reactLogger.error('FetchDashboards Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface ISaveDashboardInput {
  dashboardTitle: string;
}

class SaveDashboard implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SAVE_DASHBOARD,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.SAVE_DASHBOARD,
      status: StateStatus.Success,
      data: null,
    };
  }
  onFailed(): IAction {
    return {
      type: Types.SAVE_DASHBOARD,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: ISaveDashboardInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        let currentDashboard: IDashboard | null = getState(
          ReducerKeys.DASHBOARD_BUILDER_REDUCER
        ).builderConfiguration.data;
        if (!currentDashboard) {
          currentDashboard = {
            dashboardElements: [],
            title: '',
          };
        }
        currentDashboard.title = input.dashboardTitle;

        const dashboardid = getState(ReducerKeys.DASHBOARD_BUILDER_REDUCER)
          .selectedDashboardid;

        if (dashboardid) {
          await DashboardBuilderService.editDashboard({
            dashboardData: currentDashboard,
            dashboardid: dashboardid,
          });
        } else {
          await DashboardBuilderService.createDashboard(currentDashboard);
        }
        dispatch(this.onSuccess());
        dispatch(new FetchDashboards().action());
      } catch (error) {
        reactLogger.error('SaveDashboard Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  saveDashboardAction: (payload: ISaveDashboardInput) =>
    new SaveDashboard().action(payload),
  fetchDashboardByID: (id?: string) => new GetDashboardByID().action(id),
  fetchDataFieldsAction: (payload: IDataFieldsInput) =>
    new FetchDataFields().action(payload),
  fetchDashboardsAction: () => new FetchDashboards().action(),
  setBuilderConfigurationAction: (payload: IBuilderConfigurationInput) =>
    new SetBuilderConfiguration().action(payload),
  setSelectedDashboardElementAction: (
    payload: ISelectedDashboardElementInput
  ) => new SetSelectedDashboardElement().action(payload),
  setPalleteVisibilityAction: (payload: ISetPalleteVisibilityInput) =>
    new SetPalleteVisibility().action(payload),
  setBuilderGlobalsAction: (payload: ISetBuilderGlobalsInput) =>
    new SetBuilderGlobals().action(payload),
};
