import IntroSlider from 'components/app_common/intro_slider';
import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router'; // import the react-router-dom components
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import {
  default as Actions,
  default as ScenarioActions,
} from 'redux/reducers/scenario_reducer/actions';
import { useGetUserScenarios } from 'redux/reducers/scenario_reducer/selectors';
import CombineScenario from './components/CombineScenario';
import DatatableList from './components/datatable';
import ScenarioModal from './components/datatable/ViewEditData';
import { TableDefaultFetchValues } from './configuration';
import useStyles from './styles';

const ScenarioPage = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(Actions.resetUserScenario());
  };

  const userData = useSelectUserData();
  const userScenarios = useGetUserScenarios();

  React.useEffect(() => {
    dispatch(Actions.getUserScenario(userData.id));
  }, []);

  React.useEffect(() => {
    userScenarios.data?.user?.isNewUser && handleClickOpen();
  }, [userScenarios]);

  React.useEffect(() => {
    dispatch(
      ScenarioActions.fetchScenarioListAction({
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
      })
    );
    dispatch(ScenarioActions.fetchScenarioOption());
    dispatch(ScenarioActions.fetchEstablishmentsname());
  }, [dispatch]);

  return (
    <div>
      <ScenarioModal />
      <IntroSlider handleClose={handleClose} open={open} />
      <CombineScenario />
      <DatatableList />
    </div>
  );
};

export default withRouter(ScenarioPage);
