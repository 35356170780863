import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import ModalActions from '../../../../redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from '../../../../redux/reducers/gl_modal_reducer/types';
import useStyles from './styles';
import { TableDefaultFetchValues, Translations } from '../../configuration';
import Swal from 'sweetalert2';
import GroupActions from 'redux/reducers/group_reducer/actions';

interface IProps {
  rowIndex: number;
  data: string;
  dataid: string | number;
}

export default function ActionsBlock(props: IProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClick = (type: 'view' | 'edit') => {
    dispatch(
      ModalActions.openModalAction({
        modalID: ModalIDs.MODAL_ADD_EDIT_GROUP,
        params: {
          type,
          id: props.dataid,
        },
      })
    );
  };
  function handleDelete(id: string | number) {
    console.log(id);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        dispatch(
          GroupActions.deleteGroup({
            id: id.toString(),
            fetchGroupInput: {
              sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
              page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
              pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
              order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
            },
          })
        );
        // dispatch(Actions.UpdateProduct({ id: data._id, isDeleted: true }));
        // Swal.fire('Deleted!', 'The product has been deleted.', 'success');
      }
    });
  }
  return (
    <div>
      <Button
        className={classes.editButton}
        // variant="outlined"
        onClick={() => handleClick('edit')}
      >
        Edit
      </Button>
      <Button
        // variant="outlined"
        className={classes.viewButton}
        onClick={() => handleClick('view')}
      >
        View
      </Button>
      <Button
        // variant="outlined"
        className={classes.deleteButton}
        onClick={() => handleDelete(props.dataid)}
      >
        Delete
      </Button>
    </div>
  );
}
