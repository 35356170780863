import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';

interface IState {
  ScenarioList: {
    status: StateStatus | null;
    data: any;
  };
  addScenario: {
    status: StateStatus | null;
  };
  editScenario: {
    status: StateStatus | null;
  };
  viewScenario: {
    status: StateStatus | null;
    data: any;
  };
  modal: {
    isOpen: boolean;
    data: any;
    type: string;
  };
  selectedScenario: string | null;
  scenarioOption: any[];
  establishmentsName: {
    status: StateStatus | null;
    data: any;
  };
  combine: {
    isOpen: boolean;
    data: any;
  };
  activeScenario: {
    status: StateStatus | null;
    data: any;
  };
  combineScenarios: string[];
  deleteScenario: {
    status: StateStatus | null;
  };
  getUserScenario: {
    status: StateStatus | null;
    data: any;
  };
  updateUserScenario: {
    status: StateStatus | null;
  };
}

const initialState: IState = {
  ScenarioList: {
    status: null,
    data: null,
  },
  addScenario: {
    status: null,
  },
  editScenario: {
    status: null,
  },
  viewScenario: {
    status: null,
    data: null,
  },
  modal: {
    isOpen: false,
    data: null,
    type: 'add',
  },
  selectedScenario: null,
  scenarioOption: [],
  establishmentsName: {
    status: null,
    data: null,
  },
  combine: {
    isOpen: false,
    data: null,
  },
  activeScenario: {
    status: null,
    data: null,
  },
  combineScenarios: [],
  deleteScenario: {
    status: null,
  },
  getUserScenario: {
    status: null,
    data: null,
  },
  updateUserScenario: {
    status: null,
  },
};

const ScenarioReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_SCENARIO_LIST:
      return {
        ...state,
        ScenarioList: {
          ...state.ScenarioList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.ScenarioList.data
              : action.data,
        },
      };
    case Types.SET_ADD_SCENARIO:
      return {
        ...state,
        addScenario: {
          ...state.addScenario,
          status: action.status || null,
        },
      };
    case Types.SET_EDIT_SCENARIO:
      return {
        ...state,
        editScenario: {
          ...state.editScenario,
          status: action.status || null,
        },
      };
    case Types.SET_SCENARIO_DETAIL:
      return {
        ...state,
        viewScenario: {
          ...state.viewScenario,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.viewScenario.data
              : action.data,
        },
      };
    case Types.SET_SET_MODAL:
      return {
        ...state,
        modal: {
          ...action.data,
        },
      };
    case Types.SET_COMBINE:
      return {
        ...state,
        combine: {
          ...action.data,
        },
      };
    case Types.SET_SELECTED_SCENARIO:
      return {
        ...state,
        selectedScenario: action.data,
      };
    case Types.SET_SCENARIO_OPTION:
      return {
        ...state,
        scenarioOption: [...action.data],
      };
    case Types.SET_ESTABLISHMENTS_NAME:
      return {
        ...state,
        establishmentsName: {
          ...state.establishmentsName,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.establishmentsName.data
              : action.data,
        },
      };
    case Types.SET_ACTIVE_SCENARIO:
      return {
        ...state,
        activeScenario: {
          ...state.activeScenario,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.activeScenario.data
              : action.data,
        },
      };
    case Types.SET_COMBINE_SCENARIOS:
      return {
        ...state,
        combineScenarios: action.data,
      };
    case Types.DELETE_SCENARIO:
      return {
        ...state,
        deleteScenario: {
          ...state.deleteScenario,
          status: action.status || null,
        },
      };
    case Types.UPDATE_USER_SCENARIO:
      return {
        ...state,
        updateUserScenario: {
          ...state.updateUserScenario,
          status: action.status || null,
        },
      };
    case Types.GET_USER_SCENARIO:
      return {
        ...state,
        getUserScenario: {
          ...state.getUserScenario,
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.RESET_USER_SCENARIO:
      return {
        ...state,
        getUserScenario: {
          status: null,
          data: null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default ScenarioReducer;
