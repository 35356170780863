import { IAction, IActionMethods, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as AuthenticationService from 'services/api/authenticate';
import { Dispatch } from 'redux';
import { showToastAction } from 'components/atoms/toast_message';
import { LanguagesEnum } from 'config/languages';
import NavigationConfig from 'navigation/config';

/** Forgot Password  */

interface IChangePasswordInput {
  newpassword: string;
  confirmNewpassword: string;
  oldpassword: string;
  history: any;
}
class ChangePassword implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      data: {
        status: StateStatus.Pending,
      },
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      data: {
        status: StateStatus.Success,
      },
    };
  }

  onFailed(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      data: {
        status: StateStatus.Failed,
      },
    };
  }

  action(data: IChangePasswordInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await AuthenticationService.changePassword({
          newPassword: data.newpassword,
          confirmPassword: data.confirmNewpassword,
          oldPassword: data.oldpassword,
        });
        dispatch(this.onSuccess());
        data.history.push(NavigationConfig.mainPage().path);
      } catch (error: any) {
        console.log('ChangePassword Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  changePasswordAction: (data: any) => {
    // {
    //   newPassword: 'string',
    //   confirmNewPassword: 'string',
    //   oldPassword: 'string',
    //   history: null,
    // }
    return new ChangePassword().action(data);
  },
};
