import React from 'react';
import { useDispatch } from 'react-redux';
import Datatable from '../../../../components/molecules/datatable';
import { TableColumns } from '../../configurations';
import ActionsBlock from './ActionsBlock';
import { useGetUserList } from '../../../../redux/reducers/user_management_reducer/selectors';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import Actions from '../../../../redux/reducers/user_management_reducer/actions';

export default function DatatableList() {
  const result = useGetUserList();
  const paging = result.data?.paging;
  const sortField = React.useRef<string>(TableColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  const dispatch = useDispatch();

  return (
    <Box display="flex" flexDirection="column" marginBottom="50px">
      {/* <Box display="flex" padding="5px" fontFamily="Open sans">
        <Typography style={{ fontWeight: 'bold' }} variant="h5">
          
        </Typography>
      </Box> */}

      <Datatable
        loading={true}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        paging={paging}
        data={result.data?.items || []}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          sortType.current = order;
          sortField.current = _sortField;
          dispatch(
            Actions.fetchUserList({
              sortField: _sortField,
              page: selectedPage,
              pageSize: pageSize,
              order: order,
            })
          );
        }}
      />
    </Box>
  );
}

export { ActionsBlock };
