import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  highlightText: {
    color: '#22BAF1',
    fontWeight: 'bold',
    marginRight: 5,
  },
}));

export default useStyles;
