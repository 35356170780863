import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_ESTABLISHMENT_LIST: string = typeCreatorV2(
  'EstablishmentReducer',
  'SetEstablishmentList'
);

export const SET_ADD_ESTABLISHMENT: string = typeCreatorV2(
  'EstablishmentReducer',
  'SetAddEstablishment'
);

export const SET_EDIT_ESTABLISHMENT: string = typeCreatorV2(
  'EstablishmentReducer',
  'SetEditEstablishment'
);

export const SET_ESTABLISHMENT_DETAIL: string = typeCreatorV2(
  'EstablishmentReducer',
  'SetEstablishmentDetail'
);

export const SET_USERNAMES: string = typeCreatorV2(
  'EstablishmentReducer',
  'SetUsernames'
);
export const DELETE_ESTABLISHMENT: string = typeCreatorV2(
  'EstablishmentReducer',
  'DeleteEstablishment'
);
export const SET_GROUPS: string = typeCreatorV2(
  'EstablishmentReducer',
  'SetGroups'
);
