import { IAction, IActionMethods, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as AuthenticationService from 'services/api/authenticate';
import { Dispatch } from 'redux';
import { showToastAction } from 'components/atoms/toast_message';
import { LanguagesEnum } from 'config/languages';
import NavigationConfig from 'navigation/config';

/** Forgot Password  */

interface IForgotPasswordInput {
  email: string;
  history: any;
}
class ForgotPassword implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.FORGOT_PASSOWRD,
      data: {
        status: StateStatus.Pending,
      },
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.FORGOT_PASSOWRD,
      data: {
        status: StateStatus.Success,
      },
    };
  }

  onFailed(): IAction {
    return {
      type: Types.FORGOT_PASSOWRD,
      data: {
        status: StateStatus.Failed,
      },
    };
  }

  action(data: IForgotPasswordInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await AuthenticationService.forgotPassword({
          email: data.email,
        });
        dispatch(this.onSuccess());
        // showToastAction(
        //   dispatch,
        //   {
        //     [LanguagesEnum.English]:
        //       'Email has been sent to your email to reset your password',
        //     [LanguagesEnum.Greek]:
        //       'Στάλθηκε email για την επαναφορά του κωδικού',
        //   },
        //   'success'
        // );
        data.history.push(NavigationConfig.loginPage().path);
      } catch (error) {
        console.log('ForgotPassword Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
        // showToastAction(
        //   dispatch,
        //   {
        //     [LanguagesEnum.English]: 'Something goes wrong',
        //     [LanguagesEnum.Greek]: 'Κάτι πήγε στραβά',
        //   },
        //   'error'
        // );
      }
    };
  }
}

export default {
  forgotPasswordAction: (email: string, history: any) =>
    new ForgotPassword().action({ email, history }),
};
