import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface EstablishmentListElement {
  name: string;
  address: string;
  users: string;
  admin: string;
}

interface EstablishmentListData {
  paging: EstablishmentListPaging;
  items: EstablishmentListElement[];
}

interface EstablishmentListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface EstablishmentistOutput {
  status: StateStatus;
  data: EstablishmentListData | null;
}

export const useGetEstablishmentList = (): EstablishmentistOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ESTABLISHMENT_REDUCER].EstablishmentList
  );
  return data;
};

/* selector: useGetEstablishmentDetails */
interface EstablishmentDetailsData {
  name: string;
  address: string;
  users: string;
  admin: string[];
  emails: string[];
  group: string;
}

interface EstablishmentDetailsOutput {
  status: StateStatus;
  data: EstablishmentDetailsData | null;
}

export const useGetEstablishmentDetails = (): EstablishmentDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ESTABLISHMENT_REDUCER].viewEstablishment
  );
  return data;
};
interface fetchUsernamesOutput {
  status: StateStatus;
  data: Usernames[] | null;
}
export interface Usernames {
  _id: string;
  firstName: string;
  surname: string;
}

export const useGetUsernames = (): fetchUsernamesOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ESTABLISHMENT_REDUCER].usersname
  );
  return data;
};
interface IFetchGroupsOutput {
  status: StateStatus;
  data: IGroup[] | null;
}

export declare interface IGroup {
  _id: string;
  name: string;
  address: string;
  establishments: string[] | any[];
  admin: string[] | any[];
}
export const useGetGroups = (): IFetchGroupsOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.ESTABLISHMENT_REDUCER].groups
  );
  return data;
};
