import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';

interface ScenarioListElement {
  type: string;
  totalnumberofguestsperyear: string;
  totalnumberofrooms: string;
  hotelguestcapacity: string;
  restaurantcafecapacity: string;
  totalnumberoftablesacrossallrestaurant: string;
  beachbarcapacity: string;
  totalnumberoftables: string;
  totalcapacity: string;
  user: string;
}

interface ScenarioListData {
  paging: ScenarioListPaging;
  items: ScenarioListElement[];
}

interface ScenarioListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface ScenarioistOutput {
  status: StateStatus;
  data: ScenarioListData | null;
}

export const useGetScenarioList = (): ScenarioistOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.SCENARIO_REDUCER].ScenarioList
  );
  return data;
};

/* selector: useGetScenarioDetails */
interface ScenarioDetailsData {
  type: string;
  totalnumberofguestsperyear: string;
  totalnumberofrooms: string;
  hotelguestcapacity: string;
  restaurantcafecapacity: string;
  totalnumberoftablesacrossallrestaurant: string;
  beachbarcapacity: string;
  totalnumberoftables: string;
  totalcapacity: string;
  user: string;
}

interface ScenarioDetailsOutput {
  status: StateStatus;
  data: ScenarioDetailsData | null;
}

export const useGetScenarioDetails = (): ScenarioDetailsOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.SCENARIO_REDUCER].viewScenario
  );
  return data;
};
export const useGetModal = (): { isOpen: boolean; data: any; type: string } => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.SCENARIO_REDUCER].modal
  );
  return data;
};
export const useGetSelectedScenario = (): string | null => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.SCENARIO_REDUCER].selectedScenario
  );
  return data;
};

export const useGetScenarioOption = (): any[] => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.SCENARIO_REDUCER].scenarioOption
  );
  return data;
};
interface fetchEstablishmentsnameOutput {
  status: StateStatus;
  data: EstablishmentName[] | null;
}
export interface EstablishmentName {
  _id: string;
  name: string;
}
export const useGetEstablishmentsname = (): fetchEstablishmentsnameOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.SCENARIO_REDUCER].establishmentsName
  );
  return data;
};
export const useGetCombine = (): { isOpen: boolean; data: any } => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.SCENARIO_REDUCER].combine
  );
  return data;
};
export const useGetActiveScenario = (): {
  status: StateStatus;
  data: any | null;
} => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.SCENARIO_REDUCER].activeScenario
  );
  return data;
};

export const useGetCombineScenarios = (): string[] => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.SCENARIO_REDUCER].combineScenarios
  );
  return data;
};

export const useGetUserScenarios = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.SCENARIO_REDUCER].getUserScenario
  );
  return data;
};
