import { LanguagesEnum } from '../../../config/languages';

export const Translations = {
  contentStep1: {
    [LanguagesEnum.English]: `Proceed to the first step to create a new scenario.`,
    [LanguagesEnum.Greek]: `Proceed to the first step to create a new scenario.`,
  },
  contentTitleStep1: {
    [LanguagesEnum.English]: `Create the Scenaios`,
    [LanguagesEnum.Greek]: `Create the Scenaios`,
  },
  contentStep2: {
    [LanguagesEnum.English]: `Select Alternatives.`,
    [LanguagesEnum.Greek]: `Select Alternatives.`,
  },
  contentTitleStep2: {
    [LanguagesEnum.English]: `Select Alternatives`,
    [LanguagesEnum.Greek]: `Select Alternatives`,
  },
  contentStep3: {
    [LanguagesEnum.English]: `Generate Results.`,
    [LanguagesEnum.Greek]: `Generate Results.`,
  },
  contentTitleStep3: {
    [LanguagesEnum.English]: `Generate Results`,
    [LanguagesEnum.Greek]: `Generate Results`,
  },
  buttonSkip: {
    [LanguagesEnum.English]: `Skip`,
    [LanguagesEnum.Greek]: `Skip`,
  },
  buttonNext: {
    [LanguagesEnum.English]: `Next`,
    [LanguagesEnum.Greek]: `Next`,
  },
};
