import { Box, CssBaseline, Drawer, IconButton } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import CommonForm from './CommonForm';
import ChartsForm from './ChartsForm';
import MapsForm from './MapsForm';
import SubFormLayout from './SubFormLayout';
import TranslatedText from 'components/atoms/translated_text';
import * as DashboardBuilderSelectors from '../../store/selectors';
import { IChartConfig } from 'components/compounds/dashboard_builder/plugins/types/chart';
import CloseIcon from '@material-ui/icons/Close';
import DashboardBuilderActions from '../../store/actions';
import { useDispatch } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import { AppSelect } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import { LensTwoTone } from '@material-ui/icons';

export default function BuilderForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch(
      DashboardBuilderActions.setPalleteVisibilityAction({
        visible: open,
      })
    );
  };

  return (
    <div>
      <SubFormLayout
        title={
          <Box display="flex" alignItems="space-between">
            <Box display="flex" flex="1" alignItems="center">
              <IconButton
                style={{ marginRight: 10 }}
                onClick={toggleDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
              <DashboardsSelectorWrapper />
              {/* <TranslatedText defaultText="Configure Dashboard" /> */}
            </Box>
            <IconButton
              style={{ marginRight: 10 }}
              onClick={() =>
                dispatch(
                  ModalActions.openModalAction({
                    modalID: ModalIDs.MODAL_DASHBOARD_SAVE,
                    params: {},
                  })
                )
              }
            >
              <SaveAltOutlinedIcon />
            </IconButton>
            <IconButton
              style={{ marginRight: 10 }}
              onClick={() =>
                dispatch(
                  ModalActions.openModalAction({
                    modalID: ModalIDs.MODAL_DASHBOARD_ELEMENT_CONFIG,
                    params: {},
                  })
                )
              }
            >
              <VisibilityOutlinedIcon />
            </IconButton>
            <IconButton
              style={{ marginRight: 10 }}
              onClick={() =>
                dispatch(
                  ModalActions.openModalAction({
                    modalID: ModalIDs.MODAL_DASHBOARD_BUILDER_SETTINGS,
                    params: {},
                  })
                )
              }
            >
              <SettingsIcon />
            </IconButton>
          </Box>
        }
      >
        <React.Fragment>
          <CommonForm />
          <SubFormWrapper />
        </React.Fragment>
      </SubFormLayout>
    </div>
  );
}

const SubFormWrapper = () => {
  const { data } = DashboardBuilderSelectors.useGetLoadDataFields();
  const {
    urlPath,
    type,
    size,
    reqMethod,
  } = DashboardBuilderSelectors.useGetDataUrl();
  const initialValuesconfig = DashboardBuilderSelectors.useGetSelectedElementDashboard(
    'config'
  ) as any;
  const selectedItemIndex = DashboardBuilderSelectors.useGetSelectedElementDashboardIndex();

  return (
    <React.Fragment>
      {Array.isArray(data) && data.length > 0 && urlPath && type && size && (
        <React.Fragment>
          {type === 'chart' && (
            <ChartsForm
              urlPath={urlPath}
              reqMethod={reqMethod}
              dashboardElementType={type}
              width={size}
              type={Boolean(initialValuesconfig) ? 'edit' : 'add'}
              itemIndex={selectedItemIndex}
              initialValuesconfig={initialValuesconfig || null}
            />
          )}
          {type === 'map' && (
            <MapsForm
              urlPath={urlPath}
              reqMethod={reqMethod}
              dashboardElementType={type}
              width={size}
              type={Boolean(initialValuesconfig) ? 'edit' : 'add'}
              itemIndex={selectedItemIndex}
              initialValuesconfig={initialValuesconfig || null}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DashboardsSelectorWrapper = React.memo(() => {
  const {
    setValue,
    reset,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<any>({
    defaultValues: { selectedDashboard: 'add-dashboard' },
  });
  const { data } = DashboardBuilderSelectors.useGetDashboards();
  const dispatch = useDispatch();
  let dashboards = [
    {
      label: 'Configure Dashboard',
      id: 'add-dashboard',
    },
    ...data,
  ];

  React.useEffect(() => {
    handleItemChange('add-dashboard');
  }, [dispatch]);

  const handleItemChange = (value: any) => {
    if (value !== 'add-dashboard') {
      dispatch(DashboardBuilderActions.fetchDashboardByID(value));
    } else {
      dispatch(DashboardBuilderActions.fetchDashboardByID());
    }
  };

  return (
    <AppSelect
      variant="standard"
      margin="dense"
      rules={{ required: true }}
      style={{ minWidth: 180 }}
      size="small"
      handleChange={handleItemChange}
      id="selectedDashboard"
      options={dashboards}
      control={control}
      autoComplete="selectedDashboard"
      name="selectedDashboard"
    />
  );
});
