import { makeStyles } from '@material-ui/core/styles';
import { LOGIN_REGISTER_BACKGROUND_COLOR } from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 500,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  img_logo_black: {
    marginBottom: 13,
    height: 50,
    width: 'auto',
    background: '#292F5F',
  },
  boxLogoFirst: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 -20px',
    marginTop: 60,
    gap: 30,
  },
  boxLogoSecond: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
    gap: 30,
  },
  img_logo: {
    height: 34,
  },
  loginContainer: {
    background: LOGIN_REGISTER_BACKGROUND_COLOR(theme),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  wrapper: {
    width: '100%',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
