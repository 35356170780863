import React from 'react';
import GridLayout from 'components/molecules/grid_layout';
import TranslatedText from 'components/atoms/translated_text';
import { Box, Typography } from '@material-ui/core';
import ColumnStackedChart from './ColumnStackedChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import MultiTypeChart from './MultiTypeChart';

/**
 * Official Documentation: https://reactchartjs.github.io/react-chartjs-2/#/
 * @returns
 */
export default function ReactChartsJS2() {
  return (
    <Box>
      <Typography variant="h4">
        <TranslatedText defaultText="React Charts JS2" />
      </Typography>
      <GridLayout
        spacing={1}
        elements={[
          {
            id: '1',
            size: 6,
            element: <ColumnStackedChart />,
          },
          {
            id: '2',
            size: 6,
            element: <LineChart />,
          },
          {
            id: '3',
            size: 6,
            element: <PieChart />,
          },
          {
            id: '4',
            size: 6,
            element: <MultiTypeChart />,
          },
        ]}
      />
    </Box>
  );
}
