import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as GroupService from '../../../services/api/group';
import * as EstablishmentService from '../../../services/api/establishment';

import ModalActions from '../gl_modal_reducer/actions';
import { ModalIDs } from '../gl_modal_reducer/types';
import * as UserService from '../../../services/api/user';

/** Fetch FetchGroupListInput  */

interface FetchGroupListInput {
  sortField: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  name?: string;
}

class FetchGroupList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_GROUP_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_GROUP_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_GROUP_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchGroupListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await GroupService.fetchGroupList(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetcGroupList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class SearchGroup implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_GROUP_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_GROUP_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_GROUP_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: FetchGroupListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await GroupService.searchGroup(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('FetcGroupList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add NewGroup  */

interface AddNewGroupInput {
  name: string | null;
  address: string | null;
  establishments: string[];
  admin: string[];
  fetchGroupInput: FetchGroupListInput;
}

class AddNewGroup implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ADD_GROUP,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ADD_GROUP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ADD_GROUP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: AddNewGroupInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchGroupInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await GroupService.addGroup(rest);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchGroupList().action(fetchGroupInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_GROUP,
          })
        );
      } catch (error: any) {
        console.log('AddNewGroup Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** Edt Group  */

interface EditGroupInput {
  name: string | null;
  address: string | null;
  establishments: string | null;
  admin: string | null;
  fetchGroupInput: FetchGroupListInput;
}

interface DeleteGroupInput {
  id: string;
  fetchGroupInput: FetchGroupListInput;
}

class EditGroup implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_EDIT_GROUP,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_EDIT_GROUP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_EDIT_GROUP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: EditGroupInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchGroupInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await GroupService.editGroup(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchGroupList().action(fetchGroupInput));

        dispatch(
          ModalActions.closeModalAction({
            modalID: ModalIDs.MODAL_ADD_EDIT_GROUP,
          })
        );
      } catch (error: any) {
        console.log('EditNotification Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}

/** View Group  */

interface ViewGroupInput {
  id: string;
}

class ViewGroup implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_GROUP_DETAIL,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_GROUP_DETAIL,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_GROUP_DETAIL,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: ViewGroupInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await GroupService.fetchGroupDetail({
          id: payload.id,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('ViewGroup Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchUsernames implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USERNAMES,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USERNAMES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USERNAMES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getUsernames();
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('Fetch usernames Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}
class FetchEstablishmentsname implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ESTABLISHMENTS_NAME,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_ESTABLISHMENTS_NAME,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ESTABLISHMENTS_NAME,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await EstablishmentService.fetchEstablishmentName();
        dispatch(this.onSuccess(response.data));
      } catch (error: any) {
        console.log('Fetch establishmentnames Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

class DeleteGroup implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.DELETE_GROUP,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.DELETE_GROUP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.DELETE_GROUP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: DeleteGroupInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { fetchGroupInput, ...rest } = payload;

        dispatch(this.onPending());
        let response = await GroupService.deleteGroup({ id: payload.id });
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchGroupList().action(fetchGroupInput));
      } catch (error: any) {
        console.log('delete group Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // add something
      }
    };
  }
}
export default {
  fetchGroupListAction: (payload: FetchGroupListInput) =>
    new FetchGroupList().action(payload),
  searchGroupAction: (payload: FetchGroupListInput) =>
    new SearchGroup().action(payload),
  addNewGroupAction: (payload: AddNewGroupInput) =>
    new AddNewGroup().action(payload),
  editGroupAction: (payload: EditGroupInput) => new EditGroup().action(payload),
  viewGroupAction: (payload: ViewGroupInput) => new ViewGroup().action(payload),
  fetchUsernames: () => new FetchUsernames().action(),
  fetchEstablishmentsname: () => new FetchEstablishmentsname().action(),
  deleteGroup: (payload: DeleteGroupInput) => new DeleteGroup().action(payload),
};
