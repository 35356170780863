import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../utils/common';
import { IModal } from './reducer';

interface UserProductsListElement {
  _id: string;
  quantity: string;
  suggested: string;
  productcost: string;
  product: string;
  user: string;
}

interface UserProductsListData {
  paging: UserProductsListPaging;
  items: UserProductsListElement[];
}

interface UserProductsListPaging {
  page: number;
  pageSize: number;
  total: number;
}

interface UserProductsistOutput {
  status: StateStatus;
  data: UserProductsListData | null;
}

export const useGetUserProductsList = (): UserProductsistOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PRODUCT_REDUCER].UserProductsList
  );
  return data;
};
export const useGetUserOutputList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PRODUCT_REDUCER].userOutputList
  );
  return data;
};
export const useGetModal = (): IModal => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PRODUCT_REDUCER].modal
  );
  return data;
};
export const useGetProductsList = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PRODUCT_REDUCER].productsList
  );
  return data;
};
export const useGetSelected = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PRODUCT_REDUCER].selected
  );
  return data;
};

export const useGetEnvImpactSelection = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PRODUCT_REDUCER].envImpactSelection
  );
  return data;
};

export const useGetEnvDescSelection = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PRODUCT_REDUCER].envDescSelection
  );
  return data;
};
export const useGetProductCategorySelection = (): any => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.USER_PRODUCT_REDUCER].productCategorySelection
  );
  return data;
};
export const useGetGraphsImg = (): any => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PRODUCT_REDUCER].graphImg
  );
  return data;
};
export const useGetExportModal = (): IModal => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PRODUCT_REDUCER].exportModal
  );
  return data;
};
