import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Actions from 'redux/reducers/user_management_reducer/actions';
import DatatableList from './components/datatable';
import UpdateModal from './components/UpdateModal';

import { TableDefaultFetchValues } from './configurations';

export default function UserManagement() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      Actions.fetchUserList({
        page: TableDefaultFetchValues.TABLE_DEFAULT_PAGE,
        pageSize: TableDefaultFetchValues.TABLE_DEFAULT_PAGE_SIZE,
        order: TableDefaultFetchValues.TABLE_DEFAULT_ORDER,
        sortField: TableDefaultFetchValues.TABLE_DEFAULT_SORT_KEY,
      })
    );
  }, []);

  return (
    <Box marginTop="50px">
      <UpdateModal />
      <DatatableList />
    </Box>
  );
}
