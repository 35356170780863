import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import GridLayout from 'components/molecules/grid_layout';
import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router'; // import the react-router-dom components
import { ReducerKeys } from 'redux/config';
import { withReducer } from 'redux/withReducer';
import TranslatedText from '../../components/atoms/translated_text';
import LoginForm from 'components/app_common/login_form';
import RegisterForm from './components/register_form';
import { Translations } from './configuration';
import useStyles from './styles';

const RegisterPage = () => {
  const classes = useStyles();
  return (
    <div>
      <LoginForm>
        <Fragment>
          <Typography component="h1" variant="h5" className={classes.title}>
            <TranslatedText textMap={Translations.signup_title} />
          </Typography>
          <RegisterForm />
        </Fragment>
      </LoginForm>
    </div>
  );
};

const Page = withRouter(RegisterPage);
export default withReducer(ReducerKeys.REGISTER_REDUCER)(Page);

// export default withRouter(RegisterPage);
