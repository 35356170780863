import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  ButtonProps,
} from '@material-ui/core';
import * as UserProfileSelectors from '../../../../redux/reducers/userProfile_reducer/selectors';
import GridLayout from '../../../../components/molecules/grid_layout';
import {
  AppDatePicker,
  AppRadioGroup,
  AppTextField,
} from '../../../../components/molecules/form_controls';
import TranslatedText from '../../../../components/atoms/translated_text';
import { useForm } from 'react-hook-form';
import { Translations } from '../../configuration';
import { StateStatus } from '../../../../redux/utils/common';
import { Gender } from '../../../../config';
import CountriesSelect from './FormCountriesSelect';
import { useDispatch } from 'react-redux';
import UserProfileActions from '../../../../redux/reducers/userProfile_reducer/actions';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import './style.css';
const defaultValues = {
  firstName: null,
  surname: null,
  email: null,
  gender: null,
  nationality: null,
  registeredDate: null,
  role: null,
  birthDate: null,
};

interface IFormData {
  firstName: string | null;
  surname: string | null;
  email: string | null;
  gender: string | null;
  nationality: string | null;
  registeredDate: string | null;
  role: string | null;
  birthDate: string | null;
}

const useStyles = makeStyles(() => ({
  root: {},
}));

const ProfileDetails = () => {
  console.log('Render ProfileDetails:');
  const classes = useStyles();
  const dispatch = useDispatch();
  const userDetailsData = UserProfileSelectors.useGetUserProfile();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
  } = useForm<IFormData>({
    defaultValues: defaultValues,
  });

  React.useEffect(() => {
    if (userDetailsData.status === StateStatus.Success) {
      reset({
        firstName: userDetailsData.data?.user?.firstname || '',
        surname: userDetailsData.data?.user?.surname || '',
        email: userDetailsData.data?.user?.email || '',
        registeredDate: userDetailsData.data?.user?.registeredDate || null,
      });
    }
  }, [userDetailsData.status]);

  const onSubmit = (data: IFormData) => {
    // dispatch(
    //   UserProfileActions.editUserProfileAction({
    //     email: data.email || '',
    //     firstName: data.firstName || '',
    //     surname: data.surname || '',
    //     nationality: data.nationality || '',
    //     gender: data.gender || '',
    //     birthDate: data.birthDate || '',
    //   })
    // );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
      className={clsx(classes.root)}
    >
      <Card variant={'outlined'}>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <GridLayout
            elements={[
              {
                id: '1',
                size: 6,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    error={'firstName' in errors}
                    helperText={errors.firstName && errors.firstName.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="firstName"
                    control={control}
                    label={
                      <TranslatedText
                        defaultText={'Firstname'}
                        textMap={Translations.form_field_firstname}
                      />
                    }
                    autoComplete="firstName"
                    name="firstName"
                    icon={{
                      position: 'right',
                      Component: (
                        <KeyboardArrowDownIcon style={{ opacity: 0.5 }} />
                      ),
                    }}
                  />
                ),
              },
              {
                id: '2',
                size: 6,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    error={'surname' in errors}
                    helperText={errors.surname && errors.surname.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="surname"
                    control={control}
                    label={
                      <TranslatedText
                        defaultText={'Surname'}
                        textMap={Translations.form_field_surname}
                      />
                    }
                    autoComplete="surname"
                    name="surname"
                    type="email"
                    icon={{
                      position: 'right',
                      Component: (
                        <KeyboardArrowDownIcon style={{ opacity: 0.5 }} />
                      ),
                    }}
                  />
                ),
              },
              {
                id: '3',
                size: 6,
                element: (
                  <AppTextField
                    variant="outlined"
                    margin="normal"
                    error={'email' in errors}
                    helperText={errors.email && errors.email.message}
                    rules={{
                      required: <RequiredField />,
                    }}
                    required
                    fullWidth
                    id="email"
                    control={control}
                    label={
                      <TranslatedText
                        defaultText={'Email'}
                        textMap={Translations.form_field_email}
                      />
                    }
                    autoComplete="email"
                    name="email"
                    icon={{
                      position: 'right',
                      Component: (
                        <KeyboardArrowDownIcon style={{ opacity: 0.5 }} />
                      ),
                    }}
                  />
                ),
              },

              {
                id: '7',
                size: 6,
                element: (
                  <AppDatePicker
                    fullWidth
                    margin="normal"
                    disabled={true}
                    label={
                      <TranslatedText
                        defaultText={'Registered Date'}
                        textMap={Translations.form_field_registereddate}
                      />
                    }
                    autoOk
                    helperText={
                      errors.registeredDate && errors.registeredDate.message
                    }
                    error={'registeredDate' in errors}
                    control={control}
                    rules={{
                      required: false,
                    }}
                    name="registeredDate"
                  />
                ),
              },
              // {
              //   id: '8',
              //   size: 6,
              //   element: (
              //     <AppRadioGroup
              //       row
              //       helperText={errors.gender && errors.gender.message}
              //       error={'gender' in errors}
              //       control={control}
              //       rules={{
              //         required: <RequiredField />,
              //       }}
              //       values={Gender.GenderConfig}
              //       name="gender"
              //       label={
              //         <TranslatedText
              //           defaultText={'Gender'}
              //           textMap={Translations.form_field_gender}
              //         />
              //       }
              //     />
              //   ),
              // },
            ]}
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <SubmitWrapper />
        </Box>
      </Card>
    </form>
  );
};

const SubmitWrapper = React.memo(() => {
  const submitStatus = UserProfileSelectors.useGetEditUserStatus();

  return (
    <Button
      type={'submit'}
      color="primary"
      variant="contained"
      style={{ backgroundColor: '#D9A21B' }}
      disabled={submitStatus === StateStatus.Pending}
    >
      <TranslatedText
        defaultText={'Save details'}
        textMap={Translations.form_submit}
      />
    </Button>
  );
});

const RequiredField = () => {
  return (
    <TranslatedText
      textMap={Translations.form_error_required}
      defaultText={'Required Value'}
    />
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

export default ProfileDetails;
